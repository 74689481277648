type Error = string | undefined | null;

const errorTranslations = [
  {
    original: 'email: is invalid',
    replacement: '"email": "Email address is invalid"',
  },
  {
    original: 'pre_shared_key: is too weak',
    replacement: '"password": "Password is invalid"',
  },
  {
    original: 'pre_shared_key: is not valid, is too weak',
    replacement: '"password": "Password is invalid"',
  },
  {
    original: 'pre_shared_key: is not valid',
    replacement: '"password": "Password is invalid"',
  },
];

const parseError = (error: Error): Error =>
  !error
    ? error
    : error
        .split('\n')
        .map((e) => errorTranslations.find((t) => t.original === e)?.replacement || e)
        .join(', ');

export default parseError;
