import { FCWithChildren } from '@types';
import React from 'react';

import moment, { MomentInput } from 'moment';
import { DeviceStatus, DeviceTooltipStatus } from '../../constants';
import { Tooltip } from 'react-tooltip';
import { ToolTipRight } from '@components';
import useConnectionStatus from '@hooks/useConnectionStatus';

type StatusInfoProps = {
  lastLogin: MomentInput | undefined;
  status: string;
  id: number;
  openStatusInfoModal: (str: string) => void;
};

const StatusInfo: FCWithChildren<StatusInfoProps> = ({
  id,
  lastLogin,
  openStatusInfoModal,
  status,
}) => {
  const { data: statusDiagnostics = [] } = useConnectionStatus();

  const onClickHandler = () => openStatusInfoModal(status);
  const getStatusLabel = (status: string) => {
    const statusObject = statusDiagnostics.find(
      (item) => item.value.toLowerCase() === status.toLowerCase(),
    );

    return statusObject?.label ? statusObject.label : DeviceTooltipStatus[status];
  };

  return (
    <div className="text-sm">
      {status === DeviceStatus.ACTIVE ? 'Since:' : 'Last seen:'}
      <Tooltip id={`device-${id}`} place="top">
        {getStatusLabel(status)}
      </Tooltip>
      <ToolTipRight clickHandler={onClickHandler} type={status} />
      <div className="text-gray-600">
        {!moment.utc(lastLogin).isValid()
          ? '--'
          : moment.utc(lastLogin).local().format('MM/DD/YYYY H:mm')}
      </div>
    </div>
  );
};

export default StatusInfo;
