import { FCWithChildren } from '@types';
import React from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { Link } from '@components';

import { getHelpfulLinks, IHelpfulLink } from 'sdk-apogee';
import useLayoutContext from '@hooks/useLayoutContext';

type InformationProps = {
  currentPage: string;
};

const Information: FCWithChildren<InformationProps> = ({ currentPage }) => {
  const { subdomain } = useLayoutContext();
  const { data: helpfulLinks = [] } = useSuspenseQuery<IHelpfulLink[]>({
    queryKey: [subdomain],
    queryFn: () => getHelpfulLinks(subdomain),
  });

  const links = helpfulLinks?.filter(
    ({ page }) => page.toLowerCase() === currentPage.toLowerCase(),
  );

  return (
    <section className="w-full flex flex-col items-center overflow-hidden my-2 sm:pl-4 lg:my-2">
      <h2 className="my-2 text-base font-semibold">Helpful Links</h2>
      <div className="break-words w-full">
        {links &&
          links.map(({ id, label, url }) => (
            <Link key={id} url={url} buttonClassName="w-full" aria-label="Support">
              <div className="flex flex-wrap flex-row w-full">{label}</div>
            </Link>
          ))}
      </div>
    </section>
  );
};

export default Information;
