import { FCWithChildren } from '@types';
import React from 'react';

import { FaAngleRight } from 'react-icons/fa';

type BoxProps = {
  description: string;
  Icon: any;
  onClick?: () => void;
  title: string;
};

const Box: FCWithChildren<BoxProps> = ({ description, Icon, onClick, title }) => (
  <button
    className="w-full overflow-hidden px-8 py-4 border rounded-lg shadow-md cursor-pointer text-left focus:shadow-focus"
    onClick={onClick}
  >
    <div className="w-full flex justify-between items-center space-x-4">
      <div className="flex w-1/12">
        <Icon size={30} />
      </div>
      <div className="flex flex-col w-10/12">
        <h3>{title}</h3>
        <span className="text-sm">{description}</span>
      </div>
      <div className="flex w-1/12">
        <FaAngleRight size={30} />
      </div>
    </div>
  </button>
);

export default Box;
