/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment, useState, useEffect, useRef } from 'react';
import classname from 'classnames';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateSelectedPackages } from 'sdk-apogee';

import useLayoutContext from '@hooks/useLayoutContext';
import { Button, RequestManagerWrapper } from '@components';

import Plans from './components/Plans';
import { useHistory } from 'react-router-dom';
import useDevices from '@hooks/useDevices';

import MaxAllowedDevicesModal from './components/MaxAllowedDevicesModal';
import usePageTitle from '@hooks/usePageTitle';
import { FCWithChildren } from '@types';

type Service = {
  id: string;
  planName: string;
  planType: string;
  price: string;
  adjustedPrice: string;
  maxDevices: number;
  internet: boolean;
  isCancellationPlan: boolean;
};

type SelectedServices = {
  [key: string]: Service;
};

const packageNewAmount = (newPackage: Service, oldPackages: SelectedServices) => {
  if (
    Object.entries(oldPackages).find(
      ([_, service]) =>
        service.planType === newPackage.planType &&
        Number(service.price) <= Number(newPackage.price),
    )
  ) {
    return '0.00';
  }
  return newPackage?.price;
};

const calculateTotal = (services: SelectedServices, currentServices: SelectedServices) => {
  if (!services) return 0;

  const result = Object.entries(services).reduce(
    (total, [_, value]) => total + Number(value ? packageNewAmount(value, currentServices) : 0),
    0,
  );

  return result.toFixed(2);
};

const Services: FCWithChildren<{}> = () => {
  usePageTitle('Upgrade Service');
  const { isMobile, largeHeroImage } = useLayoutContext();
  const history = useHistory();
  const queryClient = useQueryClient();
  const stickyTop = useRef<HTMLDivElement | null>(null);
  const [isPositionedAtBottom, setIsPositionedAtBottom] = useState(false);
  const [selectedServices, setSelectedServices] = useState<SelectedServices>({});
  const [currentSelectedPackages, setCurrentSelectedPackages] = useState({});
  const [disableCheckout, setDisableCheckout] = useState(true);
  const { data } = useDevices();
  const internetService = () => {
    const serviceItem = Object.entries(selectedServices).find(([_, service]) => service.internet);
    return serviceItem && serviceItem[1];
  };
  const hasReachedMaxQtyDevices = () => {
    const numDevices = data?.length || 0;
    const service = internetService();
    const maxDevices = service?.maxDevices || 0;
    return (service && numDevices > maxDevices && maxDevices > 0) || false;
  };
  const [maxQtyDevicesModal, setMaxQtyDevicesModal] = useState(false);

  useEffect(() => {
    if (!isMobile) window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedServices) {
      const selectedPackageIds = Object.values(selectedServices).map(({ id }) => Number(id));
      const selectedPackages = Object.values<any>(currentSelectedPackages);
      const currentSelectedPackageIds = selectedPackages.map(({ id }) => Number(id));
      const isSame =
        selectedPackageIds.length === currentSelectedPackageIds.length &&
        currentSelectedPackageIds.every(function (element, index) {
          return element === selectedPackageIds[index];
        });
      setDisableCheckout(isSame);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServices, currentSelectedPackages]);

  useEffect(() => {
    const onScroll = () => {
      const stickyTopEdge = (stickyTop.current && stickyTop.current?.offsetTop) as number;
      const stickyHeight = (stickyTop.current && stickyTop.current?.offsetHeight) as number;

      const isAtBottomEdge =
        window.pageYOffset + window.screen.height >= stickyHeight + stickyTopEdge;

      isAtBottomEdge ? setIsPositionedAtBottom(true) : setIsPositionedAtBottom(false);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  });

  const { mutate: updateSelection, isPending } = useMutation({
    mutationFn: (ids: number[]) => updateSelectedPackages(ids),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['selected-packages'] });
    },
  });

  const handleCheckout = () => {
    if (hasReachedMaxQtyDevices()) {
      setMaxQtyDevicesModal(true);
    } else {
      history.push('/checkout', { selectedServices: selectedServices });
    }
  };

  const closeMaxQtyDevicesModal = () => setMaxQtyDevicesModal(false);

  const onClickHandler = (selection: Service) => {
    const [key, value]: any = Object.entries(selection).flat();
    setSelectedServices((prev) => ({ ...prev, [key]: value }));
  };

  const mobileCheckoutCls = classname(
    'h-24 w-screen flex justify-between items-center py-8 px-6 shadow-custom',
    `${isPositionedAtBottom ? '-mx-4 shadow-md' : 'fixed bg-white shadow-md left-0 bottom-0'}`,
  );
  const desktopCheckoutCls = 'flex flex-col items-end py-8';

  const checkoutCls = isMobile ? mobileCheckoutCls : desktopCheckoutCls;

  const backgroundColor = classname(
    'h-64 opacity-75 flex flex-col justify-center px-4 sm:px-6 lg:px-40 xl:px-40 -mx-4 sm:-mx-6 lg:-mx-40 xl:-mx-40',
    largeHeroImage ? 'bg-cover bg-center text-white' : 'bg-gray-300 text-black',
  );

  const landingContainerCls = isMobile ? 'w-full pt-8' : backgroundColor;

  return (
    <Fragment>
      <MaxAllowedDevicesModal isOpen={maxQtyDevicesModal} closeModal={closeMaxQtyDevicesModal} />

      <div
        className={landingContainerCls}
        style={isMobile ? {} : { backgroundImage: `url(${largeHeroImage})` }}
        ref={stickyTop}
      >
        <h1 className="text-3xl mb-4 sm:text-4xl">Upgrade Service</h1>
        <div className="text-lg font-thin sm:text-lg sm:font-normal">
          Add more devices. Enjoy faster speeds.
        </div>
        <div className="text-lg font-thin sm:text-lg sm:font-normal">Enjoy more bandwidth.</div>
      </div>
      <div className="sm:py-10 text-black">
        <RequestManagerWrapper>
          <Plans
            isMobile={isMobile}
            onClickHandler={onClickHandler}
            updateSelectedServices={setSelectedServices}
            selectedServices={selectedServices}
            setCurrentSelectedPackages={setCurrentSelectedPackages}
            currentSelectedPackages={currentSelectedPackages}
          />
        </RequestManagerWrapper>

        <div
          ref={stickyTop}
          className={`bg-transparent w-screen flex justify-between items-center -mx-4 ${
            isPositionedAtBottom ? 'h-0' : 'h-24'
          }`}
        />

        <div className={checkoutCls}>
          {!disableCheckout && (
            <>
              <span className="py-4 pt-0 text-base sm:text-xl">
                {`New Selection: $${calculateTotal(selectedServices, currentSelectedPackages)}`}
              </span>
              <span className="py-4 pt-0 text-xs">
                If a refund is due, that will be shown in the next checkout screen
              </span>
            </>
          )}
          <Button
            disabled={isPending || disableCheckout}
            layout="primary"
            loading={isPending}
            onClick={handleCheckout}
          >
            Checkout
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default Services;
