import { FCWithChildren } from '@types';
import React from 'react';

import { Button } from '@components';

type LoadingErrorMessageProps = {
  error: any;
  resetErrorBoundary: any;
};

const LoadingErrorMessage: FCWithChildren<LoadingErrorMessageProps> = ({
  error,
  resetErrorBoundary,
}) => {
  return (
    <div className="flex flex-col w-full my-12 items-center justify-center">
      <span>There was an error</span>
      <pre className="mb-8">{(error as any).message}</pre>
      <Button onClick={resetErrorBoundary}>Try again</Button>
    </div>
  );
};

export default LoadingErrorMessage;
