import { FCWithChildren } from '@types';
import React from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToasts } from 'react-toast-notifications';

import { removeDevice } from 'sdk-apogee';

import { Button, Modal, ModalBody } from '@components';

type RemoveDeviceModalProps = {
  closeModal: () => void;
  id: number;
  isOpen: boolean;
};

const RemoveDeviceModal: FCWithChildren<RemoveDeviceModalProps> = ({ closeModal, id, isOpen }) => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const { mutate: remove, isPending } = useMutation({
    mutationFn: (deviceId: number) => removeDevice(deviceId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['devices'] });
      addToast('Device deleted successfully', { appearance: 'success' });
      closeModal();
    },
    onError: () => addToast('An error occurred deleting your device', { appearance: 'error' }),
  });

  const handleRemoveDevice = () => {
    remove(id);
  };

  return (
    <Modal closeModal={closeModal} isOpen={isOpen} noHeader>
      <ModalBody>
        <section className="flex flex-col justify-center items-center">
          <div>Delete this device from account?</div>
          <div className="mt-8">
            <Button className="mx-2" layout="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button disabled={isPending} loading={isPending} onClick={handleRemoveDevice}>
              Delete
            </Button>
          </div>
        </section>
      </ModalBody>
    </Modal>
  );
};

export default RemoveDeviceModal;
