import { IBuilding } from 'sdk-apogee';

const outageNotificationValidator = (user, data) => {
  let buildingNameFromUser = '';
  let outageBuildingLocation = '';
  let hasNotification = false;
  if (user && user.buildingId) {
    outageBuildingLocation = data[0]['location'];

    (user.customer.buildings as IBuilding[]).map(function (building) {
      if (user.buildingId === building.id) {
        buildingNameFromUser = building.name;
      }
    });
    const buildingName = outageBuildingLocation.split(': ')[1];
    if (buildingName === buildingNameFromUser) {
      hasNotification = true;
    }
  } else {
    hasNotification = true;
  }
  return hasNotification;
};

export default outageNotificationValidator;
