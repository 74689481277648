import { FCWithChildren } from '@types';
import React from 'react';

import activeIcon from '@assets/images/connected-status-icon.png';
import disconnectedIcon from '@assets/images/disconnected-status-icon.png';
import inactiveIcon from '@assets/images/inactive-status-icon.png';
import quarantinedIcon from '@assets/images/quarantined-status-icon.png';
import notOnNetworkIcon from '@assets/images/not-on-network-status-icon.png';

type StatusLedProps = {
  dataTip?: boolean;
  dataFor?: string;
  type: string;
};

type Status = 'active' | 'disconnected' | 'inactive' | 'quarantined' | 'unknown';

const ICON_TYPES: Record<Status, string> = {
  active: activeIcon,
  disconnected: disconnectedIcon,
  inactive: inactiveIcon,
  quarantined: quarantinedIcon,
  unknown: notOnNetworkIcon,
};

const StatusLed: FCWithChildren<StatusLedProps> = ({ dataTip, dataFor, type }) => {
  const props = {
    'data-tip': dataTip,
    'data-for': dataFor,
  };

  return (
    <div className="flex justify-center items-center w-6 h-6 mr-2" {...props}>
      <img alt={type} src={ICON_TYPES[type as Status]} />
    </div>
  );
};

export default StatusLed;
