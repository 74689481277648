import React from 'react';

import { LoginForm } from '@components';
import { FCWithChildren } from '@types';

const SignIn: FCWithChildren<{}> = () => (
  <div className="flex flex-col justify-start items-center w-1/2 my-4 h-64 bg-white">
    <div className="flex flex-col items-start md:w-4/5 md:max-w-lg">
      <span className="font-bold text-sm">Have an Account?</span>
      <LoginForm formStyle="w-full" />
    </div>
  </div>
);

export default SignIn;
