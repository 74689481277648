import { FCWithChildren } from '@types';
/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import { RequestManagerWrapper, ModalWifiEnabled, ModalRandomMacWarning } from '@components';
import useLayoutContext from '@hooks/useLayoutContext';
import { useAppContext } from '@contexts/AppContext';

import { ContactUs } from '@components';

import Welcome from './components/Welcome';
import QuestionsAndAnswers from './components/QuestionsAndAnswers';
import Stream2 from './components/Stream2';
import { useQueryClient } from '@tanstack/react-query';
import usePageTitle from '@hooks/usePageTitle';

type PreAuthenticationProps = {
  location?: {
    state: {
      isUserCreated: boolean;
      preWiredKey?: string;
    };
  };
};

const PreAuthentication: FCWithChildren<PreAuthenticationProps> = ({ location }) => {
  usePageTitle();
  const { isMobile, largeHeroImage, pskEnabled, subdomain, welcomeMessage } = useLayoutContext();
  const { isAuthenticated } = useAppContext();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const [isWifiEnabledModalOpen, setIsWifiEnabledModalOpen] = useState(false);
  const [isRandomMacWarningOpen, setIsRandomMacWarningOpen] = useState(false);

  const isUserCreated = location?.state?.isUserCreated;
  const preWiredKey = location?.state?.preWiredKey;

  useEffect(() => {
    // if (isAuthenticated) history.push('/home');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    const isRandomMac =
      sessionStorage.getItem('mac') && !/^.[^2367ABEF]/i.test(sessionStorage.getItem('mac') || '');
    if (pskEnabled) {
      setIsRandomMacWarningOpen(false);
    } else if (isRandomMac) {
      setIsRandomMacWarningOpen(true);
      setIsWifiEnabledModalOpen(false);
    }
  }, [pskEnabled]);

  useEffect(() => {
    if (isRandomMacWarningOpen) {
      setIsWifiEnabledModalOpen(false);
    } else if (isUserCreated) {
      addToast('User created successfully', { appearance: 'success' });
      setIsWifiEnabledModalOpen(true);
    }
  }, [isUserCreated, isRandomMacWarningOpen]);

  useEffect(() => {
    window.scrollTo(0, 0);
    queryClient.invalidateQueries({ queryKey: ['devices'] });
    queryClient.invalidateQueries({ queryKey: ['profile'] });
    queryClient.invalidateQueries({ queryKey: ['selected-packages'] });
  }, []);

  const welcomeTxt = welcomeMessage as string;

  return (
    <Fragment>
      <ModalWifiEnabled
        isOpen={isWifiEnabledModalOpen}
        onClose={() => {
          setIsWifiEnabledModalOpen(false);
        }}
        preWiredKey={preWiredKey}
      />
      <ModalRandomMacWarning
        isOpen={isRandomMacWarningOpen}
        onClose={() => {
          setIsRandomMacWarningOpen(false);
        }}
      />
      <Welcome welcome={welcomeTxt} bgImg={largeHeroImage || ''} />
      {subdomain?.length > 0 && (
        <RequestManagerWrapper>
          <QuestionsAndAnswers subdomain={subdomain} />
        </RequestManagerWrapper>
      )}
      <Stream2 isMobile={isMobile} />
      <ContactUs showApogeeText redirectToSupportPage={true} />
    </Fragment>
  );
};

export default PreAuthentication;
