import React, { useMemo } from 'react';

import { RequestManagerWrapper } from '@components';
import UserInfo from '@pages/UserInfo';
import { useLocation } from 'react-router-dom';
import usePageTitle from '@hooks/usePageTitle';
import { FCWithChildren } from '@types';

const Register: FCWithChildren<{}> = () => {
  usePageTitle('Complete Profile');
  const { state }: any = useLocation();
  const isCsrCreated = useMemo(() => !!state?.csrCreated, []);
  const csrToken = useMemo(() => state?.csrToken, []);

  return (
    <RequestManagerWrapper>
      <UserInfo
        path="/complete-profile"
        isRegistering={isCsrCreated}
        isCsrCreated={isCsrCreated}
        csrToken={csrToken}
      />
    </RequestManagerWrapper>
  );
};

export default Register;
