import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@components';

const NotFound = () => (
  <div className="w-full my-20 flex flex-col justify-center items-center">
    <div>
      <h1 className="text-6xl">404</h1>
      <span>Page not Found</span>
    </div>
    <div className="w-1/3 my-12 lg:w-1/5">
      <Link to="/">
        <Button block layout="secondary">
          Return Home{' '}
        </Button>
      </Link>
    </div>
  </div>
);

export default NotFound;
