"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var updateNotification = function (_a) {
    var notifId = _a.notifId, actionType = _a.actionType;
    return (0, service_1.getService)()
        .patch("/notifications/".concat(notifId), { actionType: actionType })
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.default = updateNotification;
