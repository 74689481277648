import escapeHtml from 'escape-html';
import { Text } from 'slate';

const serialize = (node: any) => {
  if (Text.isText(node)) {
    return escapeHtml(node.text);
  }

  const children = node.nodes.map((n: any) => serialize(n)).join('');

  switch (node.type) {
    case 'block':
      return `<div>${children}</div>`;
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`;
    case 'paragraph':
      return `<p class="font-light text-sm md:text-base mt-12">${children}</p>`;
    case 'list-item':
      return `<li class="py-2">${children}</li>`;
    case 'link':
      return `<a href="${node.data.href}" class="focus:shadow-focus"><span class="text-blue-700 underline font-semibold">${children}<span></a>`;
    case 'ordered-list':
      return `<ul class="list-decimal">${children}</li>`;
    case 'unordered-list':
      return `<ul class="list-disc py-1 sm:py-4">${children}</ul>`;
    case 'image':
      return node.data.alt
        ? `<img src="${node.data.src}" alt="${node.data.alt}">${children}</img>`
        : `<img src="${node.data.src}">${children}</img>`;
    default:
      return children;
  }
};

export default serialize;
