import React, { forwardRef } from 'react';

import Button from '../Button';

const DropdownItem = forwardRef<any, any>(function DropdownItem(props, ref) {
  const { className, children, ...other } = props;

  const baseStyle = 'mb-2 last:mb-0';

  return (
    <li className={baseStyle}>
      <Button layout="__dropdownItem" ref={ref} className={className} {...other}>
        {children}
      </Button>
    </li>
  );
});

export default DropdownItem;
