import { FCWithChildren } from '@types';
import React, { useState } from 'react';
import classNames from 'classnames';

import { Button, Input } from '@components';

import { AiFillCloseCircle, AiOutlineSearch } from 'react-icons/ai';

type SearchBarWMagnifierProps = {
  input: string;
  // eslint-disable-next-line no-unused-vars
  onChangehandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearSearchHandler?: () => void;
  onKeyDownHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  magnifierRight?: boolean;
  placeholder?: string;
  showClearSearch?: boolean;
};

const SearchBarWMagnifier: FCWithChildren<SearchBarWMagnifierProps> = ({
  input,
  onChangehandler,
  onClearSearchHandler,
  onKeyDownHandler,
  magnifierRight = false,
  placeholder = '',
  showClearSearch = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const containerCls = classNames(
    'flex items-center w-full rounded bg-white border-2 text-black',
    magnifierRight ? 'flex-row-reverse pr-2' : 'pl-2',
    isFocused ? 'border-blue-400' : 'border-grey-300',
  );

  const onFocusHandler = () => setIsFocused(true);

  const onBlurHandler = () => setIsFocused(false);

  return (
    <div className={containerCls}>
      <AiOutlineSearch className="text-xl" color="gray" />
      <Input
        noBorder
        name="search"
        type="text"
        value={input}
        onChange={onChangehandler}
        onKeyPress={onKeyDownHandler}
        placeholder={placeholder}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        className="text-black"
        aria-label="Search"
      />
      {showClearSearch && (
        <Button
          aria-label="Clear search"
          layout="link"
          className="right-2"
          style={{ padding: 4 }}
          onClick={typeof onClearSearchHandler === 'function' ? onClearSearchHandler : () => {}}
        >
          <AiFillCloseCircle className="text-gray-500" />
        </Button>
      )}
    </div>
  );
};
export default SearchBarWMagnifier;
