import base from './base';
import dark from './dark';
import university from './mockUniversity';
import { IThemes } from './utils';

export const DEFAULT_THEME: string = 'base';

export const themes: IThemes = {
  base,
  dark,
  university,
};
