"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var updateCampusDevice = function (id, device) {
    return (0, service_1.getService)()
        .patch("/managed_campus/devices/".concat(id), device)
        .then(function (_a) {
        var data = _a.data;
        return data;
    })
        .catch(function (_a) {
        var response = _a.response;
        return Promise.reject(response.data);
    });
};
exports.default = updateCampusDevice;
