import { FCWithChildren } from '@types';
import React from 'react';
import { Modal, ModalBody } from '@components';
import { useHistory } from 'react-router-dom';
import { Button } from '@components';
import { useMutation } from '@tanstack/react-query';
import { forgotPassword } from 'sdk-apogee';
import { useToasts } from 'react-toast-notifications';
import { getSubdomain, getURL } from '@utils';

const URL = getURL();
const SUBDOMAIN = getSubdomain();

type ModalAccountFoundProps = {
  isOpen: boolean;
  onClose: () => void;
  email: string;
};

const ModalAccountFound: FCWithChildren<ModalAccountFoundProps> = ({ isOpen, onClose, email }) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { mutate: requestNewPassword, isPending } = useMutation({
    mutationFn: (email: string) => forgotPassword({ email, url: URL, subdomain: SUBDOMAIN }),
    onSuccess: () => {
      history.push({
        pathname: '/',
        state: { message: 'Email Sent', appearance: 'success' },
      });
    },
    onError: (apiError: any) => {
      addToast(apiError?.data?.error || 'An unknown error occurred', { appearance: 'error' });
    },
  });

  return (
    <Modal customWebClass="w-3/4" maxWidthClass="max-w-90" closeModal={onClose} isOpen={isOpen}>
      <ModalBody>
        <div className="mb-2">
          <h3 className="text-2xl">Account Found</h3>
        </div>
        <p>
          This email address is already associated with an account at this site. Click "Reset" to
          receive a password reset link emailed to {email}.
        </p>
        <div className="flex flex-row-reverse justify-between gap-16 mt-8">
          <Button
            className="flex-grow"
            loading={isPending}
            onClick={() => {
              requestNewPassword(email);
            }}
          >
            Reset
          </Button>
          <Button className="flex-grow" layout="outline" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalAccountFound;
