import React from 'react';
import classNames from 'classnames';

interface Props extends React.HTMLAttributes<HTMLLabelElement> {
  check?: boolean;
  radio?: boolean;
  disabled?: boolean;
  htmlFor?: string;
}

const Label = React.forwardRef<HTMLLabelElement, Props>(function Label(props, ref) {
  const { children, check, radio, disabled, className, htmlFor, ...other } = props;

  const baseStyle = 'block text-sm text-gray-700';
  const checkStyle = 'inline-flex items-center';
  const disabledStyle = 'opacity-50 cursor-not-allowed';

  const cls = classNames(
    baseStyle,
    (check || radio) && checkStyle,
    disabled && disabledStyle,
    className,
  );

  return (
    // eslint-disable-next-line
    <label className={cls} ref={ref} {...other} htmlFor={htmlFor}>
      {children}
    </label>
  );
});

export default Label;
