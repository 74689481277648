import React from 'react';

import { MdImportantDevices } from 'react-icons/md';
import { FCWithChildren } from '@types';

const EmptyDevices: FCWithChildren<{}> = () => (
  <div className="w-full py-12 flex flex-col items-center border rounded-lg shadow-md">
    <div className="rounded-full h-32 w-32 bg-gray-300 flex items-center justify-center">
      <MdImportantDevices className="text-gray-400" size={50} />
    </div>
    <h1 className="text-xl text-gray-600 font-semibold">Registered Devices</h1>
    <p className="text-base text-gray-600">You do not have any devices.</p>
    <p className="text-base text-gray-600">Click the add button to start.</p>
  </div>
);

export default EmptyDevices;
