import { FCWithChildren } from '@types';
import React from 'react';

import { ISupportQuestions } from 'sdk-apogee';

type MobileSectionsListProps = {
  list: ISupportQuestions[];
  // eslint-disable-next-line no-unused-vars
  onClickHandler: (reference: React.MutableRefObject<HTMLElement | null>) => void;
  refsContainer: {
    current: Record<string, React.MutableRefObject<HTMLElement | null>>;
  };
};

const MobileTopicsList: FCWithChildren<MobileSectionsListProps> = ({
  list,
  refsContainer,
  onClickHandler,
}) => {
  return (
    <div className="divide-x-2 divide-gray-300 whitespace-no-wrap border-b border-gray-300 bg-gray-100 w-screen h-8 overflow-x-scroll scrolling-touch -mx-4 sm:-mx-6 lg:-mx-40 xl:-mx-40">
      {list.map(({ id, topic }) => (
        <button
          key={id}
          className="underline cursor-pointer focus:outline-none text-xs text-thin place-self-center px-4 mt-1 h-6"
          onClick={() => onClickHandler(refsContainer?.current[id])}
          onKeyPress={() => onClickHandler(refsContainer?.current[id])}
        >
          {topic}
        </button>
      ))}
    </div>
  );
};

export default MobileTopicsList;
