import { FCWithChildren } from '@types';
import React from 'react';
import { Button } from '@components';
import { useToasts } from 'react-toast-notifications';
import useLayoutContext from '@hooks/useLayoutContext';
import { CgPassword } from 'react-icons/cg';
import { MdAddBox, MdAutorenew, MdDevicesOther, MdSearch, MdWifi } from 'react-icons/md';

type OtherBodyProps = {
  preWiredKey?: string;
};

const OtherBody: FCWithChildren<OtherBodyProps> = ({ preWiredKey }) => {
  const { ssid } = useLayoutContext();
  const { addToast } = useToasts();

  return (
    <div>
      <div className="flex gap-4 p-1">
        <div className="w-10 h-10 text-center font-bold">
          <MdSearch className="text-3xl text-primary" />
        </div>
        <div>
          <p>
            Find the MAC address of your device
            <br />
            <b>Note:</b> Turn off Random or Private setting to get the static address
          </p>
          <br />
        </div>
      </div>
      <div className="flex gap-4 p-1">
        <div className="w-10 h-10 text-center font-bold">
          <MdAddBox className="text-3xl text-primary" />
        </div>
        <div>
          <p>
            Log into <b className="text-primary font-bold break-all">{window.location.host}</b> and
            click on <b className="text-primary">"Add Device"</b>
          </p>
          <br />
        </div>
      </div>
      <div className="flex gap-4 p-1">
        <div className="w-10 h-10 text-center font-bold">
          <MdDevicesOther className="text-3xl text-primary" />
        </div>
        <div>
          <p>Select device type, then enter MAC address</p>
          <br />
        </div>
      </div>
      <div className="flex gap-4 p-1">
        <div className="w-10 h-10 text-center font-bold">
          <MdWifi className="text-3xl text-primary" />
        </div>
        <div className="w-full">
          After device is added, then go to that device and select network
          <br />
          <div className="text-primary font-bold break-all text-3xl">{ssid}</div>
        </div>
      </div>
      <div className="flex gap-4 p-1">
        <div className="w-10 h-10 text-center font-bold">
          <CgPassword className="text-3xl text-primary" />
        </div>
        <div className="w-full">
          Enter your WiFi Password/PSK for the network
          <br />
          <div className="flex text-3xl flex-wrap items-center">
            <div className="text-primary font-bold break-all mr-4">{preWiredKey}</div>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(preWiredKey || '');
                addToast('Wi-Fi password copied', { appearance: 'success' });
              }}
              size="small"
            >
              Copy
            </Button>
          </div>
        </div>
      </div>
      <div className="flex gap-4 p-1">
        <div className="w-10 h-10 text-center font-bold">
          <MdAutorenew className="text-3xl text-primary" />
        </div>
        <div>
          <p>
            Select <b className="text-primary">"Auto-reconnect"</b> option for MyResNet
          </p>
          <br />
        </div>
      </div>
      <p>Device registration is required for wired connections, as well.</p>
    </div>
  );
};

export default OtherBody;
