import React, { useEffect } from 'react';

import { MdFileUpload } from 'react-icons/md';
import { Button } from '@components';
import { useQuery } from '@tanstack/react-query';
import { exportCampusDevices, ICampusDevicesExport } from 'sdk-apogee';
import { useToasts } from 'react-toast-notifications';
import { FCWithChildren } from '@types';

const ExportCSV: FCWithChildren<{}> = () => {
  const {
    data,
    refetch: exportCSV,
    isFetching,
    status,
  } = useQuery({
    queryKey: ['exportCampusDevices'],
    queryFn: exportCampusDevices,
    enabled: false,
    gcTime: 0,
  });
  const { addToast } = useToasts();

  useEffect(() => {
    if (status === 'success') {
      const filePath = (data as ICampusDevicesExport).filePath;
      if (filePath) {
        window.open(filePath, '_blank');
        addToast('CSV exported successfully', { appearance: 'success' });
      } else {
        addToast('An error occurred while exporting CSV. Please try again', {
          appearance: 'error',
        });
      }
    } else if (status === 'error') {
      addToast('An error occurred while exporting CSV. Please try again', { appearance: 'error' });
    }
  }, [data, status, addToast]);

  return (
    <div className="flex flex-row w-full justify-end">
      <Button layout="link" onClick={exportCSV} loading={isFetching}>
        <MdFileUpload className="text-base mr-1" />
        Export CSV
      </Button>
    </div>
  );
};

export default ExportCSV;
