import { FCWithChildren } from '@types';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useAppContext } from '@contexts/AppContext';
import { Button } from '@components';
import useLogout from '@hooks/useLogout';

type SectionObject = {
  title: string;
  ref: React.MutableRefObject<HTMLElement | null>;
};

type AccountSectionsListProps = {
  accountSections: {
    logInInfo: SectionObject;
    personalInfo: SectionObject;
    servicesPackages: SectionObject;
    preferences: SectionObject;
  };
  // eslint-disable-next-line no-unused-vars
  onClickHandler: (reference: React.MutableRefObject<HTMLElement | null>) => void;
};

const AccountSectionsList: FCWithChildren<AccountSectionsListProps> = ({
  accountSections,
  onClickHandler,
}) => {
  const { logout } = useLogout();
  const { setIsAuthenticated } = useAppContext();

  const stickyTop = useRef<HTMLDivElement | null>(null);
  const container = useRef<HTMLDivElement | null>(null);
  const [isPositionedAtBottom, setIsPositionedAtBottom] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const containerHeight = (container.current && container.current?.offsetHeight) as number;
      const stickyHeight = (stickyTop.current && stickyTop.current?.offsetHeight) as number;

      const isAtBottomEdge = window.pageYOffset >= containerHeight - stickyHeight;

      isAtBottomEdge ? setIsPositionedAtBottom(true) : setIsPositionedAtBottom(false);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  });

  const signOut = () => {
    setIsAuthenticated(false);
    logout();
  };

  const stickyCls = classNames('pt-20', isPositionedAtBottom ? 'absolute bottom-0' : 'fixed');

  return (
    <div className="w-1/3 flex flex-col items-start relative" ref={container}>
      <div className={stickyCls} ref={stickyTop}>
        <div className="w-full flex flex-col">
          <h1 className="font-black text-2xl pt-8">Account</h1>
          <span className="font-black pt-12">On this Page:</span>
        </div>
        <ul className="pt-4 pb-8">
          {Object.entries(accountSections).map(([key, val]) => (
            <li key={key + val.title}>
              <button
                className="my-2 underline cursor-pointer focus:shadow-focus rounded "
                onClick={() => onClickHandler(val.ref)}
                onKeyPress={() => onClickHandler(val.ref)}
              >
                {val.title}
              </button>
            </li>
          ))}
          <li>
            <Button className="mt-8" layout="link" onClick={signOut} size="noPadding">
              <span className="text-blue-700 font-semibold">Sign Out</span>
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AccountSectionsList;
