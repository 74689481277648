"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var getPackages = function () {
    return (0, service_1.getService)()
        .get('/packages/available_packages')
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.default = getPackages;
