import { FCWithChildren } from '@types';
import React, { useEffect, useState, Fragment, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classname from 'classnames';

import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';

import {
  login,
  service,
  validateEmailDomain,
  forgotPassword,
  // @ts-ignore
  IPasswordReset,
  resetPassword,
} from 'sdk-apogee';

import { getSubdomain } from '@utils';

import { Button, Input, Label, NetworkError, HelperText } from '@components';
import { useAppContext } from '@contexts/AppContext';
import useLayoutContext from '@hooks/useLayoutContext';

import { getURL, validateEmail } from '@utils';

const LOGIN = 'LOGIN';
const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const RETRIEVE_PROFILE = 'RETRIEVE_PROFILE';
const CHECK_IN_EMAIL = 'CHECK_IN_EMAIL';
const RESET_PASSWORD = 'RESET_PASSWORD';
const PASSWORD_UPDATED = 'PASSWORD_UPDATED';
const URL = getURL();
const SUBDOMAIN = getSubdomain();

type LoginFormProps = {
  formStyle?: string;
  option?: string;
  setNextOption?: (option: string) => void;
  isCreatingNewPassword?: boolean;
  checkEmail?: boolean;
};

const LoginForm: FCWithChildren<LoginFormProps> = ({
  formStyle = '',
  option = LOGIN,
  setNextOption = (option: string) => {},
  checkEmail = false,
}) => {
  const history = useHistory();
  const { saveUser } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);
  const [errorInfo, setErrorInfo] = useState();
  const { subdomain, usernameLabel, passwordLabel, eduOnly } = useLayoutContext();
  const { addToast } = useToasts();
  const resetInputRef = useRef<HTMLInputElement>(null);

  const { isSam } = useLayoutContext();
  const { logInRedirect } = useLayoutContext();

  const search = useLocation().search;

  const {
    getValues,
    handleSubmit,
    register,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const openModal = () => setIsOpen(true);

  const errorHandler = (apiError: any) => {
    setErrorInfo(apiError.response);
    openModal();
  };
  const { isLoading, error, refetch } = useQuery({
    queryKey: ['sign-in'],
    queryFn: () => {
      const userForm = getValues();
      const user = { ...userForm, subdomain };

      return login({ user }).catch(errorHandler);
    },
    enabled: false,
    retry: (_, apiError: any) => apiError.response.status !== 401,
  });

  const { mutate: requestNewPassword } = useMutation({
    mutationFn: (email: string) => forgotPassword({ email, url: URL, subdomain: SUBDOMAIN }),
    onSuccess: () => {
      forgotPasswordAction();
    },
    onError: (apiError: any) => {
      addToast(apiError?.data?.error || 'An unknown error occurred', { appearance: 'error' });
    },
  });

  const { mutate: createNewPassword } = useMutation({
    mutationFn: (newData: IPasswordReset) => resetPassword(newData),
    onSuccess: () => {
      setNextOption(PASSWORD_UPDATED);
    },
    onError: (apiError: any) => {
      addToast(apiError?.data?.error || 'An unknown error occurred', { appearance: 'error' });
    },
  });

  const onSubmit = async () => {
    const response = (await refetch()) as any;

    if (response?.authToken) {
      saveUser(response);
      service.interceptors.request.use((config: any) => {
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${response.authToken}`,
          },
        };
      });

      localStorage.setItem('token', response.authToken);
      history.push('/home');
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onSubmit();
      }
    };

    const token = new URLSearchParams(search).get('token');
    if (token) {
      sessionStorage.setItem('resetToken', token);
    }

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    setErrorInfo(undefined);
    setIsOpen(false);
  };

  const isValid = (input: string, isController: boolean = false): boolean =>
    isController ? !(errors as any)[input]?.value?.message : !(errors as any)[input]?.message;

  const focusInput = () => resetInputRef && resetInputRef.current?.focus();

  const onClickHandler = () => {
    if (option === RESET_PASSWORD) {
      const { username, password } = getValues();
      if (username !== password) {
        addToast('Check password and confirmation password are the same', { appearance: 'error' });
      } else {
        const newPasswordData = {
          password: username,
          passwordConfirmation: password,
          token: sessionStorage.getItem('resetToken') || '',
        };
        createNewPassword(newPasswordData);
      }
      return;
    }

    if (checkEmail && validateEmail(getValues().username) === false) {
      setError('username', {
        type: 'address',
        message: 'Please verify email address',
      });

      focusInput();
      return;
    }

    if (checkEmail && eduOnly) {
      const isEduDomain = validateEmailDomain({ email: getValues().username, domain: 'edu' });

      if (!isEduDomain) {
        setError('username', {
          type: 'domain',
          message: 'Email domain must be .edu',
        });

        addToast('Check email field', { appearance: 'error' });
        return;
      }
    }

    if (option === LOGIN) {
      if (getValues().username.trim() === '' || getValues().password.trim() === '') {
        if (getValues().username.trim() === '') {
          setError('username', {
            type: 'username',
            message: 'Username is required',
          });
        }

        if (getValues().password.trim() === '') {
          setError('password', {
            type: 'password',
            message: 'Password is required',
          });
        }
      } else {
        handleSubmit(onSubmit)();
      }
    } else if ([FORGOT_PASSWORD, RETRIEVE_PROFILE].includes(option)) {
      requestNewPassword(getValues().username);
    }
  };

  const forgotPasswordAction = () => {
    reset({ username: '' });
    setNextOption(CHECK_IN_EMAIL);
  };

  if (error) return <div>An error has occurred:</div>;

  const LinkTo = ({
    txt,
    onClickHandler,
    customCls,
  }: {
    txt: string;
    onClickHandler: () => void;
    customCls?: string;
  }) => {
    const cls = classname('text-blue-500 cursor-pointer', customCls);
    return (
      <Button layout="link" size="noPadding" className={cls} onClick={onClickHandler}>
        <span>{txt}</span>
      </Button>
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onClickHandler();
    }
  };

  const goToLogin = () => {
    if (!isSam) {
      const url = logInRedirect && logInRedirect();

      return window.location.assign(url as string);
    } else {
      return history.push('/login');
    }
  };

  const getForm = () => {
    switch (option) {
      case LOGIN:
        return (
          <Fragment>
            <Label className="my-4">
              <span>{usernameLabel}</span>
              <Input
                className="my-1"
                {...register('username')}
                placeholder={usernameLabel}
                type="text"
                onKeyDown={handleKeyDown}
              />
              <HelperText valid={isValid('username')}>{errors.username?.message}</HelperText>
            </Label>
            <Label className="my-4">
              <div className="flex justify-between w-full">
                <div className="w-1/2">
                  <span>{passwordLabel}</span>
                </div>
                <div className="w-1/2">
                  <LinkTo
                    txt="Forgot Username or Password?"
                    customCls="text-xs w-full flex justify-end text-right focus:border-blue-700"
                    onClickHandler={() => history.push('/forgot-password')}
                  />
                </div>
              </div>
              <Input
                className="my-1"
                {...register('password')}
                placeholder={passwordLabel}
                type="password"
                onKeyDown={handleKeyDown}
              />
              <HelperText valid={isValid('password')}>{errors.password?.message}</HelperText>
            </Label>

            <Button block loading={isLoading} disabled={isLoading} onClick={onClickHandler}>
              Sign In
            </Button>
          </Fragment>
        );

      case FORGOT_PASSWORD:
      case RETRIEVE_PROFILE:
        return (
          <Fragment>
            <Label className="my-4">
              <span>Email</span>
              <Input
                className="my-1"
                {...register('username')}
                placeholder={'Enter your email'}
                type="text"
                onKeyDown={handleKeyDown}
              />
              <HelperText valid={isValid('username')}>{errors.username?.message}</HelperText>
            </Label>

            <Button block loading={isLoading} disabled={isLoading} onClick={onClickHandler}>
              Submit
            </Button>
          </Fragment>
        );

      case CHECK_IN_EMAIL:
        return (
          <div className="w-full flex justify-start pb-20">
            <LinkTo txt="Back to Log In Page" onClickHandler={() => goToLogin()} />
          </div>
        );

      case RESET_PASSWORD:
        return (
          <Fragment>
            <Label className="my-4">
              <span>New Password</span>
              <Input
                className="my-1"
                {...register('username')}
                placeholder={'Password'}
                type="password"
                autoComplete="off"
                onKeyDown={handleKeyDown}
              />
              <HelperText valid={isValid('username')}>{errors.username?.message}</HelperText>
            </Label>

            <Label className="my-4">
              <div className="flex justify-between">
                <span>Verify Password</span>
              </div>
              <Input
                className="my-1"
                {...register('password')}
                placeholder={'Re-enter Password'}
                type="password"
                autoComplete="off"
                onKeyDown={handleKeyDown}
              />
            </Label>

            <Button block loading={isLoading} disabled={isLoading} onClick={onClickHandler}>
              Submit
            </Button>
          </Fragment>
        );

      case PASSWORD_UPDATED:
        return (
          <div className="w-full flex justify-start pb-20">
            <LinkTo txt="Go to Log In Page" onClickHandler={goToLogin} />
          </div>
        );
    }
  };

  return (
    <form className={formStyle}>
      <NetworkError closeModal={closeModal} isOpen={isOpen} errorInfo={errorInfo} />
      {getForm()}
    </form>
  );
};

export default LoginForm;
