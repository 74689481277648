import { FCWithChildren } from '@types';
import React from 'react';
import { Button, Modal, ModalBody } from '@components';

type ModalRandomMacWarningProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ModalRandomMacWarning: FCWithChildren<ModalRandomMacWarningProps> = ({ isOpen, onClose }) => (
  <Modal
    customWebClass="xl:w-1/3 l:w-1/2 m:w-2/3"
    maxWidthClass="max-w-90"
    closeModal={onClose}
    isOpen={isOpen}
  >
    <ModalBody>
      <div className="mb-2">
        <h3 className="text-2xl pb-2">Private Wifi/Random MAC is On</h3>
        <p className="py-2">Hi, thank you for signing up for WiFi access.</p>
        <p className="py-2">
          Unfortunately, your device has Private WiFi (or Random MAC) address set to "on".
        </p>
        <p className="py-2">
          Please go to your device WiFi settings and turn off "Private Address" (or change MAC
          Address type from "Randomized" to "Phone" MAC).
        </p>
        <p className="py-2">
          When that is done, please reconnect and we can add your device to your account!
        </p>
      </div>
      <div className="flex justify-center mt-6">
        <Button layout="secondary" className="w-32" onClick={onClose}>
          Close
        </Button>
      </div>
    </ModalBody>
  </Modal>
);

export default ModalRandomMacWarning;
