import { serializeHtmlNode } from './index';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';

const getParsedHtml = (content: string) =>
  ReactHtmlParser(content, {
    decodeEntities: true,
    transform: function transform(node: any, index: number) {
      node = serializeHtmlNode(node);
      return convertNodeToElement(node, index, transform);
    },
  });

export default getParsedHtml;
