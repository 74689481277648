import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

type Params = {
  mac: string;
};

const MacAddressRedirect = () => {
  let { mac }: Params = useParams();
  sessionStorage.setItem(
    'mac',
    mac.match(/\w{2}%3A\w{2}%3A\w{2}%3A\w{2}%3A\w{2}%3A\w{2}/)
      ? decodeURIComponent(mac.toUpperCase())
      : mac.toUpperCase(),
  );

  return <Redirect to="/" />;
};

export default MacAddressRedirect;
