/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { forwardRef, useState, useReducer, useEffect, useMemo } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToasts } from 'react-toast-notifications';
import { addDevice } from 'sdk-apogee';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, HelperText, Input, RequestManagerWrapper, Modal, ModalBody } from '@components';
import useLayoutContext from '@hooks/useLayoutContext';
import { useAppContext } from '@contexts/AppContext';
import { yupResolver } from '@hookform/resolvers/yup';
import useDevices from '@hooks/useDevices';
import DeviceTypeSelect from '../DeviceForm/DeviceTypeSelect';
import MaxAllowedDevicesModal from '../MaxAllowedDevicesModal';
import { RESET_DEVICE_INFO, SAVE_CONFLICT_DEVICE_INFO } from '../Steps/Steps';
import {
  DEVICE_NAME_REGEX,
  DEVICE_NAME_VALIDATION_MESSAGE,
  SAM_DEVICE_NAME_REGEX,
  SAM_DEVICE_NAME_VALIDATION_MESSAGE,
  SAM_REPLACE_DEVICE_NAME_REGEX,
} from '../../../../constants';
import ConfirmationModal from '../ConfirmationModal';
import DeviceConflictModal from '../DeviceConflictModal';
import { FaCheckCircle } from 'react-icons/fa';

export const OPEN_CONFLICT_MODAL = 'OPEN_CONFLICT_MODAL';
export const PROCEED_ADDING_CONFLICT_DEVICE = 'PROCEED_ADDING_CONFLICT_DEVICE';
export const CONFLICT_DEVICE_ADDED = 'CONFLICT_DEVICE_ADDED';
export const CLOSE_CONFLICT_MODAL = 'CLOSE_CONFLICT_MODAL';

type FormularyData = {
  deviceType: Record<string, number> | null;
  name: string;
};

type Params = {
  deviceTypeId: number;
  macAddress: string;
  name: string;
};

type DataErrorType = {
  deviceConflict: boolean;
  errors?: Record<string, string[]>;
  error?: string;
};

type ErrorType = {
  data: DataErrorType;
};

type ConflictDeviceType = {
  name: string;
  deviceTypeId: number;
  macAddress: string;
};
interface ActionType {
  type: string;
  payload?: {
    conflictingDevice?: ConflictDeviceType | null | undefined;
    clearFields?: undefined | (() => void);
  };
}
interface PostAddActionInterface extends ActionType {
  network?: string;
}

interface AddActionType extends ActionType {
  network?: string;
}

export type ActionTypes = PostAddActionInterface | AddActionType;

export type StateType = {
  isModalOpen: boolean;
  conflictingDevice: ConflictDeviceType | null | undefined;
  clearFields?: undefined | (() => void);
};

const initialState = {
  isModalOpen: false,
  conflictingDevice: null,
};

const reducer = (state: StateType, action: ActionTypes): StateType => {
  switch (action.type) {
    case OPEN_CONFLICT_MODAL:
      return {
        ...state,
        isModalOpen: true,
      };

    case PROCEED_ADDING_CONFLICT_DEVICE:
      return {
        ...state,
        isModalOpen: true,
      };

    case CONFLICT_DEVICE_ADDED:
      return {
        ...state,
        isModalOpen: true,
      };

    case SAVE_CONFLICT_DEVICE_INFO:
      return {
        ...state,
        conflictingDevice: action.payload?.conflictingDevice,
        clearFields: action.payload?.clearFields,
      };

    case RESET_DEVICE_INFO:
      return {
        ...state,
        conflictingDevice: null,
      };

    case CLOSE_CONFLICT_MODAL:
      return {
        ...state,
        isModalOpen: false,
      };

    default:
      return state;
  }
};

const AutoDetectDeviceModal = (props, ref) => {
  const { isMobile, isSam, testCaptiveMac } = useLayoutContext();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const { user } = useAppContext();
  const { data } = useDevices();
  const [isOpen, setIsOpen] = useState(false);
  const [maxQtyDevicesModal, setMaxQtyDevicesModal] = useState(false);
  const [deviceNameWarning, setDeviceNameWarning] = useState('');
  const macAddress = useMemo(() => {
    if (!sessionStorage.getItem('mac') && testCaptiveMac) {
      sessionStorage.setItem('mac', testCaptiveMac);
    }
    return sessionStorage.getItem('mac');
  }, [testCaptiveMac]);

  useEffect(() => {
    if (!macAddress) {
      setIsOpen(false);
    } else if (data?.find((d) => d.macAddress === macAddress)) {
      sessionStorage.removeItem('mac');
    } else {
      setIsOpen(true);
    }
  }, [data, macAddress]);

  const hasReachedMaxDevices =
    (data?.length || 0) >= (user?.maxDevices || 0) && (user?.maxDevices || 0) > 0;
  const hasReachedMaxSessions = !user?.maxSessions
    ? false
    : (data?.filter((d) => d.status === 'active').length || 0) >= user?.maxSessions;
  const hasReachedMaxQtyDevices = hasReachedMaxDevices || hasReachedMaxSessions;

  const nameValidation = isSam ? SAM_DEVICE_NAME_REGEX : DEVICE_NAME_REGEX;
  const warningMessage = isSam
    ? SAM_DEVICE_NAME_VALIDATION_MESSAGE
    : DEVICE_NAME_VALIDATION_MESSAGE;

  const SCHEMA = yup.object().shape({
    deviceType: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.number().required('Device Type is required'),
      })
      .required('Device Type is required'),
    name: yup
      .string()
      .required('Name is required')
      .test('valid', warningMessage, (val) => (val ? nameValidation.test(val) : true)),
  });

  const methods = useForm<FormularyData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    // @ts-ignore
    resolver: yupResolver(SCHEMA),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    setError,
  } = methods;

  const errorAddingDevice = (addDeviceErrors: ErrorType) => {
    const formErrors = addDeviceErrors.data.errors;
    const formError = addDeviceErrors.data.error;
    if (formErrors && formErrors.constructor === Object) {
      addToast('Check one or more fields', { appearance: 'error' });
      Object.keys(formErrors).forEach(function (key) {
        // @ts-ignore
        setError(key, { type: 'server', message: formErrors[key].join('. ') });
      });
    } else if (formError) {
      addToast(formError, { appearance: 'error' });
    } else {
      addToast('Unknown error', { appearance: 'error' });
    }
  };

  const { mutate: createDevice, isPending } = useMutation({
    mutationFn: (values: Params) => addDevice(values),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['devices'] });
      setDeviceNameWarning('');
      reset({ deviceType: null });
      dispatch({ type: RESET_DEVICE_INFO });
      addToast('Device added', { appearance: 'success' });
      sessionStorage.removeItem('mac');
      setIsOpen(false);
      toggleConfirmationModal();
    },
    onError: (addDeviceErrors: ErrorType) => {
      if (addDeviceErrors.data.deviceConflict) openConflictModal();
      errorAddingDevice(addDeviceErrors);
    },
  });

  const onSubmit = (data: FormularyData) => {
    if (hasReachedMaxQtyDevices) {
      setMaxQtyDevicesModal(true);
    } else {
      const newDevice = {
        name: data.name,
        deviceTypeId: (data && data?.deviceType && data.deviceType.value) as number,
        macAddress,
      };

      dispatch({
        type: SAVE_CONFLICT_DEVICE_INFO,
        payload: {
          // @ts-ignore
          conflictingDevice: { ...newDevice },
          clearFields: () => reset({ deviceType: null }),
        },
      });

      // @ts-ignore
      createDevice(newDevice);
    }
  };

  const isValid = (input: string) => !(errors as any)[input]?.message;

  const deviceNameBuilder = (deviceType: string) => {
    const devicePrefix =
      user.firstName && user.firstName !== 'Not Provided' ? user.firstName : 'My';
    const deviceNameSuggestion = `${devicePrefix} ${deviceType}`;
    const newDeviceName = isSam
      ? deviceNameSuggestion.replace(SAM_REPLACE_DEVICE_NAME_REGEX, '')
      : deviceNameSuggestion.toLowerCase();
    setError('name', { message: '' });
    setValue('name', newDeviceName);
  };

  const closeMaxQtyDevicesModal = () => setMaxQtyDevicesModal(false);

  const [isConfirmationModalOpen, toggleConfirmationModal] = useReducer((isOpen) => !isOpen, false);
  const [conflictModalState, dispatch] = useReducer(reducer, initialState);

  const closeModal = () => {
    setIsOpen(false);
    sessionStorage.removeItem('mac');
  };

  const openConflictModal = () => {
    dispatch({ type: OPEN_CONFLICT_MODAL });
  };
  const closeConflictModal = () => {
    dispatch({ type: CLOSE_CONFLICT_MODAL, payload: { conflictingDevice: null } });
  };

  const { isModalOpen: isConflictModalOpen, conflictingDevice, clearFields } = conflictModalState;

  return (
    <>
      <Modal
        closeModal={() => {
          closeModal();
        }}
        isOpen={isOpen && !isConflictModalOpen && !maxQtyDevicesModal}
        noHeader
      >
        <ModalBody>
          <div ref={ref}>
            <RequestManagerWrapper>
              <FormProvider {...methods}>
                <form>
                  <div>
                    <div className="flex mb-5">
                      <FaCheckCircle className="mr-2 text-5xl text-primary" />
                      Your device has been identified and is ready to be added to the network.
                      Please complete the information below:
                    </div>

                    <RequestManagerWrapper size="small" loadingContainer>
                      <DeviceTypeSelect
                        isMobile={isMobile}
                        isModal={true}
                        deviceNameBuilder={deviceNameBuilder}
                      />
                    </RequestManagerWrapper>

                    <div className="mt-5 mb-5">
                      <div className="flex w-full items-center justify-between">
                        <label
                          className={
                            isSam
                              ? 'font-semibold mb-3 text-sm md:text-base'
                              : 'font-semibold mb-0 text-sm md:text-base'
                          }
                          htmlFor="name"
                        >
                          2. Name Your Device
                        </label>
                      </div>
                      <Input
                        className="mt-4 pt-2"
                        {...register('name')}
                        id="name"
                        readOnly={isSam}
                        style={isSam ? { color: '#808080' } : {}}
                      />
                      <HelperText valid={isValid('name') && !deviceNameWarning}>
                        {errors.name?.message || deviceNameWarning}
                      </HelperText>
                    </div>

                    <div className="mt-5 mb-5">
                      <div className="flex w-full items-center justify-between">
                        <label className="font-semibold mb-0 text-sm md:text-base">
                          MAC Address
                        </label>
                        {macAddress}
                      </div>
                    </div>
                  </div>
                  <div className="row-span-2 flex flex-col justify-center">
                    <Button
                      block
                      className="my-1 font-black"
                      disabled={isPending}
                      loading={isPending}
                      onClick={handleSubmit(onSubmit)}
                      role="button"
                    >
                      Add Device
                    </Button>
                  </div>
                </form>
              </FormProvider>
            </RequestManagerWrapper>
          </div>
        </ModalBody>
      </Modal>
      <MaxAllowedDevicesModal isOpen={maxQtyDevicesModal} closeModal={closeMaxQtyDevicesModal} />
      <ConfirmationModal closeModal={toggleConfirmationModal} isOpen={isConfirmationModalOpen} />
      <DeviceConflictModal
        closeModal={closeConflictModal}
        isOpen={isConflictModalOpen}
        conflictingDevice={conflictingDevice}
        clearFields={clearFields}
        dispatch={dispatch}
        isAutoDetectSignedIn
      />
    </>
  );
};

export default forwardRef<HTMLDivElement, {}>(AutoDetectDeviceModal);
