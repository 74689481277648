import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, NotificationVignette } from '@components';
import { MdNotificationsNone, MdNotifications } from 'react-icons/md';
import { useQuery } from '@tanstack/react-query';
import {
  getNotifications,
  getUnreadNotificationsCount,
  INotifications,
  INotificationsCount,
} from 'sdk-apogee';
import { FCWithChildren } from '@types';
import { useAppContext } from '@contexts/AppContext';

const NotificationsMenu: FCWithChildren<{}> = () => {
  const { isAuthenticated } = useAppContext();
  const notificationButtonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const { data: notificationsObject, refetch: fetchNotifications } = useQuery<INotifications>({
    queryKey: ['allNotifications'],
    queryFn: () => getNotifications({}),
    enabled: isAuthenticated,
  });

  const { data: unreadCont, status } = useQuery<INotificationsCount>({
    queryKey: ['unreadNotificationsCount'],
    queryFn: getUnreadNotificationsCount,
    refetchInterval: 120000,
    enabled: isAuthenticated,
  });

  const notificationsUnreadCount: number = notificationsObject?.unreadCount || 0;

  const toggleDropdown = () => setIsOpen(!isOpen);
  const closeDropDown = () => setIsOpen(false);

  useEffect(() => {
    if (status === 'success') {
      const currentUnreadCount = notificationsObject?.unreadCount || 0;
      const currentSystemNotificationsUnreadCount = notificationsObject?.systemUnreadCount || 0;
      if (
        unreadCont.notification.unreadCount > currentUnreadCount ||
        unreadCont.notification.systemUnreadCount > currentSystemNotificationsUnreadCount ||
        !notificationsObject
      ) {
        fetchNotifications();
      }
    }
  }, [unreadCont, status]);

  return (
    <div className="relative">
      {notificationsUnreadCount ? (
        <>
          <button
            className="w-6 h-6 border border-transparent rounded focus:border-blue-700"
            onClick={toggleDropdown}
            ref={notificationButtonRef}
          >
            <MdNotifications aria-haspopup="true" aria-label="Notifications" />
          </button>
          <sup
            data-show="true"
            className="bg-white py-0 px-1 text-center border rounded-full border-white text-black min-h-5 min-w-5 leading-5 absolute -top-2 -right-3.5"
          >
            <span>{notificationsUnreadCount}</span>
          </sup>
        </>
      ) : (
        <button
          className="border border-transparent rounded focus:border-blue-700"
          onClick={toggleDropdown}
          ref={notificationButtonRef}
        >
          <MdNotificationsNone aria-haspopup="true" aria-label="Notifications" size={25} />
        </button>
      )}
      <Dropdown
        align="right"
        isOpen={isOpen}
        onClose={closeDropDown}
        style={{ width: '27vw' }}
        borderless
        openerRef={notificationButtonRef}
      >
        <NotificationVignette closeDropDown={closeDropDown} />
      </Dropdown>
    </div>
  );
};

export default NotificationsMenu;
