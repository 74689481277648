import * as yup from 'yup';

const CHECKOUT_SCHEMA = yup.object().shape({
  card_number: yup.string().required('Please enter your card number'),
  card_expiration: yup
    .string()
    .required('Please enter your card expiration month and year')
    .test('valid', 'Expiration date is invalid', (val) => {
      const monthAndYear = val.split('/').map(function (item) {
        return item.trim();
      });
      const month = Number(monthAndYear[0]);
      return month >= 1 && month <= 12;
    }),
  card_cvc: yup.string().required('Please enter your security code'),
});

export default CHECKOUT_SCHEMA;
