import React, { Fragment } from 'react';

import { RequestManagerWrapper, Notifications } from '@components';
import useLayoutContext from '@hooks/useLayoutContext';
import { FCWithChildren } from '@types';

type NotificationVignetteProps = {
  closeDropDown?: () => void;
};

const NotificationVignette: FCWithChildren<NotificationVignetteProps> = ({
  closeDropDown = undefined,
}) => {
  const { isMobile } = useLayoutContext();

  const OverlayNotifications: FCWithChildren<{}> = ({ children }) => (
    <div className="fixed inset-0 h-screen w-full mt-20 z-40">
      <div className="w-full h-3/4 bg-white z-40 overflow-scroll ">
        <div className="flex flex-col mb-12 items-center py-4 flex items-end bg-white sm:items-center sm:justify-center appear-done enter-done">
          {children}
        </div>
      </div>
      <div className="w-full h-full bg-black opacity-25"></div>
    </div>
  );

  const Wrapper = isMobile ? OverlayNotifications : Fragment;

  return (
    <Wrapper>
      <RequestManagerWrapper loadingContainer>
        <Notifications isOverlay closeDropDown={closeDropDown} />
      </RequestManagerWrapper>
    </Wrapper>
  );
};

export default NotificationVignette;
