import { FCWithChildren } from '@types';
import React, { Fragment, useState } from 'react';

import className from 'classnames';

import { StatusInfoModal } from '@components';
import useConnectionStatus from '@hooks/useConnectionStatus';
import useDevices from '@hooks/useDevices';
import useLayoutContext from '@hooks/useLayoutContext';

import MobileDevicesList from './MobileDevicesList';
import WebDevicesList from './WebDevicesList';

type DevicesListProps = {
  addDeviceAnchorHandler: () => void;
  showFooter?: boolean;
};

const DevicesList: FCWithChildren<DevicesListProps> = ({ addDeviceAnchorHandler, showFooter }) => {
  const [isStatusInfoModalOpen, setIsStatusInfoModalOpen] = useState(false);
  const [deviceStatus, setDeviceStatus] = useState('');
  const { data = [], isFetching, refetch } = useDevices();
  const { isMobile } = useLayoutContext();

  const { data: statusDiagnostics = [] } = useConnectionStatus();

  const sectionCls = className(
    'flex flex-col items-center',
    isMobile ? ' -mx-4 w-screen' : ' w-full',
  );
  const divCls = className(
    'sm:-mx-6 lg:-mx-8 w-full',
    isMobile ? ' mt-4 border-t boder-gray-400' : ' -mt-2 mb-2 py-2',
  );

  const closeStatusInfoModal = () => {
    setIsStatusInfoModalOpen(false);
    setDeviceStatus('');
  };

  const openStatusInfoModal = (status: string) => {
    setIsStatusInfoModalOpen(true);
    setDeviceStatus(status);
  };

  const diagnostic = statusDiagnostics.find(({ value }) => value === deviceStatus);

  return (
    <Fragment>
      <StatusInfoModal
        closeModal={closeStatusInfoModal}
        isOpen={isStatusInfoModalOpen}
        statusDiagnostic={diagnostic}
      />

      <section className={sectionCls}>
        <div className={divCls}>
          {isMobile ? (
            <MobileDevicesList
              addDeviceAnchorHandler={addDeviceAnchorHandler}
              openStatusInfoModal={openStatusInfoModal}
              devices={data}
              showFooter={showFooter}
            />
          ) : (
            <WebDevicesList
              addDeviceAnchorHandler={addDeviceAnchorHandler}
              devices={data}
              loading={isFetching}
              openStatusInfoModal={openStatusInfoModal}
              refresh={refetch}
              showFooter={showFooter}
            />
          )}
        </div>
      </section>
    </Fragment>
  );
};

export default DevicesList;
