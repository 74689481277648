import { FCWithChildren } from '@types';
import React, { createContext, useContext, useState, useRef, useEffect } from 'react';

import { IAuthUser, IOrder, service } from 'sdk-apogee';
import jwt from 'jsonwebtoken';
import useLogout from '@hooks/useLogout';

type AppContextType = {
  isAuthenticated: boolean;
  order: IOrder;
  removeOrder: any;
  saveOrder: any;
  saveUser: any;
  setIsAuthenticated: any;
  user: IAuthUser;
  navBarRef: React.MutableRefObject<HTMLDivElement | null>;
};

const AppContext = createContext<AppContextType>({} as AppContextType);

const isAuth = () => {
  const token = localStorage.getItem('token');

  if (!token) return false;

  const parsedToken: any = jwt.decode(token);

  if (!parsedToken) return false;
  const dateNow = new Date();

  return !(parsedToken.exp < dateNow.getTime() / 1000);
};

const getOrder = () => {
  const order = localStorage.getItem('order');

  if (order) {
    try {
      return JSON.parse(order);
    } catch (error) {
      return {};
    }
  }

  return {};
};

const getUserInfo = () => {
  const userInfo = localStorage.getItem('user');

  if (userInfo) {
    try {
      return JSON.parse(userInfo);
    } catch (error) {
      return {};
    }
  }

  return {};
};

const AppProvider: FCWithChildren = ({ children }) => {
  const { logout } = useLogout();
  const [isAuthenticated, setIsAuthenticated] = useState(() => isAuth());
  const [user, setUser] = useState<IAuthUser>(getUserInfo);
  const [order, setOrder] = useState<IOrder>(getOrder);
  const navBarRef = useRef(null);

  document.addEventListener('message', (token: any) => {
    if (token) {
      localStorage.setItem('token', token);
    }
  });

  const removeOrder = () => {
    localStorage.removeItem('order');
    // @ts-ignore
    setOrder({});
  };

  const saveOrder = (values: IOrder) => {
    localStorage.setItem('order', JSON.stringify(values));
    setOrder(values);
  };

  const saveUser = (values: IAuthUser) => {
    localStorage.setItem('user', JSON.stringify(values));
    setIsAuthenticated(true);
    setUser(values);
  };

  if (isAuthenticated) {
    service.interceptors.request.use((config) => ({
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }));
    service.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        switch (error.response.status) {
          case 401:
            setIsAuthenticated(false);
            logout();
            break;
          default:
            return Promise.reject(error);
        }
      },
    );
  }

  const props = {
    isAuthenticated,
    saveOrder,
    saveUser,
    setIsAuthenticated,
    order,
    removeOrder,
    user,
    navBarRef,
  };

  return <AppContext.Provider value={props}>{children}</AppContext.Provider>;
};

const useAppContext = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppProvider');
  }

  return context;
};

export { AppContext, AppProvider, useAppContext };
