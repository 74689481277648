import isHex from './isHex';
import hexToRgb from './hexToRgb';

type TRgb = null | {
  r: number;
  g: number;
  b: number;
};

const stringToRgb = (color: string): TRgb => {
  if (isHex(color)) {
    const rgb = hexToRgb(color);
    if (rgb) {
      return rgb;
    }
    console.warn('Cannot extract hexadecimal value from color: ', color);
    return null;
  } else {
    const rgb = color.match(/\d+(\.\d+)?/g);
    if (rgb && rgb.length >= 3) {
      return {
        r: parseInt(rgb[0], 10),
        g: parseInt(rgb[1], 10),
        b: parseInt(rgb[2], 10),
      };
    } else {
      console.warn('Cannot extract rgb value from color: ', color);
      return null;
    }
  }
};

export default stringToRgb;
