import { colorLuminance } from './utils';

export default {
  primary: '#0066A8',
  secondary: '#254E70',
  negative: '#e45b78',
  positive: '#A3D9B1',
  textPrimary: '#333',
  backgroundPrimary: '#0066A8',
  backgroundSecondary: '#E5E5E5',
  backgroundPrimaryLighten: colorLuminance('#0066A8', -0.1),
  backgroundSecondaryLighten: colorLuminance('#E5E5E5', -0.2),
  backgroundHover: '#0066A8',
};
