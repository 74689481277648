import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IUpdateProfileResponse, IBuilding, updateProfile } from 'sdk-apogee';

import { useAppContext } from '@contexts/AppContext';

type Props = {
  id: number;
  csrCreated?: boolean;
  handleSuccess?: (res: IUpdateProfileResponse) => any;
  handleError?: (apiErrors: any) => void;
};

type AccountData = {
  building?: IBuilding;
  cellPhone?: string;
  email?: string;
  emailNotification?: boolean;
  firstName?: string;
  lastName?: string;
  password?: string;
  passwordConfirmation?: string;
  preWiredKey?: string;
  room?: string;
  username?: string;
};

type Data =
  | AccountData
  | {
      account: AccountData;
    };

const useUpdateProfile = ({ id, csrCreated, handleSuccess, handleError }: Props) => {
  const queryClient = useQueryClient();

  const { saveUser, user } = useAppContext();

  const { mutate: update, ...updateQueryInfo } = useMutation({
    mutationFn: (data: Data) => updateProfile(id, data, csrCreated),
    onSuccess: (res) => {
      if (!csrCreated) {
        let updatedProfile = res.data;
        updatedProfile.authToken = user?.authToken;
        saveUser(updatedProfile);
        queryClient.invalidateQueries({ queryKey: ['profile'] });
      }

      if (handleSuccess) {
        return handleSuccess(res);
      }
    },
    onError: (apiError: any) => {
      if (handleError) {
        return handleError(apiError);
      }
    },
  });

  return {
    update,
    ...updateQueryInfo,
  };
};

export default useUpdateProfile;
