import React from 'react';
import { RequestManagerWrapper } from '@components';
import ServicesComponent from './Services';

const WrappedServicesComponent = (props: any) => {
  return (
    <RequestManagerWrapper>
      <ServicesComponent {...props} />
    </RequestManagerWrapper>
  );
};

export default WrappedServicesComponent;
