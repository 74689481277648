import { FCWithChildren } from '@types';
import React from 'react';

import { IoMdPersonAdd } from 'react-icons/io';

import useLayoutContext from '@hooks/useLayoutContext';

import { Button } from '@components';

type CreateNetworkProps = {
  openModal: () => void;
};

const CreateNetwork: FCWithChildren<CreateNetworkProps> = ({ openModal }) => {
  const { isMobile } = useLayoutContext();

  const divClass = 'w-full py-12 flex flex-col items-center';
  const textBtnClass = 'flex flex-col justify-center items-center mt-8';
  const shadowClass = divClass.concat(isMobile ? '' : ' border rounded-lg shadow-md mb-8');
  const textBtnContainer = textBtnClass.concat(isMobile ? ' w-full' : ' w-5/12');
  const btnWidthClass = 'mt-8'.concat(isMobile ? ' w-full' : ' w-4/5');

  return (
    <div className={shadowClass}>
      <div className="rounded-full h-32 w-32 bg-gray-300 flex items-center justify-center">
        <IoMdPersonAdd className="text-gray-400" size={50} />
      </div>

      <div className={textBtnContainer}>
        <h1 className="text-xl text-gray-600 font-semibold">Sharing</h1>
        <p className="text-base text-gray-600">Let friends join your network, so you can share</p>
        <p className="text-base text-gray-600">devices such as printers, smartTVs, etc.</p>

        <Button layout="secondary" className={btnWidthClass} onClick={openModal}>
          Create Network
        </Button>
      </div>
    </div>
  );
};

export default CreateNetwork;
