import { FCWithChildren } from '@types';
import React from 'react';

import { FaExclamationTriangle } from 'react-icons/fa';

import { Button, Modal, ModalBody } from '@components';

type PackageExpirationModalProps = {
  closeModal: () => void;
  accountButton: () => void;
  packagesButton: () => void;
  isOpen: boolean;
};

const PackageExpirationModal: FCWithChildren<PackageExpirationModalProps> = ({
  isOpen,
  closeModal,
  accountButton,
  packagesButton,
}) => {
  return (
    <Modal
      closeModal={closeModal}
      isOpen={isOpen}
      noHeader
      customWebClass="max-h-screen-90 max-w-90"
    >
      <ModalBody>
        <div>
          <div className="text-sm font-thin">
            <div className="flex justify-center items-center pb-8">
              <FaExclamationTriangle className="mt-1 text-4xl text-primary mr-8" />
              You have no Internet Service Package assigned
              <br />
              Verify or Update your Account Information
              <br />
              Then Select a new Service Package
            </div>
          </div>
          <div className="flex flex-col mt-4 justify-center pb-4 md:pb-4 md:flex-row md:space-x-6">
            <Button
              block
              onClick={accountButton}
              className="w-1/2 justify-center items-center md:mx-4 mx-0"
            >
              Account
            </Button>
            <Button
              block
              onClick={packagesButton}
              className="w-1/2 justify-center items-center mt-2 md:mt-0 md:mx-4"
            >
              Packages
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PackageExpirationModal;
