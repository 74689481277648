import { FCWithChildren } from '@types';
import React, { Fragment } from 'react';
import classname from 'classnames';

import { SearchBarWMagnifier, Button } from '@components';
import useLayoutContext from '@hooks/useLayoutContext';

import MobileSearchSection from '../MobileSearchSection';

type SearchbarSectionProps = {
  input: string;
  isMobile: boolean;
  handler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDownHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onContactUsClick: () => void;
  showClearSearch?: boolean;
  onClearSearchHandler: () => void;
};

const SearchbarSection: FCWithChildren<SearchbarSectionProps> = ({
  input,
  handler,
  onClearSearchHandler,
  onKeyDownHandler,
  isMobile,
  onContactUsClick,
  showClearSearch,
}) => {
  const { largeHeroImage } = useLayoutContext();

  const landingContainerCls = classname(
    'h-48 bg-cover w-screen text-center -mx-4 sm:-mx-6 lg:-mx-40 xl:-mx-40 bg-gray-300 text-black',
  );

  return (
    <Fragment>
      {isMobile ? (
        <MobileSearchSection
          input={input}
          handler={handler}
          onKeyDownHandler={onKeyDownHandler}
          onContactUsClick={onContactUsClick}
        />
      ) : (
        <div className={landingContainerCls}>
          <div className="h-48 inset-0 w-screen bg-gray-100 bg-opacity-50 flex flex-col justify-end">
            <h1 className="font-sans font-black text-3xl pb-5">Support Center</h1>

            <div className="flex flex-row items-stretch justify-center pb-8 w-full">
              <div className="flex flex-col w-4/5 md:w-1/3">
                <div className="bg-white flex items-center w-full">
                  <SearchBarWMagnifier
                    input={input}
                    onChangehandler={handler}
                    onClearSearchHandler={onClearSearchHandler}
                    onKeyDownHandler={onKeyDownHandler}
                    showClearSearch={showClearSearch}
                  />
                </div>
                <div className="flex w-full justify-end pt-2">
                  <Button layout="link" onClick={onContactUsClick} size="noPadding">
                    <span className="text-sm text-black underline">Contact Us</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SearchbarSection;
