"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var getCampusDevices = function (_a) {
    var page = _a.page, pageSize = _a.pageSize, sortBy = _a.sortBy, sortOrder = _a.sortOrder;
    return (0, service_1.getService)()
        .get("/managed_campus/devices?page=".concat(page, "&page_size=").concat(pageSize, "&sort_by=").concat(sortBy, "&sort_order=").concat(sortOrder))
        .then(function (_a) {
        var data = _a.data;
        return data;
    })
        .catch(function (_a) {
        var response = _a.response;
        return Promise.reject(response.data);
    });
};
exports.default = getCampusDevices;
