import { FCWithChildren } from '@types';
import React, { useState, useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Select from 'react-select';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import { validateEmail } from '@utils';

import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  HelperText,
} from '@components';

import {
  createNetwork,
  IInvitation,
  inviteUserByEmail,
  expireNetwork,
  joinToNetwork,
  deleteInvitation,
} from 'sdk-apogee';

import {
  StateType,
  CREATE_NETWORK,
  EXPIRE_NETWORK,
  SEND_INVITE,
  REMOVE_SENT_INVITATION,
  LEAVE_NETWORK,
  JOIN_NETWORK,
  REMOVE_RECEIVED_INVITATION,
  CLOSE_PAN_MODAL,
} from '../SharedList';

type ActionType = {
  type: string;
};

type ErrorType = {
  error?: string;
  errors?: Record<string, string[]>;
};

type PANModalProps = {
  isOpen: boolean;
  modalState: StateType;
  dispatch: (action: ActionType) => void;
};

type FormData = {
  email?: string;
  address?: string;
};

const DURATION_OPTIONS = [
  { id: 101, label: '2 Hours', value: 2 },
  { id: 102, label: '8 Hours', value: 8 },
  { id: 103, label: '24 Hours', value: 24 },
  { id: 104, label: 'Unlimited', value: 0 },
];

const PANModal: FCWithChildren<PANModalProps> = ({ isOpen, modalState, dispatch }) => {
  const [duration, setDuration] = useState<number | null>(null);
  const { addToast } = useToasts();
  const resetInputRef = useRef();
  const queryClient = useQueryClient();

  const {
    formState: { errors },
    register,
    getValues,
    setError,
  } = useForm<FormData>();

  const closeModal = () => dispatch({ type: CLOSE_PAN_MODAL });

  const { mutate: createNewNetwork } = useMutation({
    mutationFn: () => createNetwork(duration === 0 ? null : (duration as number) * 60),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['my-network'] });
      addToast('You are a host of a network', { appearance: 'success' });
      closeModal();
      dispatch({ type: SEND_INVITE });
    },
    onError: (apiErrors: ErrorType) => {
      addToast(apiErrors?.error || 'An error has occurred', { appearance: 'error' });
    },
  });

  const { mutate: deleteNetwork } = useMutation({
    mutationFn: (id: number) => expireNetwork(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['my-network'] });
      closeModal();
    },
    onError: (apiErrors: ErrorType) => {
      addToast(apiErrors?.error || 'An error has occurred', { appearance: 'error' });
    },
  });

  const { mutate: inviteUser } = useMutation({
    mutationFn: (invitation: IInvitation) => inviteUserByEmail(invitation),
    onSuccess: () => {
      addToast('Invite has been sent.', { appearance: 'success' });
      queryClient.invalidateQueries({ queryKey: ['my-network'] });
      closeModal();
    },
    onError: (apiErrors: ErrorType) => {
      if (apiErrors?.errors) {
        setError('email', {
          type: 'manual',
          message:
            (apiErrors.errors.account && apiErrors.errors.account[0]) || 'An error has occurred',
        });
      } else {
        // Clear any previous error message
        setError('email', {
          type: 'manual',
          message: '',
        });
        addToast(apiErrors?.error || 'An error has occurred', { appearance: 'error' });
      }
    },
  });

  const { mutate: join } = useMutation({
    mutationFn: (networkId: number) => joinToNetwork(networkId),
    onSuccess: () => {
      addToast('Invite has been accepted.', { appearance: 'success' });
      queryClient.invalidateQueries({ queryKey: ['my-network'] });
      queryClient.invalidateQueries({ queryKey: ['received-invites'] });
      closeModal();
    },
    onError: (apiErrors: ErrorType) => {
      addToast(apiErrors?.error || 'An error has occurred', { appearance: 'error' });
    },
  });

  const { mutate: leave } = useMutation({
    mutationFn: (networkId: number) => deleteInvitation(networkId),
    onSuccess: () => {
      addToast('Your invitation has been deleted.', { appearance: 'success' });
      queryClient.invalidateQueries({ queryKey: ['my-network'] });
      queryClient.invalidateQueries({ queryKey: ['received-invites'] });
      closeModal();
    },
    onError: (apiErrors: ErrorType) => {
      addToast(apiErrors?.error || 'An error has occurred', { appearance: 'error' });
    },
  });

  const { myNetworkStatus } = modalState;
  const isUserWithinNetwork = myNetworkStatus ? true : false;
  const isValid = (input: string): boolean => !(errors as any)[input]?.message;

  const durationHandler = (option: any) => setDuration(option.value);

  const focusInput = () => resetInputRef && (resetInputRef.current as any)?.focus();

  const invite = () => {
    const email = getValues('email') as string;

    if (validateEmail(email) === false) {
      setError('email', {
        type: 'address',
        message: 'Please verify email address',
      });

      focusInput();
      return;
    }

    const invitation: IInvitation = {
      sharingInvitation: {
        email,
        sharedNetworkId: (myNetworkStatus && myNetworkStatus.id) as number,
      },
    };
    inviteUser(invitation);
  };

  const joinNetwork = () => join(modalState?.invitation?.id || 0);

  const leaveNetwork = () => leave(modalState?.myNetworkStatus?.id || 0);

  const removeReceivedInvitation = () => leave(modalState?.invitation?.id || 0);

  const removeSentInvitation = () => leave(modalState?.sentInvitationId as number);

  const getModalComponents = () => {
    switch (modalState.actionToPerform) {
      case CREATE_NETWORK:
        return {
          buttonText: 'Create',
          submitHandler: createNewNetwork,
          modalTitle: 'Create a Network',
          body: (
            <Label className="my-8">
              <div className="pb-2">
                <span className="text-xs text-black">Set Duration</span>
              </div>
              <Select
                components={{ IndicatorSeparator: () => null }}
                name="duration"
                options={DURATION_OPTIONS}
                placeholder="Duration"
                onChange={durationHandler}
              />
            </Label>
          ),
        };

      case SEND_INVITE:
        return {
          modalTitle: 'Send an Invite',
          buttonText: 'Invite',
          submitHandler: invite,
          body: (
            <Label className="my-8">
              <div className="pb-2">
                <span className="text-xs text-black">Enter Email Address</span>
              </div>
              <Input
                autoComplete="off"
                {...register('email')}
                ref={(e) => {
                  register('email').ref(e);
                  resetInputRef.current = e;
                }}
              />

              {isValid('email') ? (
                <span className="text-white">-</span>
              ) : (
                <HelperText valid={isValid('email')}>
                  {
                    // @ts-ignore
                    errors.email?.message
                  }
                </HelperText>
              )}
            </Label>
          ),
        };

      case EXPIRE_NETWORK:
        return {
          modalTitle: 'End Shared Network',
          buttonText: 'End',
          cancelButtonText: 'Cancel',
          submitHandler: () => deleteNetwork(modalState.myNetworkStatus?.id || 0),
          body: (
            <div className="w-full flex justify-center items-center">
              <span className="my-8">Are you sure you want to end your network sharing?</span>
            </div>
          ),
        };

      case JOIN_NETWORK:
        return {
          modalTitle: isUserWithinNetwork ? '' : 'Joining Network',
          buttonText: isUserWithinNetwork ? 'OK' : 'Join',
          submitHandler: isUserWithinNetwork ? closeModal : joinNetwork,
          body: isUserWithinNetwork ? (
            <div className="w-full flex flex-col justify-center items-center">
              <span className="">You are already part of a network.</span>
              <span className="">You must leave that network before joining another.</span>
            </div>
          ) : (
            <div className="w-full flex flex-col justify-center items-center">
              <span className="text-center">
                {`Accept invite to join ${
                  modalState.invitation?.accountName.replace(/Not Provided/gi, '').trim() ||
                  modalState.invitation?.accountEmail
                }'s network?`}
              </span>
              <span className="uppercase py-2">
                Current users: {(modalState.invitation?.invitations.length || 0) + 1}
              </span>
            </div>
          ),
        };

      case LEAVE_NETWORK:
        return {
          modalTitle: 'Leave Network',
          buttonText: 'Leave',
          submitHandler: () => leaveNetwork(),
          body: (
            <div className="w-full flex justify-center items-center">
              <span className="my-8">Are you sure you want to leave this network?</span>
            </div>
          ),
        };

      case REMOVE_RECEIVED_INVITATION:
        return {
          modalTitle: 'Delete Invitation',
          buttonText: 'Delete',
          submitHandler: removeReceivedInvitation,
          body: (
            <div className="w-full flex justify-center items-center">
              <span className="my-8">Are you sure you want to delete this invitation?</span>
            </div>
          ),
        };

      case REMOVE_SENT_INVITATION:
        return {
          modalTitle: 'Delete Invitation',
          buttonText: 'Delete',
          submitHandler: removeSentInvitation,
          body: (
            <div className="w-full flex justify-center items-center">
              <span className="my-8">Are you sure you want to delete this invitation?</span>
            </div>
          ),
        };
    }
  };

  const components = getModalComponents();

  const isButtonDisabled = isUserWithinNetwork ? false : duration === null;

  const disableButton =
    isButtonDisabled &&
    (modalState.actionToPerform === SEND_INVITE || modalState.actionToPerform === CREATE_NETWORK);

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} label={components?.modalTitle}>
      <ModalHeader>{components?.modalTitle}</ModalHeader>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (!disableButton) {
            components?.submitHandler();
          }
        }}
      >
        <ModalBody>{components?.body}</ModalBody>
        <ModalFooter>
          <>
            {components?.cancelButtonText ? (
              <Button size="regular" block layout="primary" onClick={closeModal} className="mr-4">
                {components?.cancelButtonText}
              </Button>
            ) : null}
            <Button type="submit" size="regular" block layout="primary" disabled={disableButton}>
              {components?.buttonText}
            </Button>
          </>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default PANModal;
