"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateEmailDomain = void 0;
var validateEmailDomain = function (_a) {
    var _b;
    var email = _a.email, domain = _a.domain, callback = _a.callback;
    var emailDomain = (_b = email.split('.').pop()) === null || _b === void 0 ? void 0 : _b.toLowerCase();
    return emailDomain === domain.toLowerCase();
};
exports.validateEmailDomain = validateEmailDomain;
