import { FCWithChildren } from '@types';
import React from 'react';
import className from 'classnames';

import { IInvitee, IReceivedInvitation } from 'sdk-apogee';
import { Collapsible, Invitee } from '@components';

import { ActionTypes } from '../../SharedList';

type InviteProps = {
  title: string;
  list: IInvitee[] | IReceivedInvitation[];
  isMobile?: boolean;
  isRowClickable?: boolean;
  dispatch: (action: ActionTypes) => void;
};

const Invite: FCWithChildren<InviteProps> = ({
  title,
  list,
  isMobile = false,
  isRowClickable,
  dispatch,
}) => {
  const isSentInviteSection = title.toLowerCase().includes('sent') ? true : false;

  const emptyListMsg = `No Invites ${isSentInviteSection ? `Sent` : 'Received'}`;

  const containerCls = className(
    'w-full flex border border-gray-400 rounded-md p-4',
    isMobile ? 'flex-col -mx-4 sm:-mx-6 lg:-mx-40 xl:-mx-40 p-2 mb-4' : '',
  );

  return (
    <>
      <div className="w-full flex flex-col pt-2 items-center">
        <div className={containerCls}>
          <Collapsible title={title} icon="arrow" showContentByDefault={true}>
            {!!list.length &&
              list.map((invitee, idx) => (
                <Invitee
                  key={idx + invitee.accountEmail}
                  invitee={invitee}
                  box
                  isRowClickable={isRowClickable}
                  dispatch={dispatch}
                />
              ))}
            {list.length === 0 && (
              <div className="my-4 w-full flex justify-center items-center">
                <span className="font-semibold">{emptyListMsg}</span>
              </div>
            )}
          </Collapsible>
        </div>
      </div>
    </>
  );
};

export default Invite;
