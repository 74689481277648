import React, { useContext, useMemo } from 'react';
import { AppContext, useAppContext } from '@contexts/AppContext';
import { Button } from '@components';
import { useHistory, useLocation } from 'react-router-dom';
import { FCWithChildren } from '@types';

const CompleteUserInfoBanner: FCWithChildren<{}> = () => {
  const history = useHistory();
  const location: any = useLocation();
  const { isAuthenticated } = useContext(AppContext);
  const { user } = useAppContext();

  const showBanner = useMemo(() => {
    return (
      location.pathname !== '/' &&
      isAuthenticated &&
      user &&
      (!user.firstName || !user.lastName || !user.buildingId || !(user.panRoom || user.room))
    );
  }, [user, isAuthenticated]);

  return !showBanner ? null : (
    <div className="w-screen pl-0 pr-4 sm:px-6 lg:px-40 xl:px-40 z-10 bg-white py-2 text-base font-semibold bg-gray-200">
      Please complete Account Profile information for best support
      {location.pathname !== '/account' && (
        <div>
          <Button
            size="small"
            className="my-1"
            onClick={() => {
              history.push('/account');
            }}
          >
            Account Profile
          </Button>
        </div>
      )}
    </div>
  );
};

export default CompleteUserInfoBanner;
