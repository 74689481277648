import { FCWithChildren } from '@types';
import React from 'react';
import { FaWifi } from 'react-icons/fa';
import { MdCheck, MdLock, MdWifiLock } from 'react-icons/md';
import { Button } from '@components';
import { useToasts } from 'react-toast-notifications';
import useLayoutContext from '@hooks/useLayoutContext';

type PskBodyProps = {
  preWiredKey?: string;
};

const PskBody: FCWithChildren<PskBodyProps> = ({ preWiredKey }) => {
  const { isMobile, ssid, startHereSsid } = useLayoutContext();
  const { addToast } = useToasts();

  return (
    <div>
      <div className="flex gap-4 p-1">
        <div
          className="border-4 rounded-full w-10 h-10 leading-8 text-center font-bold"
          style={{ borderColor: '#FAA83A', color: '#FAA83A' }}
        >
          1
        </div>
        <div>
          <p>
            Open Wifi Settings <FaWifi className="text-2xl inline" />
          </p>
          <br />
        </div>
      </div>
      <div className="flex gap-4 p-1">
        <div
          className="border-4 rounded-full w-10 h-10 leading-8 text-center font-bold"
          style={{ borderColor: '#FAA83A', color: '#FAA83A' }}
        >
          2
        </div>
        <div className="w-full">
          Select the network
          <br />
          <div className="flex column-gap-10 text-3xl justify-between flex-wrap">
            {!isMobile && <MdCheck color="green" className="pr-3 text-5xl" />}
            <div className="text-primary font-bold break-all">{ssid}</div>
            {!isMobile && <MdWifiLock className="pl-3 text-5xl" />}
          </div>
        </div>
      </div>
      <div className="flex gap-4 p-1">
        <div
          className="border-4 rounded-full w-10 h-10 leading-8 text-center font-bold"
          style={{ borderColor: '#FAA83A', color: '#FAA83A' }}
        >
          3
        </div>
        <div className="w-full">
          Enter your WiFi Password or Security Key
          <br />
          <div className="flex column-gap-10 text-3xl justify-between flex-wrap">
            {!isMobile && <MdLock className="pr-3 text-5xl" />}
            <div className="text-primary font-bold break-all">{preWiredKey}</div>
            <div className="pl-3">
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(preWiredKey || '');
                  addToast('Wi-Fi password copied', { appearance: 'success' });
                }}
                size="small"
              >
                Copy
              </Button>
            </div>
          </div>
          <br />
        </div>
      </div>

      <div className="flex gap-4 p-1">
        <div
          className="border-4 rounded-full w-10 h-10 leading-8 text-center font-bold"
          style={{ borderColor: '#FAA83A', color: '#FAA83A' }}
        >
          4
        </div>
        <div>
          <div>
            Forget the <span className="text-primary font-bold">{startHereSsid}</span> network{' '}
            <br /> if you had connected to it previously
          </div>
          <br />
        </div>
      </div>

      <b>Note:</b>
      <ul className="list-disc list-inside">
        <li>Select to Auto reconnect to the network</li>
        <li>For Wired connections, click Add Device to add your MAC address</li>
      </ul>
    </div>
  );
};

export default PskBody;
