import { FCWithChildren } from '@types';
import React, { useMemo, useState } from 'react';
import { ModalGuestAccount } from '@components';
import useCreateFreeGuestAccount from '@hooks/useCreateFreeGuestAccount';
import useLayoutContext from '@hooks/useLayoutContext';
import { Redirect, useHistory } from 'react-router-dom';
import { addDevice, getDeviceStatus, login, service } from 'sdk-apogee';
import { useMutation } from '@tanstack/react-query';
import { getSubdomain } from '@utils';
import usePageTitle from '@hooks/usePageTitle';

type Params = {
  deviceTypeId?: number;
  macAddress: string;
  name: string;
};
type PlaceHolderProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};
type TCreateDeviceError = {
  data?: {
    deviceConflict: boolean;
    errors?: Record<string, string[]>;
    error?: string;
  };
};

const GuestVisitorsConfirm: FCWithChildren<PlaceHolderProps> = () => {
  usePageTitle('Guest Account');
  const history = useHistory();
  const SUBDOMAIN = getSubdomain();
  const { customerId, subdomain, testCaptiveMac } = useLayoutContext();
  const [localError, setLocalError] = useState('');
  const [deviceConflict, setDeviceConflict] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const { mutate: createDevice, isPending: createDeviceLoading } = useMutation({
    mutationFn: (values: Params) => addDevice(values),
    onSuccess: () => {
      history.push('/thank-message-guest', { isUserCreated: true });
    },
    onError: (error: TCreateDeviceError) => {
      if (error?.data?.deviceConflict) {
        setLocalLoading(true);
        getDeviceStatus({
          domain: SUBDOMAIN,
          macAddress: testCaptiveMac || '',
        })
          .then(({ userType }) => {
            if (userType === 'guest') {
              history.push('/thank-message-guest', { isUserCreated: true });
            } else {
              setLocalLoading(false);
              setDeviceConflict(true);
              setLocalError(
                'This device is already registered, please go back and click Forgot Username or Password if needed',
              );
            }
          })
          .catch(() => {
            setLocalLoading(false);
            setLocalError('Unknown error');
          });
      } else {
        setLocalError('Unknown error');
      }
    },
  });
  const {
    mutate: createFreeGuestAccount,
    isPending,
    error: createFreeGuestAccountError,
  } = useCreateFreeGuestAccount((account) => {
    setDeviceConflict(false);
    setLocalError('');
    setLocalLoading(true);
    login({
      user: {
        username: account.username,
        password: account.preWiredKey,
        subdomain,
      },
    })
      .then((response) => {
        setLocalLoading(false);
        if (response?.authToken) {
          setLocalError('');
          service.interceptors.request.use((config: any) => ({
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${response.authToken}`,
            },
          }));
          createDevice({
            name: `${account.firstName.replace(/[^a-z0-9]/gi, '')} device`.toLowerCase(),
            macAddress: testCaptiveMac || '',
          });
        } else {
          setLocalError('Unknown error');
        }
      })
      .catch((error) => {
        setLocalError(error);
        setLocalLoading(false);
      });
  });
  const error = useMemo(
    () => createFreeGuestAccountError || localError,
    [createFreeGuestAccountError, localError],
  );
  const loading = useMemo(
    () => isPending || createDeviceLoading || localLoading,
    [isPending, createDeviceLoading, localLoading],
  );
  const onClose = () => {
    setIsOpen(false);
  };

  const onConfirm = () => {
    createFreeGuestAccount(customerId);
  };

  return !testCaptiveMac ? (
    <Redirect to={'/'} />
  ) : (
    <ModalGuestAccount
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      loading={loading}
      deviceConflict={deviceConflict}
      error={error}
    />
  );
};

export default GuestVisitorsConfirm;
