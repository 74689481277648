import { FCWithChildren } from '@types';
import React, { useMemo } from 'react';
import className from 'classnames';

import useLayoutContext from '@hooks/useLayoutContext';

type StudentGreetingProps = {
  studentName: string;
};

const StudentGreeting: FCWithChildren<StudentGreetingProps> = ({ studentName }) => {
  const { isMobile } = useLayoutContext();

  const name = useMemo(() => (studentName === 'Not Provided' ? '' : studentName), [studentName]);

  const { largeHeroImage } = useLayoutContext();

  const containerCls = className(
    'md:absolute left-0 right-0 ml-auto mr-auto md:left-0 lg:left-0 xl:left-0',
    isMobile ? 'mt-4' : 'mt-32 pl-4 sm:pl-6 lg:pl-40 xl:pl-40',
  );

  const spansContCls = className(
    'flex ',
    isMobile ? 'w-full justify-center' : 'pl-8 w-1/2 justify-start lg:justify-center',
  );

  const spanCls = className(
    'flex flex-col text-4xl',
    isMobile ? 'w-full pl-0 items-center' : 'w-1/2 pl-8 items-start',
    largeHeroImage ? 'text-white' : 'text-black',
  );

  return (
    <div className={containerCls}>
      <div className={spansContCls}>
        {!!name && (
          <h1 className={spanCls}>
            <span>Welcome,</span>
            <span className="max-w-full truncate">{name}</span>
          </h1>
        )}
      </div>
    </div>
  );
};

export default StudentGreeting;
