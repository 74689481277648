import { FCWithChildren } from '@types';
import React from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { Collapsible } from '@components';
import { serializeHtml } from '@utils';

import { getQuestionsAndAnswers, IQuestionsAndAnswers } from 'sdk-apogee';

type QuestionsAndAnswersProps = {
  subdomain: string;
};

const QuestionsAndAnswers: FCWithChildren<QuestionsAndAnswersProps> = ({ subdomain }) => {
  const { data = [] } = useSuspenseQuery<IQuestionsAndAnswers[]>({
    queryKey: ['questions-and-answers', subdomain],
    queryFn: () => getQuestionsAndAnswers(subdomain),
  });

  return (
    <section>
      {data.map(({ id, title, questions }) => (
        <div key={id} className="flex flex-col w-full">
          <h2 className="pt-8 text-base md:text-2xl">{title}</h2>
          {questions.map(({ id, question, answer }) => (
            <Collapsible key={id} title={question}>
              <ul className="py-4 list-disc">
                <div
                  dangerouslySetInnerHTML={{
                    __html: serializeHtml(answer.document),
                  }}
                ></div>
              </ul>
            </Collapsible>
          ))}
        </div>
      ))}
    </section>
  );
};

export default QuestionsAndAnswers;
