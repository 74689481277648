import { FCWithChildren } from '@types';
import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import classname from 'classnames';

import { DevicesList, RequestManagerWrapper, Information } from '@components';

import { useAppContext } from '@contexts/AppContext';
import useLayoutContext from '@hooks/useLayoutContext';

import Service from './components/Service';
import Support from './components/Support';
import StudentGreeting from './components/StudentGreeting';
import RegisteredDevices from './components/RegisteredDevices';
import AutoDetectDeviceModal from '@pages/ManageDevices/components/AutoDetectDeviceModal';
import usePageTitle from '@hooks/usePageTitle';

type HomeProps = {
  location?: {
    state: { isProfileUpdated: boolean };
  };
};

const Home: FCWithChildren<HomeProps> = ({ location }) => {
  usePageTitle();
  const history = useHistory();
  const { largeHeroImage } = useLayoutContext();
  const { user } = useAppContext();
  const { addToast } = useToasts();
  const isProfileUpdated = location && location.state && location.state.isProfileUpdated;

  useEffect(() => {
    if (isProfileUpdated) {
      addToast('Profile updated successfully', { appearance: 'success' });
    }
  }, [addToast, isProfileUpdated, history]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const studentName = user?.firstName ? user.firstName : 'student';

  const addDeviceHandler = () =>
    history.push({ pathname: '/manage-devices', state: { isAddingDevice: true } });

  const landingContainerCls = classname(
    '-mx-40 h-64 bg-cover bg-center',
    largeHeroImage ? 'bg-cover bg-center text-white' : 'bg-gray-300 text-black',
  );

  return (
    <Fragment>
      <div className={landingContainerCls} style={{ backgroundImage: `url(${largeHeroImage})` }}>
        <StudentGreeting studentName={studentName} />
        <RequestManagerWrapper>
          <RegisteredDevices />
        </RequestManagerWrapper>
      </div>
      <div className="mt-80 md:my-40" aria-label="home-container">
        <h1 className="text-lg font-bold mb-2 -mt-6">Your Devices</h1>
        <RequestManagerWrapper>
          <DevicesList addDeviceAnchorHandler={addDeviceHandler} showFooter={false} />
        </RequestManagerWrapper>
        <Support />
        <div className="flex flex-col overflow-hidden w-full lg:flex-row">
          <div className="flex w-full lg:w-2/3">
            <RequestManagerWrapper>
              <Service />
            </RequestManagerWrapper>
          </div>
          <div className="flex flex-col w-full lg:pl-8 my-2 lg:w-1/3">
            <RequestManagerWrapper>
              <Information currentPage="home" />
            </RequestManagerWrapper>
          </div>
        </div>
      </div>
      <RequestManagerWrapper>
        <AutoDetectDeviceModal />
      </RequestManagerWrapper>
    </Fragment>
  );
};

export default Home;
