import React from 'react';
import {
  FaCcAmex,
  FaCcDinersClub,
  FaCcDiscover,
  FaCcJcb,
  FaCcMastercard,
  FaCcVisa,
  FaCreditCard,
} from 'react-icons/fa';
import { getCreditCardNameByNumber } from 'creditcard.js';

const creditCardIcon = (cardNumber: string) => {
  const cardType = getCreditCardNameByNumber(cardNumber);
  switch (cardType.toLowerCase()) {
    case 'visa':
      return <FaCcVisa />;
    case 'mastercard':
      return <FaCcMastercard />;
    case 'amex':
      return <FaCcAmex />;
    case 'diners-club':
      return <FaCcDinersClub />;
    case 'jcb':
      return <FaCcJcb />;
    case 'discover':
      return <FaCcDiscover />;
    default:
      return <FaCreditCard />;
  }
};

export default creditCardIcon;
