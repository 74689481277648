import { FCWithChildren } from '@types';
import React, { useState, useEffect } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';

import { editDevice, IUserDevice } from 'sdk-apogee';

import { Button, Input, Label, Modal, ModalBody, ModalFooter, HelperText } from '@components';
import {
  DEVICE_NAME_REGEX,
  DEVICE_NAME_VALIDATION_MESSAGE,
  SAM_DEVICE_NAME_REGEX,
  SAM_DEVICE_NAME_VALIDATION_MESSAGE,
} from '../../../constants';
import useLayoutContext from '@hooks/useLayoutContext';

type EditModalProps = {
  closeModal: () => void;
  id: number;
  name: string;
  isOpen: boolean;
};

type DataErrorType = {
  errors?: Record<string, string[]>;
  error?: string;
};

type ErrorType = {
  data: DataErrorType;
};

const EditDeviceModal: FCWithChildren<EditModalProps> = ({ closeModal, id, name, isOpen }) => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const [nameWarning, setNameWarning] = useState('');
  const { isSam } = useLayoutContext();

  const errorEditingDevice = (editDeviceErrors: ErrorType) => {
    const formErrors = editDeviceErrors.data.errors;
    const formError = editDeviceErrors.data.error;
    if (formErrors && formErrors.constructor === Object) {
      addToast('Check one or more fields', { appearance: 'error' });
      Object.keys(formErrors).forEach(function (key) {
        setNameWarning(formErrors[key].join('. '));
      });
    } else if (formError) {
      addToast(formError, { appearance: 'error' });
    } else {
      addToast('An error occurred updating your device', { appearance: 'error' });
    }
  };

  const { mutate: saveDevice, isPending } = useMutation({
    mutationFn: (data: Partial<IUserDevice>) => editDevice(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['devices'] });
      addToast('Device updated successfully', { appearance: 'success' });
      closeModal();
    },
    onError: (editDeviceErrors: ErrorType) => {
      errorEditingDevice(editDeviceErrors);
    },
  });
  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      name,
    },
  });

  const deviceName = watch('name');

  useEffect(() => {
    const nameValidation = isSam ? SAM_DEVICE_NAME_REGEX : DEVICE_NAME_REGEX;
    const warningMessage = isSam
      ? SAM_DEVICE_NAME_VALIDATION_MESSAGE
      : DEVICE_NAME_VALIDATION_MESSAGE;
    if (!deviceName || deviceName === '') {
      setNameWarning('Device Name is required');
    } else if (!nameValidation.test(deviceName)) {
      setNameWarning(warningMessage);
    } else {
      setNameWarning('');
    }
  }, [deviceName, isSam]);

  const onSubmit = (data: any) => saveDevice(data);

  return (
    <Modal closeModal={closeModal} isOpen={isOpen} noHeader>
      <ModalBody>
        <form>
          <Label>
            <span>
              Name
              <span className="text-red-600">*</span>
            </span>
            <Input placeholder="Device Name" {...register('name')} type="text" />
            <HelperText valid={!nameWarning}>{nameWarning}</HelperText>
          </Label>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button block onClick={handleSubmit(onSubmit)} loading={isPending}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditDeviceModal;
