import { FCWithChildren } from '@types';
import React, { useEffect, useState } from 'react';
import { Steps, Loading, RequestManagerWrapper } from '@components';
import CheckOutStep1 from './CheckOutStep1';
import CheckOutStep3 from './CheckOutStep3';
import CheckOutStep2 from './CheckOutStep2';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as jQuery from 'jquery';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createOrder, createSelectedPackages, updateOrder } from 'sdk-apogee';
import { useAppContext } from '@contexts/AppContext';
import CHECKOUT_SCHEMA from './Schema';
import STEPS from './Steps';
import creditCardIcon from './CreditCardIcon';
import { appendEProtectScript, callEProtectApi } from './EProtect';
import * as yup from 'yup';
import { useToasts } from 'react-toast-notifications';
import getEProtectError from './EProtect/Errors/Errors';
import useLayoutContext from '@hooks/useLayoutContext';
import usePageTitle from '@hooks/usePageTitle';

type CheckoutProps = {
  location?: {
    state: { selectedServices: any };
  };
};

type FormData = {
  paypage_registration_id: string;
  card_expiration: string;
  vantiv_txn_id: string;
  card_number: string;
  card_cvc: string;
};

declare global {
  interface Window {
    eProtect: any;
    jQuery: any;
  }
}

window.jQuery = jQuery;

// for eProtect test cases
const TEST_MODE = false;

const Checkout: FCWithChildren<CheckoutProps> = ({ location }) => {
  usePageTitle('Checkout');
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { order, removeOrder, saveOrder } = useAppContext();
  const { phoneSupport } = useLayoutContext();
  const orderTotal = order ? Number(order['total']).toFixed(2) || 0 : 0;
  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setError,
    setValue,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
    // @ts-ignore
    resolver: orderTotal > 0 ? yupResolver(CHECKOUT_SCHEMA) : yupResolver(yup.object().shape({})),
  });
  const selectedServices = location && location.state && location.state?.selectedServices;

  const getPackageIds: () => number[] = () =>
    Object.values(selectedServices).map(({ id }: any) => Number(id));

  const nextStep = () => setCurrentStep(currentStep + 1);

  const { mutate: createOrUpdateOrder, isPending } = useMutation({
    mutationFn: (values: number[]) =>
      !order.id ? createOrder(values) : updateOrder(order.id, values),
    onSuccess: (res) => {
      saveOrder(res.data);
      if (Number(res.data.total) <= 0) {
        nextStep();
      }
    },
    onError: () => {
      addToast('An error occurred with your order. Please try again', { appearance: 'error' });
    },
  });

  useEffect(() => {
    if (
      !selectedServices ||
      (Object.keys(selectedServices).length === 0 && selectedServices.constructor === Object)
    ) {
      history.push('/services');
    }
    appendEProtectScript();
    createOrUpdateOrder(getPackageIds());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSelectedPackages = () =>
    selectedServices
      ? Object.keys(selectedServices).map(function (key) {
          return (
            <div
              className="px-8 flex w-full space-x-12 border-silver border-b-2 border-solid pb-2 mb-5"
              key={key}
            >
              <div className="w-2/3">
                <div>{selectedServices[key].planName}</div>
                <div className="capitalize">{key}</div>
              </div>
              <div className="w-1/3">${Number(selectedServices[key].price).toFixed(2)}</div>
            </div>
          );
        })
      : null;

  const formatCardNumberOrCode = (cardNumberOrCode: string) =>
    cardNumberOrCode?.replace(/[^0-9]/g, '');
  const formatCardExpirationDate = (preFormatCardExpirationDate: string) =>
    preFormatCardExpirationDate?.replace(/ /g, '');

  const handleCardErrors = (response: { response: any }) => {
    const { errorCode, key, message } = getEProtectError(response?.response, phoneSupport);
    const formattedErrorCode = `${errorCode}: `;
    setError(key, { message: `${TEST_MODE && errorCode ? formattedErrorCode : ''}${message}` });
    setLoading(false);
  };

  const previousStep = () => {
    setValue('card_number', '');
    setValue('card_expiration', '');
    setValue('card_cvc', '');
    setValue('paypage_registration_id', '');
    setValue('vantiv_txn_id', '');
    setCurrentStep(currentStep - 1);
  };

  const { mutate: createNewSelectedPackages } = useMutation({
    mutationFn: (values: {}) => createSelectedPackages(values),
    onSuccess: () => {
      setLoading(false);
      queryClient.refetchQueries({ queryKey: ['selected-packages'] });
      queryClient.refetchQueries({ queryKey: ['profile'] });
      removeOrder();
      nextStep();
    },
    onError: (apiError: any) => {
      setLoading(false);
      addToast(
        apiError?.error && typeof apiError?.error === 'string'
          ? apiError?.error
          : 'An error occurred with your order. Please try again',
        { appearance: 'error' },
      );
    },
  });

  const payPageRegistrationIdCallback = (response: {
    paypageRegistrationId: string;
    vantivTxnId: string;
  }) => {
    const payPageRegistrationId = response.paypageRegistrationId;
    const vantivTxnId = response.vantivTxnId;

    if (payPageRegistrationId) {
      setValue('paypage_registration_id', payPageRegistrationId);
      setValue('vantiv_txn_id', vantivTxnId);
      setLoading(false);
      nextStep();
    } else {
      setError('card_number', {
        message: 'An error occurred while verifying your card. Please try again.',
      });
      setLoading(false);
    }
  };

  const getPayPageRegistrationId = (data: any, orderTransactionId: string) => {
    if ('card_number' in data) {
      data['card_number'] = formatCardNumberOrCode(data['card_number']);
      data['card_expiration'] = formatCardExpirationDate(data['card_expiration']);
      data['card_cvc'] = formatCardNumberOrCode(data['card_cvc']);
    }
    callEProtectApi(
      data,
      order,
      orderTransactionId,
      payPageRegistrationIdCallback,
      data,
      handleCardErrors,
    );
  };

  const setOrderTransactionId = () => {
    const suffix = order.transactionId
      ? parseInt(order.transactionId.replace(`${order.orderNum}_`, ''), 10) + 1
      : 1;
    const orderTransactionId = `${order.orderNum}_${suffix}`;
    saveOrder({ ...order, transactionId: orderTransactionId });
    return orderTransactionId;
  };

  const onSubmit = (data: any) => {
    if (currentStep === 1) {
      if (orderTotal !== 0) {
        setLoading(true);
        let orderTransactionId = setOrderTransactionId();
        if (orderTransactionId) {
          getPayPageRegistrationId(data, orderTransactionId);
        }
      } else {
        nextStep();
      }
    } else if (currentStep === 2) {
      setLoading(true);
      const cardNumber = formatCardNumberOrCode(getValues('card_number'));
      createNewSelectedPackages({
        order_id: order.id,
        paypage_registration_id: getValues('paypage_registration_id'),
        vantiv_txn_id: getValues('vantiv_txn_id'),
        transaction_id: order.transactionId,
        first_six: cardNumber ? cardNumber.slice(0, 6) : '',
        last_four: cardNumber ? cardNumber.slice(cardNumber.length - 4) : '',
        expiration_date: formatCardExpirationDate(getValues('card_expiration')),
        ids: getPackageIds(),
      });
    }
  };

  /**
   * Get the content of the step
   */
  const stepContent: any = {
    1: (
      <CheckOutStep1
        errors={errors}
        formRef={register}
        getCardIcon={creditCardIcon}
        getSelectedPackages={getSelectedPackages}
        loading={loading}
      />
    ),
    2: (
      <CheckOutStep2
        getCardIcon={creditCardIcon}
        getSelectedPackages={getSelectedPackages}
        getValues={getValues}
        loading={loading}
        previousStep={previousStep}
        formatCardNumberOrCode={formatCardNumberOrCode}
      />
    ),
    3: (
      <RequestManagerWrapper>
        <CheckOutStep3 />
      </RequestManagerWrapper>
    ),
  };

  return (
    <section className="flex flex-col pt-8">
      <div className="w-full py-6">
        <Steps steps={STEPS} currentStep={currentStep} key="steps" />
        {isPending ? (
          <div className="mt-10">
            <Loading size="regular" />
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col md:flex-row">{stepContent[currentStep]}</div>
          </form>
        )}
      </div>
    </section>
  );
};

export default Checkout;
