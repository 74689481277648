import { FCWithChildren } from '@types';
import React, { useRef, useState } from 'react';

import { MdFileUpload } from 'react-icons/md';
import { Button, HelperText, Input, Modal, ModalBody, ModalHeader, Steps } from '@components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { importCampusDevices } from 'sdk-apogee';
import { useToasts } from 'react-toast-notifications';
import STEPS from './Steps';
import CsvDownloader from 'react-csv-downloader';

type ImportCSVModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

const csvHeaderData = [
  {
    id: 'name',
    displayName: 'Name',
  },
  {
    id: 'mac-address',
    displayName: 'Mac Address',
  },
  {
    id: 'campus-device-type',
    displayName: 'Campus Device Type',
  },
  {
    id: 'device-group',
    displayName: 'Device Group',
  },
];

const sampleData = [
  {
    name: 'Printer-Hallway',
    'mac-address': 'ce:ad:16:94:84:d9',
    'campus-device-type': '3D Printer',
    'device-group': 'Students',
  },
];

const ImportCSV: FCWithChildren<ImportCSVModalProps> = ({ isOpen, closeModal }) => {
  const { addToast } = useToasts();
  const [currentStep, setCurrentStep] = useState(1);
  const fileInputRef = useRef<HTMLInputElement | null>();
  const [uploadError, setUploadError] = useState();
  const queryClient = useQueryClient();

  const getUploadedFileFormData = (file: File) => {
    const apiFormData = new FormData();
    apiFormData.set('campus_device[file]', new Blob([file], { type: 'text/csv' }));
    return apiFormData;
  };

  const { mutate: importCsv, isPending } = useMutation({
    mutationFn: (values: FormData) => importCampusDevices(values),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['campus-devices'] });
      addToast('Devices imported successfully');
      closeModal();
    },
    onError: (apiError: any) => {
      setUploadError(
        apiError.errors
          ? apiError.errors.map((str, index) => <p key={index}>{str}</p>)
          : 'An unknown error occurred while processing your request. Please try again',
      );
    },
  });

  const clickFileInput = () => {
    fileInputRef.current?.click();
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files ? e.currentTarget.files[0] : null;
    // @ts-ignore
    setUploadError('');
    if (file) {
      importCsv(getUploadedFileFormData(file));
    }
  };

  const nextStep = () => setCurrentStep(currentStep + 1);
  const previousStep = () => setCurrentStep(currentStep - 1);

  /**
   * Get the content of the step
   */
  const stepContent: any = {
    1: (
      <div className="mt-5">
        <CsvDownloader
          filename="CampusDevices"
          separator=","
          wrapColumnChar=""
          columns={csvHeaderData}
          datas={sampleData}
          text="Download Import Template"
          className="mb-5 block underline text-blue-700 cursor-pointer"
          bom={false}
        />
        <div className="w-40">
          <Button
            type="button"
            block
            onClick={nextStep}
            className="flex items-center justify-center mt-3"
          >
            <span className="text-white ml-2">Next</span>
          </Button>
        </div>
      </div>
    ),
    2: (
      <>
        <div className="mt-5">
          <div
            className="rounded-full h-10 w-40 flex items-center justify-center bg-gray-300 mb-5 block cursor-pointer"
            onClick={clickFileInput}
            role="presentation"
          >
            <Input
              name="campus_device"
              type="file"
              accept="text/csv"
              onClick={(e: { target: { value: null } }) => {
                e.target.value = null;
              }}
              onChange={onChangeHandler}
              className="hidden"
              ref={fileInputRef}
            />
            <MdFileUpload className="text-2xl text-gray-600" />
            <span className="text-xs my-4">Import CSV</span>
          </div>
          {uploadError && <HelperText valid={false}>{uploadError}</HelperText>}
        </div>
        <div className="w-full">
          <div className="w-40">
            <Button
              type="button"
              block
              onClick={previousStep}
              className="flex items-center justify-center mt-3"
              loading={isPending}
            >
              <span className="text-white ml-2">Previous</span>
            </Button>
          </div>
        </div>
      </>
    ),
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} customWebClass="w-1/2">
      <ModalHeader>
        <span className="font-semibold">Import CSV</span>
      </ModalHeader>
      <ModalBody>
        <div className="w-full py-6">
          <div className="flex flex-row w-full flex-wrap">
            <Steps steps={STEPS} currentStep={currentStep} key="steps" />
            <div className="w-full flex flex-wrap">
              <div className="flex flex-row flex-wrap">{stepContent[currentStep]}</div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ImportCSV;
