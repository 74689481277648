import { FCWithChildren } from '@types';
import React, { Fragment, useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classname from 'classnames';

import classNames from 'classnames';

import { AppContext } from '@contexts/AppContext';
import { LoginForm, SeparatorLine } from '@components';
import useLayoutContext from '@hooks/useLayoutContext';
import { useQueryClient } from '@tanstack/react-query';
import usePageTitle from '@hooks/usePageTitle';
const LOGIN = 'LOGIN';
const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const RETRIEVE_PROFILE = 'RETRIEVE_PROFILE';
const CHECK_IN_EMAIL = 'CHECK_IN_EMAIL';
const RESET_PASSWORD = 'RESET_PASSWORD';
const PASSWORD_UPDATED = 'PASSWORD_UPDATED';

type LoginType = {
  initialState?: string;
  checkEmail?: boolean;
};

const Login: FCWithChildren<LoginType> = ({ initialState, checkEmail = false }) => {
  const [option, setOption] = useState(initialState ? initialState : LOGIN);
  const { isMobile, largeHeroImage, isSso } = useLayoutContext();
  const { isAuthenticated } = useContext(AppContext);
  const queryClient = useQueryClient();
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    queryClient.invalidateQueries({ queryKey: ['devices'] });
    queryClient.invalidateQueries({ queryKey: ['profile'] });
    queryClient.invalidateQueries({ queryKey: ['selected-packages'] });
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/home');
    } else if (!isAuthenticated && isSso) {
      history.push('/');
    }
  }, [isAuthenticated, isSso, history]);

  const signInStyle = classNames('w-full overflow-hidden bg-white px-4 lg:w-1/2 xl:w-1/3', {
    'border shadow-md relative -top-14': !isMobile,
  });

  const landingContainerCls = classname(
    '-mx-40 h-64 bg-cover bg-center',
    largeHeroImage ? 'bg-cover bg-center text-white' : 'bg-gray-300 text-black',
  );

  const setNextNexOption = (nextOption: string) => setOption(nextOption);

  const getTitleAndBody = () => {
    switch (option) {
      case LOGIN:
        return { titleTxt: 'Log In', bodyTxt: `Don't have an account?` };

      case FORGOT_PASSWORD:
        return {
          titleTxt: 'Forgot Password',
          bodyTxt: 'Enter email associated with your account to reset password',
        };

      case RETRIEVE_PROFILE:
        return {
          titleTxt: 'User Token Expired',
          bodyTxt:
            'Enter email associated with your account to retrieve your login information and set your password',
        };

      case CHECK_IN_EMAIL:
        return {
          titleTxt: 'Check Your Email',
          bodyTxt:
            'If you do not receive an email, make sure you entered the correct email associated with your account',
        };

      case RESET_PASSWORD:
        return { titleTxt: 'Reset Password', bodyTxt: '' };

      case PASSWORD_UPDATED:
        return { titleTxt: 'Password Updated', bodyTxt: 'Please log in with your new password' };

      default:
        return { titleTxt: '', bodyTxt: '' };
    }
  };

  const { titleTxt, bodyTxt } = getTitleAndBody();

  usePageTitle(titleTxt);

  const titleCls = classname('antialiased font-normal', isMobile ? 'text-2xl' : 'text-3xl');

  return (
    <Fragment>
      {!isMobile ? (
        <div
          className={landingContainerCls}
          style={{ backgroundImage: `url(${largeHeroImage})` }}
        />
      ) : null}
      <div className="flex justify-end w-full">
        <section className={signInStyle}>
          <div className="px-2 py-8">
            <div className="mb-6">
              <h1 className={titleCls}>{titleTxt}</h1>
              <h6 className="text-sm antialiased font-thin">
                {option !== LOGIN && bodyTxt}
                {!isMobile ? (
                  <Fragment>
                    {!option && (
                      <button
                        className="pl-2 text-blue-500"
                        onClick={() => history.push('/register')}
                      >
                        Sign Up
                      </button>
                    )}
                  </Fragment>
                ) : null}
              </h6>
            </div>
            <LoginForm option={option} setNextOption={setNextNexOption} checkEmail={checkEmail} />
          </div>
          {isMobile ? (
            <div>
              <SeparatorLine />
              <div className="flex flex-col items-center justify-center mt-6">
                <p>Get your devices on campus Wi-Fi</p>
                <Link className="my-4 text-blue-500" to="/register">
                  Create an Account
                </Link>
              </div>
            </div>
          ) : null}
        </section>
      </div>
    </Fragment>
  );
};

export default Login;
