import { getProfile, IAccount } from 'sdk-apogee';
import { useQuery } from '@tanstack/react-query';

const useProfile = (isEnabled = true) => {
  return useQuery<IAccount>({
    queryKey: ['profile'],
    queryFn: () => getProfile().then((value) => ({ ...value, maxDevices: value?.maxDevices || 4 })),
    enabled: isEnabled,
  });
};

export default useProfile;
