import { FCWithChildren } from '@types';
/* eslint-disable jsx-a11y/label-has-for */
import React, { ReactNode } from 'react';

import { useSuspenseQuery } from '@tanstack/react-query';
import Select from 'react-select';

import { getDeviceTypes } from 'sdk-apogee';

import { Controller, useFormContext } from 'react-hook-form';

import { MdDevicesOther } from 'react-icons/md';

import { HelperText } from '@components';
import classNames from 'classnames';

type DeviceTypeSelectProps = {
  isMobile: boolean;
  isModal?: boolean;
  deviceNameBuilder: (value: string) => void;
};

const DeviceTypeSelect: FCWithChildren<DeviceTypeSelectProps> = ({
  isMobile,
  isModal,
  deviceNameBuilder,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { data = [] } = useSuspenseQuery({
    queryKey: ['device-types'],
    queryFn: getDeviceTypes,
  });

  const options = data.map(({ id, name }) => ({ label: name, value: id }));
  const sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div
      className={classNames({ 'w-full my-2 mr-10 md:w-1/3 md:my-4': !isModal })}
      style={{ zIndex: 2 }}
    >
      {!(isMobile || isModal) && (
        <MdDevicesOther className="text-header text-5xl text-primary mb-4" />
      )}
      <label
        className="font-semibold text-sm md:text-base"
        htmlFor="choose-your-device"
        id="choose-your-device-label"
      >
        1. Choose Your Device
      </label>
      <Controller
        control={control}
        name="deviceType"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select
            className="mt-4 pt-2 md:pt-0"
            options={sortedOptions}
            onBlur={onBlur}
            onChange={(e) => {
              deviceNameBuilder((e as any).label);
              onChange(e);
            }}
            value={value}
            ref={ref}
            id="choose-your-device"
            aria-labelledby={'choose-your-device-label'}
          />
        )}
      />

      <HelperText valid={!errors.deviceType?.message}>
        {errors.deviceType?.message as ReactNode}
      </HelperText>
    </div>
  );
};

export default DeviceTypeSelect;
