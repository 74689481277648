"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var acceptNotification = function (id, readAt) {
    return (0, service_1.getService)()
        .patch("/notifications/".concat(id), { readAt: readAt })
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.default = acceptNotification;
