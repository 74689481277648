import { FCWithChildren } from '@types';
import React, { useMemo } from 'react';

import { IConnectionDiagnostics } from 'sdk-apogee';

import { Modal, ModalBody, ModalHeader } from '@components';
import { serializeHtml } from '@utils';

type StatusInfoModalProps = {
  closeModal: () => void;
  isOpen: boolean;
  statusDiagnostic?: IConnectionDiagnostics;
};

const StatusInfoModal: FCWithChildren<StatusInfoModalProps> = ({
  closeModal,
  isOpen,
  statusDiagnostic,
}) => {
  const [htmlResult] = useMemo(
    () => statusDiagnostic?.description.document.nodes.map(serializeHtml) || ['<div></div>'],
    [statusDiagnostic],
  );

  return (
    <Modal closeModal={closeModal} isOpen={isOpen}>
      <ModalHeader> Connection Instructions </ModalHeader>
      <ModalBody>
        <div
          className="flex flex-col items-center w-10/12 mx-auto my-8 font-thin"
          dangerouslySetInnerHTML={{
            __html: htmlResult,
          }}
        />
      </ModalBody>
    </Modal>
  );
};

export default StatusInfoModal;
