"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var createOrder = function (values) {
    return (0, service_1.getService)()
        .post('/orders', { package_ids: values })
        .then(function (_a) {
        var data = _a.data;
        return data;
    })
        .catch(function (_a) {
        var response = _a.response;
        return Promise.reject(response);
    });
};
exports.default = createOrder;
