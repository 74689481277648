import isWcagContrastMin from './isWcagContrastMin';
import contrastRatio from './contrastRatio';
import luminance from './luminance';
import stringToRgb from './stringToRgb';

type TParams = {
  bg: string;
  fontWeight?: string;
  fontSize?: string;
  px?: number;
  bold?: boolean;
  level?: 'AA' | 'AAA';
};
const textCssFromBgContrast = ({
  bg,
  fontSize,
  fontWeight,
  bold = (fontWeight || '').match(/\d/g)
    ? parseFloat(fontWeight || '') > 400
    : ['bold', 'bolder'].includes(fontWeight || ''),
  level = 'AA',
  px = !fontSize ? 0 : parseInt(fontSize.match(/^\d+(\.\d+)?px$/i) ? fontSize : '0', 10),
}: TParams): string => {
  const bgRgb = stringToRgb(bg);
  if (bgRgb) {
    const white = { r: 255, g: 255, b: 255 };
    const bgLuminance = luminance(bgRgb);
    const whiteLuminance = luminance(white);
    const ratio = contrastRatio(bgLuminance, whiteLuminance);
    const pass = isWcagContrastMin({
      ratio,
      px,
      bold,
      level,
    });
    return pass ? 'text-white' : 'text-black';
  }
  console.warn('Cannot create rgb object from background:', bg);
  return 'text-white';
};
export default textCssFromBgContrast;
