import { FCWithChildren } from '@types';
import React, { Fragment, useEffect, useMemo } from 'react';

import { IPackage } from 'sdk-apogee';

import { groupBy } from 'ramda';

import usePackages from '@hooks/usePackages';
import useSelectedPackages from '@hooks/useSelectedPackages';

import Packages from '../Packages';

type Service = {
  id: string;
  planName: string;
  planType: string;
  price: string;
  adjustedPrice: string;
  internet: boolean;
  maxDevices: number;
  isCancellationPlan: boolean;
};

type SelectedServices = {
  [key: string]: Service;
};

type PlansProps = {
  isMobile: boolean;
  onClickHandler: any;
  selectedServices: SelectedServices;
  currentSelectedPackages: SelectedServices;
  setCurrentSelectedPackages: (ids: any) => void;
  updateSelectedServices: (ids: any) => void;
};

const byPlanType = groupBy(({ planType }: { planType: string }) => planType);

const Plans: FCWithChildren<PlansProps> = ({
  isMobile,
  onClickHandler,
  selectedServices,
  currentSelectedPackages,
  setCurrentSelectedPackages,
  updateSelectedServices,
}) => {
  const { data: packages = [] } = usePackages();
  const { data: selectedPackages } = useSelectedPackages();

  useEffect(() => {
    if (selectedPackages) {
      const ids = selectedPackages.reduce(
        (
          acc,
          {
            id,
            price,
            adjustedPrice,
            planType,
            planName,
            internet,
            maxDevices,
            isCancellationPlan,
          },
        ) => ({
          ...acc,
          [planType]: {
            id: id.toString(),
            price,
            adjustedPrice,
            planName,
            internet,
            maxDevices,
            isCancellationPlan,
          },
        }),
        {},
      );

      updateSelectedServices(ids);
      setCurrentSelectedPackages(ids);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackages, updateSelectedServices]);

  const mappedPackages = useMemo(() => byPlanType(packages), [packages]);

  return (
    <Fragment>
      {Object.keys(mappedPackages).map((plan) => (
        <Packages
          key={plan}
          isMobile={isMobile}
          services={mappedPackages[plan] as IPackage[]}
          title={plan}
          onClickHandler={onClickHandler}
          selectedServices={selectedServices}
          currentSelectedPackages={currentSelectedPackages}
        />
      ))}
    </Fragment>
  );
};

export default Plans;
