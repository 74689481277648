const getSubdomain = () => {
  const location = window.location.host.split('.');

  if (location[0] === 'www') {
    return location[1];
  }

  return location[0];
};

export default getSubdomain;
