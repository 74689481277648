import { FCWithChildren } from '@types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal, ModalBody } from '@components';
import useLayoutContext from '@hooks/useLayoutContext';
import { getDeviceStatus } from 'sdk-apogee';
import { getSubdomain, localDayDateTime } from '@utils';
import usePageTitle from '@hooks/usePageTitle';

type DeviceConflictModalProps = {
  closeModal: () => void;
  isOpen: boolean;
  isAutoDetect?: boolean;
  isAutoDetectSignedIn?: boolean;
};

type deviceProps = {
  username?: string;
  userType?: string;
  expiry?: string;
};

const GuestsAndVisitors: FCWithChildren<DeviceConflictModalProps> = () => {
  usePageTitle('Guest Account');
  const history = useHistory();
  const SUBDOMAIN = getSubdomain();
  const { startHereSsid = null, testCaptiveMac = null, guestType } = useLayoutContext();
  const [deviceStatus, setDeviceStatus] = useState<deviceProps>();
  const expiry = localDayDateTime(deviceStatus?.expiry);

  useEffect(() => {
    if (testCaptiveMac) {
      getDeviceStatus({
        domain: SUBDOMAIN,
        macAddress: testCaptiveMac || '',
      }).then((res) => {
        setDeviceStatus(res);
      });
    }
  }, []);

  const closeModal = () => {
    return history.push('/');
  };

  const onContinue = () => history.push('/guest-confirm');

  const GuestBandWidthPopup = () => (
    <ModalBody>
      <div className="mb-2 text-center">
        <h2 style={{ fontSize: '125%' }} className="pb-5">
          Guest access is limited duration and limited bandwidth.
        </h2>
      </div>
      <div className="mb-12 text-center">
        If you are a Resident or Staff user, please go back and create an account or sign&nbsp;in.
      </div>
      <div>
        <Button
          layout="secondary"
          className="w-full sm:w-auto mt-3 p-2"
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
        <Button
          layout="secondary"
          className="w-full sm:w-auto mt-3 p-2 float-right"
          onClick={onContinue}
        >
          Continue
        </Button>
      </div>
    </ModalBody>
  );

  const GuestIdentifiedPopup = () => (
    <>
      <ModalBody>
        <div className="mb-2">
          <h2>Guest device not identified</h2>
        </div>
        <div className="mb-2">
          <h2>Please connect to Wi-Fi network {startHereSsid} to register.</h2>
        </div>
        <div>
          <Button
            layout="secondary"
            className="w-full sm:w-auto mt-3 p-2"
            size="lg"
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
    </>
  );

  const GuestDeviceConfirm = () => (
    <ModalBody>
      <div className="mb-2">
        <h2 className="text-xl">This device is already registered</h2>
      </div>
      {guestType === 'free' ? (
        <>
          <div className="flex flex-col w-full mt-2 divide-y">Expire on {expiry}.</div>
        </>
      ) : (
        <>
          <p>
            Go back and click{' '}
            <a
              href=""
              rel="noreferrer noopener"
              className="underline"
              onClick={() => history.push('/forgot-password')}
            >
              Forgot Username or Password
            </a>{' '}
            link to have email sent to account.
          </p>
        </>
      )}
      <div className="flex justify-center items-center">
        <Button
          className="w-24 h-8 sm:w-auto mt-3 p-2"
          variant="primary"
          onClick={() => history.goBack()}
        >
          Close
        </Button>
      </div>
    </ModalBody>
  );

  return (
    <Modal closeModal={closeModal} isOpen={true}>
      {!testCaptiveMac ? (
        <GuestIdentifiedPopup />
      ) : deviceStatus?.userType === 'guest' ? (
        <GuestDeviceConfirm />
      ) : (
        <GuestBandWidthPopup />
      )}
    </Modal>
  );
};

export default GuestsAndVisitors;
