import React from 'react';

const School = () => (
  <div className="grid gap-4 lg:gap-8 m-5">
    <nav className="border p-4">Sidebar</nav>
    <main className="border p-4">Content</main>
  </div>
);

export default School;
