import { FCWithChildren } from '@types';
import React, { ReactComponentElement, useState } from 'react';
import { Button, Input, InputWithMask } from '@components';
import { FaAngleLeft, FaExclamationCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { FieldErrors } from 'react-hook-form';
import { useAppContext } from '@contexts/AppContext';

type CheckOutStep1Props = {
  errors: FieldErrors;
  formRef: (rules: any) => any;
  getCardIcon: (value: string) => ReactComponentElement<any>;
  getSelectedPackages: () => any;
  loading: boolean;
};

const CheckOutStep1: FCWithChildren<CheckOutStep1Props> = ({
  errors,
  formRef,
  getCardIcon,
  getSelectedPackages,
  loading,
}) => {
  const history = useHistory();
  const { order }: any = useAppContext();
  const [cardNumber, setCardNumber] = useState('');

  const getFieldError = (field: string) => {
    if (errors[field]) {
      return (
        <span className="text-red-700 text-sm font-normal">{`${errors?.[field]?.message}`}</span>
      );
    }
  };

  const getOrderTotalByKey = (key: string) => Number(order[key]).toFixed(2) || 0;

  const getOrderValue = (key: string, label: string) => {
    return (
      <div className="px-8  flex w-full space-x-12 pb-2">
        <div className="w-2/3">
          <div>{label}</div>
        </div>
        <div className="w-1/3">${getOrderTotalByKey(key)}</div>
      </div>
    );
  };

  const handleCardNumberChange = (e: { target: { value: string } }) => {
    let cardNumberValue = e.target.value;
    setCardNumber(cardNumberValue.replace(/[^\d]/g, '') || '');
  };

  return (
    <div className="w-full mt-10">
      <span className="text-3xl  pb-8">Checkout</span>
      <div className="flex flex-col md:flex-row">
        {getOrderTotalByKey('total') !== 0 ? (
          <div className="w-full md:w-6/12">
            <span className="text-lg font-bold pb-2  mt-12 block">Payment Information</span>
            <div className="w-full">
              <div className="my-4 mr-10 w-11/12 relative">
                <span className="mb-4 text-sm font-normal">Card Number</span>
                <span className="z-10 absolute text-center text-black absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3 top-8 left-0 text-3xl">
                  {getCardIcon(cardNumber)}
                </span>
                <InputWithMask
                  type="text"
                  className="mt-4 pt-2 pl-12"
                  name="card_number"
                  mask="9999 9999 9999 9999"
                  maskPlaceholder={null}
                  onChange={handleCardNumberChange}
                  ref={formRef}
                />
                {getFieldError('card_number')}
              </div>
              <div className="my-4 mr-10 w-11/12">
                <div className="w-6/12 inline-block align-top">
                  <span className="mb-4 text-sm font-normal">Expiration</span>
                  <InputWithMask
                    type="text"
                    className="mt-4 pt-2 appearance-none"
                    name="card_expiration"
                    mask="99 / 99"
                    placeholder="MM / YY"
                    maskPlaceholder={null}
                    ref={formRef}
                  />
                  {getFieldError('card_expiration')}
                </div>
                <div className="w-6/12 inline-block pl-5 relative align-top">
                  <span className="mb-4 text-sm font-normal">Security code</span>
                  <span
                    className="z-10 absolute text-center text-black absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3 top-10 right-2 text-lg"
                    title="This is a 3 or 4 digit code on back of card"
                  >
                    <FaExclamationCircle />
                  </span>
                  <Input className="mt-4 pt-2" name="card_cvc" maxLength={5} ref={formRef} />
                  {getFieldError('card_cvc')}
                </div>
              </div>
            </div>
            <span className="inline-block mt-2 text-gray-700 text-sm max-w-90">
              All refunds for package downgrades, upgrades and cancellations will be sent back to
              the method of your original payment
            </span>
          </div>
        ) : null}
        <div className="w-full md:w-1/3">
          <span className="text-lg font-bold pb-2  mt-12 block mb-3 w-full">Your Order</span>
          <div className="py-4 flex border rounded-lg shadow-lg">
            <div className="flex-row w-full">
              {getSelectedPackages()}
              {getOrderValue('subTotal', 'Sub Total')}
              {getOrderValue('tax', 'Tax')}
              {getOrderValue('total', 'Total')}
              <Button
                className="block text-xs flex w-full justify-center items-center py-2 text-gray-700"
                layout="link"
                size="noPadding"
                onClick={() => history.push('/services')}
              >
                <span className="text-sm">
                  <FaAngleLeft className="inline text-lg" /> Modify your order
                </span>
              </Button>
              <div className="px-8  flex w-full space-x-12 pb-2">
                <Button block type="submit" loading={loading}>
                  Review
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOutStep1;
