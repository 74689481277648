"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var searchCampusDevices = function (_a) {
    var keyword = _a.keyword, page = _a.page, pageSize = _a.pageSize, sortBy = _a.sortBy, sortOrder = _a.sortOrder;
    return (0, service_1.getService)()
        .get("/managed_campus/devices/search?search=".concat(keyword, "&page=").concat(page, "&page_size=").concat(pageSize, "&sort_by=").concat(sortBy, "&sort_order=").concat(sortOrder))
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.default = searchCampusDevices;
