import React, { Fragment, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import className from 'classnames';

import { FaWifi } from 'react-icons/fa';

import { Button } from '@components';
import useLayoutContext from '@hooks/useLayoutContext';
import useSelectedPackages from '@hooks/useSelectedPackages';

import { STORAGE_URL } from 'sdk-apogee';
import usePackages from '@hooks/usePackages';
import { FCWithChildren } from '@types';

const Service: FCWithChildren<{}> = () => {
  const history = useHistory();

  const { isMobile } = useLayoutContext();

  const { data = [] } = useSelectedPackages();
  const { data: packages = [] } = usePackages();
  const bodyCls = className('flex w-full', isMobile ? 'flex-col' : 'flex-row');
  const btnContainerCls = className(
    'flex flex-wrap',
    isMobile ? 'flex-col' : 'flex-row w-4/5 justify-center',
  );

  const handleUpgradeClick = () => history.push('/services');

  const internetPackage = data && data[0];

  const htmlToRender = useMemo(
    () => internetPackage?.planDescriptionHtml || null,
    [internetPackage],
  );

  const ButtonsContainer = () =>
    packages.length < 2 ? null : (
      <div className={btnContainerCls}>
        <Button className="w-40 m-1" onClick={handleUpgradeClick} block={isMobile}>
          See Plans
        </Button>
      </div>
    );

  return (
    <section className="flex flex-col w-full overflow-hidden lg:my-2">
      <h2 className="my-2 text-base font-semibold">Your Service</h2>
      <div className="flex flex-col px-8 py-4 border rounded-lg shadow-lg">
        <div className={bodyCls}>
          <div className="flex flex-row w-full">
            <div className="w-1/5 flex items-center justify-center">
              {internetPackage?.icon ? (
                <img src={`${STORAGE_URL}${internetPackage.icon}`} alt="logo" className="pr-2" />
              ) : (
                <FaWifi className="text-4xl" />
              )}
            </div>

            <div className="w-4/5 flex flex-col justify-center items-center pl-2">
              {internetPackage ? (
                <Fragment>
                  <h3 className="my-1 text-xl font-semibold">{internetPackage?.planName}</h3>
                  <div
                    className="flex flex-col my-4"
                    dangerouslySetInnerHTML={{
                      __html: htmlToRender || '',
                    }}
                  />
                </Fragment>
              ) : (
                <div className="flex flex-col items-center justify-center w-full my-4">
                  <h3 className="my-1 text-xl font-semibold">No package selected</h3>
                  {packages.length > 1 && (
                    <span className="my-1 text-base font-semibold">
                      Click on "See Plans" to select one
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          {isMobile && <ButtonsContainer />}
        </div>
        {!isMobile && (
          <div className="flex flex-row">
            <div className="w-1/5 h-6" />
            <ButtonsContainer />
          </div>
        )}
      </div>
    </section>
  );
};

export default Service;
