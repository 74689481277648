import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

type Type =
  | 'base'
  | 'active'
  | 'disabled'
  | 'valid'
  | 'invalid'
  | 'radio'
  | 'checkbox'
  | 'borderless'
  | 'readOnly';

const inputStyle: Record<Type, string> = {
  base: 'block w-full rounded py-2 px-2 text-sm focus:outline-none form-input leading-5',
  active: 'focus:border-black',
  disabled: 'cursor-not-allowed opacity-50 bg-gray-300',
  valid: 'border-green-600 focus:border-green-400 focus:shadow-outline-green',
  invalid: 'border-red-600 focus:border-red-400 focus:shadow-outline-red',
  radio:
    'text-black form-radio border-2 h-4 w-4 border-gray-600 focus:border-black focus:shadow-outline-black',
  checkbox: 'text-black form-checkbox h-4 w-4 border-2 border-gray-600 focus:border-gray-600',
  borderless: 'block w-full border-0 py-2 px-2 text-sm focus:border-black leading-5',
  readOnly: 'block w-full rounded py-2 px-2 text-sm leading-5 my-1 border border-gray-300',
};

const Input = React.forwardRef<any, any>(function Input(props, ref) {
  const {
    autocompleteKey,
    autocompletePlaceholder,
    clearInput,
    valid,
    disabled,
    className,
    noBorder,
    type = 'text',
    onChange,
    ...other
  }: any = props;
  const [autocompletePlaceholderValue, setAutocompletePlaceholderValue] =
    useState(autocompletePlaceholder);
  const baseStyle = inputStyle.base;
  const activeStyle = inputStyle.active;
  const disabledStyle = inputStyle.disabled;
  const validStyle = inputStyle.valid;
  const invalidStyle = inputStyle.invalid;
  const radioStyle = inputStyle.radio;
  const checkStyle = inputStyle.checkbox;
  const borderlessStyle = inputStyle.borderless;
  const readOnlyStyle = inputStyle.readOnly;

  const { readOnly = false } = other;

  useEffect(() => {
    if (clearInput) {
      setAutocompletePlaceholderValue(autocompletePlaceholder);
    }
  }, [clearInput]);

  function hasValidation(valid: boolean): boolean {
    return valid !== undefined;
  }

  function validationStyle(valid: boolean): string | undefined {
    if (hasValidation(valid)) {
      return valid ? validStyle : invalidStyle;
    }
  }

  function typeStyle(type: Type) {
    if (noBorder) return borderlessStyle;

    switch (type) {
      case 'radio':
        return radioStyle;
      case 'checkbox':
        return checkStyle;
      default:
        return baseStyle;
    }
  }

  const cls = readOnly
    ? readOnlyStyle
    : classNames(
        typeStyle(type),
        // don't apply activeStyle if has valid or disabled
        !hasValidation(valid) && !disabled && activeStyle,
        // don't apply disabledStyle if has valid
        !hasValidation(valid) && disabled && disabledStyle,
        validationStyle(valid),
        className,
      );

  const attr = {
    className: cls,
    type,
    ref,
    disabled,
    onChange,
    ...other,
  };
  return !autocompletePlaceholder ? (
    <input {...attr} />
  ) : (
    <div
      style={{
        position: 'relative',
      }}
    >
      <input
        {...{
          ...attr,
          onChange: (e) => {
            if (onChange) {
              onChange(e);
            }
            setAutocompletePlaceholderValue(
              !e.target.value
                ? autocompletePlaceholder
                : `${e.target.value}${autocompletePlaceholder.slice(e.target.value.length)}`,
            );
          },
          style: {
            ...other?.style,
            background: 'none',
            position: 'absolute',
            zIndex: 1,
          },
        }}
      />
      <input
        {...{
          ...attr,
          name: null,
          id: null,
          ref: null,
          style: {
            ...other?.style,
            color: 'gray',
          },
          disabled: true,
          value: autocompletePlaceholderValue,
        }}
        aria-hidden="true"
      />
    </div>
  );
});

export default Input;
