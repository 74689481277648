"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var getNotifications = function (_a) {
    var pageSize = _a.pageSize;
    return (0, service_1.getService)()
        .get('/notifications', { params: { page_size: pageSize } })
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.default = getNotifications;
