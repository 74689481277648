import React, { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Notification } from '@components';
import { getOutageNotifications, IOutageNotification } from 'sdk-apogee';
import { getSubdomain, outageNotificationMessage, outageNotificationValidator } from '@utils';
import { useAppContext } from '@contexts/AppContext';
import { FCWithChildren } from '@types';

const SUBDOMAIN = getSubdomain();
const OutageNotifications: FCWithChildren<{}> = () => {
  const { data = [] } = useSuspenseQuery<IOutageNotification[]>({
    queryKey: ['outageNotifications'],
    queryFn: () => getOutageNotifications(SUBDOMAIN),
  });
  const { user } = useAppContext();

  let validNotificationForUser = false;
  if (data.length > 0) {
    validNotificationForUser = outageNotificationValidator(user, data);
  }

  const message = useMemo(() => outageNotificationMessage(data), [data[0]]);

  return !data.length || !validNotificationForUser ? null : (
    <div className="w-screen pl-0 pr-4 sm:px-6 lg:px-40 xl:px-40 flex z-10 bg-white">
      <Notification type="SystemNotification" message={message} banner small={true} />
    </div>
  );
};

export default OutageNotifications;
