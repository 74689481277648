"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROPERTY_DOMAIN = exports.SCHOOL_DOMAIN = exports.REACT_APP_EPROTECT_API_JS = exports.APOGEE_DOMAIN = exports.STORAGE_URL = exports.BASE_URL = exports.getUrl = exports.isMobile = exports.authToken = exports.DEFAULT_URL = void 0;
exports.DEFAULT_URL = 'REACT_APP_API_URL' in process.env ? process.env.REACT_APP_API_URL : 'https://api-campus.mob.console.apogee.us';
// export const DEFAULT_URL = 'http://api.localhost:3001';
var DEFAULT_APOGEE_DOMAIN = 'campus';
var DEFAULT_REACT_APP_EPROTECT_API_JS = 'https://request.eprotect.vantivprelive.com/eProtect/eProtect-api3.js';
var authToken = function () { return global.AUTH_TOKEN; };
exports.authToken = authToken;
var isMobile = function () { return global.MOBILE; };
exports.isMobile = isMobile;
var isEmptyUrl = function () { return !(window === null || window === void 0 ? void 0 : window.API_URL) || (window === null || window === void 0 ? void 0 : window.API_URL) === ''; };
var isEmptyDomain = !(window === null || window === void 0 ? void 0 : window.APOGEE_DOMAIN) || (window === null || window === void 0 ? void 0 : window.APOGEE_DOMAIN) === '';
var isEmptyEprotectJs = !(window === null || window === void 0 ? void 0 : window.REACT_APP_EPROTECT_API_JS) || (window === null || window === void 0 ? void 0 : window.REACT_APP_EPROTECT_API_JS) === '';
var isEmptySchoolDomain = !(window === null || window === void 0 ? void 0 : window.SCHOOL_DOMAIN) || (window === null || window === void 0 ? void 0 : window.SCHOOL_DOMAIN) === '';
var isEmptyPropertyDomain = !(window === null || window === void 0 ? void 0 : window.PROPERTY_DOMAIN) || (window === null || window === void 0 ? void 0 : window.PROPERTY_DOMAIN) === '';
var getUrl = function () {
    if ((0, exports.isMobile)()) {
        var isEnvReachable = global.REACHABLE_ENV.includes(global.ENV);
        return global.ENV && isEnvReachable ? global.ENV : global.REACHABLE_ENV[0];
    }
    return isEmptyUrl() ? exports.DEFAULT_URL : window.API_URL;
};
exports.getUrl = getUrl;
var BASE_URL = function () {
    return "".concat((0, exports.getUrl)(), "/api/v1");
};
exports.BASE_URL = BASE_URL;
exports.STORAGE_URL = (0, exports.getUrl)();
exports.APOGEE_DOMAIN = isEmptyDomain ? DEFAULT_APOGEE_DOMAIN : window === null || window === void 0 ? void 0 : window.APOGEE_DOMAIN;
exports.REACT_APP_EPROTECT_API_JS = isEmptyEprotectJs
    ? DEFAULT_REACT_APP_EPROTECT_API_JS
    : window === null || window === void 0 ? void 0 : window.REACT_APP_EPROTECT_API_JS;
exports.SCHOOL_DOMAIN = isEmptySchoolDomain ? null : window === null || window === void 0 ? void 0 : window.SCHOOL_DOMAIN;
exports.PROPERTY_DOMAIN = isEmptyPropertyDomain ? null : window === null || window === void 0 ? void 0 : window.PROPERTY_DOMAIN;
