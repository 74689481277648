import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { NotificationsMenu, RequestManagerWrapper } from '@components';
import { useAppContext } from '@contexts/AppContext';
import { textCssFromBgContrast } from '@utils';
import classNames from 'classnames';
import { FCWithChildren } from '@types';

const SchoolNavigation: FCWithChildren<{}> = () => {
  const { user, isAuthenticated } = useAppContext();
  const [bg, setBg] = useState('');

  useEffect(() => {
    const newBg = getComputedStyle(document.documentElement).getPropertyValue(
      '--background-primary',
    );
    if (newBg !== bg) {
      setBg(newBg);
    }
  });

  const textCss = useMemo(
    () =>
      !bg
        ? ''
        : textCssFromBgContrast({
            bg,
            fontSize: '16px',
            fontWeight: '900',
          }),
    [bg],
  );
  const linkClassName =
    'border-2 border-transparent rounded focus:border-blue-700 active:bg-transparent mx-2 font-black';

  return (
    <nav className="ml-auto">
      <ul className={classNames('h-full flex justify-center items-center', textCss)}>
        {(user?.accountType as string) === 'admin' && (
          <li>
            <Link className={linkClassName} to="/managed-campus" aria-label="Campus devices">
              Campus Devices
            </Link>
          </li>
        )}
        <li>
          <Link className={linkClassName} to="/manage-devices" aria-label="My Devices">
            My Devices
          </Link>
        </li>
        <li>
          <Link className={linkClassName} to="/faq" aria-label="Support">
            Support
          </Link>
        </li>
        <li>
          <Link className={linkClassName} to="/account" aria-label="Account">
            Account
          </Link>
        </li>
        {isAuthenticated && (
          <li className="mx-2">
            <RequestManagerWrapper>
              <NotificationsMenu />
            </RequestManagerWrapper>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default SchoolNavigation;
