import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useLayoutContext from '@hooks/useLayoutContext';

import { Button } from '@components';
import { textCssFromBgContrast } from '@utils';
import classNames from 'classnames';

const PublicNavigation = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { isSam, isSso } = useLayoutContext();
  const { logInRedirect } = useLayoutContext();
  const [bg, setBg] = useState('');

  useEffect(() => {
    const newBg = getComputedStyle(document.documentElement).getPropertyValue(
      '--background-primary',
    );
    if (newBg !== bg) {
      setBg(newBg);
    }
  });

  const textCss = useMemo(
    () =>
      !bg
        ? ''
        : textCssFromBgContrast({
            bg,
            fontSize: '16px',
            fontWeight: '900',
          }),
    [bg],
  );

  const redirectToLogin = () => {
    if (!isSam) {
      const url = logInRedirect && logInRedirect();

      return window.location.assign(url as string);
    } else {
      return history.push('/login');
    }
  };

  return (
    <nav className="ml-auto">
      {pathname === '/select' ? null : (
        <ul className={classNames('h-full flex justify-center items-center', textCss)}>
          <li>
            <Button
              className="mx-2"
              layout="link"
              size="noPadding"
              onClick={() => history.push('/support')}
              aria-label="Support"
              isBgPrimary
            >
              <span className="font-black mx-2"> Support</span>
            </Button>
          </li>
          <li>
            <Button
              className="mx-2"
              layout="link"
              size="noPadding"
              onClick={() => redirectToLogin()}
              aria-label="Log in"
              isBgPrimary
            >
              <span className="font-black mx-2">Sign In</span>
            </Button>
          </li>
          {!isSso && (
            <li>
              <Button
                className="mx-2"
                layout="link"
                size="noPadding"
                onClick={() => history.push('/register')}
                aria-label="Register"
                isBgPrimary
              >
                <span className="font-black mx-2"> Sign Up</span>
              </Button>
            </li>
          )}
        </ul>
      )}
    </nav>
  );
};

export default PublicNavigation;
