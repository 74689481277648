import { FCWithChildren } from '@types';
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState, useRef, Fragment, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToasts } from 'react-toast-notifications';

import { addDevice } from 'sdk-apogee';

import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';

import {
  Button,
  HelperText,
  Input,
  RequestManagerWrapper,
  MACAddressInput,
  UploadMacAddressImage,
} from '@components';
import useLayoutContext from '@hooks/useLayoutContext';
import { useAppContext } from '@contexts/AppContext';
import { yupResolver } from '@hookform/resolvers/yup';
import useDevices from '@hooks/useDevices';

import { BsBoxArrowInRight } from 'react-icons/bs';
import { MdInfo } from 'react-icons/md';
import { RiPencilFill } from 'react-icons/ri';

import DeviceTypeSelect from './DeviceTypeSelect';
import MACAddressHelpModal from '../MACAddressHelpModal';

import MaxAllowedDevicesModal from '../MaxAllowedDevicesModal';

import { ActionTypes, RESET_DEVICE_INFO, SAVE_CONFLICT_DEVICE_INFO } from '../Steps/Steps';
import {
  DEVICE_NAME_REGEX,
  DEVICE_NAME_VALIDATION_MESSAGE,
  SAM_DEVICE_NAME_REGEX,
  SAM_DEVICE_NAME_VALIDATION_MESSAGE,
  SAM_REPLACE_DEVICE_NAME_REGEX,
} from '../../../../constants';

type DeviceFormProps = {
  closeModal: () => void;
  openConflictModal: () => void;
  dispatch: React.Dispatch<ActionTypes>;
};

type FormularyData = {
  macAddress: string;
  deviceType: Record<string, number> | null;
  name: string;
};

type Params = {
  deviceTypeId: number;
  macAddress: string;
  name: string;
};

type DataErrorType = {
  deviceConflict: boolean;
  errors?: Record<string, string[]>;
  error?: string;
};

type ErrorType = {
  data: DataErrorType;
};

const DeviceForm: FCWithChildren<DeviceFormProps> = ({
  closeModal,
  openConflictModal,
  dispatch,
}) => {
  const { isMobile, isSam, pskEnabled } = useLayoutContext();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const { user } = useAppContext();
  const { data } = useDevices();
  const [isDeviceTypeSelected, setIsDeviceTypeSelected] = useState(false);
  const macAddressHelpRef = useRef<HTMLButtonElement | null>(null);
  const clearFormRef = useRef<HTMLButtonElement | null>(null);
  const [isMACAddressHelpModalOpen, setIsMACAddressHelpModalOpen] = useState(false);
  const [maxQtyDevicesModal, setMaxQtyDevicesModal] = useState(false);
  const [focusMACInput, setFocusMACInput] = useState(false);
  const [deviceNameWarning, setDeviceNameWarning] = useState('');

  const hasReachedMaxDevices =
    (data?.length || 0) >= (user?.maxDevices || 0) && (user?.maxDevices || 0) > 0;
  const hasReachedMaxSessions = !user?.maxSessions
    ? false
    : (data?.filter((d) => d.status === 'active').length || 0) >= user?.maxSessions;
  const hasReachedMaxQtyDevices = hasReachedMaxDevices || hasReachedMaxSessions;

  const nameValidation = isSam ? SAM_DEVICE_NAME_REGEX : DEVICE_NAME_REGEX;
  const warningMessage = isSam
    ? SAM_DEVICE_NAME_VALIDATION_MESSAGE
    : DEVICE_NAME_VALIDATION_MESSAGE;

  const SCHEMA = yup.object().shape({
    deviceType: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.number().required('Device Type is required'),
      })
      .required('Device Type is required'),
    macAddress: yup
      .string()
      .required('MAC Address is required')
      .test('macAddressValidation', 'Please enter a valid MAC Address', (val) =>
        val ? /^(([A-F0-9]{2}\W){5}[A-F0-9]{2})|(([A-F0-9]{2}){6})$/i.test(val.trim()) : false,
      ),
    name: yup
      .string()
      .required('Name is required')
      .test('valid', warningMessage, (val) => (val ? nameValidation.test(val) : true)),
  });

  const methods = useForm<FormularyData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    // @ts-ignore
    resolver: yupResolver(SCHEMA),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    setError,
    watch,
  } = methods;

  const selectedDeviceType = watch('deviceType');
  const macAddress = watch('macAddress');

  const errorAddingDevice = (addDeviceErrors: ErrorType) => {
    const formErrors = addDeviceErrors.data.errors;
    const formError = addDeviceErrors.data.error;
    if (formErrors && formErrors.constructor === Object) {
      addToast('Check one or more fields', { appearance: 'error' });
      Object.keys(formErrors).forEach(function (key) {
        // @ts-ignore
        setError(key, { type: 'server', message: formErrors[key].join('. ') });
      });
    } else if (formError) {
      addToast(formError, { appearance: 'error' });
    } else {
      addToast('Unknown error', { appearance: 'error' });
    }
  };

  const { mutate: createDevice, isPending } = useMutation({
    mutationFn: (values: Params) => addDevice(values),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['devices'] });
      setDeviceNameWarning('');
      reset({ deviceType: null });
      dispatch({ type: RESET_DEVICE_INFO });
      closeModal();
      sessionStorage.removeItem('mac');
    },
    onError: (addDeviceErrors: ErrorType) => {
      if (addDeviceErrors.data.deviceConflict) openConflictModal();
      errorAddingDevice(addDeviceErrors);
    },
  });

  const clearHandler = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    setValue('macAddress', '');
  };

  useEffect(() => {
    if (sessionStorage.getItem('mac')) {
      setValue('macAddress', sessionStorage.getItem('mac') || '');
    }
  }, []);

  const onSubmit = (data: FormularyData) => {
    if (hasReachedMaxQtyDevices) {
      setMaxQtyDevicesModal(true);
    } else {
      const newDevice = {
        name: data.name,
        deviceTypeId: (data && data?.deviceType && data.deviceType.value) as number,
        macAddress: data?.macAddress?.match(/\w{2}/g)?.join(':') || '',
      };

      dispatch({
        type: SAVE_CONFLICT_DEVICE_INFO,
        payload: {
          conflictingDevice: { ...newDevice },
          clearFields: () => reset({ deviceType: null }),
        },
      });

      createDevice(newDevice);
    }
  };

  const isValid = (input: string) => !(errors as any)[input]?.message;

  const deviceNameBuilder = (deviceType: string) => {
    const devicePrefix =
      user.firstName && user.firstName !== 'Not Provided' ? user.firstName : 'My';
    const deviceNameSuggestion = `${devicePrefix} ${deviceType}`;
    const newDeviceName = isSam
      ? deviceNameSuggestion.replace(SAM_REPLACE_DEVICE_NAME_REGEX, '')
      : deviceNameSuggestion.toLowerCase();
    setIsDeviceTypeSelected(true);
    setError('name', { message: '' });
    setValue('name', newDeviceName);
  };

  const focusMACAddresshelp = () => macAddressHelpRef.current?.focus();

  const focusClearFormButton = () => clearFormRef.current?.focus();

  const nextElementToFocus = () =>
    isDeviceTypeSelected ? focusMACAddresshelp() : focusClearFormButton();

  const openMACAddressHelpModal = () => setIsMACAddressHelpModalOpen(true);

  const closeMACAddressHelpModal = () => setIsMACAddressHelpModalOpen(false);

  const closeMaxQtyDevicesModal = () => setMaxQtyDevicesModal(false);

  const onKeyDownClearHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key, shiftKey } = e;

    if (shiftKey && key === 'Tab' && isDeviceTypeSelected) {
      e.preventDefault();
      focusMACAddresshelp();
    }
  };

  const onKeyDownHelpHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { key, shiftKey } = e;

    if (shiftKey && key === 'Tab') {
      setFocusMACInput(true);
      return;
    }

    if (key === 'Tab') {
      focusClearFormButton();
    }
  };

  const onKeyDownImageHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key, shiftKey } = e;

    if (shiftKey && key === 'Tab') {
      e.preventDefault();
      focusClearFormButton();
    }
  };

  return (
    <Fragment>
      <MACAddressHelpModal
        isOpen={isMACAddressHelpModalOpen}
        closeModal={closeMACAddressHelpModal}
        selectedDeviceType={selectedDeviceType}
      />

      <MaxAllowedDevicesModal isOpen={maxQtyDevicesModal} closeModal={closeMaxQtyDevicesModal} />

      <FormProvider {...methods}>
        <form>
          <div className="flex flex-col md:flex-row">
            <RequestManagerWrapper size="small" loadingContainer>
              <DeviceTypeSelect isMobile={isMobile} deviceNameBuilder={deviceNameBuilder} />
            </RequestManagerWrapper>

            <div className="w-full my-4 mr-10 md:w-1/3">
              {!isMobile && <RiPencilFill className="text-header text-5xl text-primary mb-4" />}
              <div className="flex w-full items-center justify-between">
                <label
                  className={
                    isSam
                      ? 'font-semibold mb-3 text-sm md:text-base'
                      : 'font-semibold mb-0 text-sm md:text-base'
                  }
                  htmlFor="name"
                >
                  2. Name Your Device
                </label>
              </div>
              <Input
                className="mt-4 pt-2"
                {...register('name')}
                id="name"
                readOnly={isSam}
                style={isSam ? { color: '#808080' } : {}}
              />
              <HelperText valid={isValid('name') && !deviceNameWarning}>
                {errors.name?.message || deviceNameWarning}
              </HelperText>
            </div>

            <div className="w-full my-4 mr-10 md:w-1/3">
              {!isMobile && (
                <BsBoxArrowInRight className="text-header text-5xl text-primary mb-4" />
              )}

              <div className="flex flex-wrap w-full items-center justify-between">
                <label className="font-semibold mb-4 text-sm md:text-base" htmlFor="macAddress">
                  3. Enter MAC Address
                </label>

                {isDeviceTypeSelected && (
                  <div className="flex text-blue-700 text-xs items-center">
                    <MdInfo className="mr-1" />
                    <Button
                      ref={macAddressHelpRef}
                      tabIndex={-1}
                      onKeyDown={onKeyDownHelpHandler}
                      layout="link"
                      size="noPadding"
                      onClick={openMACAddressHelpModal}
                      role="button"
                    >
                      MAC Address Help
                    </Button>
                  </div>
                )}
              </div>

              <MACAddressInput
                id="macAddress"
                clearInput={!macAddress}
                register={register}
                nextFocus={nextElementToFocus}
                focusMACInput={focusMACInput}
                setFocusMACInput={() => setFocusMACInput(false)}
              />
              {!pskEnabled && macAddress?.match(/^.[2367ABEF]/i) && (
                <div>
                  <HelperText warning>Random/Private MAC address entered</HelperText>
                </div>
              )}
              <HelperText valid={isValid('macAddress')}>{errors.macAddress?.message}</HelperText>
              <div className="text-xs py-2">
                <Button
                  onKeyDown={onKeyDownClearHandler}
                  className="text-blue-700"
                  onClick={clearHandler}
                  ref={clearFormRef}
                  tabIndex={-1}
                  layout="link"
                  size="noPadding"
                  role="button"
                >
                  Clear form
                </Button>
              </div>
              <UploadMacAddressImage
                clearHandler={clearHandler}
                setValue={setValue}
                onKeyDown={onKeyDownImageHandler}
              />
            </div>
          </div>
          <div className="row-span-2 flex flex-col justify-center">
            <Button
              block
              className="my-1 font-black"
              disabled={isPending}
              loading={isPending}
              onClick={handleSubmit(onSubmit)}
              // onClick={openConflictModal}
              role="button"
            >
              Add Device
            </Button>
          </div>
        </form>
      </FormProvider>
    </Fragment>
  );
};

export default DeviceForm;
