import { FCWithChildren } from '@types';
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Loading, LoadingContainer, LoadingErrorMessage } from '@components';

type RequestManagerWrapperProps = {
  loadingContainer?: boolean;
  size?: string;
};

const RequestManagerWrapper: FCWithChildren<RequestManagerWrapperProps> = ({
  loadingContainer,
  size,
  children,
}) => {
  const fallbackComponent = loadingContainer ? (
    <LoadingContainer />
  ) : (
    <div className="flex w-full justify-center">
      <Loading size={size} />
    </div>
  );
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <LoadingErrorMessage error={error} resetErrorBoundary={resetErrorBoundary} />
      )}
      onReset={() => {
        // Reset logic can be handled here if necessary
        // For example, you might reset some state or refetch data
      }}
    >
      <Suspense fallback={fallbackComponent}>{children}</Suspense>
    </ErrorBoundary>
  );
};

export default RequestManagerWrapper;
