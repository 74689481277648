import { FCWithChildren } from '@types';
import React, { useEffect, useState } from 'react';
import classname from 'classnames';

import SignIn from '../SignIn';
import WelcomeOptions from '../WelcomeOptions';
import { useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

type WelcomeProps = {
  welcome: string;
  bgImg: string;
};

const Welcome: FCWithChildren<WelcomeProps> = ({ welcome, bgImg }) => {
  const location: any = useLocation();
  const { addToast } = useToasts();
  const [isSigningIn, setIsSigningIn] = useState(false);

  const toggleFlag = () => setIsSigningIn((prev) => !prev);

  const sectionClass =
    'flex justify-start items-center md:rounded-md w-full divide-x divide-gray-300';

  const landingContainerCls = classname(
    'h-64 bg-center bg-cover',
    bgImg ? 'bg-cover bg-center text-white' : 'bg-gray-300 text-black',
  );

  useEffect(() => {
    if (location?.state?.message) {
      addToast(location.state.message, { appearance: location.state.appearance });
    }
  }, [location?.state]);

  return (
    <div className="-mx-4 sm:-mx-6 lg:-mx-40 xl:-mx-40 relative flex flex-col">
      <div className={landingContainerCls} style={{ backgroundImage: `url(${bgImg})` }} />
      <div className="md:px-24 lg:px-40 w-full px-4 md:-mt-48 flex">
        <section className={`${sectionClass} ${isSigningIn && 'md:shadow-xl rounded-md bg-white'}`}>
          <WelcomeOptions isSigningIn={isSigningIn} toggleFlag={toggleFlag} welcome={welcome} />
          {isSigningIn && <SignIn />}
        </section>
      </div>
    </div>
  );
};

export default Welcome;
