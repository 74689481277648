/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { RequestManagerWrapper } from '@components';
import { searchCampusDevices, ICampusDevices, ICampusGroups, searchCampusGroups } from 'sdk-apogee';

import LeftPanel from './components/LeftPanel';
import FilterAndPagination from './components/FilterAndPagination';
import Devices from './components/Devices';
import Groups from './components/Groups';
import usePageTitle from '@hooks/usePageTitle';
import { FCWithChildren } from '@types';

type OptionType = {
  label: number;
  value: number;
};

const CampusDevices: FCWithChildren<{}> = () => {
  usePageTitle('Manage Campus');
  const [keyword, setKeyword] = useState('');
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState({ label: 50, value: 50 });
  const [isShowingSearchResult, setIsShowingSearchResult] = useState(false);
  const [activeButton, setActiveButton] = useState('devices');
  const paginationRef = useRef(null);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: deviceSearchResult, refetch: deviceRefetch } = useSuspenseQuery<ICampusDevices>({
    queryKey: ['device-search-result'],
    queryFn: () =>
      searchCampusDevices({
        keyword,
        page: currentPage,
        pageSize: pageSize.value,
        sortBy,
        sortOrder,
      }),
  });

  const { data: groupSearchResult, refetch: groupRefetch } = useSuspenseQuery<ICampusGroups>({
    queryKey: ['group-search-result'],
    queryFn: () => searchCampusGroups(keyword, currentPage, pageSize.value),
  });

  useEffect(() => {
    if (isShowingSearchResult) {
      activeButton === 'devices' ? deviceRefetch() : groupRefetch();
    }
  }, [pageSize]);

  useEffect(() => {
    if (isShowingSearchResult) {
      activeButton === 'devices' ? deviceRefetch() : groupRefetch();
    }
  }, [currentPage]);

  useEffect(() => {
    if (isShowingSearchResult) deviceRefetch();
  }, [sortOrder, isShowingSearchResult]);

  const onKeyDownHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pressedKey = (e as any).key;

    if (pressedKey === 'Enter') {
      setIsShowingSearchResult(true);
      setCurrentPage(1);
      if (currentPage === 1) activeButton === 'devices' ? deviceRefetch() : groupRefetch();
      (paginationRef.current as any)?.resetActivePage();
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);

    if (e.target.value === '') {
      setIsShowingSearchResult(false);
      setCurrentPage(1);
      (paginationRef.current as any)?.resetActivePage();
    }
  };

  const buttonHandler = (str: string) => {
    setActiveButton(str);
    setCurrentPage(1);
    setPageSize({ label: 50, value: 50 });
    (paginationRef.current as any)?.resetActivePage();
  };

  const pageSizeHandler = (option: OptionType) => {
    if (option.label === pageSize.label) return;

    setCurrentPage(1);
    setPageSize(option);
  };

  return (
    <div className="flex flex-row flex-1 -mx-4 sm:-mx-6 lg:-mx-40 xl:-mx-40">
      <div className="flex flex-col w-3/12 min-h-screen pt-24 items-start shadow-xl bg-white z-10">
        <LeftPanel activeButton={activeButton} buttonHandler={buttonHandler} />
        <div className="border-t border-gray-300 h-1 w-full mt-8" />
      </div>

      <div className="flex flex-col items-center w-9/12">
        <FilterAndPagination
          input={keyword}
          onChangehandler={onChangeHandler}
          pageSizeHandler={pageSizeHandler}
          onKeyDownHandler={onKeyDownHandler}
          total={total}
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          ref={paginationRef}
          activeButton={activeButton}
        />
        {activeButton === 'devices' ? (
          <RequestManagerWrapper loadingContainer>
            <Devices
              page={currentPage}
              setTotal={setTotal}
              pageSize={pageSize}
              deviceSearchResult={deviceSearchResult as ICampusDevices}
              isShowingSearchResult={isShowingSearchResult}
              sortBy={sortBy}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              setSortBy={setSortBy}
            />
          </RequestManagerWrapper>
        ) : (
          <RequestManagerWrapper loadingContainer>
            <Groups
              setTotal={setTotal}
              page={currentPage}
              pageSize={pageSize}
              groupSearchResult={groupSearchResult as ICampusGroups}
              isShowingSearchResult={isShowingSearchResult}
            />
          </RequestManagerWrapper>
        )}
      </div>
    </div>
  );
};

export default CampusDevices;
