import { FCWithChildren } from '@types';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { IUserDevice } from 'sdk-apogee';

import { AiOutlineReload } from 'react-icons/ai';

import {
  Button,
  DashedButton,
  Input,
  Loading,
  SeparatorLine,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableRow,
  TableHeaderCell,
} from '@components';

import Device from '../Device';
import EmptyDevices from '../EmptyDevices';
import { DeviceStatus } from '../../../constants';
import usePackages from '@hooks/usePackages';

type WebDevicesListProps = {
  addDeviceAnchorHandler?: () => void;
  devices: IUserDevice[];
  loading: boolean;
  openStatusInfoModal: (str: string) => void;
  refresh: () => void;
  showFooter?: boolean;
};

const WebDevicesList: FCWithChildren<WebDevicesListProps> = ({
  addDeviceAnchorHandler,
  devices,
  loading,
  openStatusInfoModal,
  refresh,
  showFooter = true,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [showActiveOnly, setShowActiveOnly] = useState(false);
  const { data: packages = [] } = usePackages();

  const handleAddDeviceClick = () =>
    pathname === '/home'
      ? history.push({ pathname: '/manage-devices', state: { isAddingDevice: true } })
      : addDeviceAnchorHandler
      ? addDeviceAnchorHandler()
      : null;

  const upgradeHandler = () => history.push('/services');

  return (
    <>
      {devices.length ? (
        <TableContainer>
          <Table>
            <TableHeader>
              <TableRow className="bg-white border-0 uppercase">
                <TableHeaderCell id="device_Type">Device Type</TableHeaderCell>
                <TableHeaderCell id="name">Name</TableHeaderCell>
                <TableHeaderCell id="status">Status</TableHeaderCell>
                <TableHeaderCell id="active">
                  <div className="flex w-full justify-end">
                    <div>
                      <Input
                        size="large"
                        type="checkbox"
                        checked={showActiveOnly}
                        onClick={() => {
                          setShowActiveOnly(!showActiveOnly);
                        }}
                        aria-label="Active Only"
                        id="activeOnly"
                      />{' '}
                      <label htmlFor="activeOnly">Active Only</label>
                    </div>
                  </div>
                </TableHeaderCell>
                <TableHeaderCell id="actions">
                  <div className="flex w-full justify-end font-bold text-base text-blue-700">
                    <Button layout="link" onClick={refresh} aria-label="Reload">
                      <AiOutlineReload />
                    </Button>
                  </div>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {loading ? (
                <TableCell colSpan={4}>
                  <div className="w-full flex justify-center my-8">
                    <Loading />
                  </div>
                </TableCell>
              ) : (
                devices
                  .filter((d) => !showActiveOnly || d.status === DeviceStatus.ACTIVE)
                  .map((info) => (
                    <Device
                      key={`device-${info.id}`}
                      {...info}
                      openStatusInfoModal={openStatusInfoModal}
                    />
                  ))
              )}
            </TableBody>
          </Table>
          {showFooter && (
            <TableFooter className="flex flex-col space-y-4">
              <Button
                block
                className="px-4 py-2"
                layout="secondary"
                onClick={handleAddDeviceClick}
                size="noPadding"
              >
                Add a Device
              </Button>
              <SeparatorLine />
              {packages.length > 1 && (
                <DashedButton title="Upgrade for More Devices" noIcon handler={upgradeHandler} />
              )}
            </TableFooter>
          )}
        </TableContainer>
      ) : (
        <EmptyDevices />
      )}
    </>
  );
};

export default WebDevicesList;
