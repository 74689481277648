import { FCWithChildren } from '@types';
import React, { Fragment, ReactElement, useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import classNames from 'classnames';

type CollapsibleProps = {
  title: string | ReactElement;
  icon?: string;
  wide?: boolean;
  showContentByDefault?: boolean;
};

const Collapsible: FCWithChildren<CollapsibleProps> = ({
  title,
  children,
  icon,
  wide,
  showContentByDefault = false,
}) => {
  const [toggle, setToggle] = useState(showContentByDefault);

  const isArrow = icon === 'arrow';

  const CollapsedIcon = isArrow ? RiArrowUpSLine : FaMinus;
  const NonCollapsedIcon = isArrow ? RiArrowDownSLine : FaPlus;

  const containerCls = classNames(
    'w-full py-2 font-light md:my-2 ',
    isArrow ? 'w-full' : 'border-b',
    wide ? 'xl:w-4/5' : 'lg:w-4/5 xl:w-1/2',
  );

  const iconCls = classNames(
    'cursor-pointer fill-current text-blue-700',
    isArrow ? 'text-2xl font-bold text-black' : 'text-sm',
  );

  const headerCls = classNames('text-sm md:text-base', isArrow ? 'w-full font-semibold' : 'w-4/5');

  const childerenContainerCls = classNames(
    'focus:border-blue-700 border border-transparent rounded',
    isArrow ? 'px-0' : 'px-8',
  );

  const iconProps = {
    className: iconCls,
  };

  const toggleHandler = () => setToggle(!toggle);

  return (
    <Fragment>
      <section className={containerCls}>
        <button
          className="flex justify-between cursor-pointer focus:border-blue-700 border border-transparent rounded text-left w-full collapsible-button"
          onClick={toggleHandler}
        >
          <div className={headerCls}>{title}</div>
          <div className="flex justify-end  w-1/5 pt-1 pr-2 md:pt-0 md:pr-0">
            {toggle ? <CollapsedIcon {...iconProps} /> : <NonCollapsedIcon {...iconProps} />}
          </div>
        </button>
        <div className={childerenContainerCls}>{toggle ? children : null}</div>
      </section>
    </Fragment>
  );
};

export default Collapsible;
