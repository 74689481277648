/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  Children,
  Fragment,
  cloneElement,
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
  forwardRef,
} from 'react';
import { createPortal } from 'react-dom';

import { MdClose } from 'react-icons/md';

import { Button } from '@components';
import useLayoutContext from '@hooks/useLayoutContext';
import useScrollBlock from '@hooks/useScrollBlock';
import classNames from 'classnames';

type ModalProps = {
  children?: any;
  closeModal: () => void;
  closeOnEscape?: boolean;
  closeOnOutsideClick?: boolean;
  isOpen: boolean;
  noHeader?: boolean;
  headerTitle?: string;
  customWebClass?: string;
  maxWidthClass?: string;
  label?: string;
};

const Modal = forwardRef<HTMLElement, ModalProps>(function WrappedModal(
  {
    children,
    closeModal,
    closeOnEscape = true,
    closeOnOutsideClick = true,
    headerTitle = '',
    isOpen,
    noHeader = false,
    customWebClass,
    maxWidthClass,
    label = 'Dialog',
  },
  ref,
) {
  const { isMobile } = useLayoutContext();
  const [mounted, setMounted] = useState(false);

  const [blockScroll, allowScroll] = useScrollBlock();

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!modalRef.current) return;

    const focusableModalElements = modalRef.current.querySelectorAll<HTMLElement>(
      'a[href], button:not([disabled]), textarea, input, select',
    );
    const firstElement = focusableModalElements[0];
    const lastElement = focusableModalElements[focusableModalElements.length - 1];

    const handleKeyDown = (e: KeyboardEvent) => {
      console.log('KEYDOWN');
      if (e.key !== 'Tab') return;

      if (e.shiftKey) {
        if (document.activeElement === firstElement) {
          lastElement?.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastElement) {
          firstElement?.focus();
          e.preventDefault();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isOpen]);

  useEffect(() => {
    if (!modalRef.current) return;

    const focusableModalElements = modalRef.current.querySelectorAll<HTMLElement>(
      'a[href], button:not([disabled]), textarea, input, select',
    );
    const firstElement = focusableModalElements[0];
    if (firstElement) {
      firstElement?.focus();
    }
  }, [mounted]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleEsc = (e: KeyboardEvent) => {
    if ((e.key === 'Esc' || e.key === 'Escape') && isOpen && closeOnEscape) {
      closeModal();
    }
  };

  useEffect(() => {
    modalRef.current?.focus();
    // document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleEsc);
    return () => {
      // document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleEsc);
    };
    // eslint-disable-next-line
  }, [isOpen]);

  useLayoutEffect(() => {
    if (isOpen) {
      blockScroll();
      return () => {
        allowScroll();
      };
    }
  }, [allowScroll, blockScroll, isOpen]);

  const mobileClass = 'relative w-full h-full';
  const webClass = classNames(
    'relative my-6 mx-auto',
    maxWidthClass ? maxWidthClass : 'max-w-3xl',
    customWebClass ? customWebClass : 'w-1/3',
  );
  const contentStyle =
    'border-0  relative flex flex-col w-full bg-white outline-none focus:outline-none';
  const contentClass = contentStyle.concat(isMobile ? ' h-full' : ' rounded-lg shadow-lg');
  const headerCloseButtonClass = classNames('mr-auto', headerTitle ? 'absolute top-3 left-0' : '');

  const modalComponent = (
    <>
      {isOpen ? (
        <Fragment>
          <div className="font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-white bg-opacity-75">
            <div className={isMobile ? mobileClass : webClass}>
              <div
                className={contentClass}
                ref={modalRef}
                tabIndex={-1}
                aria-label={label}
                role="dialog"
              >
                {noHeader ? (
                  <>
                    <Button
                      className={headerCloseButtonClass}
                      size="small"
                      layout="link"
                      onKeyPress={closeModal}
                      ref={ref}
                    >
                      <MdClose className="text-black opacity-5 text-2xl" onClick={closeModal} />
                    </Button>
                    {headerTitle ? (
                      <span className="text-center w-full border-t border-solid border-gray-300 border-b p-4">
                        {headerTitle}
                      </span>
                    ) : (
                      ''
                    )}
                  </>
                ) : null}
                {Children.map(children, (child: any) => {
                  if (child) {
                    return cloneElement(child, { closeModal });
                  }
                })}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </Fragment>
      ) : null}
    </>
  );

  return mounted ? createPortal(modalComponent, document.body) : null;
});

export default Modal;
