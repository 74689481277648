type TParams = {
  ratio: number;
  px: number;
  pt?: number;
  bold?: boolean;
  level?: 'AA' | 'AAA';
  largeText?: boolean;
};
const isWcagContrastMin = ({
  ratio,
  px,
  pt = px * 0.75,
  bold = false,
  level = 'AA',
  largeText = pt === 18 || (pt === 14 && bold),
}: TParams): boolean =>
  level === 'AA'
    ? largeText
      ? ratio < 1 / 3
      : ratio < 1 / 4.5
    : level === 'AAA'
    ? largeText
      ? ratio < 1 / 4.5
      : ratio < 1 / 7
    : false;
export default isWcagContrastMin;
