import React, { useContext, useState } from 'react';

import { BsFillPersonFill, BsFillQuestionCircleFill } from 'react-icons/bs';

import Box from './Box';
import { useHistory } from 'react-router-dom';
import { AppContext, useAppContext } from '@contexts/AppContext';
import { ModalWifiEnabled } from '@components';
import { FCWithChildren } from '@types';

const Support: FCWithChildren<{}> = () => {
  const [isWifiEnabledModalOpen, setIsWifiEnabledModalOpen] = useState(false);
  const { user } = useAppContext();
  const history = useHistory();
  const { isAuthenticated } = useContext(AppContext);
  const getSupportPageRedirectUrl = () => (isAuthenticated ? '/faq' : '/support');

  return (
    <section className="my-4">
      <ModalWifiEnabled
        isOpen={isWifiEnabledModalOpen}
        preWiredKey={user?.preWiredKey}
        onClose={() => {
          setIsWifiEnabledModalOpen(false);
        }}
      />
      <h2 className="my-2 text-base font-semibold">Support</h2>
      <div className="grid gap-4 sm:gap-4 md:gap-4 lg:gap-10 xl:gap-10 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
        <Box
          description="24/7 Customer Support"
          Icon={BsFillQuestionCircleFill}
          title="Problem Connecting?"
          onClick={() => history.push(getSupportPageRedirectUrl())}
        />
        <Box
          description="Set Up Guide Instructions"
          Icon={BsFillPersonFill}
          title="Quick Setup"
          onClick={() => {
            setIsWifiEnabledModalOpen(true);
          }}
        />
      </div>
    </section>
  );
};

export default Support;
