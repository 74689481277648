import { FCWithChildren } from '@types';
import React, { useEffect } from 'react';
import useFitText from './use_fit_text';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { useQuery } from '@tanstack/react-query';
import { getProfile, service } from 'sdk-apogee';

import { Button } from '@components';
import { useAppContext } from '@contexts/AppContext';
import useLayoutContext from '@hooks/useLayoutContext';
import { redirectCsrCreatedUser } from '@utils';

type WelcomeOptionsProps = {
  isSigningIn: boolean;
  welcome: string;
  toggleFlag: () => void;
};

const WelcomeOptions: FCWithChildren<WelcomeOptionsProps> = ({
  isSigningIn,
  welcome,
  toggleFlag,
}) => {
  const history = useHistory();
  const { saveUser, setIsAuthenticated } = useAppContext();
  const { isSam, isSso, logInRedirect, isMobile, isGuestType, authType, schoolUidName } =
    useLayoutContext();

  const { refetch } = useQuery({
    queryKey: ['profile'],
    queryFn: getProfile,
    enabled: false,
    retry: (_, error: any) => error.status !== 401,
    throwOnError: false,
  });

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('jwt');
    const newUser = urlParams.get('new_user');
    const username = urlParams.get('username');
    const first = urlParams.get('first');
    const last = urlParams.get('last');
    const email = urlParams.get('email');
    const group = urlParams.get('group');
    const phone = urlParams.get('phone');

    if (token && newUser) {
      return history.push({
        pathname: '/complete-profile',
        state: { data: { email, username, first, last, group, phone } },
      });
    }

    redirectCsrCreatedUser(queryString, history);

    console.log(token);
    if (token) {
      setIsAuthenticated(true);

      service.interceptors.request.use((config: any) => {
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          },
        };
      });

      refetch().then(({ data }) => saveUser(data));

      localStorage.setItem('token', token);
      history.push('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const register = () => {
    history.push('/register');
  };

  const containerCls = classNames(
    'flex flex-col items-center bg-white py-8 w-full md:w-1/2',
    isSigningIn ? 'rounded-l-md' : 'md:shadow-xl rounded-md',
  );

  const redirectToLogin = () => {
    if (!isSam) {
      const url = logInRedirect && logInRedirect();

      return window.location.assign(url as string);
    }

    if (isMobile) {
      return history.push('/login');
    }

    return toggleFlag();
  };

  const boxHeight = () => {
    const windowInnerWidth = window.innerWidth;
    if (windowInnerWidth >= 1280) {
      return 90;
    } else if (windowInnerWidth >= 1024) {
      return 75;
    }
    return 60;
  };

  const largeSizedFont = () => {
    const windowInnerWidth = window.innerWidth;
    if (windowInnerWidth > 1280) {
      return 187;
    }
    return 125;
  };

  const { fontSize, ref } = useFitText({ maxFontSize: largeSizedFont() });

  return (
    <div className={containerCls}>
      <div className="flex flex-col w-full space-y-2 md:w-4/5 md:max-w-lg">
        <h1 ref={ref} style={{ fontSize, maxHeight: boxHeight(), width: '100%' }}>
          {welcome}
        </h1>
        <span className="text-xs font-light">
          Create an account to get your devices on residential Wi-Fi
        </span>

        <div className="flex flex-col w-full mt-2 divide-y">
          {!isSso && (
            <Button className={`my-4 font-black ${isSigningIn ? 'mt-8' : ''}`} onClick={register}>
              Create Account
            </Button>
          )}

          {!isSigningIn && (
            <>
              <div className="h-4"></div>
              <Button layout="secondary" onClick={() => redirectToLogin()}>
                {authType === 'sso' && schoolUidName !== ''
                  ? `Sign In ( ${schoolUidName} )`
                  : `Sign In`}
              </Button>
            </>
          )}
          <div className="w-full flex flex-row justify-end">
            <Button
              layout="link"
              className="mt-2 text-xs text-right"
              onClick={() => {
                isSso
                  ? history.push('/support')
                  : !isSso && !isSigningIn
                  ? history.push('/forgot-password')
                  : history.push('/');
              }}
            >
              {isSso ? 'Need help with sign-in?' : 'Forgot Username or Password?'}
            </Button>
          </div>

          {isGuestType && (
            <>
              <div className="h-4"></div>
              <Button layout="secondary" onClick={() => history.push('/guest-visitors')}>
                Guests and Visitors
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default WelcomeOptions;
