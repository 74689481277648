import { FCWithChildren } from '@types';
import React, { useMemo, useRef } from 'react';
import { ISupportQuestions } from 'sdk-apogee';
import QuestionsList from '../QuestionsList';

type SectionCellProps = {
  questionItem: ISupportQuestions;
  refsContainer: {
    current: Record<string, React.MutableRefObject<HTMLElement | null>>;
  };
  searchTerm?: string | undefined;
  isTopicShow?: boolean;
};

const SectionCell: FCWithChildren<SectionCellProps> = ({
  questionItem,
  refsContainer,
  searchTerm,
}) => {
  const { id, topic, questionsAndAnswers } = questionItem;
  const isTopicShow: boolean = useMemo(() => {
    const escapedSearchTerm =
      searchTerm && searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')?.toLowerCase();
    if (!escapedSearchTerm) return false;
    return !!questionsAndAnswers.find(
      ({ question, answerHtml }) =>
        question?.toLowerCase().indexOf(escapedSearchTerm) !== -1 ||
        answerHtml?.toLowerCase().indexOf(escapedSearchTerm) !== -1,
    );
  }, [questionsAndAnswers, searchTerm]);

  refsContainer.current[id] = useRef(null);

  return (
    <section className="py-8 px-0" ref={refsContainer.current[id]}>
      {searchTerm ? (
        isTopicShow ? (
          <h2 className="text-xl font-bold">{topic}</h2>
        ) : null
      ) : (
        <h2 className="text-xl font-bold">{topic}</h2>
      )}
      <QuestionsList questionsAndAnswers={questionsAndAnswers} searchTerm={searchTerm} />
    </section>
  );
};

export default SectionCell;
