type TRgb = {
  r: number;
  g: number;
  b: number;
};
const luminance = ({ r, g, b }: TRgb): number => {
  const a = [r, g, b].map((v: number) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export default luminance;
