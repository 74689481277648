import { FCWithChildren } from '@types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from '@tanstack/react-query';
import { Modal, ModalBody, ModalHeader, Label, Button, Input } from '@components';
import { deleteProfile } from 'sdk-apogee';
import classNames from 'classnames';

type errorType = {
  data: { error: string };
};

type DeleteAccountModalProps = {
  closeModal: () => void;
  isOpen: boolean;
  recoveryQuestion: string;
  accountId: number;
  signOut: () => void;
};

const DeleteAccountModal: FCWithChildren<DeleteAccountModalProps> = ({
  closeModal,
  isOpen,
  accountId,
  signOut,
}) => {
  const { addToast } = useToasts();
  const [password, setPassword] = useState(undefined as undefined | string);

  const { mutate: mutation, isPending } = useMutation({
    mutationFn: () => deleteProfile(accountId, password),
    onSuccess: () => {
      closeModal();
      addToast('Account deleted successfully.', { appearance: 'success' });
      signOut();
    },
    onError: (error: errorType) => {
      let errorMessage =
        'Some error occured while deleting your account. Please contact administrator.';
      if (error?.data?.error) {
        errorMessage = error?.data?.error;
      }
      addToast(errorMessage, { appearance: 'error' });
    },
  });

  const { handleSubmit } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onSubmit = (data: any) => {
    mutation(data);
  };

  const buttonStyle = classNames('w-full bg-gray-400', 'w-2/12 md:w-2/5 md:mt-0');

  return (
    <Modal closeModal={closeModal} isOpen={isOpen} customWebClass="max-h-screen-90 max-w-90">
      <ModalHeader> Delete Account </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="flex flex-col full-width mx-auto my-0 font-thin">
            <span className="my-2">Are you sure you want to delete your account?</span>
            <span className="my-2">
              If you confirm, your account will be deleted within 48 hours.
            </span>
            <span className="my-2">
              To delete your account and sign out, confirm your Password:
            </span>
          </section>
          <section className="py-2 md:py-4 flex flex-col w-10/12 md:w-3/5">
            <Label className="my-2 font-thin text-sm">
              <span>Password:</span>
              <Input
                name="password"
                type="password"
                onChange={onChangeHandler}
                autoFocus
                className="my-1"
              />
            </Label>
          </section>
          <div>
            <Button
              className={buttonStyle + ' mr-6 mb-4'}
              disabled={isPending}
              loading={isPending}
              type="submit"
              block
              secondary
            >
              Delete
            </Button>
            <Button className={buttonStyle + ' mr-6 mb-4'} block primary onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default DeleteAccountModal;
