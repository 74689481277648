import { FCWithChildren } from '@types';
import React, { useState, Fragment } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToasts } from 'react-toast-notifications';

import { Button, Modal, ModalBody, ModalHeader } from '@components';
import { addDevice } from 'sdk-apogee';

import { ActionTypes } from '../Steps/Steps';
import { useHistory } from 'react-router-dom';

type DataErrorType = {
  deviceConflict: boolean;
  errors?: Record<string, string[]>;
  error?: string;
};

type ErrorType = {
  data: DataErrorType;
};

type ConflictDeviceType = {
  name: string;
  deviceTypeId: number;
  macAddress: string;
};

type Params = {
  deviceTypeId: number;
  macAddress: string;
  name: string;
  request_conflict_resolution: boolean;
};

type DeviceConflictModalProps = {
  closeModal: () => void;
  isOpen: boolean;
  isAutoDetect?: boolean;
  isAutoDetectSignedIn?: boolean;
  conflictingDevice: ConflictDeviceType | null | undefined;
  dispatch: React.Dispatch<ActionTypes>;
  clearFields: undefined | (() => void);
};

const DeviceConflictModal: FCWithChildren<DeviceConflictModalProps> = ({
  isOpen,
  isAutoDetect,
  isAutoDetectSignedIn,
  closeModal,
  conflictingDevice,
  clearFields,
}) => {
  const { addToast } = useToasts();
  const [isAdded, setIsAdded] = useState(false);
  const history = useHistory();
  const queryClient = useQueryClient();
  const { mutate: createDevice } = useMutation({
    mutationFn: (values: Params) => addDevice(values),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['devices'] });
      closeModal();
    },
    onError: (apiErrors: ErrorType) => {
      if (apiErrors.data.deviceConflict) {
        clearFields && clearFields();
        setIsAdded(true);
      } else if (apiErrors.data.error) {
        addToast(apiErrors.data.error, { appearance: 'error' });
        closeModal();
      } else {
        addToast('Something went wrong. Try again later.', { appearance: 'error' });
        closeModal();
      }
    },
  });

  const continueHandler = () => {
    const conflictingDeviceData = conflictingDevice;
    createDevice({ ...conflictingDeviceData, request_conflict_resolution: true } as Params);
  };

  return (
    <Modal closeModal={closeModal} isOpen={isOpen}>
      <ModalHeader closeModal={closeModal}>
        <span>{isAutoDetect ? 'This device is already registered' : 'Device Conflict'}</span>
      </ModalHeader>
      {isAutoDetect ? (
        <ModalBody>
          <div className="w-full flex flex-col items-center justify-center">
            <span className="pt-4 pb-6">
              Go back and click
              <Button
                layout="link"
                size="noPadding"
                className="justify-start"
                onClick={() => {
                  history.push('/forgot-password');
                }}
              >
                <div className="w-full flex flex-row items-center justify-between text-primary text-base font-medium text-left py-1">
                  Forgot Username or Password
                </div>
              </Button>
              link to have email sent to account.
            </span>
            <div className="w-full flex justify-around pt-2">
              <Button onClick={closeModal}>Close</Button>
            </div>
          </div>
        </ModalBody>
      ) : (
        <ModalBody>
          <div className="w-full flex flex-col items-center justify-center">
            <span className="pt-4 pb-2">{conflictingDevice?.macAddress}</span>
            <span className="pt-4 pb-6">
              {isAdded
                ? 'Your request has been sent. You will be notified when the conflict is resolved. If you do not get a response within 2 days, please contact Support.'
                : isAutoDetectSignedIn
                ? "This MAC Address is already registered on another account. If this is your device, click 'Continue' to send a removal request to that account owner."
                : "This MAC Address is already registered on another account. Please verify that you have entered the correct MAC Address. If correct, click 'Continue' to send a removal request to that account owner."}
            </span>
            <div className="w-full flex justify-around pt-2">
              {isAdded ? (
                <Button onClick={closeModal}>OK</Button>
              ) : (
                <Fragment>
                  <Button onClick={continueHandler}>Continue</Button>
                  <Button onClick={closeModal}>Cancel</Button>
                </Fragment>
              )}
            </div>
          </div>
        </ModalBody>
      )}
    </Modal>
  );
};

export default DeviceConflictModal;
