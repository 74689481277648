import { FCWithChildren } from '@types';
import React, { useEffect, useRef, useState, Fragment } from 'react';
import useLayoutContext from '@hooks/useLayoutContext';
import { Button, DevicesList, SharedList } from '@components';
import { useLocation } from 'react-router-dom';

type TabsProps = {
  addDeviceAnchorHandler: () => void;
};

const Tabs: FCWithChildren<TabsProps> = ({ addDeviceAnchorHandler }) => {
  const { panSharingEnabled } = useLayoutContext();
  const [activeTab, setActiveTab] = useState('MY_DEVICES');
  const location = useLocation();
  const tabRef = useRef<HTMLDivElement | null>(null);
  const locationState = location.state as { activeTab?: string } | undefined;

  useEffect(() => {
    if (locationState?.activeTab) {
      setActiveTab(locationState?.activeTab);
      setTimeout(() => {
        (tabRef?.current as any)?.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  }, [locationState]);

  const activeBtnClass = (currentTab: string) => {
    return currentTab === activeTab
      ? 'bg-indigo-300 border-b-2 border-black'
      : 'border-b-2 border-transparent';
  };

  return (
    <div className="flex flex-col items-center w-full mt-12" ref={tabRef}>
      <div className="flex flex-row justify-start mb-2 border-b border-gray-400 items center sm:mb-8">
        <div className={`${activeBtnClass('MY_DEVICES')} flex justify-center w-40`}>
          <Button layout="link" size="small" onClick={() => setActiveTab('MY_DEVICES')}>
            <span className="text-base font-semibold text-black uppercase">My devices</span>
          </Button>
        </div>

        {panSharingEnabled && (
          <Fragment>
            <div className="w-4" />

            <div className={`${activeBtnClass('SHARED_WITH')} flex justify-center w-40`}>
              <Button layout="link" onClick={() => setActiveTab('SHARED_WITH')}>
                <span className="text-base font-semibold text-black uppercase">Shared With</span>
              </Button>
            </div>
          </Fragment>
        )}
      </div>

      {activeTab === 'MY_DEVICES' ? (
        <DevicesList addDeviceAnchorHandler={addDeviceAnchorHandler} />
      ) : (
        <SharedList />
      )}
    </div>
  );
};

export default Tabs;
