import { FCWithChildren } from '@types';
import React from 'react';
import { Link } from 'react-router-dom';

import { IUserDevice } from 'sdk-apogee';

import { Button } from '@components';

import MobileDevice from './MobileDevice';
import usePackages from '@hooks/usePackages';

type MobileDevicesListProps = {
  addDeviceAnchorHandler: () => void;
  devices: IUserDevice[];
  showFooter?: boolean;
  openStatusInfoModal: (str: string) => void;
};

const MobileDevicesList: FCWithChildren<MobileDevicesListProps> = ({
  addDeviceAnchorHandler,
  devices,
  showFooter = true,
  openStatusInfoModal,
}) => {
  const { data: packages = [] } = usePackages();

  return (
    <div className="flex flex-col w-full">
      {devices.map((device) => (
        <MobileDevice key={device.id} {...device} openStatusInfoModal={openStatusInfoModal} />
      ))}
      {showFooter && (
        <div className="my-2 px-4">
          <Button block layout="link" className="text-blue-700" onClick={addDeviceAnchorHandler}>
            Add Device
          </Button>
          {packages.length > 1 && (
            <Link to="/services">
              <Button block className="border-dashed border-4 my-2" layout="outline">
                Upgrade for More Devices
              </Button>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default MobileDevicesList;
