import { FCWithChildren } from '@types';
import React from 'react';

import { MdFileUpload } from 'react-icons/md';
import { MdImportantDevices } from 'react-icons/md';

import { Modal, ModalHeader, ModalBody } from '@components';

type AddDeviceOptionsModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  openAddModal: () => void;
  openImportModal: () => void;
};

const AddDeviceOptionsModal: FCWithChildren<AddDeviceOptionsModalProps> = ({
  isOpen,
  closeModal,
  openAddModal,
  openImportModal,
}) => {
  const addIndividual = () => {
    closeModal();
    openAddModal();
  };

  const openImport = () => {
    closeModal();
    openImportModal();
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <ModalHeader>
        <span className="font-semibold">Add a Device</span>
      </ModalHeader>
      <ModalBody>
        <div className="flex flex-row w-full">
          <div
            className="flex flex-col w-1/2 justify-center items-center cursor-pointer"
            onClick={openImport}
            onKeyPress={openImport}
            role="presentation"
          >
            <div className="rounded-full h-20 w-20 flex items-center justify-center bg-gray-300">
              <MdFileUpload className="text-2xl text-gray-600" />
            </div>
            <span className="text-xs my-4">Upload CSV</span>
          </div>
          <div
            className="flex flex-col w-1/2 justify-center items-center cursor-pointer"
            onClick={addIndividual}
            onKeyPress={addIndividual}
            role="presentation"
          >
            <div className="rounded-full h-20 w-20 flex items-center justify-center bg-gray-300">
              <MdImportantDevices className="text-2xl text-gray-600" />
            </div>
            <span className="text-xs my-4">Individual</span>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddDeviceOptionsModal;
