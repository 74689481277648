import { FCWithChildren } from '@types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Modal, ModalBody, Input, Label, HelperText } from '@components';
import { useForm } from 'react-hook-form';
import useLayoutContext from '@hooks/useLayoutContext';
// @ts-ignore
import ApogeeEuaPdf from '@assets/documents/apogee-end-user-agreement.pdf';

type ModalGuestAccountProps = {
  deviceConflict?: boolean;
  error?: any;
  loading?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

type FormData = {
  termsAndConditions?: boolean;
};

const ModalGuestAccount: FCWithChildren<ModalGuestAccountProps> = ({
  deviceConflict,
  error,
  isOpen,
  loading,
  onClose,
  onConfirm,
}) => {
  const history = useHistory();
  const { type } = useLayoutContext();
  const isPropertyType = type === 'Property';

  const GUEST_SCHEMA = yup.object().shape({
    termsAndConditions: yup.boolean().oneOf([true], 'You must accept the Terms and Conditions'),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(GUEST_SCHEMA),
  });

  const redirectToLogin = () => {
    return history.push('/');
  };
  const isValid = (input: string, isController: boolean = false): boolean =>
    isController ? !(errors as any)[input]?.value?.message : !(errors as any)[input]?.message;

  return (
    <Modal closeModal={onClose} isOpen={isOpen}>
      <form>
        <ModalBody>
          <div className="mb-6">
            <h1 className="text-3xl antialiased font-normal">Guest Account</h1>
            <div className="text-sm antialiased font-thin">
              Already have an account?{' '}
              <Button layout="link" size="noPadding" onClick={() => redirectToLogin()}>
                Sign in
              </Button>
            </div>
          </div>
          <Label check>
            <Input
              {...register('termsAndConditions')}
              type="checkbox"
              {...(isValid('termsAndConditions') ? {} : { valid: false })}
            />
            <p className="px-2 text-xs-left">
              Check box to indicate you agree to the{' '}
              <a
                href={
                  isPropertyType ? 'https://mycampusnet.com/public/files/CC_EUA.pdf' : ApogeeEuaPdf
                }
                target="_blank"
                rel="noreferrer noopener"
                className="underline border-2 border-transparent focus:border-blue-700"
              >
                Terms and Conditions
              </a>
            </p>
          </Label>
          <HelperText valid={isValid('termsAndConditions')} className="flex">
            {errors.termsAndConditions?.message}
          </HelperText>
          {!!error && (
            <HelperText valid={false} className="flex">
              {deviceConflict
                ? 'This device is already registered, please go back and click Forgot Username or Password if needed.'
                : 'There was a problem creating a temporary guest account. Please try again.'}
            </HelperText>
          )}
          <div className="flex flex-col w-full mt-5 divide-y">
            <Button loading={loading} onClick={handleSubmit(onConfirm)}>
              Create Guest Account
            </Button>
          </div>
        </ModalBody>
      </form>
    </Modal>
  );
};

export default ModalGuestAccount;
