"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var order = [
    'active',
    'disconnected',
    'inactive',
    'unknown',
    'quarantined',
];
var getDevices = function () {
    return (0, service_1.getService)()
        .get('/devices')
        .then(function (_a) {
        var data = _a.data;
        return data.sort(function (_a, _b) {
            var a = _a.status, aDate = _a.lastLogin;
            var b = _b.status, bDate = _b.lastLogin;
            return (order.indexOf(a) < order.indexOf(b)) ? -1 : (order.indexOf(a) > order.indexOf(b)) ? 1
                : (new Date(aDate || 0) > new Date(bDate || 0)) ? -1 : (new Date(aDate || 0) < new Date(bDate || 0)) ? 1 : 0;
        });
    });
};
exports.default = getDevices;
