import { FCWithChildren } from '@types';
import React, { useState } from 'react';

import { BsThreeDots } from 'react-icons/bs';
import { Dropdown, DropdownItem } from '@components';

type SchoolProps = {
  id: number;
  logo: string;
  name: string;
  status: string;
  url: string;
};

const School: FCWithChildren<SchoolProps> = ({ id, name, status, url }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div
      key={id}
      className="w-full lg:w-8/12 xl:w-8/12 flex items-center py-4 border-t first:border-t-0"
    >
      <div className="mr-10">
        <div className="w-24 h-24 relative mb-4">
          <div className="group w-full h-full rounded-full overflow-hidden shadow-inner text-center bg-purple table cursor-pointer">
            <img
              src="https://pickaface.net/gallery/avatar/unr_random_180410_1905_z1exb.png"
              alt="University Logo"
              className="object-cover object-center w-full h-full visible group-hover:hidden"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center">
        <h1>{url}</h1>
        <small className="text-gray-800">{name}</small>
        <span className="text-sm text-gray-800 mt-2">{status}</span>
      </div>
      <div className="relative ml-auto">
        <BsThreeDots className="cursor-pointer" onClick={toggleDropdown} size={25} />
        <Dropdown className="right-1/2" isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <DropdownItem>
            <span>Edit</span>
          </DropdownItem>
          <DropdownItem>
            <span>Duplicate</span>
          </DropdownItem>
          <DropdownItem>
            <span>Delete</span>
          </DropdownItem>
        </Dropdown>
      </div>
    </div>
  );
};

export default School;
