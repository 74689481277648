import { FCWithChildren } from '@types';
import React from 'react';
import moment from 'moment';

import { Invitee, Button } from '@components';

import { INetworkInfo, IInvitee } from 'sdk-apogee';

import { useAppContext } from '@contexts/AppContext';
import useLayoutContext from '@hooks/useLayoutContext';

import { SEND_INVITE, EXPIRE_NETWORK, LEAVE_NETWORK, ActionTypes } from '../SharedList/SharedList';

type NetworkMembersTableProps = {
  myNetworkStatus: INetworkInfo;
  dispatch: (action: ActionTypes) => void;
};

const NetworkMembersTable: FCWithChildren<NetworkMembersTableProps> = ({
  dispatch,
  myNetworkStatus,
}) => {
  const { user } = useAppContext();
  const { isMobile } = useLayoutContext();

  const { ownerId, ownerName, ownerEmail, invitations, expires, duration } = myNetworkStatus;

  const openSendInviteModal = () => dispatch({ type: SEND_INVITE });

  const isHost = user?.id === ownerId;

  const buttonTxt = isHost ? 'End Shared Network' : 'Leave Network';

  const members = invitations.filter((user) => user.accepted);

  const endSessionHandler = () => {
    if (isHost) {
      dispatch({ type: EXPIRE_NETWORK });
    } else {
      dispatch({ type: LEAVE_NETWORK });
    }
  };

  const hostInfo = {
    accountId: ownerId,
    accountName: ownerName,
    accountEmail: ownerEmail,
  };

  const expirationDate = expires
    ? moment.utc(new Date(expires)).local().format('MM/DD/YYYY h:mm A')
    : 'None';

  const expirationTxt = duration === null ? 'None' : expirationDate;

  return (
    <div className="w-full h-full flex flex-col justify-center items-center my-4">
      <div className="w-full flex justify-between text-gray-600 text-sm uppercase pl-2">
        <span>NAME</span>
        {!isMobile && (
          <span>
            NETWORK EXPIRATION: <span className="text-black">{expirationTxt}</span>
          </span>
        )}
      </div>
      <div className="w-full border border-gray-400 rounded-md mt-2 overflow-hidden">
        <Invitee
          invitee={hostInfo as IInvitee}
          isHost
          isLast={!members.length}
          dispatch={() => {}}
        />

        {members.map((invitee, idx) => (
          <Invitee
            key={invitee.accountId}
            invitee={invitee}
            isLast={idx === invitations.length - 1}
            dispatch={() => {}}
          />
        ))}

        {isHost && (
          <div className="w-full flex justify-center border-t border-gray-200">
            <div className="w-full flex justify-center py-4 px-2">
              <Button block onClick={openSendInviteModal}>
                Send Invite
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="w-full flex flex-col items-end">
        {isMobile && (
          <span className="text-sm pt-2 w-full text-gray-600 text-sm uppercase">
            NETWORK EXPIRATION: <span className="text-black">{expirationTxt}</span>
          </span>
        )}

        <Button layout="link" size="noPadding" className="py-2" onClick={endSessionHandler}>
          {buttonTxt}
        </Button>
      </div>
    </div>
  );
};

export default NetworkMembersTable;
