const serialize = (node: any) => {
  if (node.type === 'tag') {
    switch (node.name) {
      case 'block':
        node.name = 'div';
        break;
      case 'quote':
        node.name = 'blockquote';
        break;
      case 'paragraph':
        node.attribs.class = 'font-light text-sm md:text-base mt-12';
        break;
      case 'li':
        node.attribs.class = 'py-2';
        break;
      case 'a':
        node.attribs.class = 'text-blue-700';
        break;
      case 'ol':
        node.name = 'ul';
        node.attribs.class = 'list-decimal';
        break;
      case 'ul':
        node.attribs.class = 'list-disc py-1 sm:py-4';
        break;
    }
  }

  return node;
};

export default serialize;
