"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var resetPassword = function (_a) {
    var token = _a.token, password = _a.password, passwordConfirmation = _a.passwordConfirmation;
    return (0, service_1.getService)()
        .put('/reset_password', {
        account: {
            reset_password_token: token,
            password: password,
            password_confirmation: passwordConfirmation,
        },
    })
        .then(function (_a) {
        var data = _a.data;
        return data;
    })
        .catch(function (_a) {
        var response = _a.response;
        return Promise.reject(response);
    });
};
exports.default = resetPassword;
