import { FCWithChildren } from '@types';
import React, { Fragment } from 'react';

import { STORAGE_URL } from 'sdk-apogee';

import useLayoutContext from '@hooks/useLayoutContext';
import { serializeHtml } from '@utils';

type Stream2Props = {
  isMobile?: boolean;
};

const Stream2: FCWithChildren<Stream2Props> = ({ isMobile }) => {
  const { marketingEnabled, marketingHeader, marketingImage, marketingCopy } = useLayoutContext();

  const marketingImg = `${STORAGE_URL}${marketingImage}`;

  return (
    <Fragment>
      {marketingEnabled && marketingCopy ? (
        <section className="mt-4 bg-gray-100 -mx-40 px-40 md:mt-8">
          <h1 className="text-base pt-4 md:pt-24 md:text-2xl">{marketingHeader}</h1>

          <div className="flex flex-col pt-8 pb-4 md:pb-8 md:flex-row">
            <div className="flex flex-col w-full md:w-1/2 md:pr-10">
              <div
                dangerouslySetInnerHTML={{
                  __html: serializeHtml(marketingCopy?.document),
                }}
              />
            </div>

            {!isMobile && (
              <div className="flex flex-col sm:w-full justify-start item-start pl-10 md:w-1/2">
                <img alt="Stream2" src={marketingImg} className="w-4/5 object-cover" />
              </div>
            )}
          </div>

          <div>
            {!isMobile && (
              <div className="divide-y">
                <div className="w-full"></div>
                <div></div>
              </div>
            )}
          </div>

          {isMobile && (
            <div className="flex w-full justify-center item-center pb-16">
              <img alt="Stream2" src={marketingImg} className="w-4/5 object-cover" />
            </div>
          )}
        </section>
      ) : (
        <div className="mt-4 -mx-40 px-40 md:mt-8" />
      )}
    </Fragment>
  );
};

export default Stream2;
