import { buildUrl } from './index';
import { RouteComponentProps } from 'react-router-dom';

const redirectCsrCreatedUser = (queryString: string, history: RouteComponentProps['history']) => {
  const urlParams = new URLSearchParams(queryString);
  const csrCreatedToken = urlParams.get('token');
  const csrCreated = urlParams.get('csr_created');
  const domain = urlParams.get('domain');

  if (csrCreatedToken && csrCreated) {
    if (domain) {
      urlParams.delete('domain');
      window.location.replace([buildUrl(domain.split('.')[0]), urlParams.toString()].join('?'));
    } else {
      return history.push({
        pathname: '/retrieve-profile',
        state: { token: csrCreatedToken },
      });
    }
  }
};

export default redirectCsrCreatedUser;
