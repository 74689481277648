import React, { useState, createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { NotFound } from '@components';
import { FCWithChildren } from '@types';

type FormErrorType = Record<string, string[]>;

type ErrorType = {
  status: string;
  data: {
    errors?: FormErrorType;
    error?: string;
  };
};

type ErrorContextType = {
  setErrorContext: (error: any) => void;
  setError: (e: any, fn: (e: any) => void) => void;
};

const ErrorStatusContext = createContext<ErrorContextType>({} as ErrorContextType);

const ErrorContext: FCWithChildren<{}> = ({ children }) => {
  const history = useHistory();
  const [errorContext, setErrorContext] = useState<any>();

  React.useEffect(() => {
    const unlisten = history.listen(() => setErrorContext(undefined));

    return unlisten;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const buildErrorMsg = (inputErrors: FormErrorType) => {
    const fields = Object.keys(inputErrors);
    return fields.map((field) => ({
      field,
      message: capitalizeFirstLetter(inputErrors[field].join(' .')),
    }));
  };

  const setError = (error: ErrorType, fn: (error: any) => void) => {
    setErrorContext(error);
    // @ts-ignore
    fn(buildErrorMsg(error.data.errors));
  };

  const renderContent = () => {
    if (errorContext) {
      switch (errorContext.status) {
        case 404:
          return <NotFound />;
        case 422:
          return children;
        default:
          return <NotFound />;
      }
    }

    return children;
  };

  const contextPayload = React.useMemo(() => ({ setErrorContext }), [setErrorContext]);

  return (
    <ErrorStatusContext.Provider value={{ ...contextPayload, setError } as any}>
      {renderContent()}
    </ErrorStatusContext.Provider>
  );
};

const useErrorContext = () => useContext(ErrorStatusContext);

export { ErrorContext, useErrorContext };
