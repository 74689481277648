import React, { forwardRef, useReducer } from 'react';

import ConfirmationModal from '../ConfirmationModal';
import DeviceConflictModal from '../DeviceConflictModal';
import DeviceForm from '../DeviceForm';

import { RequestManagerWrapper } from '@components';

export const OPEN_CONFLICT_MODAL = 'OPEN_CONFLICT_MODAL';
export const PROCEED_ADDING_CONFLICT_DEVICE = 'PROCEED_ADDING_CONFLICT_DEVICE';
export const CONFLICT_DEVICE_ADDED = 'CONFLICT_DEVICE_ADDED';
export const CLOSE_CONFLICT_MODAL = 'CLOSE_CONFLICT_MODAL';
export const SAVE_CONFLICT_DEVICE_INFO = 'SAVE_CONFLICT_DEVICE_INFO';
export const RESET_DEVICE_INFO = 'RESET_DEVICE_INFO';

type ConflictDeviceType = {
  name: string;
  deviceTypeId: number;
  macAddress: string;
};
interface ActionType {
  type: string;
  payload?: {
    conflictingDevice?: ConflictDeviceType | null | undefined;
    clearFields?: undefined | (() => void);
  };
}
interface PostAddActionInterface extends ActionType {
  network?: string;
}

interface AddActionType extends ActionType {
  network?: string;
}

export type ActionTypes = PostAddActionInterface | AddActionType;

export type StateType = {
  isModalOpen: boolean;
  conflictingDevice: ConflictDeviceType | null | undefined;
  clearFields?: undefined | (() => void);
};

const initialState = {
  isModalOpen: false,
  conflictingDevice: null,
};

const reducer = (state: StateType, action: ActionTypes): StateType => {
  switch (action.type) {
    case OPEN_CONFLICT_MODAL:
      return {
        ...state,
        isModalOpen: true,
      };

    case PROCEED_ADDING_CONFLICT_DEVICE:
      return {
        ...state,
        isModalOpen: true,
      };

    case CONFLICT_DEVICE_ADDED:
      return {
        ...state,
        isModalOpen: true,
      };

    case SAVE_CONFLICT_DEVICE_INFO:
      return {
        ...state,
        conflictingDevice: action.payload?.conflictingDevice,
        clearFields: action.payload?.clearFields,
      };

    case RESET_DEVICE_INFO:
      return {
        ...state,
        conflictingDevice: null,
      };

    case CLOSE_CONFLICT_MODAL:
      return {
        ...state,
        isModalOpen: false,
      };

    default:
      return state;
  }
};

const Steps = forwardRef<HTMLDivElement, {}>(function WrappedSection(props, ref) {
  const [isConfirmationModalOpen, toggleConfirmationModal] = useReducer((isOpen) => !isOpen, false);
  const [conflictModalState, dispatch] = useReducer(reducer, initialState);

  const openConflictModal = () => {
    dispatch({ type: OPEN_CONFLICT_MODAL });
  };
  const closeConflictModal = () => {
    dispatch({ type: CLOSE_CONFLICT_MODAL, payload: { conflictingDevice: null } });
  };

  const { isModalOpen, conflictingDevice, clearFields } = conflictModalState;

  return (
    <div className="-mx-40 px-40 bg-gray-200 py-8" ref={ref}>
      <ConfirmationModal closeModal={toggleConfirmationModal} isOpen={isConfirmationModalOpen} />

      <DeviceConflictModal
        closeModal={closeConflictModal}
        isOpen={isModalOpen}
        conflictingDevice={conflictingDevice}
        clearFields={clearFields}
        dispatch={dispatch}
      />

      <RequestManagerWrapper>
        <DeviceForm
          closeModal={toggleConfirmationModal}
          openConflictModal={openConflictModal}
          dispatch={dispatch}
        />
      </RequestManagerWrapper>
    </div>
  );
});

export default Steps;
