"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var getQuestionsAndAnswers = function (subdomain) {
    return (0, service_1.getService)()
        .get('/preAuth/questionsAndAnswers', { params: { subdomain: subdomain } })
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.default = getQuestionsAndAnswers;
