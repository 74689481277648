"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var getDeviceStatus = function (values) {
    return (0, service_1.getService)()
        .get("/customers/subdomain/".concat(values === null || values === void 0 ? void 0 : values.domain, "/mac_fetch/").concat(values === null || values === void 0 ? void 0 : values.macAddress))
        .then(function (_a) {
        var data = _a.data;
        return data;
    })
        .catch(function (_a) {
        var response = _a.response;
        return Promise.reject(response);
    });
};
exports.default = getDeviceStatus;
