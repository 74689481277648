"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var forgotPassword = function (_a) {
    var email = _a.email, url = _a.url, subdomain = _a.subdomain;
    return (0, service_1.getService)()
        .post('/forgot_password', {
        account: { subdomain: subdomain, email: email, url: "".concat(url, "/reset-password?token=") },
    })
        .then(function (_a) {
        var data = _a.data;
        return data;
    })
        .catch(function (_a) {
        var response = _a.response;
        return Promise.reject(response);
    });
};
exports.default = forgotPassword;
