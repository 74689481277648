import React from 'react';

import Login from '@pages/Login';
import { FCWithChildren } from '@types';

const ForgotPassword: FCWithChildren<{}> = () => (
  <Login initialState="FORGOT_PASSWORD" checkEmail />
);

export default ForgotPassword;
