import React from 'react';

import { RequestManagerWrapper, Notifications } from '@components';
import { FCWithChildren } from '@types';

const NotificationPage: FCWithChildren<{}> = () => {
  return (
    <RequestManagerWrapper loadingContainer>
      <Notifications />
    </RequestManagerWrapper>
  );
};

export default NotificationPage;
