import { FCWithChildren } from '@types';
import React, { useState } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useToasts } from 'react-toast-notifications';

import { MdFolder } from 'react-icons/md';
import { BsThreeDots } from 'react-icons/bs';

import { Button, Dropdown, DropdownItem } from '@components';

import { deleteGroup, ICampusGroup } from 'sdk-apogee';

type ItemGroupProps = {
  group: ICampusGroup;
  openDeleteModal: (name: string, fn: () => void) => void;
  openEditModal: (arg: ICampusGroup) => void;
};

const ItemGroup: FCWithChildren<ItemGroupProps> = ({ group, openDeleteModal, openEditModal }) => {
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const closeDropdown = () => setIsOpen(false);

  const { mutate: remove } = useMutation({
    mutationFn: () => deleteGroup(group.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['campusGroups'] });
      closeDropdown();
    },
    onError: (apiError: any) => {
      const formError = apiError?.error;
      if (formError) {
        addToast(formError, { appearance: 'error' });
      } else {
        addToast('An unknown error occurred trying to update the device group', {
          appearance: 'error',
        });
      }
    },
  });

  const { name, campusDevices } = group;

  const toggleDropdown = () => setIsOpen(!isOpen);

  const deleteHandler = () => {
    closeDropdown();
    openDeleteModal(name, remove);
  };

  const editGroup = () => {
    closeDropdown();
    openEditModal(group);
  };

  return (
    <div className="flex w-1/3">
      <div className="flex flex-col w-full mr-8 mb-8 p-4 pb-2 shadow-xl bg-white">
        <div className="flex flex-row justify-between text-xl">
          <Button layout="iconLink" size="noPadding" className="text-gray-600">
            <MdFolder />
          </Button>

          <div className="relative">
            <Button
              layout="iconLink"
              size="noPadding"
              className="text-gray-600"
              onClick={toggleDropdown}
            >
              <BsThreeDots />
            </Button>
            <Dropdown isOpen={isOpen} onClose={() => setIsOpen(false)}>
              <DropdownItem onClick={editGroup}>
                <span>Edit</span>
              </DropdownItem>
              <DropdownItem onClick={closeDropdown}>
                <span>Duplicate</span>
              </DropdownItem>
              <DropdownItem onClick={closeDropdown}>
                <span>Activate</span>
              </DropdownItem>
              <DropdownItem onClick={deleteHandler}>
                <span>Delete</span>
              </DropdownItem>
            </Dropdown>
          </div>
        </div>
        <span className="text-xl py-4 text-gray-800">{name}</span>
        <div className="text-base pt-2 border-t border-gray-400 -mx-4 px-4 text-gray-600">
          <span>Devices: {campusDevices && campusDevices.length}</span>
        </div>
      </div>
    </div>
  );
};

export default ItemGroup;
