import { FCWithChildren } from '@types';
import React from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from '@components';

type DeleteConfirmationModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  confirmedAction: null | (() => void);
  name: string;
};

const DeleteConfirmationModal: FCWithChildren<DeleteConfirmationModalProps> = ({
  isOpen,
  closeModal,
  confirmedAction,
  name,
}) => {
  const deleteHandler = () => {
    confirmedAction && confirmedAction();
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <ModalHeader>
        <span className="font-semibold">Delete</span>
      </ModalHeader>
      <ModalBody>
        <div className="w-full flex justify-center">
          <span className="text-xs">Are you sure you want to delete {name}?</span>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="w-full flex justify-around">
          <Button onClick={closeModal}>Cancel</Button>
          <Button onClick={deleteHandler}>Yes</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteConfirmationModal;
