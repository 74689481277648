import React, { useReducer } from 'react';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import { MdImportantDevices } from 'react-icons/md';

import { Button } from '@components';
import { useAppContext } from '@contexts/AppContext';
import useDevices from '@hooks/useDevices';
import useLayoutContext from '@hooks/useLayoutContext';
import PackageExpirationModal from '../PackageExpirationModal';
import { FCWithChildren } from '@types';

const border = 'border rounded-md shadow-md';
const size = 'w-full w-2/3 sm:w-5/12 md:w-1/3';
const position =
  'md:absolute mt-32 right-0 ml-auto mr-auto left-1/4 sm:left-1/2 lg:left-7/12 xl:left-7/12';
const padding = 'py-6 px-8';

const RegisteredDevices: FCWithChildren<{}> = () => {
  const history = useHistory();
  const { user } = useAppContext();
  const { wifiPasswordEnabled } = useLayoutContext();

  const { data } = useDevices();

  const devicesContainer = classNames('bg-white mx-2 text-black', border, size, position, padding);

  const addDeviceHandler = () =>
    history.push({ pathname: '/manage-devices', state: { isAddingDevice: true } });

  const goToWifiPasswordHandler = () => history.push('/manage-devices');

  const remainingAllowedDevices = () =>
    user?.maxDevices ? `${data?.length || 0} of ${user?.maxDevices}` : `${data?.length}`;

  const remainingAllowedSessions = () =>
    user?.maxSessions
      ? `${data?.filter((d) => d.status === 'active').length || 0} of ${user?.maxSessions}`
      : `${data?.length}`;

  const remainingAllowed = () =>
    user?.maxSessions ? remainingAllowedSessions() : remainingAllowedDevices();

  const userExpiredPackageWarning = () => user?.expiredPackageWarning;

  const expiredPackageWarning = () =>
    localStorage.getItem('confirmPackageExpiration') !== 'true' && userExpiredPackageWarning();

  const [isPackageExpirationModalOpen, togglePackageExpirationModal] = useReducer(
    (isOpen) => !isOpen,
    expiredPackageWarning(),
  );

  const accountButton = () => {
    togglePackageExpirationModal();
    localStorage.setItem('confirmPackageExpiration', 'true');
    history.push('/account');
  };

  const packagesButton = () => {
    togglePackageExpirationModal();
    localStorage.setItem('confirmPackageExpiration', 'true');
    history.push('/services');
  };

  return (
    <>
      <PackageExpirationModal
        closeModal={togglePackageExpirationModal}
        accountButton={accountButton}
        packagesButton={packagesButton}
        isOpen={isPackageExpirationModalOpen}
      />

      {!userExpiredPackageWarning() && (
        <section className={devicesContainer}>
          <div className="flex flex-col items-center justify-center pb-4">
            <div>
              <MdImportantDevices size={50} />
            </div>
            <span className="text-4xl">{remainingAllowed()}</span>
            <span className="text-base font-semibold">
              {user?.maxSessions ? 'Active Devices' : 'Registered Devices'}
            </span>
          </div>

          <Button block onClick={addDeviceHandler}>
            Add a Device
          </Button>
          {wifiPasswordEnabled && (
            <div className="pb-4 pt-2">
              <Button layout="secondary" block onClick={goToWifiPasswordHandler}>
                Go to Your Wi-Fi Password
              </Button>
            </div>
          )}
        </section>
      )}
    </>
  );
};

export default RegisteredDevices;
