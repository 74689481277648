import { FCWithChildren } from '@types';
import React, { useState, createContext, useContext, useMemo } from 'react';
import { FieldErrors } from 'react-hook-form';

export type UserInfoStepType = {
  errors: FieldErrors<FormData>;
  fields: string[];
  init: boolean;
  valid: any;
  update?: number;
};
type UserInfoStepsType = {
  account: UserInfoStepType;
  location: UserInfoStepType;
  login: UserInfoStepType;
  personal: UserInfoStepType;
};
export type UserInfoType = {
  accountType: string;
  building: any;
  customerId?: number;
  email: string;
  emailConfirmation: string;
  firstName: string;
  group?: boolean;
  lastName: string;
  pan_room: any;
  password: string;
  passwordConfirmation: string;
  phone: string;
  question: any;
  recoveryAnswer: string;
  room?: string;
  roomNumber?: string;
  termsAndConditions: boolean;
  token?: string;
  userId?: number;
  username: string;
};
type UserInfoContextType = {
  hasExistingData: boolean;
  setHasExistingData: any;
  setSteps: any;
  setStepsErrors: any;
  setUserInfo: any;
  steps: UserInfoStepsType;
  updateStep: any;
  userInfo: UserInfoType;
};
const step = {
  errors: {},
  fields: [],
  init: false,
  valid: undefined,
};
const steps = {
  account: { ...step },
  location: { ...step },
  login: { ...step },
  personal: { ...step },
};
const userInfo = {
  accountType: '',
  building: undefined,
  customerId: undefined,
  email: '',
  emailConfirmation: '',
  firstName: '',
  lastName: '',
  pan_room: undefined,
  password: '',
  passwordConfirmation: '',
  phone: '',
  question: undefined,
  recoveryAnswer: '',
  room: '',
  termsAndConditions: false,
  username: '',
};

const defaultValue = { userInfo, steps };
const UserInfoContext = createContext<UserInfoContextType>({} as UserInfoContextType);

const UserInfoProvider: FCWithChildren = ({ children }) => {
  const [hasExistingData, setHasExistingData] = useState(false);
  const [userInfo, setUserInfo] = useState(defaultValue.userInfo);
  const [steps, setSteps] = useState(defaultValue.steps);
  const setStepsErrors = (errors: Record<string, string[]>) => {
    let stepsWithErrors = { ...steps };
    const setError = (
      step: 'account' | 'login' | 'location' | 'personal',
      key: string,
      error: string,
    ) => {
      stepsWithErrors = {
        ...stepsWithErrors,
        [step]: {
          ...stepsWithErrors[step],
          errors: {
            ...stepsWithErrors[step].errors,
            [key]: !(stepsWithErrors as any)[step].errors[key]
              ? Array.isArray(error)
                ? error
                : [error]
              : Array.isArray(error)
              ? [...(stepsWithErrors as any)[step].errors[key], ...error]
              : [...(stepsWithErrors as any)[step].errors[key], error],
          },
          valid: false,
        },
      };
    };
    Object.keys(errors).forEach((key) => {
      if (Array.isArray(steps.location.fields) && steps.location.fields.includes(key as never)) {
        setError('location', key, (errors as any)[key]);
      } else if (Array.isArray(steps.login.fields) && steps.login.fields.includes(key as never)) {
        setError('login', key, (errors as any)[key]);
      } else if (
        Array.isArray(steps.personal.fields) &&
        steps.personal.fields.includes(key as never)
      ) {
        setError('personal', key, (errors as any)[key]);
      } else {
        setError('account', key, (errors as any)[key]);
      }
    });
    setSteps(stepsWithErrors);
  };
  const updateStep = (step: 'account' | 'login' | 'location' | 'personal', updates: {}) => {
    setSteps({ ...steps, [step]: { ...steps[step], ...updates } });
  };
  const value = useMemo(
    () => ({
      hasExistingData,
      setHasExistingData,
      setSteps,
      setStepsErrors,
      setUserInfo,
      steps,
      updateStep,
      userInfo,
    }),
    [hasExistingData, userInfo, steps, steps.account, steps.login, steps.location, steps.personal],
  );
  return <UserInfoContext.Provider value={value} children={children} />;
};

const useUserInfoContext = () => {
  const context = useContext(UserInfoContext);

  if (context === undefined) {
    throw new Error('useUserInfoContext must be used within a UserInfoProvider');
  }

  return context;
};

export { UserInfoContext, UserInfoProvider, useUserInfoContext };
