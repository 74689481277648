import { FCWithChildren } from '@types';
import React from 'react';

import { Button, SearchBarWMagnifier, SeparatorLine } from '@components';

type MobileSearchbarSectionProps = {
  input: string;
  // eslint-disable-next-line no-unused-vars
  handler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDownHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onContactUsClick: () => void;
};

const MobileSearchSection: FCWithChildren<MobileSearchbarSectionProps> = ({
  input,
  handler,
  onKeyDownHandler,
  onContactUsClick,
}) => {
  return (
    <>
      <div className="w-full flex flex-col justify-evenly items-center">
        <span className="pb-2 pt-4 font-sans text-2xl">Support Center</span>

        <div className="flex flex-row justify-center w-full pt-2 pb-2">
          <SearchBarWMagnifier
            input={input}
            onChangehandler={handler}
            onKeyDownHandler={onKeyDownHandler}
          />
        </div>
        <div className="flex w-full justify-end pb-6">
          <Button layout="link" onClick={onContactUsClick} size="noPadding">
            <span className="text-sm text-black underline">Contact Us</span>
          </Button>
        </div>
      </div>
      <SeparatorLine />
    </>
  );
};

export default MobileSearchSection;
