import { useMutation } from '@tanstack/react-query';
import signUpFreeGuest from 'sdk-apogee/lib/user/signUpFreeGuest';

const useCreateFreeGuestAccount = (
  onSuccess?: (account: any) => void,
  onError?: (error: any) => void,
) => {
  return useMutation({
    mutationFn: (profile: any) => signUpFreeGuest(profile),
    onSuccess: async (account) => {
      if (onSuccess) onSuccess(account);
    },
    onError: (error: any) => {
      if (onError) onError(error);
    },
  });
};

export default useCreateFreeGuestAccount;
