"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var updateProfile = function (id, values, csrCreated) {
    return csrCreated
        ? (0, service_1.getService)()
            .post("/accounts/".concat(id, "/csr_created_update"), values)
            .then(function (_a) {
            var data = _a.data;
            return data;
        })
            .catch(function (_a) {
            var response = _a.response;
            return Promise.reject(response);
        })
        : (0, service_1.getService)()
            .patch("/accounts/".concat(id), values)
            .then(function (_a) {
            var data = _a.data;
            return data;
        })
            .catch(function (_a) {
            var response = _a.response;
            return Promise.reject(response);
        });
};
exports.default = updateProfile;
