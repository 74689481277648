import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { textCssFromBgContrast } from '@utils';
import classNames from 'classnames';
import { FCWithChildren } from '@types';

const AdminNavigation: FCWithChildren<{}> = () => {
  const [bg, setBg] = useState('');

  useEffect(() => {
    const newBg = getComputedStyle(document.documentElement).getPropertyValue(
      '--background-primary',
    );
    if (newBg !== bg) {
      setBg(newBg);
    }
  });

  const textCss = useMemo(
    () =>
      !bg
        ? ''
        : textCssFromBgContrast({
            bg,
            fontSize: '16px',
            fontWeight: '900',
          }),
    [bg],
  );

  return (
    <nav className="ml-auto">
      <ul className={classNames('h-full flex justify-center items-center', textCss)}>
        <li>
          <Link className="mx-2 font-black" to="/schools" aria-label="Schools">
            Schools
          </Link>
        </li>
        <li>
          <Link className="mx-2 font-black" to="/definitions" aria-label="Definitions">
            Definitions
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default AdminNavigation;
