import React from 'react';

import Help from '@pages/Help';
import { RequestManagerWrapper } from '@components';
import { FCWithChildren } from '@types';

const Support: FCWithChildren<{}> = () => {
  return (
    <RequestManagerWrapper loadingContainer>
      <Help />
    </RequestManagerWrapper>
  );
};

export default Support;
