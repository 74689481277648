import { FCWithChildren } from '@types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Modal, ModalBody } from '@components';

type MaxAllowedDevicesModalProps = {
  closeModal: () => void;
  isOpen: boolean;
};

const MaxAllowedDevicesModal: FCWithChildren<MaxAllowedDevicesModalProps> = ({
  closeModal,
  isOpen,
}) => {
  const history = useHistory();

  const handler = () => history.push('/services');

  return (
    <Modal
      closeModal={closeModal}
      isOpen={isOpen}
      noHeader
      customWebClass="w-8/12 sm:w-7/12 md:w-6/12"
    >
      <ModalBody>
        <section className="flex flex-col justify-center items-center">
          <span className="text-center my-2">
            You have reached the maximum number of allowed or active devices
          </span>
          <span className="text-center">Do you wish to upgrade your service package?</span>
          <div className="mt-8">
            <Button onClick={handler}>Upgrade package</Button>
          </div>
        </section>
      </ModalBody>
    </Modal>
  );
};

export default MaxAllowedDevicesModal;
