import { FCWithChildren } from '@types';
import React from 'react';

import { MdClose } from 'react-icons/md';
import { Button } from '@components';

type ModalHeaderProps = {
  closeModal?: () => void;
};

const ModalHeader: FCWithChildren<ModalHeaderProps> = ({ children, closeModal }) => (
  <div className="flex justify-start px-2 py-2 border-b border-solid border-gray-300">
    <Button size="small" layout="link" onClick={closeModal}>
      <MdClose className="text-black opacity-5 text-2xl" />
    </Button>

    <div className="flex flex-row w-full justify-center mr-8">
      <span className="text-xl font-base">{children}</span>
    </div>
  </div>
);

export default ModalHeader;
