import { FCWithChildren } from '@types';
import React, { useState } from 'react';

import classNames from 'classnames';

import { useQueryClient, useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { useToasts } from 'react-toast-notifications';

import { BsThreeDots, BsFillFlagFill } from 'react-icons/bs';

import {
  deleteCampusDevice,
  setFlagStatus,
  ICampusDevice,
  getPlaceOnNetwork,
  INetworkPlace,
} from 'sdk-apogee';

import {
  StatusLed,
  TableCell,
  TableRow,
  Button,
  Dropdown,
  DropdownItem,
  StatusInfo,
} from '@components';
import { Status } from '@types';

type CampusDeviceProps = {
  device: ICampusDevice;
  openEditModal: (arg: ICampusDevice) => void;
  openDeleteModal: (name: string, fn: () => void) => void;
  openStatusInfoModal: (status: string) => void;
};

type FlagStatusType = {
  flag: boolean;
};

const CampusDevice: FCWithChildren<CampusDeviceProps> = ({
  device,
  openEditModal,
  openDeleteModal,
  openStatusInfoModal,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  // @ts-ignore
  const { id, lastLogin, name, deviceGroupName, deviceType, networkId, status, flag } = device;

  const closeDropdown = () => setIsDropDownOpen(false);

  const { data: networkPlaceOptions = [] } = useSuspenseQuery<INetworkPlace[]>({
    queryKey: ['campus-location'],
    queryFn: getPlaceOnNetwork,
  });

  const { mutate: removeDevice } = useMutation({
    mutationFn: () => deleteCampusDevice(id),
    onSuccess: () => {
      addToast('The Device was successfully removed', { appearance: 'success' });
      queryClient.invalidateQueries({ queryKey: ['campus-devices'] });
      closeDropdown();
    },
    onError: (apiError: any) => {
      const formError = apiError?.error;
      if (formError) {
        addToast(formError, { appearance: 'error' });
      } else {
        addToast('An unknown error occurred trying to remove the device', { appearance: 'error' });
      }
    },
  });

  const { mutate: toggleFlagCampusDevice } = useMutation({
    mutationFn: (newState: FlagStatusType) => setFlagStatus(id, newState),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['campus-devices'] });
      closeDropdown();
    },
  });

  const flagBaseStyle = 'text-base';
  const flagCls = classNames(flagBaseStyle, flag ? 'text-red-800' : 'text-gray-700');

  const toggleDropdown = () => setIsDropDownOpen(!isDropDownOpen);

  const removeCampusDevice = () => removeDevice();

  const toggleFlagDevice = () => toggleFlagCampusDevice({ flag: !flag });

  const editDevice = () => openEditModal(device);

  const deleteHandler = () => {
    closeDropdown();
    openDeleteModal(name, removeCampusDevice);
  };

  const groupName = deviceGroupName;

  const placeOnNetwork = networkPlaceOptions.find(
    (networkPlace) => networkPlace.id === networkId,
  )?.name;

  return (
    <TableRow>
      <TableCell>
        <div className="flex justify-start text-base pl-6">
          <Button layout="iconLink" size="noPadding" onClick={toggleFlagDevice}>
            <BsFillFlagFill className={flagCls} />
          </Button>
        </div>
      </TableCell>

      <TableCell>
        <div className="flex justify-start text-base pl-6">
          <span className="text-base text-blue-700">{name}</span>
        </div>
      </TableCell>

      <TableCell>
        <div className="flex flex-col justify-start text-base">
          <span>{groupName}</span>
          <span className="text-sm text-gray-500">{placeOnNetwork}</span>
        </div>
      </TableCell>

      <TableCell>
        <div className="flex flex-col justify-start text-base">
          <span>{deviceType}</span>
        </div>
      </TableCell>

      <TableCell className="flex items-center">
        <div className="flex flex-row w-full justify-between items-center">
          <StatusLed dataTip dataFor={`campus-device-${id}`} type={status as Status} />
          <StatusInfo
            lastLogin={lastLogin}
            status={status}
            id={id}
            openStatusInfoModal={openStatusInfoModal}
          />
          <div className="flex items-center relative">
            <Button
              layout="iconLink"
              size="noPadding"
              className="text-gray-600"
              onClick={toggleDropdown}
            >
              <BsThreeDots className="text-xl" />
            </Button>
            <Dropdown
              align="right"
              isOpen={isDropDownOpen}
              onClose={() => setIsDropDownOpen(false)}
            >
              <DropdownItem onClick={editDevice}>
                <span>Edit</span>
              </DropdownItem>
              <DropdownItem onClick={toggleFlagDevice}>
                <span>{flag ? 'Unflag' : 'Flag'}</span>
              </DropdownItem>
              <DropdownItem onClick={deleteHandler}>
                <span>Delete</span>
              </DropdownItem>
            </Dropdown>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default CampusDevice;
