import { FCWithChildren } from '@types';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useReducer, useEffect } from 'react';

import { useSuspenseQuery } from '@tanstack/react-query';
import { getGroups, ICampusGroup, ICampusGroups } from 'sdk-apogee';

import usePrevious from '@hooks/usePrevious';

import { DashedButton } from '@components';
import GroupModal from '../GroupModal';
import ItemGroup from '../ItemGroup';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

type StateType = {
  campusGroup: ICampusGroup | undefined;
  isModalOpen: boolean;
};
interface ActionType {
  type: string;
  isModalOpen?: boolean;
}
interface EditActionInterface extends ActionType {
  campusGroup?: ICampusGroup;
}

interface AddActionType extends ActionType {
  campusGroup?: undefined;
}

type ActionTypes = EditActionInterface | AddActionType;

type DeleteModalStateType = {
  isOpen: boolean;
  deleteFn: null | (() => void);
  name: string;
};

const initialState = {
  isModalOpen: false,
  campusGroup: undefined,
};

const reducer = (state: StateType, action: ActionTypes): StateType => {
  switch (action.type) {
    case 'addGroup':
      return { ...state, isModalOpen: true };
    case 'editGroup':
      return {
        ...state,
        campusGroup: action.campusGroup && action.campusGroup,
        isModalOpen: true,
      };
    case 'closeModal':
      return {
        ...state,
        campusGroup: undefined,
        isModalOpen: false,
      };
    default:
      return state;
  }
};

type GroupsProps = {
  setTotal: (amount: number) => void;
  page: number;
  pageSize: { label: number; value: number };
  groupSearchResult: ICampusGroups | undefined;
  isShowingSearchResult: boolean;
};

const Groups: FCWithChildren<GroupsProps> = ({
  page,
  pageSize,
  setTotal,
  groupSearchResult,
  isShowingSearchResult = false,
}) => {
  const [list, setList] = useState<ICampusGroup[]>();
  const [{ isModalOpen, campusGroup }, dispatch] = useReducer(reducer, initialState);
  const [deleteModalState, setDeleteModalState] = useState<DeleteModalStateType>({
    isOpen: false,
    deleteFn: null,
    name: '',
  });

  const prevPageIndex = usePrevious(page);
  const prevPageSize = usePrevious(pageSize);

  const { data: allGroups, refetch } = useSuspenseQuery<ICampusGroups>({
    queryKey: ['campusGroups'],
    queryFn: () => getGroups(page, pageSize.value),
    refetchOnMount: true,
  });

  useEffect(() => {
    if (isShowingSearchResult) {
      groupSearchResult && setTotal(groupSearchResult.total);
      groupSearchResult && setList(groupSearchResult?.groups);
    } else {
      allGroups && setTotal(allGroups.total);
      allGroups && setList(allGroups?.groups);
    }
  }, [groupSearchResult, allGroups, isShowingSearchResult]);

  useEffect(() => {
    if (!isShowingSearchResult) {
      if (prevPageSize && pageSize.value !== prevPageSize?.value) refetch();
      if (page !== prevPageIndex) refetch();
    }
  }, [pageSize, page, isShowingSearchResult]);

  const openAddModal = () => dispatch({ type: 'addGroup' });

  const closeModal = () => dispatch({ type: 'closeModal' });

  const openEditModal = (group: ICampusGroup) =>
    dispatch({ type: 'editGroup', campusGroup: group });

  const openDeleteModal = (name: string, fn: () => void) =>
    setDeleteModalState({ isOpen: true, deleteFn: fn, name });

  const closeDeleteModal = () => setDeleteModalState({ isOpen: false, deleteFn: null, name: '' });

  return (
    <div className="flex min-h-screen flex-col w-full px-12 pt-16 bg-gray-100">
      <GroupModal isOpen={isModalOpen} closeModal={closeModal} group={campusGroup} />
      <DeleteConfirmationModal
        isOpen={deleteModalState.isOpen}
        closeModal={closeDeleteModal}
        confirmedAction={deleteModalState.deleteFn}
        name={deleteModalState.name}
      />
      <DashedButton title="Create a Group" handler={openAddModal} />

      <div className="flex w-full flex-wrap">
        {list?.length
          ? list.map((group) => (
              <ItemGroup
                group={group}
                key={group.id}
                openEditModal={openEditModal}
                openDeleteModal={openDeleteModal}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default Groups;
