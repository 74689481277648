import { APOGEE_DOMAIN } from 'sdk-apogee';

const getDomain = () => {
  const location = window.location.host.split('.');
  const isDefaultSubdomain = location[0] === 'www' || location[0] === APOGEE_DOMAIN;
  const domain = isDefaultSubdomain ? location.slice(1).join('.') : location.join('.');

  return domain;
};

export default getDomain;
