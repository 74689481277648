"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-undef */
var service_1 = require("../service");
var getMacAddressFromUploadedImage = function (values) {
    return (0, service_1.getService)()
        .post('/image_rekognition/find_mac_address', values)
        .then(function (_a) {
        var data = _a.data;
        return data;
    })
        .catch(function (error) { return Promise.reject(error); });
};
exports.default = getMacAddressFromUploadedImage;
