import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AppProvider } from '@contexts/AppContext';
import Router from '@routes/Router';
import { FCWithChildren } from '@types';

const App: FCWithChildren<{}> = () => (
  <BrowserRouter>
    <AppProvider>
      <Router />
    </AppProvider>
  </BrowserRouter>
);
export default App;
