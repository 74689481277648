import { FCWithChildren } from '@types';
import React, { Fragment, useEffect, useRef } from 'react';

import { RequestManagerWrapper, Tabs } from '@components';

import { useAppContext } from '@contexts/AppContext';
import useLayoutContext from '@hooks/useLayoutContext';

import Steps from './components/Steps';
import MyDevices from './components/MyDevices';
import Instructions from './components/Instructions';
import SetUpPassword from './components/SetUpPassword';
import usePageTitle from '@hooks/usePageTitle';

type ManageDevicesProps = {
  location?: {
    state: { isAddingDevice: boolean };
  };
};

const ManageDevices: FCWithChildren<ManageDevicesProps> = ({ location }) => {
  usePageTitle('My Devices');
  const isAddingDevice = location && location.state && location.state.isAddingDevice;
  const addDeviceSectionRef = useRef<HTMLDivElement | null>(null);
  const { navBarRef } = useAppContext();
  const { wifiPasswordEnabled } = useLayoutContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addDeviceAnchorHandler = () => {
    const navBarOffset = navBarRef && (navBarRef?.current?.offsetHeight as number);
    const addDeviceSectionPosition =
      addDeviceSectionRef && (addDeviceSectionRef.current?.offsetTop as number);

    window.scrollTo(0, addDeviceSectionPosition - navBarOffset);
  };

  useEffect(() => {
    if (isAddingDevice) {
      addDeviceAnchorHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddingDevice]);

  return (
    <Fragment>
      <MyDevices>
        {wifiPasswordEnabled && <SetUpPassword />}
        <RequestManagerWrapper>
          <Instructions />
        </RequestManagerWrapper>
      </MyDevices>

      <Steps ref={addDeviceSectionRef} />

      <RequestManagerWrapper>
        <Tabs addDeviceAnchorHandler={addDeviceAnchorHandler} />
      </RequestManagerWrapper>
    </Fragment>
  );
};
export default ManageDevices;
