import { FCWithChildren } from '@types';
import React from 'react';

import { Button } from '@components';

import { MdDevicesOther, MdFolder } from 'react-icons/md';
import { FaNetworkWired } from 'react-icons/fa';

type LeftPanelProps = {
  activeButton: string;
  // eslint-disable-next-line no-unused-vars
  buttonHandler: (str: string) => void;
};

const LeftPanel: FCWithChildren<LeftPanelProps> = ({ activeButton, buttonHandler }) => {
  const buttonBackgrounder = (button: string) => {
    return activeButton === button ? 'bg-gray-200' : 'bg-white';
  };

  return (
    <div className="flex flex-col items-center z-10 w-full">
      <div className="flex flex-row items-center pb-4">
        <FaNetworkWired className="mr-2 text-2xl" />
        <span className="text-black text-xl">Campus Devices</span>
      </div>

      <div className="flex flex-row items-center pb-4 w-full">
        <Button
          block
          className={buttonBackgrounder('devices')}
          layout="link"
          onClick={() => buttonHandler('devices')}
        >
          <div className="w-full flex items-center justify-center">
            <MdDevicesOther className="text-xl mr-2" />
            <span className="text-black">Devices</span>
          </div>
        </Button>
      </div>

      <div className="flex flex-row items-center pb-4 w-full">
        <Button
          block
          className={buttonBackgrounder('groups')}
          layout="link"
          onClick={() => buttonHandler('groups')}
        >
          <div className="w-full flex items-center justify-center">
            <MdFolder className="text-xl mr-2" />
            <span className="text-black">Groups</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default LeftPanel;
