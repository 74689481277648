"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var getOrder = function (orderId) {
    return (0, service_1.getService)()
        .get("/orders/".concat(orderId))
        .then(function (_a) {
        var data = _a.data;
        return data;
    })
        .catch(function (_a) {
        var response = _a.response;
        return Promise.reject(response);
    });
};
exports.default = getOrder;
