import { FCWithChildren } from '@types';
import React from 'react';
import { Modal, ModalBody } from '@components';

type ModalThankYouGuestProps = {
  isOpen: boolean;
  onClose: () => void;
  expiry: string;
};

const ModalThankYouGuest: FCWithChildren<ModalThankYouGuestProps> = ({
  isOpen,
  onClose,
  expiry = '',
}) => (
  <Modal closeModal={onClose} isOpen={isOpen}>
    <ModalBody>
      <div className="mb-2 text-center">
        <h2 style={{ fontSize: '125%' }} className="pb-5">
          Thank You!
        </h2>
      </div>
      <div className="mb-5">
        <p>Your Guest Account has been created and this device is now online!</p>
      </div>
      <div className="mb-5">
        <p>Guest access will expire on {expiry}.</p>
      </div>
    </ModalBody>
  </Modal>
);

export default ModalThankYouGuest;
