import { FCWithChildren } from '@types';
import React, { Fragment, useRef, useState, useEffect } from 'react';

import { useSuspenseQuery } from '@tanstack/react-query';
import { ISupportQuestions, getSupportQuestions } from 'sdk-apogee';

import { MobileTopicsList, TopicsList, ContactUs, Information } from '@components';
import useLayoutContext from '@hooks/useLayoutContext';
import { useAppContext } from '@contexts/AppContext';

import SearchbarSection from './components/SearchbarSection';
import TroubleShooting from './components/TroubleShooting';
import usePageTitle from '@hooks/usePageTitle';

const Help: FCWithChildren = () => {
  usePageTitle('Support Center');
  const [enableQuestionsFetch, setEnableQuestionsFetch] = useState(false);
  const { isMobile, subdomain } = useLayoutContext();

  const refsContainer = useRef({});
  const contactUsRef = useRef<HTMLDivElement>(null);
  const { navBarRef } = useAppContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [input, setInput] = useState('');

  useEffect(() => {
    if (subdomain) setEnableQuestionsFetch(true);
  }, [subdomain]);

  const { data: supportQuestions = [] } = useSuspenseQuery<ISupportQuestions[]>({
    queryKey: ['support-questions', subdomain],
    queryFn: () => getSupportQuestions(subdomain),
    // enabled: enableQuestionsFetch,
  });

  const handler = (e: React.ChangeEvent<HTMLInputElement>) => setInput(e.target.value);

  const onClearSearchHandler = () => {
    setSearchTerm('');
    setInput('');
  };

  const onKeyDownHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((e as any).key === 'Enter') {
      setSearchTerm(input);
    }
  };

  const navBarOffset = navBarRef && (navBarRef?.current?.offsetHeight as number);

  const onClickHandler = (reference: React.MutableRefObject<HTMLElement | null>) => {
    const topic = reference.current;
    const firstQuestion = topic?.querySelector<HTMLInputElement>('.collapsible-button');
    window.scrollTo(0, (topic?.offsetTop as number) - navBarOffset);
    firstQuestion?.focus();
  };

  const onContactUsClick = () => {
    window.scrollTo(0, (contactUsRef.current?.offsetTop as number) - navBarOffset);
  };

  return (
    <Fragment>
      {isMobile && (
        <MobileTopicsList
          list={supportQuestions}
          refsContainer={refsContainer}
          onClickHandler={onClickHandler}
        />
      )}

      <SearchbarSection
        input={input}
        handler={handler}
        onClearSearchHandler={onClearSearchHandler}
        onKeyDownHandler={onKeyDownHandler}
        isMobile={isMobile}
        onContactUsClick={onContactUsClick}
        showClearSearch
      />

      {isMobile ? (
        <TroubleShooting
          list={supportQuestions}
          refsContainer={refsContainer}
          searchTerm={searchTerm}
        />
      ) : (
        <div className="flex flex-row font-sans">
          <TopicsList
            list={supportQuestions}
            refsContainer={refsContainer}
            onClickHandler={onClickHandler}
          />
          <TroubleShooting
            list={supportQuestions}
            refsContainer={refsContainer}
            searchTerm={searchTerm}
          />
        </div>
      )}

      <Information currentPage="support" />

      <ContactUs ref={contactUsRef} />
    </Fragment>
  );
};

export default Help;
