import { FCWithChildren } from '@types';
import React from 'react';

import { Button } from '@components';
import { FaQuestionCircle } from 'react-icons/fa';

type ToolTipRightProps = {
  clickHandler?: () => void;
  type: string;
};

const ToolTipRight: FCWithChildren<ToolTipRightProps> = ({ clickHandler, type }) => {
  return type === 'active' ? null : (
    <Button
      layout="link"
      onClick={clickHandler}
      className="absolute"
      style={{ padding: '5px 8px', marginTop: -5 }}
    >
      <FaQuestionCircle aria-hidden="true" />
      <span className="sr-only">Details</span>
    </Button>
  );
};

export default ToolTipRight;
