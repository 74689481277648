const STEPS = [
  {
    title: 'Download Template',
    stepTitle: 'Download Template',
    iconTitle: 1,
  },
  {
    title: 'Import CSV',
    stepTitle: 'Import CSV',
    iconTitle: 2,
  },
];

export default STEPS;
