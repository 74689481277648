import { useSuspenseQuery } from '@tanstack/react-query';

import { IPackage, getSelectedPackages } from 'sdk-apogee';

const useSelectedPackages = () =>
  useSuspenseQuery<IPackage[]>({
    queryKey: ['selected-packages'],
    queryFn: getSelectedPackages,
  });

export default useSelectedPackages;
