import React, { useEffect } from 'react';
import Select from 'react-select';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';

import { Input, Label, SeparatorLine, HelperText } from '@components';
import usePanRooms from '@hooks/usePanRooms';
import { NumericDropdown } from '@types';

import { IBuilding } from 'sdk-apogee';
import { Link } from 'react-router-dom';

type PersonalInformationProps = {
  hasMultipleSites?: boolean;
  isMobile?: boolean;
  buildingOptions: IBuilding[];
  roomBasedPanEnabled: boolean;
};

const PersonalInformation = React.forwardRef<HTMLElement, PersonalInformationProps>(
  function WrappedSection(
    { hasMultipleSites, isMobile, buildingOptions, roomBasedPanEnabled },
    ref,
  ) {
    const {
      register,
      formState: { errors },
      control,
      getValues,
      setError,
      setValue,
      clearErrors,
    } = useFormContext();

    const isValid = (input: string, isController: boolean = false): boolean =>
      isController ? !(errors as any)[input]?.value?.message : !(errors as any)[input]?.message;

    const selectedBuilding: NumericDropdown = useWatch({ control, name: 'building' });
    // @ts-ignore
    const selectedBuildingId = selectedBuilding?.id;
    // @ts-ignore
    const selectedBuildingLabel = selectedBuilding?.label;

    const { getPanRooms, panRooms, panRoomOptions } = usePanRooms({
      onError: () => {
        if (selectedBuildingId) {
          setValue('panRoom', '');
          setError('panRoom', {
            type: 'manual',
            // @ts-ignore Using JSX works, but typed as string.
            message: (
              <>
                We are having trouble finding rooms for the {selectedBuildingLabel} building. You
                can{' '}
                <button className="text-blue-500" onClick={() => fetchPanRooms(selectedBuilding)}>
                  try again
                </button>{' '}
                or select another building. Please{' '}
                <Link to={'/support'} className="text-blue-500">
                  contact support
                </Link>{' '}
                if the problem persists.
              </>
            ),
          });
        }
      },
    });

    const fetchPanRooms = (selectedBuilding: NumericDropdown) => {
      clearErrors('panRoom');
      getPanRooms(selectedBuilding?.id).then((response) => {
        if (selectedBuilding?.id && Array.isArray(response) && !response.length) {
          setValue('panRoom', '');
          setError('panRoom', {
            type: 'manual',
            // @ts-ignore Using JSX works, but typed as string.
            message: (
              <>
                {selectedBuilding?.name} doesn't have any rooms assigned. Select another building or{' '}
                <Link to={'/support'}>contact support</Link>.
              </>
            ),
          });
        } else if (selectedBuilding?.id) {
          setValue(
            'panRoom',
            response?.find(({ id }) => id === getValues('panRoom')?.id),
            { shouldValidate: true },
          );
        }
      });
    };

    useEffect(() => {
      if (roomBasedPanEnabled) {
        fetchPanRooms(selectedBuilding);
      }
    }, [selectedBuilding, roomBasedPanEnabled]);

    return (
      <section className="py-2 md:py-8 w-full md:w-3/5" ref={ref}>
        <h2 className="text-sm md:text-xl font-bold">Personal Information</h2>

        <Label className="my-2 font-thin">
          <span>First Name</span>
          <Input
            className="my-1"
            {...register('firstName')}
            type="text"
            {...(isValid('firstName') ? {} : { valid: false })}
          />
          {/* @ts-ignore */}
          <HelperText valid={isValid('firstName')}>{errors.firstName?.message}</HelperText>
        </Label>

        <Label className="my-2 font-thin">
          <span>Last Name</span>
          <Input
            className="my-1"
            {...register('lastName')}
            type="text"
            {...(isValid('lastName') ? {} : { valid: false })}
          />
          {/* @ts-ignore */}
          <HelperText valid={isValid('lastName')}>{errors.lastName?.message}</HelperText>
        </Label>

        <Label className="my-2 font-thin">
          <span>Email Address</span>
          <Input className="my-1" {...register('email')} type="text" />
        </Label>

        <Label className="my-2 font-thin">
          <span>Cell Phone</span>
          <PhoneInput
            control={control}
            name="phone"
            country="US"
            inputComponent={Input}
            placeholder="555-555-5555"
            {...(isValid('phone') ? {} : { valid: false })}
          />
          {/* @ts-ignore */}
          <HelperText valid={isValid('phone')}>{errors.phone?.message}</HelperText>
        </Label>

        <Label className="my-2 font-thin">
          <span>Building</span>
          <Controller
            name="building"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                isDisabled={hasMultipleSites}
                className="my-1"
                placeholder="Select one"
                options={buildingOptions.filter((b) => b.isActive)}
                getOptionValue={({ id }) => id}
                getOptionLabel={({ name }) => name}
                components={{ IndicatorSeparator: () => null }}
              />
            )}
          />
          {hasMultipleSites && (
            <HelperText>
              Contact <Link to={'/support'}>Apogee Student Support</Link> to change your building.
            </HelperText>
          )}
          <HelperText valid={isValid('building', true) && isValid('building', false)}>
            {/* @ts-ignore */}
            {errors?.building?.value?.message || errors?.building?.message}
          </HelperText>
        </Label>

        <Label className="my-2 pb-2 font-thin">
          <span>Suite and/or Room #</span>
          {roomBasedPanEnabled ? (
            <>
              <Controller
                name="panRoom"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isDisabled={!panRoomOptions.length}
                    isLoading={panRooms.isPending}
                    className="my-1"
                    placeholder={
                      !getValues('building') ? 'First Select A Building' : 'Select A Room'
                    }
                    noOptionsMessage={() => 'Room not found'}
                    options={panRooms?.data}
                    getOptionValue={({ id }) => id}
                    getOptionLabel={({ name }) => name}
                  />
                )}
              />
              <HelperText valid={isValid('panRoom', true) && isValid('panRoom', false)}>
                {/* @ts-ignore */}
                {errors?.panRoom?.value?.message || errors?.panRoom?.message}
              </HelperText>
            </>
          ) : (
            <>
              <Input
                className="my-1"
                {...register('room')}
                placeholder="Enter your Suite/Room #"
                type="text"
                {...(isValid('room') ? {} : { valid: false })}
              />
              {/* @ts-ignore */}
              <HelperText valid={isValid('room')}>{errors.room?.message}</HelperText>
            </>
          )}
        </Label>

        {isMobile && <SeparatorLine />}
      </section>
    );
  },
);

export default PersonalInformation;
