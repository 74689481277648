import { FCWithChildren } from '@types';
import React from 'react';

import { FaCheckCircle } from 'react-icons/fa';

import { Button, Modal, ModalBody } from '@components';
import { useAppContext } from '@contexts/AppContext';
import { useToasts } from 'react-toast-notifications';
import useLayoutContext from '@hooks/useLayoutContext';

type ConfirmationModalProps = {
  closeModal: () => void;
  isOpen: boolean;
};

const ConfirmationModal: FCWithChildren<ConfirmationModalProps> = ({ isOpen, closeModal }) => {
  const { user } = useAppContext();
  const { ssid, wifiPasswordEnabled } = useLayoutContext();
  const { addToast } = useToasts();

  return (
    <Modal closeModal={closeModal} isOpen={isOpen} noHeader>
      <ModalBody>
        <div>
          <div className="text-sm font-thin">
            <div className="flex">
              <FaCheckCircle className="mt-1 mr-2 text-2xl text-primary" />
              Your device has been added to the network. Please see further instructions below:
            </div>
          </div>
          <div className="p-4 my-4 rounded-md" style={{ backgroundColor: '#F2F3F6' }}>
            <div className="my-2">
              <h2 className="font-thin">1. Connect Your Device to the Network: </h2>
              {ssid && <span className="mx-2 my-2 font-semibold">&quot;{ssid}&quot;</span>}
            </div>
            {wifiPasswordEnabled && (
              <div className="my-2">
                <h2 className="font-thin">2. Connect to Wi-Fi with Password:</h2>
                <div className="mx-2 my-2">
                  <div className="text-xs">Wi-Fi Password</div>
                  <div className="flex items-center">
                    <span className="pr-4 text-sm font-thin text-gray-700">{user.preWiredKey}</span>
                    <Button
                      layout="link"
                      onClick={() => {
                        navigator.clipboard.writeText(user.preWiredKey);
                        addToast('Wi-Fi password copied', { appearance: 'success' });
                      }}
                      size="small"
                    >
                      Copy
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            <Button block onClick={closeModal}>
              OK
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationModal;
