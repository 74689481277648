import { FCWithChildren } from '@types';
import React from 'react';
import classNames from 'classnames';

import { IInvitee, IReceivedInvite } from 'sdk-apogee';
import {
  ActionTypes,
  REMOVE_RECEIVED_INVITATION,
  JOIN_NETWORK,
  REMOVE_SENT_INVITATION,
} from '../SharedList/SharedList';

import { Button } from '@components';

import { IoMdTrash } from 'react-icons/io';

type InviteeProps = {
  invitee: IInvitee | IReceivedInvite;
  isLast?: boolean;
  isHost?: boolean;
  box?: boolean;
  isRowClickable?: boolean;
  dispatch: (action: ActionTypes) => void;
};

const Invitee: FCWithChildren<InviteeProps> = ({
  invitee,
  isLast = false,
  isHost = false,
  box,
  isRowClickable,
  dispatch,
}) => {
  const { id, accountName, accountEmail } = invitee as IReceivedInvite;

  const containerCls = classNames(
    'w-full flex justify-between items-center my-2 p-2 text-sm text-gray-600 overflow-hidden',
    isLast && !box ? '' : 'border-b border-gray-200',
    box ? 'border border-black rounded-md shadow-md' : '',
    isRowClickable ? 'cursor-pointer' : '',
  );

  const joinNetwork = () => {
    const invitation = invitee as IReceivedInvite;

    isRowClickable && dispatch && dispatch({ type: JOIN_NETWORK, payload: { invitation } });
  };

  const removeInvitation = () => {
    const invitation = invitee as IReceivedInvite;
    isRowClickable
      ? dispatch({ type: REMOVE_RECEIVED_INVITATION, payload: { invitation } })
      : dispatch({ type: REMOVE_SENT_INVITATION, payload: { sentInvitationId: id } });
  };

  return (
    <div className={containerCls}>
      <div className="flex flex-col" onClick={joinNetwork}>
        <div className="w-full flex flex-row items-center">
          <span>{accountName.replace(/Not Provided/gi, '')}</span>
          {isHost && <span className="ml-1 font-semibold text-black">(HOST)</span>}
        </div>
        <span className="pb-2">{accountEmail}</span>
      </div>
      {box && !isHost && (
        <Button aria-label="Remove" layout="link" size="noPadding" onClick={removeInvitation}>
          <IoMdTrash className="text-gray-700 text-xl" />
        </Button>
      )}
    </div>
  );
};

export default Invitee;
