import { FCWithChildren } from '@types';
import React from 'react';

import { MdSort } from 'react-icons/md';
import { BsFillFlagFill } from 'react-icons/bs';

import { TableCell, TableHeader, TableRow } from '@components';

type TableHeadProps = {
  setSortBy: (by: string) => void;
  setSortOrder: (order: string) => void;
  sortOrder: string;
};

const TableHead: FCWithChildren<TableHeadProps> = ({ setSortBy, setSortOrder, sortOrder }) => {
  const sortOrderHandler = () => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

  const sortByFlag = () => {
    setSortBy('flag');
    sortOrderHandler();
  };

  const sortByName = () => {
    setSortBy('name');
    sortOrderHandler();
  };

  const sortByType = () => {
    setSortBy('type');
    sortOrderHandler();
  };

  const sortByGroup = () => {
    setSortBy('group');
    sortOrderHandler();
  };

  return (
    <TableHeader className="rounded-none ">
      <TableRow className="bg-black border-0 uppercase rounded-none">
        <TableCell className="text-white pl-10 cursor-pointer" onClick={sortByFlag}>
          <div className="flex flex-row items-center">
            <BsFillFlagFill className="text-base" />
            <MdSort className="ml-2 text-xl" />
          </div>
        </TableCell>

        <TableCell className="text-white pl-10 cursor-pointer" onClick={sortByName}>
          <div className="flex flex-row items-center">
            Name <MdSort className="ml-2 text-xl" />
          </div>
        </TableCell>
        <TableCell className="text-white cursor-pointer" onClick={sortByGroup}>
          <div className="flex flex-row items-center">
            Group <MdSort className="ml-2 text-xl" />
          </div>
        </TableCell>
        <TableCell className="text-white cursor-pointer" onClick={sortByType}>
          <div className="flex flex-row items-center">
            Device Type <MdSort className="ml-2 text-xl" />
          </div>
        </TableCell>
        <TableCell className="text-white cursor-pointer">
          <div className="flex flex-row items-center">
            Status <MdSort className="ml-2 text-xl" />
          </div>
        </TableCell>
      </TableRow>
    </TableHeader>
  );
};

export default TableHead;
