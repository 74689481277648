import React, { Fragment, useRef } from 'react';
import classNames from 'classnames';

import useLayoutContext from '@hooks/useLayoutContext';
import { ContactButton } from '@components';

import { RiQuestionnaireLine } from 'react-icons/ri';
import { MdPhone } from 'react-icons/md';
import { BsEnvelope } from 'react-icons/bs';
import { openChat } from '@utils';
type ContactUsProps = {
  showApogeeText?: boolean;
  redirectToSupportPage?: boolean;
};

const ContactUs = React.forwardRef<HTMLDivElement | null, ContactUsProps>(function WrappedSection(
  { showApogeeText, redirectToSupportPage },
  ref,
) {
  const {
    // This is to support the Text Support button, in case it is needed again
    // textSupportMessage,
    // textSupportNumber,
    phoneSupport,
    type,
    emailSupport,
  } = useLayoutContext();
  const contactUsBlockRef = useRef<HTMLDivElement>(null);

  const containerCls = classNames(
    '-mx-4 sm:-mx-6 lg:-mx-40 xl:-mx-40',
    !showApogeeText ? 'bg-white' : 'bg-gray-100',
  );

  const phoneFormat = (string: string | undefined) => {
    if (string) {
      let formatted = string.split('-');

      return formatted.join('');
    }
    return '';
  };

  const callPhoneNumber = () => (document.location.href = `tel:${phoneFormat(phoneSupport)}`);

  // This is to support the Text Support button, in case it is needed again
  // const sendSMS = () => {
  //   const isAndroid = Boolean(window.navigator.userAgent.match(/Android/i));

  //   document.location.href = `sms:${phoneFormat(phoneSupport)}${
  //     isAndroid ? '?' : '&'
  //   }body=${textSupportMessage}`;
  // };

  const sendEmail = () => {
    const subject = '';

    document.location.href = `mailto:${emailSupport}?subject=${subject}`;
  };

  return (
    <div className={containerCls} ref={ref}>
      <section className="flex flex-col pt-4 md:pt-16 px-4 sm:px-6 lg:px-40 xl:px-40">
        {showApogeeText ? (
          <Fragment>
            <h2 className="text-base font-bold md:text-2xl">Support</h2>
            <span className="my-4 w-full text-sm font-light md:w-1/2 md:text-base">
              At {type === 'Property' ? 'CampusConnect' : 'Apogee'}, we are here to support you
              24/7, whenever you have a connection issue. Here are our support phone, chat, and
              email options.
            </span>
          </Fragment>
        ) : (
          <h2 className="text-base font-bold md:text-2xl">Contact Us</h2>
        )}
        <div
          className="flex flex-col mt-4 justify-center pb-4 md:pb-32 md:flex-row md:space-x-6"
          id="contactusblock"
          ref={contactUsBlockRef}
        >
          <ContactButton
            icon={<RiQuestionnaireLine className="w-1/3 text-xl" />}
            title="LIVE CHAT"
            onClick={openChat}
          />

          <ContactButton
            icon={<MdPhone className="text-xl w-1/3" />}
            title="CALL US"
            contactNumber={phoneSupport}
            onClick={callPhoneNumber}
          />

          <ContactButton
            icon={<BsEnvelope className="text-xl w-1/3" />}
            // title={`TEXT "${textSupportMessage}" TO`}
            title={`EMAIL US`}
            // contactNumber={textSupportNumber}
            contactNumber={emailSupport}
            onClick={sendEmail}
          />
        </div>
      </section>
    </div>
  );
});

export default ContactUs;
