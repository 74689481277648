import { FCWithChildren } from '@types';
import React from 'react';
import classNames from 'classnames';

type LoadingProps = {
  size?: string;
};

const Loading: FCWithChildren<LoadingProps> = ({ size = 'regular' }) => {
  const base =
    'border border-gray-400 border-t-primary-background border-b-primary-background rounded-full box-border animate-spin-slow';

  const loadingStyle: Record<string, string> = {
    larger: 'w-32 h-32 border-8',
    large: 'w-24 h-24 border-4',
    regular: 'h-24 w-24 border-4',
    small: 'h-12 w-12 border',
    xsm: 'h-6 w-6 border',
  };

  const clx = classNames(base, loadingStyle[size]);

  return <div className={clx} />;
};

export default Loading;
