import { FCWithChildren } from '@types';
import React from 'react';

import { ToastProvider } from 'react-toast-notifications';

import { LayoutProvider } from '@contexts/LayoutContext';
import { Header, Footer, Toast } from '@components';
import classNames from 'classnames';
import useDeviceDetect from '@hooks/useDeviceDetect';

type LayoutProps = {
  admin?: boolean;
  isApogeeRoute?: boolean;
  logo?: string;
  publicRoute?: boolean;
  noFooter?: boolean;
};

const Layout: FCWithChildren<LayoutProps> = ({
  admin = false,
  children,
  isApogeeRoute = true,
  publicRoute = false,
  noFooter = false,
}) => {
  const { isMobile } = useDeviceDetect();

  return (
    <LayoutProvider isApogeeRoute={isApogeeRoute} publicRoute={publicRoute}>
      <ToastProvider autoDismiss autoDismissTimeout={5000} components={{ Toast }}>
        <section
          className={classNames('w-full min-h-screen flex flex-col font-sans', {
            'overflow-hidden': isMobile,
          })}
        >
          <Header admin={admin} isApogeeRoute={isApogeeRoute} publicRoute={publicRoute} />
          <main
            className="px-4 sm:px-6 lg:px-40 xl:px-40"
            aria-label="body-container"
            id="main-content"
          >
            {children}
          </main>
          {!admin && !noFooter ? <Footer /> : null}
        </section>
      </ToastProvider>
    </LayoutProvider>
  );
};

export default Layout;
