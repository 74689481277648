import React, { useEffect, useMemo } from 'react';

import { RequestManagerWrapper } from '@components';
import useUserByToken from '@hooks/useUserByToken';
import { getSubdomain } from '@utils';
import { useHistory, useLocation } from 'react-router-dom';
import Login from '@pages/Login';
const subdomain = getSubdomain();
const Description = {
  COULD_NOT_FIND_USER: "Couldn't find a user",
};
const Error = {
  NO_USER_FOUND_WITH_TOKEN: 'no user found with token',
  TOKEN_EXPIRED: 'token expired',
};

const RetrieveProfile = () => {
  const { state }: any = useLocation();
  const history = useHistory();
  const { data } = useUserByToken({ token: state?.token || '', subdomain });
  const validToken = useMemo(
    () =>
      !(
        [Error.TOKEN_EXPIRED, Error.NO_USER_FOUND_WITH_TOKEN].includes(data?.error || '') ||
        [Description.COULD_NOT_FIND_USER].includes(data?.description || '')
      ),
    [Error, Description, data],
  );

  useEffect(() => {
    if (data && validToken) {
      history.replace({
        pathname: '/complete-profile',
        state: { csrCreated: true, csrToken: state?.token, data },
      });
    }
  }, [data]);

  return validToken ? null : <Login initialState="RETRIEVE_PROFILE" checkEmail />;
};

const RetrieveProfileWrapper = () => (
  <RequestManagerWrapper>
    <RetrieveProfile />
  </RequestManagerWrapper>
);

export default RetrieveProfileWrapper;
