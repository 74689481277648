import { FCWithChildren } from '@types';
import React from 'react';

import { IPackage, STORAGE_URL } from 'sdk-apogee';

import Option from '../Option';

type Service = {
  id: string;
  price: string;
  adjustedPrice: string;
  planName: string;
  planType: string;
  internet: boolean;
  maxDevices: number;
  isCancellationPlan: boolean;
};

type SelectedServices = {
  [key: string]: Service;
};

type PackageProps = {
  isMobile: boolean;
  services: IPackage[];
  onClickHandler: (selection: SelectedServices) => void;
  selectedServices: SelectedServices;
  currentSelectedPackages: SelectedServices;
  title: string;
};

const Packages: FCWithChildren<PackageProps> = ({
  isMobile,
  services,
  onClickHandler,
  selectedServices,
  title,
  currentSelectedPackages,
}) => {
  const pkgClass = 'mt-10 border rounded-lg shadow-md p-8'.concat(isMobile ? ' -mx-6' : '');

  const isActive = ({ id = 0, planType = '' }: Partial<IPackage>) =>
    selectedServices?.[planType]?.id.toString() === id.toString();

  const isCurrentPackage = ({ id = 0, planType = '' }: Partial<IPackage>) =>
    currentSelectedPackages?.[planType]?.id.toString() === id.toString();

  return (
    <div className={pkgClass}>
      <div className="w-full border-b pb-2 mb-2 flex items-center">
        {services[0]?.icon && services[0]?.icon?.length ? (
          <img src={`${STORAGE_URL}${services[0].icon}`} alt="logo" className="pr-2" />
        ) : null}
        <h1 className="text-xl sm:text-3xl capitalize">{title}</h1>
      </div>

      <div className="flex flex-wrap sm:overflow-hidden lg:-mx-5 xl:-mx-4">
        {services.map((combo: IPackage) => (
          <Option
            {...combo}
            active={isActive(combo)}
            isMobile={isMobile}
            key={combo.id}
            onClickHandler={onClickHandler}
            isCurrentPackage={isCurrentPackage(combo)}
          />
        ))}
      </div>
    </div>
  );
};

export default Packages;
