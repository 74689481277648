import React, { forwardRef, useEffect, useRef } from 'react';
import classNames from 'classnames';
import FocusLock from 'react-focus-lock';

import Transition from '../Transition';

interface Props extends React.HTMLAttributes<HTMLUListElement> {
  align?: 'left' | 'right';
  isOpen: boolean;
  width?: string;
  borderless?: boolean;
  onClose: () => void;
  openerRef?: React.RefObject<HTMLElement>;
}

type Align = 'left' | 'right';

const alignStyles: Record<Align, string> = {
  left: 'left-0',
  right: 'right-0',
};

const Dropdown = forwardRef<HTMLDivElement, Props>(function Dropdown(props, ref) {
  const {
    align = 'left',
    children,
    className,
    isOpen,
    onClose,
    width,
    borderless = false,
    openerRef,
    ...other
  } = props;

  const baseStyle =
    'absolute py-2 mt-2 text-gray-600 bg-white rounded-lg shadow-md min-w-max-content z-50';
  const alignStyle = alignStyles[align as Align];

  function handleEsc(e: KeyboardEvent) {
    if (e.key === 'Esc' || e.key === 'Escape') {
      onClose();
    }
  }

  const dropdownRef = useRef<HTMLUListElement>(null);
  function handleClickOutside(e: MouseEvent) {
    if (
      !(
        (openerRef?.current && openerRef.current.contains(e.target as Node)) ||
        (dropdownRef.current && dropdownRef.current.contains(e.target as Node))
      )
    ) {
      onClose();
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleEsc);
    };
    // eslint-disable-next-line
  }, [isOpen]);

  const cls = classNames(
    baseStyle,
    alignStyle,
    className,
    width ? width : 'w-56',
    borderless ? '' : 'border border-gray-100',
  );

  return (
    <Transition
      show={isOpen}
      leave="transition ease-out duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div ref={ref}>
        <FocusLock returnFocus>
          <ul className={cls} ref={dropdownRef} aria-label="submenu" {...other}>
            {children}
          </ul>
        </FocusLock>
      </div>
    </Transition>
  );
});

export default Dropdown;
