import { FCWithChildren } from '@types';
import React, { useEffect, useRef } from 'react';

import { Button, Modal, ModalBody } from '@components';

type GenericErrorProps = {
  closeModal: () => void;
  isOpen: boolean;
  errorInfo?: {
    data: {
      error: string;
    };
    status: number;
    statusText: string;
  };
};

const NetworkError: FCWithChildren<GenericErrorProps> = ({ closeModal, isOpen, errorInfo }) => {
  const buttonRef = useRef<HTMLElement | null>(null);
  const closeButtonRef = useRef<HTMLElement | null>(null);
  const focusableModalElements = [closeButtonRef.current, buttonRef.current];
  const lastElementIndex = focusableModalElements.length - 1;

  const handleTabKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isOpen) return;

    const { key } = e;

    const firstElement = focusableModalElements[0];
    const lastElement = focusableModalElements[lastElementIndex];

    const indexOfFocused = focusableModalElements.indexOf(document.activeElement as HTMLElement);

    if (key === 'Tab') {
      if (indexOfFocused >= 0) {
        indexOfFocused === lastElementIndex
          ? (firstElement as any).focus()
          : (lastElement as any).focus();
        return e.preventDefault();
      } else {
        (firstElement as any).focus();
        return e.preventDefault();
      }
    }
  };

  const handleEnterKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const isWithinModal = focusableModalElements.indexOf(document.activeElement as HTMLElement);
    if (isWithinModal) closeModal();
  };

  const keyListenersMap = new Map([
    [27, closeModal],
    [9, handleTabKey],
    [13, handleEnterKey],
  ]);

  useEffect(() => {
    function keyListener(e: any) {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    }

    document.addEventListener('keydown', keyListener);

    return () => document.removeEventListener('keydown', keyListener);
  });

  return (
    <Modal closeModal={closeModal} isOpen={isOpen} noHeader ref={closeButtonRef}>
      <ModalBody>
        <div className="font-sans flex flex-col justify-center items-center space-y-2">
          {errorInfo ? (
            <div className="flex flex-col items-center">
              <p>{errorInfo?.statusText}</p>
              <p> {errorInfo?.data.error} </p>
            </div>
          ) : (
            <p>Sorry, something went wrong.</p>
          )}
          <p className="py-2">Please try again.</p>
          <Button
            block
            className="uppercase"
            layout="primary"
            onClick={closeModal}
            onKeyPress={(e: any) => e.preventDefault()}
            ref={buttonRef}
          >
            Ok
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NetworkError;
