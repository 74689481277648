const calculateTimeAgo = (date: string) => {
  const toSecs = 1000;
  const toMins = 1000 * 60;
  const toHours = 1000 * 3600;
  const toDays = 1000 * 3600 * 24;
  let converted = 0;
  let result = '';

  let diff = Math.abs(new Date().getTime() - new Date(date).getTime());

  converted = Math.round(diff / toDays);
  result = `${converted} day`;

  if (converted < 1) {
    converted = diff / toHours;
    result = `${Math.round(converted)} hour`;
  }

  if (converted < 1) {
    converted = diff / toMins;
    result = `${Math.round(converted)} minute`;
  }

  if (converted < 1) {
    converted = diff / toSecs;
    result = `${Math.round(converted)} second`;
  }

  if (converted > 1) result = result.concat('s');

  return result.concat(' ago');
};

export default calculateTimeAgo;
