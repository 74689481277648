import { FCWithChildren } from '@types';
import React, { Fragment, useMemo, useRef } from 'react';
import { ISupportQuestions } from 'sdk-apogee';
import useLayoutContext from '@hooks/useLayoutContext';

import SectionCell from '../SectionCell';

type QuestionsSectionProps = {
  list: ISupportQuestions[];
  refsContainer: {
    current: Record<string, React.MutableRefObject<HTMLElement | null>>;
  };
  searchTerm?: string;
};

const TroubleShooting: FCWithChildren<QuestionsSectionProps> = ({
  list,
  refsContainer,
  searchTerm,
}) => {
  const { isMobile } = useLayoutContext();
  const container = useRef<HTMLDivElement | null>(null);

  const divContainerCls = isMobile ? 'w-full' : 'w-2/3 px-8';

  const isSearchResultsEmpty: boolean = useMemo(() => {
    const escapedSearchTerm =
      searchTerm && searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')?.toLowerCase();
    if (!escapedSearchTerm) return false;
    return !list.find(({ questionsAndAnswers }) =>
      questionsAndAnswers.find(
        ({ question, answerHtml }) =>
          question?.toLowerCase().indexOf(escapedSearchTerm) !== -1 ||
          answerHtml?.toLowerCase().indexOf(escapedSearchTerm) !== -1,
      ),
    );
  }, [list, searchTerm]);

  return (
    <Fragment>
      <div className={divContainerCls} ref={container} style={{ minHeight: 300 }}>
        {isSearchResultsEmpty && (
          <section className="py-8 px-0">
            <span className="text-xl font-bold">Sorry no matches found for {searchTerm}.</span>
          </section>
        )}
        {list.map((questionItem) => (
          <SectionCell
            key={questionItem.id}
            questionItem={questionItem}
            refsContainer={refsContainer}
            searchTerm={searchTerm}
          />
        ))}
      </div>
    </Fragment>
  );
};

export default TroubleShooting;
