import { useSuspenseQuery } from '@tanstack/react-query';

import { IUserDevice, getDevices } from 'sdk-apogee';

const useDevices = () =>
  useSuspenseQuery<IUserDevice[]>({
    queryKey: ['devices'],
    queryFn: getDevices,
    retry: false,
  });

export default useDevices;
