import { FCWithChildren } from '@types';
import React from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { Modal, ModalBody, ModalHeader } from '@components';

// @ts-ignore
import { getMacAddressHelp, IMacAddressHelp } from 'sdk-apogee';

import { serializeHtml } from '@utils';

type MACAddressHelpModalHelp = {
  closeModal: () => void;
  isOpen: boolean;
  selectedDeviceType: { [x: string]: number } | null;
};

const MACAddressHelpModal: FCWithChildren<MACAddressHelpModalHelp> = ({
  isOpen,
  closeModal,
  selectedDeviceType,
}) => {
  const { data: macAddressHelp } = useSuspenseQuery<IMacAddressHelp[]>({
    queryKey: ['mac-address-help'],
    queryFn: getMacAddressHelp,
  });

  const deviceHelp = selectedDeviceType
    ? (macAddressHelp as IMacAddressHelp[]).find((help) => help.id === selectedDeviceType?.value)
    : null;

  return (
    <Modal closeModal={closeModal} isOpen={isOpen} customWebClass="max-h-screen-90">
      <ModalHeader>{deviceHelp?.name}</ModalHeader>
      <ModalBody>
        {selectedDeviceType && (
          <div
            dangerouslySetInnerHTML={{
              __html: serializeHtml(deviceHelp?.macAddressInstructions.document),
            }}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default MACAddressHelpModal;
