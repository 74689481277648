import { FCWithChildren } from '@types';
import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { BsThreeDots } from 'react-icons/bs';
import { FaBolt, FaCheck, FaExclamationCircle, FaUsers } from 'react-icons/fa';
import { Button, Dropdown, DropdownItem } from '@components';
import { calculateTimeAgo } from '@utils';

type NotificationProps = {
  asRead?: (id: number, markAsUnread?: boolean) => void;
  createdAt?: string;
  banner?: boolean;
  deleteNotification?: (id: number) => void;
  id?: number;
  message: string;
  readAt?: string;
  redirectToNotificationTypePage?: (id: number, type: string) => void;
  small?: boolean;
  type: string;
};

const TYPES: Record<string, React.ReactNode> = {
  DeviceConflictNotification: (
    <div className="border border-orange-600 p-2 rounded-full bg-orange-600">
      <FaBolt className="text-white text-xl" />
    </div>
  ),
  NetworkInvitationNotification: (
    <div className="border border-gray-700 p-2 rounded-full bg-gray-700">
      <FaUsers className="text-white text-xl" />
    </div>
  ),
  SuccessNotification: (
    <div className="border border-green-700 p-2 rounded-full bg-green-700">
      <FaCheck className="text-white text-xl" />
    </div>
  ),
  SystemNotification: (
    <div className="border border-red-700 p-2 rounded-full bg-red-700">
      <FaExclamationCircle className="text-white text-xl" />
    </div>
  ),
};

const TYPES_TEXT: Record<string, React.ReactNode> = {
  DeviceConflictNotification: 'GO TO DEVICES',
  NetworkInvitationNotification: 'GO TO SHARING',
  SuccessNotification: '',
  SystemNotification: '',
};

const Notification: FCWithChildren<NotificationProps> = ({
  asRead,
  banner,
  createdAt,
  deleteNotification,
  id,
  message,
  readAt,
  redirectToNotificationTypePage,
  small,
  type,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const notificationButtonRef = useRef(null);

  const colorBoxStyle = classNames(
    'w-full relative',
    !readAt && type !== 'SystemNotification' ? 'bg-indigo-200 bg-opacity-25' : '',
  );

  const boxStyle = classNames('flex items-center cursor-pointer', {
    'py-2 px-4': small,
    'py-4 px-10': !small,
  });

  const notificationStyle = classNames('w-full px-10', {
    'flex items-end': small,
  });

  const buttonBox = classNames('flex justify-end', small ? 'px-2' : 'px-32');

  const toggleDropdown = () => setIsOpen(!isOpen);

  const closeDropDown = () => setIsOpen(false);

  const iconComponent = TYPES[type];

  const notificationTypeText = TYPES_TEXT[type];

  const deleteHandler = (e: React.BaseSyntheticEvent<object, any, any>) => {
    e.preventDefault();
    if (deleteNotification) {
      deleteNotification(id || 0);
    }
    closeDropDown();
  };

  const handler = (e: React.MouseEvent) => {
    e.preventDefault();
    if (typeof asRead === 'function') {
      asRead(id || 0, !!readAt);
    }
    closeDropDown();
  };

  const redirectHandler = () => {
    if (redirectToNotificationTypePage) {
      redirectToNotificationTypePage(id || 0, type);
    }
    closeDropDown();
  };

  return (
    <section className={colorBoxStyle}>
      <div className={boxStyle}>
        <div className="flex items-center" onClick={redirectHandler}>
          <div>{iconComponent}</div>
          <div className={notificationStyle}>
            <div>
              <p
                className={classNames({
                  'my-1': true,
                  'text-sm': !banner,
                  'text-gray-600': !banner,
                })}
              >
                {message}
              </p>
              {createdAt && (
                <p className="my-1 text-xs text-gray-500">{calculateTimeAgo(createdAt)}</p>
              )}
            </div>
          </div>
        </div>
        {id && !small ? (
          <div className="ml-auto">
            <div className="relative">
              <button ref={notificationButtonRef} onClick={toggleDropdown}>
                <BsThreeDots className="cursor-pointer" size={25} />
              </button>
              <Dropdown
                align="right"
                width="w-40"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                openerRef={notificationButtonRef}
              >
                <DropdownItem className="">
                  <Button layout="link" onClick={handler}>
                    <span>Mark as {readAt ? 'Unread' : 'Read'}</span>
                  </Button>
                </DropdownItem>
                <DropdownItem className="">
                  <Button layout="link" onClick={deleteHandler}>
                    <span>Delete</span>
                  </Button>
                </DropdownItem>
              </Dropdown>
            </div>
          </div>
        ) : null}
      </div>
      {notificationTypeText && (
        <div className={buttonBox}>
          <Button className="uppercase" layout="link" size="small" onClick={redirectHandler}>
            {notificationTypeText}
          </Button>
        </div>
      )}
    </section>
  );
};

export default Notification;
