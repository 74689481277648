import { FCWithChildren } from '@types';
import React, { useMemo } from 'react';

import { IPackage } from 'sdk-apogee';

import { FaCheckCircle } from 'react-icons/fa';

import { serializeHtml } from '@utils';

type Service = {
  id: string;
  planName: string;
  planType: string;
  price: string;
  adjustedPrice: string;
  internet: boolean;
  maxDevices: number;
  isCancellationPlan: boolean;
};

type SelectedServices = {
  [key: string]: Service;
};

type ServiceProps = {
  active?: boolean;
  id: number;
  isCurrentPackage?: boolean;
  isMobile: boolean;
  onClickHandler: (selection: SelectedServices) => void;
  planDescription: any;
  planDescriptionHtml: string;
  planName: string;
  planType: string;
  internet: boolean;
  maxDevices: number;
  isCancellationPlan: boolean;
};

const Option: FCWithChildren<Partial<IPackage> & ServiceProps> = ({
  active = false,
  id,
  isCurrentPackage,
  isMobile,
  onClickHandler,
  planDescriptionHtml,
  planName,
  price,
  adjustedPrice,
  internet,
  planType,
  maxDevices,
  isCancellationPlan,
}) => {
  const serviceStyle =
    'flex flex-col items-center h-full rounded-lg shadow-md relative cursor-pointer focus:border-2'
      .concat(active ? ' border-2 border-black' : ' border-2 boder-gray-700')
      .concat(isMobile ? ' mt-6' : '');

  const handleClickOption = () =>
    onClickHandler({
      [planType as string]: {
        id: id.toString(),
        price: Number(price).toFixed(2),
        adjustedPrice: adjustedPrice || '',
        planName: planName,
        planType: planType,
        internet: internet,
        maxDevices: maxDevices,
        isCancellationPlan: isCancellationPlan,
      },
    });

  const htmlResult = useMemo(() => planDescriptionHtml || null, [planDescriptionHtml]);

  // The following code was when we showed custom pricing from the Middleware
  // const adjustedCost = Number(adjustedPrice).toFixed(2) !== '0.00' ? `$${Number(adjustedPrice).toFixed(2)}` : 'Free';
  const cost = Number(price).toFixed(2) !== '0.00' ? `$${Number(price).toFixed(2)}` : '$0';

  return (
    <div className="w-full mt-4 mb-8 lg:my-5 lg:px-5 lg:w-1/3 xl:my-4 xl:px-4 xl:w-1/3">
      <div
        className={serviceStyle}
        onClick={handleClickOption}
        onKeyDown={handleClickOption}
        role="button"
        tabIndex={0}
      >
        {active && (
          <div className="absolute top-0 flex flex-row -mt-4 items-center justify-center w-full">
            <FaCheckCircle size={30} className="bg-white" />
          </div>
        )}
        <div className="w-full text-center border-b py-4">
          <h1 className="font-normal sm:font-semibold text-xl">{planName}</h1>
        </div>
        <div className="px-6 py-4 w-10/12">
          <div
            dangerouslySetInnerHTML={{
              __html: htmlResult || '',
            }}
          ></div>
        </div>
        <div className="w-10/12 py-2 border-t w-full">
          {isCurrentPackage && (
            <span className="text-lg font-semibold inline-block text-left w-1/2">CURRENT</span>
          )}
          <span className="text-lg font-semibold inline-block text-right w-1/2">{cost}</span>
        </div>
      </div>
    </div>
  );
};

export default Option;
