import React, { Fragment, useRef, useEffect } from 'react';

import { useSuspenseQuery } from '@tanstack/react-query';
import { getProfile } from 'sdk-apogee';

import { RequestManagerWrapper } from '@components';
import { useAppContext } from '@contexts/AppContext';
import useDeviceDetect from '@hooks/useDeviceDetect';

import AccountSections from './components/AccountSections';
import MobileAccountSectionsList from './components/MobileAccountSectionsList';
import AccountSectionsList from './components/AccountSectionsList';
import usePageTitle from '@hooks/usePageTitle';
import { FCWithChildren } from '@types';

const AccountPage: FCWithChildren<{}> = () => {
  usePageTitle('Account');
  const { isMobile } = useDeviceDetect();
  const { navBarRef } = useAppContext();

  const { data } = useSuspenseQuery({
    queryKey: ['profile'],
    queryFn: getProfile,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const accountSections = {
    logInInfo: {
      title: 'Log In Information',
      ref: useRef<HTMLElement | null>(null),
    },
    personalInfo: {
      title: 'Personal Information',
      ref: useRef<HTMLElement | null>(null),
    },
    servicesPackages: {
      title: 'Services Packages',
      ref: useRef<HTMLElement | null>(null),
    },
    preferences: {
      title: 'Preferences',
      ref: useRef<HTMLElement | null>(null),
    },
  };

  const navBarOffset = navBarRef && (navBarRef?.current?.offsetHeight as number);

  const onClickHandler = (reference: React.MutableRefObject<HTMLElement | null>) => {
    window.scrollTo(0, (reference.current?.offsetTop as number) - navBarOffset);
  };

  if (!data) return null;

  return (
    <Fragment>
      {isMobile ? (
        <>
          <MobileAccountSectionsList
            accountSections={accountSections}
            onClickHandler={onClickHandler}
          />
          <AccountSections account={data} isMobile accountSections={accountSections} />
        </>
      ) : (
        <div className="flex flex-row">
          <AccountSectionsList accountSections={accountSections} onClickHandler={onClickHandler} />
          <AccountSections account={data} accountSections={accountSections} />
        </div>
      )}
    </Fragment>
  );
};

const Account = () => (
  <RequestManagerWrapper>
    <AccountPage />
  </RequestManagerWrapper>
);

export default Account;
