import { FCWithChildren } from '@types';
import React from 'react';

import { NotFound } from '@components';

import Account from '@pages/Account';
import Admin from '@pages/Admin';
import Checkout from '@pages/Checkout';
import CompleteProfile from '@pages/CompleteProfile';
import FAQ from '@pages/FAQ';
import ForgotPassword from '@pages/ForgotPassword';
import Home from '@pages/Home';
import Login from '@pages/Login';
import MacAddressRedirect from '@pages/MacAddressRedirect';
import Main from '@pages/Main';
import ManageDevices from '@pages/ManageDevices';
import ManagedCampus from '@pages/ManagedCampus';
import Notifications from '@pages/Notifications';
import PreAuthentication from '@pages/PreAuthentication';
import Register from '@pages/Register';
import ResetPassword from '@pages/ResetPassword';
import SchoolForm from '@pages/Admin/pages/School';
import Services from '@pages/Services';
import Support from '@pages/Support';
import GuestVisitorsConfirm from '@pages/GuestVisitorsConfirm';
import GuestsAndVisitors from '@pages/GuestsAndVisitors';
import DeviceConfirm from '@pages/DeviceConfirm';
import ThankYouGuest from '@pages/ThankYouGuest';
import RetrieveProfile from '@pages/RetrieveProfile';

export interface IRoute {
  auth?: boolean;
  component: FCWithChildren<any>;
  exact?: boolean;
  isApogeeRoute?: boolean;
  path: string | string[];
  noFooter?: boolean;
}

const ROUTES: IRoute[] = [
  {
    auth: false,
    component: Main,
    exact: true,
    isApogeeRoute: true,
    path: '/select',
  },
  {
    auth: true,
    component: Account,
    isApogeeRoute: false,
    path: '/account',
  },
  {
    auth: false,
    component: Admin,
    isApogeeRoute: false,
    path: '/admin',
  },
  {
    auth: false,
    component: MacAddressRedirect,
    isApogeeRoute: false,
    exact: true,
    path: [
      `/:mac(\\w{2}:\\w{2}:\\w{2}:\\w{2}:\\w{2}:\\w{2})`,
      '/:mac(\\w{2}%3A\\w{2}%3A\\w{2}%3A\\w{2}%3A\\w{2}%3A\\w{2})',
    ],
  },
  {
    auth: true,
    component: Checkout,
    isApogeeRoute: false,
    path: '/checkout',
  },
  {
    auth: false,
    component: PreAuthentication,
    isApogeeRoute: false,
    exact: true,
    path: '/',
  },
  {
    auth: false,
    component: Login,
    exact: true,
    isApogeeRoute: false,
    path: '/login',
  },
  {
    auth: true,
    component: ManageDevices,
    isApogeeRoute: false,
    path: '/manage-devices',
  },
  {
    auth: true,
    component: Notifications,
    isApogeeRoute: false,
    path: '/notifications',
  },
  {
    auth: false,
    component: Register,
    isApogeeRoute: false,
    path: '/register',
    noFooter: true,
  },
  {
    auth: false,
    component: RetrieveProfile,
    isApogeeRoute: false,
    path: '/retrieve-profile',
    noFooter: true,
  },
  {
    auth: true,
    component: Services,
    isApogeeRoute: false,
    path: '/services',
  },
  {
    auth: false,
    component: Support,
    isApogeeRoute: false,
    path: '/support',
  },
  {
    auth: true,
    component: Home,
    isApogeeRoute: false,
    path: '/home',
  },
  {
    auth: true,
    component: ManagedCampus,
    isApogeeRoute: false,
    path: '/managed-campus',
  },
  {
    auth: false,
    component: CompleteProfile,
    isApogeeRoute: false,
    path: '/complete-profile',
  },
  {
    auth: true,
    component: FAQ,
    isApogeeRoute: false,
    path: '/faq',
  },
  {
    auth: false,
    component: NotFound,
    isApogeeRoute: true,
    path: '/not-found',
  },
  {
    auth: false,
    component: SchoolForm,
    isApogeeRoute: false,
    path: '/admin-schools',
  },
  {
    auth: false,
    component: ResetPassword,
    exact: true,
    isApogeeRoute: false,
    path: '/reset-password',
  },
  {
    auth: false,
    component: ForgotPassword,
    exact: true,
    isApogeeRoute: false,
    path: '/forgot-password',
  },
  {
    auth: false,
    component: GuestVisitorsConfirm,
    isApogeeRoute: false,
    path: '/guest-confirm',
  },
  {
    auth: false,
    component: GuestsAndVisitors,
    isApogeeRoute: false,
    path: '/guest-visitors',
  },
  {
    component: DeviceConfirm,
    isApogeeRoute: false,
    path: '/device-confirm',
  },
  {
    component: ThankYouGuest,
    isApogeeRoute: false,
    path: '/thank-message-guest',
  },
];

export default ROUTES;
