import React, { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import {
  IBuilding,
  ICustomer,
  getSchoolByDomain,
  SCHOOL_DOMAIN,
  PROPERTY_DOMAIN,
} from 'sdk-apogee';

import { LoadingContainer } from '@components';
import useDeviceDetect from '@hooks/useDeviceDetect';
import { getSubdomain, buildUrl } from '@utils';
import { DEFAULT_THEME } from '@themes';
import { applyTheme, colorLuminance } from '@themes/utils';
import myCampusNetIcon from '@assets/icons/campus-connect.png';
// @ts-ignore
import { IClientUserType } from 'sdk-apogee';

export type LayoutContextProps = Partial<ICustomer> & {
  authType: string;
  isSam: boolean;
  isSso: boolean;
  buildings: IBuilding[];
  casAuthUrl: string;
  chatSettings: any;
  clientUserTypes: IClientUserType[];
  customerId?: number;
  isMobile: boolean;
  logo: string;
  favicon: string;
  largeHeroImage?: string;
  recoveryQuestions: any[];
  subdomain: string;
  university?: string;
  usernameLabel?: string;
  passwordLabel?: string;
  welcomeMessage?: string;
  logInRedirect?: () => string;
  roomBasedBanEnabled?: boolean;
  setupInstructions: string;
  panSharingEnabled: boolean;
  logoText: string;
  eduOnly: boolean;
  type: string;
  changeType: (type: string) => void;
  emailSupport: string;
  testCaptiveMac: string;
  ssid?: string;
  wifiPasswordEnabled: boolean;
  isGuestType?: boolean;
  guestType?: string;
  schoolUidName?: string;
  startHereSsid?: string;
  pskEnabled?: boolean;
};

const DEFAULT_VALUES = {
  buildings: [],
  casAuthUrl: '',
  chatSettings: { profiles: '', rootUrl: '', title: '', tenant: '' },
  clientUserTypes: [],
  isMobile: false,
  logo: '',
  favicon: '',
  largeHeroImage: '',
  recoveryQuestions: [],
  subdomain: '',
  usernameLabel: '',
  panSharingEnabled: false,
  eduOnly: false,
  type: undefined,
  changeType: (type: string) => {},
  emailSupport: '',
  welcomeMessage: '',
  wifiPasswordEnabled: false,
  testCaptiveMac: null,
  isGuestType: false,
  guestType: '',
  schoolUidName: '',
  startHereSsid: '',
  pskEnabled: false,
};

const GREEN_PROPERTY_COLOR = '#296130';

// @ts-ignore
const LayoutContext = createContext<LayoutContextProps>(DEFAULT_VALUES);

export type LayoutProviderProps = {
  isApogeeRoute: boolean;
  publicRoute: boolean;
};

const SUBDOMAIN = getSubdomain();

const LayoutProvider: any = ({ children, publicRoute, isApogeeRoute }: any) => {
  const history = useHistory();
  const { isMobile } = useDeviceDetect();
  const [type, setType] = useState<string | undefined>('School');
  const [isThemeApplied, setIsThemeApplied] = useState(false);

  const { data, error, isLoading, refetch, status } = useQuery({
    queryKey: ['school', SUBDOMAIN],
    queryFn: () => getSchoolByDomain({ domain: SUBDOMAIN }),
    enabled: false,
  });

  const favicon = data?.favicon;
  const logo = data?.logo;
  const isSam = data?.authType === 'sam';

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const previewColor = urlParams.get('color');
  const preview = urlParams.get('preview');

  useEffect(() => {
    if (error) {
      history.push('/not-found');
    }
  }, [error, history]);

  useEffect(() => {
    const isProperty = data?.type?.toLowerCase() === 'property';
    const faviconUpdate = async () => {
      const faviconTag: any = document.getElementById('favicon');

      if (favicon) {
        faviconTag.href = favicon;
      } else if (logo) {
        faviconTag.href = logo;
      } else if (isProperty) {
        faviconTag.href = myCampusNetIcon;
      }
    };

    faviconUpdate();
  }, [favicon, logo, data]);

  useEffect(() => {
    const shouldApplyTheme =
      !isThemeApplied && ((publicRoute && !isApogeeRoute) || (!publicRoute && !isApogeeRoute));

    if (data?.type === 'Property' && type !== 'Property') {
      setType('Property');
    }

    if (type === 'Property' && !shouldApplyTheme) {
      const favicon: any = document.getElementById('favicon');
      favicon.href = myCampusNetIcon;

      const primaryColor = GREEN_PROPERTY_COLOR;
      applyTheme({
        primary: primaryColor,
        backgroundPrimary: primaryColor,
        backgroundSecondary: '#E5E5E5',
        backgroundPrimaryLighten: colorLuminance(primaryColor, -0.1),
        backgroundSecondaryLighten: colorLuminance('#E5E5E5', -0.2),
      });
      return;
    }

    if (shouldApplyTheme) {
      refetch().then((response: any) => {
        const theme = response?.data;
        if (theme) {
          setIsThemeApplied(true);
          if (preview && previewColor) {
            return applyTheme({
              primary: `#${previewColor}`,
              backgroundPrimary: `#${previewColor}`,
              backgroundSecondary: '#E5E5E5',
              backgroundPrimaryLighten: colorLuminance(`#${previewColor}`, -0.1),
              backgroundSecondaryLighten: colorLuminance('#E5E5E5', -0.2),
            });
          }

          const primaryColor = `#${theme.color}`;
          theme.color
            ? applyTheme({
                primary: primaryColor,
                backgroundPrimary: primaryColor,
                backgroundSecondary: '#E5E5E5',
                backgroundPrimaryLighten: colorLuminance(primaryColor, -0.1),
                backgroundSecondaryLighten: colorLuminance('#E5E5E5', -0.2),
              })
            : applyTheme(DEFAULT_THEME);
        }
      });
    }
  }, [isApogeeRoute, publicRoute, refetch, preview, previewColor, type, data]);

  useEffect(() => {}, [preview, previewColor]);

  useEffect(() => {
    if (isApogeeRoute) return applyTheme(DEFAULT_THEME);
  }, [isApogeeRoute]);

  if (isLoading || (status === 'pending' && !publicRoute)) {
    return <LoadingContainer />;
  }

  const logInRedirect = () => {
    if (!isSam) {
      const url = buildUrl();

      const schoolDomain = encodeURIComponent(SCHOOL_DOMAIN ? `${SCHOOL_DOMAIN}` : '');
      const propertyDomain = encodeURIComponent(PROPERTY_DOMAIN ? `${PROPERTY_DOMAIN}` : '');
      const replaceExp = new RegExp(
        `(service=http[s]*%3A%2F%2F.*)${schoolDomain.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}`,
      );
      if (data?.type === 'Property' && schoolDomain !== propertyDomain) {
        const casAuthUrl = data?.casAuthUrl?.replace(replaceExp, `$1${propertyDomain}`);
        return `${casAuthUrl}?return_to=${url}`;
      } else {
        return `${data?.casAuthUrl}?return_to=${url}`;
      }
    } else {
      return '/login';
    }
  };

  const changeType = (newType: string) => {
    setType(newType);
  };

  // @ts-ignore
  const props: LayoutContextProps = {
    authType: data?.authType || '',
    isSam,
    isSso: data?.authType === 'sso',
    buildings: data?.buildings || [],
    casAuthUrl: data?.casAuthUrl || '',
    // @ts-ignore
    chatSettings: data?.chatSettings || { profiles: '', rootUrl: '', title: '', tenant: '' },
    clientUserTypes: data?.clientUserTypes || [],
    customerId: data?.id,
    hasMultipleSites: data?.hasMultipleSites,
    isMobile,
    largeHeroImage:
      data?.largeHeroImage?.split('/').pop() !== 'null'
        ? data?.largeHeroImage || undefined
        : undefined,
    logo: data?.logo || '',
    favicon: data?.favicon || '',
    recoveryQuestions: data?.recoveryQuestions || [],
    roomBasedPanEnabled: !!data?.roomBasedPanEnabled,
    subdomain: data?.subdomain || '',
    university: data?.displayName,
    textSupportMessage: data?.textSupportMessage,
    textSupportNumber: data?.textSupportNumber,
    phoneSupport: data?.phoneSupport,
    marketingEnabled: data?.marketingEnabled,
    marketingHeader: data?.marketingHeader,
    marketingImage: data?.marketingImage,
    marketingCopy: data?.marketingCopy,
    usernameLabel: data?.usernameLabel ? data?.usernameLabel : 'Username',
    passwordLabel: data?.passwordLabel ? data?.passwordLabel : 'Password',
    welcomeMessage: data?.welcomeMessage,
    logInRedirect: logInRedirect,
    setupInstructions: data?.setupInstructions || '',
    panSharingEnabled: !!data?.panSharingEnabled,
    logoText: data?.logoText || '',
    eduOnly: !!data?.eduOnly,
    type: type as 'Property' | 'School',
    changeType: changeType,
    emailSupport: data?.emailSupport || '',
    // @ts-ignore
    testCaptiveMac: data?.testCaptiveMac || sessionStorage.getItem('mac') || null || '',
    // @ts-ignore
    wifiPasswordEnabled: !!data?.wifiPasswordEnabled,
    // @ts-ignore
    ssid: data?.ssid,
    // @ts-ignore
    isGuestType: data?.guestType !== 'none' ? true : false,
    // @ts-ignore
    guestType: data?.guestType || '',
    // @ts-ignore
    schoolUidName: data?.schoolUidName || '',
    // @ts-ignore
    startHereSsid: data?.startHereSsid || '',
    // @ts-ignore
    pskEnabled: data?.pskEnabled,
  };

  return <LayoutContext.Provider value={props}>{children}</LayoutContext.Provider>;
};

export { LayoutContext, LayoutProvider };
