import { useMutation } from '@tanstack/react-query';

import { getPanRoomsByBuildingId, IPanRoom } from 'sdk-apogee';

const panRoomOptions = (data: IPanRoom[] = []) =>
  !data.length ? [] : data.map(({ id, name }) => ({ label: name, value: id }));

const usePanRooms = ({ onSuccess = () => {}, onError = () => {} } = {}) => {
  const panRooms = useMutation<IPanRoom[], Error, number | string | undefined>({
    mutationFn: (buildingId: number | string | undefined) =>
      buildingId
        ? getPanRoomsByBuildingId(buildingId)
        : new Promise((resolve) => {
            resolve([]);
          }),
    onSuccess,
    onError,
  });

  return {
    getPanRooms: panRooms.mutateAsync,
    panRooms,
    panRoomOptions: panRoomOptions(panRooms.data),
  };
};

export default usePanRooms;
