import { FCWithChildren } from '@types';
import React from 'react';

import { Button, Modal, ModalBody } from '@components';

type MaxAllowedDevicesModalProps = {
  closeModal: () => void;
  isOpen: boolean;
};

const MaxAllowedDevicesModal: FCWithChildren<MaxAllowedDevicesModalProps> = ({
  closeModal,
  isOpen,
}) => {
  return (
    <Modal
      closeModal={closeModal}
      isOpen={isOpen}
      noHeader
      customWebClass="w-7/12 sm:w-6/12 md:w-5/12"
    >
      <ModalBody>
        <section className="flex flex-col justify-center items-center">
          <span className="text-center my-2">
            You cannot select this Internet Package - you have too many devices registered.
          </span>
          <span className="text-center">Please go to the My Devices page and delete devices.</span>
          <div className="mt-8">
            <Button onClick={closeModal}>Close</Button>
          </div>
        </section>
      </ModalBody>
    </Modal>
  );
};

export default MaxAllowedDevicesModal;
