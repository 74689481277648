import { FCWithChildren } from '@types';
import React, { useState, useEffect } from 'react';
import { getDeviceStatus } from 'sdk-apogee';
import { getSubdomain, localDayDateTime } from '@utils';
import { ModalThankYouGuest } from '@components';
import useLayoutContext from '@hooks/useLayoutContext';
import usePageTitle from '@hooks/usePageTitle';

type deviceProps = {
  username?: string;
  userType?: string;
  expiry?: string;
};

const ThankYouGuest: FCWithChildren = () => {
  usePageTitle('Guest Account');
  const SUBDOMAIN = getSubdomain();
  const { testCaptiveMac } = useLayoutContext();
  const [isOpen, setIsOpen] = useState(true);
  const [deviceStatus, setDeviceStatus] = useState<deviceProps>();
  const expiry = localDayDateTime(deviceStatus?.expiry);
  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (testCaptiveMac) {
      getDeviceStatus({
        domain: SUBDOMAIN,
        macAddress: testCaptiveMac || '',
      }).then((res) => {
        setDeviceStatus(res);
      });
    }
  }, []);

  return <ModalThankYouGuest isOpen={isOpen} onClose={onClose} expiry={expiry} />;
};

export default ThankYouGuest;
