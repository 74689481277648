import { FCWithChildren } from '@types';
import React, { useRef, useState } from 'react';

import { useMutation } from '@tanstack/react-query';

import { getMacAddressFromUploadedImage, IMacAddresses } from 'sdk-apogee';

import { Button, Input, Loading, Modal, ModalBody, ModalFooter } from '@components';
import { AiFillCamera } from 'react-icons/ai';
import EdiText from 'react-editext';
import { FaAngleLeft, FaCheck, FaCheckCircle, FaPencilAlt, FaTimes } from 'react-icons/fa';
import classNames from 'classnames';

type UploadMacAddressImageModalProps = {
  clearHandler?: (e: React.BaseSyntheticEvent<object>) => void;
  setValue: any;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  openModalCallback?: () => void;
  closeModalCallback?: () => void;
};

const UploadMacAddressImage: FCWithChildren<UploadMacAddressImageModalProps> = ({
  clearHandler,
  setValue,
  onKeyDown,
  openModalCallback,
  closeModalCallback,
}) => {
  const cameraBtnRef = useRef<HTMLButtonElement | null>(null);
  const imgInputRef = useRef<HTMLInputElement | null>();
  const [isEditing, setIsEditing] = useState(false);
  const [macAddressParsingError, setMacAddressParsingError] = useState('');
  const [macAddressesList, setMacAddressesList] = useState([] as any);
  const [selectedMacAddress, setSelectedMacAddress] = useState('');
  const [showUploadMacAddressImageModal, setShowUploadMacAddressImageModal] = useState(false);
  const [uploadedImage, setUploadedImage] = useState('' as string | Blob);
  const [invalidMacAddressMessage, setInvalidMacAddressMessage] = useState('');

  const clickImageInput = () => {
    setMacAddressParsingError('');
    setInvalidMacAddressMessage('');
    setMacAddressesList([]);
    imgInputRef.current?.click();
  };

  const closeAndResetProps = () => {
    setMacAddressParsingError('');
    setInvalidMacAddressMessage('');
    setSelectedMacAddress('');
    setShowUploadMacAddressImageModal(false);
    setMacAddressesList([]);
    setUploadedImage('');
    if (closeModalCallback) {
      closeModalCallback();
    }
  };

  const getUploadedImageFormData = () => {
    const apiFormData = new FormData();
    apiFormData.set('image', uploadedImage);
    return apiFormData;
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.currentTarget.files ? e.currentTarget.files[0] : null;
    if (clearHandler) {
      clearHandler(e);
    }
    if (image) {
      if (openModalCallback) {
        openModalCallback();
      }
      setUploadedImage(image);
      setShowUploadMacAddressImageModal(true);
    }
  };

  const processMacAddressResponse = ({ macAddresses }: IMacAddresses) => {
    if (macAddresses.length > 0) {
      setMacAddressesList(macAddresses);
    } else {
      setMacAddressParsingError('Mac Address not detected');
    }
  };

  const saveMacAddress = (index: number, value: string) => {
    setIsEditing(false);
    setSelectedMacAddress(value);
    let updatedMacAddressesList = macAddressesList;
    updatedMacAddressesList[index] = value;
    setMacAddressesList(updatedMacAddressesList);
  };

  const selectMacAddress = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    macAddress: React.SetStateAction<string>,
  ) => {
    const el = e.target as HTMLInputElement;
    if (isEditing || el.getAttribute('editext') === 'edit-button') {
      return false;
    }
    setSelectedMacAddress(macAddress);
  };

  const setMacAddress = () => {
    if (selectedMacAddress) {
      setValue('macAddress', selectedMacAddress.toUpperCase());
      closeAndResetProps();
    } else {
      setMacAddressParsingError('Please select a Mac Address');
    }
  };

  const validateMacAddress = (value: string) => {
    setMacAddressParsingError('');
    setInvalidMacAddressMessage('');
    const regexp = /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/i;
    return regexp.test(value);
  };

  const getMacAddressesList = () => {
    return macAddressesList.length ? (
      <div className="mac-addresses mt-6">
        <div className="select-one full-w block bg-black text-center text-white p-2 mb-5 relative">
          <span>Select One</span>
        </div>
        <div className="max-h-64 overflow-y-auto relative">
          {invalidMacAddressMessage && (
            <div className="text-red-600 text-center pb-2">{invalidMacAddressMessage}</div>
          )}
          {macAddressesList.map((macAddress: string, index: number) => {
            const macAddressBoxDefaultClasses =
              'cursor-pointer relative flex text-gray-700 justify-center border border-gainsboro bg-white mb-4';
            const macAddressBoxSelectedClassed = 'border-light-sky-blue text-black bg-ghost-white';
            const macAddressBoxClasses = classNames(
              macAddressBoxDefaultClasses,
              selectedMacAddress === macAddress ? macAddressBoxSelectedClassed : '',
            );
            const checkBoxClass = classNames(
              'absolute text-xl top-3 left-2',
              selectedMacAddress === macAddress ? 'text-neon-blue' : 'text-gray-300',
            );
            // @ts-ignore
            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
              <div
                className={macAddressBoxClasses}
                key={index}
                onClick={(e) => selectMacAddress(e, macAddress)}
                role="button"
              >
                <FaCheckCircle className={checkBoxClass} />
                <EdiText
                  key={index}
                  type="text"
                  value={macAddress}
                  validationMessage=""
                  validation={(value) => validateMacAddress(value)}
                  onValidationFail={() => setInvalidMacAddressMessage('Invalid Mac Address')}
                  onEditingStart={() => {
                    setSelectedMacAddress('');
                    setIsEditing(true);
                  }}
                  onCancel={() => {
                    setInvalidMacAddressMessage('');
                    setSelectedMacAddress('');
                    setIsEditing(false);
                  }}
                  onSave={(value: any) => {
                    saveMacAddress(index, value);
                  }}
                  viewContainerClassName="min-h-40 flex items-center editext-block"
                  editButtonClassName="h-10 w-10 absolute top-0 right-0"
                  editButtonContent={<FaPencilAlt />}
                  saveButtonClassName="h-10 w-10 absolute top-0 right-0"
                  cancelButtonClassName="h-10 w-10 absolute top-0 right-10"
                  saveButtonContent={<FaCheck className="text-green-600" />}
                  cancelButtonContent={<FaTimes className="text-red-600" />}
                />
              </div>
            );
          })}
        </div>
        <Button className="mt-4" block onClick={setMacAddress} disabled={!selectedMacAddress}>
          Confirm
        </Button>
      </div>
    ) : null;
  };

  const { mutate: uploadImage, isPending: isUploading } = useMutation({
    mutationFn: (values: FormData) => getMacAddressFromUploadedImage(values),
    onSuccess: (res: IMacAddresses) => {
      processMacAddressResponse(res);
    },
    onError: () => setMacAddressParsingError('An error has occurred. Try again.'),
  });

  return (
    <>
      <div className="text-xs flex justify-end items-center py-2 text-gray-700">
        <Input
          name="image"
          type="file"
          accept="image/*"
          onClick={(e: { target: { value: null } }) => {
            e.target.value = null;
          }}
          onChange={onChangeHandler}
          className="hidden"
          ref={imgInputRef}
        />
        <Button
          ref={cameraBtnRef}
          className="text-xs flex justify-end items-center py-2 text-gray-700"
          layout="link"
          size="noPadding"
          onClick={() => clickImageInput()}
          onKeyDown={onKeyDown}
          role="button"
        >
          <span>Use image</span>
          <AiFillCamera className="text-2xl ml-2" />
        </Button>
      </div>
      {showUploadMacAddressImageModal && (
        <Modal
          noHeader
          isOpen
          headerTitle="Confirm MAC Address"
          closeModal={closeAndResetProps}
          closeOnEscape={false}
          closeOnOutsideClick={false}
          customWebClass="max-h-screen-90 w-1/3"
        >
          <ModalBody>
            {uploadedImage && uploadedImage !== '' && (
              <div className="text-center">
                <img
                  src={URL.createObjectURL(uploadedImage as Blob)}
                  className="max-h-96 inline"
                  alt="Uploaded pic"
                />
              </div>
            )}
            {macAddressParsingError && (
              <span className="text-red-700 text-center block full-w mt-3 pt-2 font-bold border-t border-red-600">
                {macAddressParsingError}
              </span>
            )}
            {getMacAddressesList()}
          </ModalBody>
          <ModalFooter customClassNames="flex-wrap">
            {isUploading && (
              <div className="text-center mb-8 w-full">
                <div className="w-6 my-0 mx-auto mb-4">
                  <Loading size="xsm" />
                </div>
                <span>Processing...This may take a moment.</span>
              </div>
            )}
            <Button
              className={macAddressesList.length || isUploading ? 'hidden' : ''}
              block
              onClick={() => uploadImage(getUploadedImageFormData())}
            >
              Submit
            </Button>
            <Button
              className={`block text-xs flex w-full justify-center items-center py-2 text-gray-700 ${
                isUploading ? 'hidden' : ''
              }`}
              layout="link"
              size="noPadding"
              onClick={() => clickImageInput()}
            >
              <span className="text-sm">
                <FaAngleLeft className="inline text-lg" /> Choose another photo
              </span>
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default UploadMacAddressImage;
