import { FCWithChildren } from '@types';
import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';

import { useForm, FormProvider } from 'react-hook-form';

import { IAccount, IBuilding } from 'sdk-apogee';

import { useToasts } from 'react-toast-notifications';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button } from '@components';
import useLogout from '@hooks/useLogout';
import useUpdateProfile from '@hooks/useUpdateProfile';
import { useAppContext } from '@contexts/AppContext';

import LoginInformation from '../LoginInformation';
import PersonalInformation from '../PersonalInformation';
import ServicePackages from '../ServicePackages';
import Preferences from '../Preferences';
import { parseError, stripCountryCode } from '@utils';

import DeleteAccountModal from '../DeleteAccountModal';
import useProfile from '@hooks/useProfile';
import useLayoutContext from '@hooks/useLayoutContext';
import { NumericDropdown } from '@types';

type AccountFormData = {
  building: IBuilding;
  phone: string;
  email: string;
  firstName: string;
  lastName: string;
  room: string;
  panRoom: NumericDropdown;
  username: string;
  emailNotification: boolean;
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
};

type SectionObject = {
  title: string;
  ref: React.MutableRefObject<HTMLElement | null>;
};

type AccountSectionsProps = {
  account: IAccount;
  accountSections: {
    logInInfo: SectionObject;
    personalInfo: SectionObject;
    servicesPackages: SectionObject;
    preferences: SectionObject;
  };
  isMobile?: boolean;
};
const AccountSection: FCWithChildren<AccountSectionsProps> = ({
  account,
  accountSections,
  isMobile = false,
}) => {
  const { logout } = useLogout();
  const { addToast } = useToasts();
  const { setIsAuthenticated } = useAppContext();
  // const history = useHistory();
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);
  const { data } = useProfile();
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const {
    email,
    firstName,
    id,
    lastName,
    phone,
    room,
    panRoom,
    username,
    emailNotification,
    buildingId,
    customer,
  } = account;

  const SCHEMA = yup.object().shape({
    username: yup.string().required().min(4),
    firstName: yup.string().required('First Name required').min(2),
    lastName: yup.string().required('Last Name required').min(2),
    email: yup.string().email('Must be a valid Email Address'),
    oldPassword: yup.string().when([], {
      is: () => isChangingPassword,
      then: () => yup.string().min(6, 'Old password must be at least 6 characters'),
    }),
    password: yup.string().when([], {
      is: () => isChangingPassword,
      then: () => yup.string().min(6, 'New password must be at least 6 characters'),
    }),
    passwordConfirmation: yup.string().when([], {
      is: () => isChangingPassword,
      then: () =>
        yup.string().oneOf([yup.ref('password')], 'Check confirmation password matches password'),
    }),
    phone: yup
      .string()
      .test('len', 'Phone number should be minimum 10 digits', (val) =>
        val ? stripCountryCode(val).length >= 10 : true,
      )
      .test('valid', 'Phone number should be valid', (val) =>
        val ? /^\d+$/.test(stripCountryCode(val)) : true,
      )
      .nullable(),
    building: yup
      .object()
      .when([], {
        is: () => !customer?.hasMultipleSites,
        then: () => yup.object().required('Please select a building'),
      })
      .nullable(),
    room: yup
      .string()
      .when([], {
        is: () => !customer?.roomBasedPanEnabled,
        then: () => yup.string().required('Suite and/or Room # required'),
      })
      .nullable(),
    panRoom: yup
      .object()
      .when([], {
        is: () => customer?.roomBasedPanEnabled,
        then: () =>
          yup
            .object()
            .shape({
              id: yup.number(),
              name: yup.string().required('Please select a room'),
            })
            .required('Please select a room'),
      })
      .nullable(),
  });

  const { recoveryQuestions } = useLayoutContext();

  const handleSuccess = () => {
    addToast('Profile updated successfully', { appearance: 'success' });
  };

  const handleError = (apiError: any) => {
    addToast(parseError(apiError?.data?.error) || 'Some errors occurred while updating profile', {
      appearance: 'error',
    });
  };

  const { isPending, update } = useUpdateProfile({ id, handleSuccess, handleError });

  const getBuilding = (building_id: number) =>
    customer.buildings?.find((building) => building.id === building_id);

  const signOut = () => {
    setIsAuthenticated(false);
    logout();
  };

  const methods = useForm<AccountFormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    // @ts-ignore
    resolver: yupResolver(SCHEMA),
    defaultValues: {
      firstName: firstName === 'Not Provided' ? '' : firstName,
      lastName: lastName === 'Not Provided' ? '' : lastName,
      email,
      phone: phone ? `+1${phone}` : '',
      room,
      panRoom,
      username,
      emailNotification,
      building: getBuilding(buildingId),
      oldPassword: '',
      password: '',
      passwordConfirmation: '',
    },
  });

  const onSubmit = (data: AccountFormData) => {
    let aux = { ...data };

    if (!data?.oldPassword && !data?.password && !data?.passwordConfirmation) {
      aux = Object.assign({}, aux, {
        oldPassword: null,
        password: null,
        passwordConfirmation: null,
        phone: aux.phone ? stripCountryCode(aux.phone) : '',
      });
    }

    // @ts-ignore
    delete aux.building;
    const updateProfile = { account: Object.assign({}, aux, { buildingId: data.building?.id }) };

    update(updateProfile as any);
  };

  const closeDeleteAccountModal = () => {
    setIsDeleteAccountModalOpen(false);
  };

  const openDeleteAccountModal = () => {
    setIsDeleteAccountModalOpen(true);
  };

  const recoveryQuestion =
    recoveryQuestions.filter(({ id }) => id === data?.recoveryQuestionId)[0]?.question ??
    'Could not find question';

  return (
    <>
      <DeleteAccountModal
        closeModal={closeDeleteAccountModal}
        recoveryQuestion={recoveryQuestion}
        accountId={data?.id || -1}
        isOpen={isDeleteAccountModalOpen}
        signOut={signOut}
      />
      <FormProvider {...methods}>
        <div className="flex flex-col flex-1 w-full">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col md:divide-y md:divide-gray-200 w-full md:w-4/5">
              <LoginInformation
                isMobile={isMobile}
                isChangingPassword={isChangingPassword}
                setIsChangingPassword={setIsChangingPassword}
                ref={accountSections.logInInfo.ref}
              />
              <PersonalInformation
                hasMultipleSites={customer.hasMultipleSites}
                isMobile={isMobile}
                buildingOptions={customer.buildings}
                roomBasedPanEnabled={customer?.roomBasedPanEnabled}
                ref={accountSections.personalInfo.ref}
              />
              <ServicePackages isMobile={isMobile} ref={accountSections.servicesPackages.ref} />
              <Preferences isMobile={isMobile} ref={accountSections.preferences.ref} />
            </div>

            <div className="pt-8">
              <Button layout="link" onClick={signOut} size="noPadding">
                <span className="text-blue-700 font-semibold">Sign Out</span>
              </Button>
            </div>

            <div className="pt-8">
              <Button layout="link" onClick={openDeleteAccountModal} size="noPadding">
                <span className="text-red-700 font-semibold">Delete Account</span>
              </Button>
            </div>

            <div className="flex my-8 w-full md:w-1/5 md:max-w-xs">
              <Button disabled={isPending} loading={isPending} type="submit" block>
                Save
              </Button>
            </div>
          </form>
        </div>
      </FormProvider>
    </>
  );
};

export default AccountSection;
