import { APOGEE_DOMAIN } from 'sdk-apogee';

const buildUrl = (subdomain: string = '') => {
  const url = window.location.host.split('.');

  if (url[0] === 'www' || url[0] === APOGEE_DOMAIN) {
    url.shift();
  }

  url.unshift(subdomain);
  const filteredUrl = url.filter((x) => x);

  return `${window.location.protocol}//${filteredUrl.join('.')}`;
};

export default buildUrl;
