import { FCWithChildren } from '@types';
import React from 'react';
import classNames from 'classnames';

type ModalFooterProps = {
  customClassNames?: string;
};

const ModalFooter: FCWithChildren<ModalFooterProps> = ({ children, customClassNames }) => {
  const footerClasses = classNames(
    'flex items-center justify-end py-4 px-12 border-t border-solid border-gray-300 rounded-b',
    customClassNames ? customClassNames : '',
  );
  return <div className={footerClasses}>{children}</div>;
};

export default ModalFooter;
