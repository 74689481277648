import React from 'react';

import { RequestManagerWrapper } from '@components';
import Help from '@pages/Help';
import usePageTitle from '@hooks/usePageTitle';
import { FCWithChildren } from '@types';

const FAQ: FCWithChildren<{}> = () => {
  usePageTitle('Support Center');
  return (
    <RequestManagerWrapper loadingContainer>
      <Help />
    </RequestManagerWrapper>
  );
};

export default FAQ;
