import { FCWithChildren } from '@types';
import React, { Fragment, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useToasts } from 'react-toast-notifications';

import { Button, Input, Label, SeparatorLine, HelperText } from '@components';
import useLayoutContext from '@hooks/useLayoutContext';
import { useAppContext } from '@contexts/AppContext';
import useUpdateProfile from '@hooks/useUpdateProfile';
import { parseError } from '@utils';

type SetUpPasswordProps = {};

type ErrorType = {
  status: string;
  data: {
    error?: string;
    errors?: Record<string, string[]>;
  };
};

const SetUpPassword: FCWithChildren<SetUpPasswordProps> = () => {
  const { user } = useAppContext();
  const { addToast } = useToasts();
  const { isMobile } = useLayoutContext();

  const [wifiPassword, setWifiPassword] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setWifiPassword(user?.preWiredKey);
  }, [user]);

  const handleSuccess = () =>
    addToast('Wi-Fi password saved  successfully', { appearance: 'success' });

  const { update, isPending } = useUpdateProfile({ id: user?.id, handleSuccess });

  const onFocusHandler = () => setIsFocused(true);
  const onBlurHandler = () => setIsFocused(false);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    isError && setIsError(false);
    setWifiPassword(e.target.value.trim());
  };

  const onClickHandler = () => {
    const preWiredKey = wifiPassword.trim();
    if ((preWiredKey || '').length < 8) {
      setIsError(true);
    } else {
      update(
        { preWiredKey },
        {
          onError: (apiError: ErrorType) => {
            if (apiError?.data?.error) {
              addToast(parseError(apiError?.data?.error), {
                appearance: 'error',
              });
            } else {
              addToast('Unknown error changing Wi-Fi Password', {
                appearance: 'error',
              });
            }
          },
        },
      );
    }
  };

  const containerCls = classNames(
    'flex flex-col justify-start my-2 ',
    isMobile ? 'w-full' : 'w-full md:pr-8 md:w-1/2 md:my-8',
  );

  const boxCls = classNames(
    'w-full bg-white md:p-4',
    isMobile ? 'w-full' : 'md:border md:border-gray-200 md:rounded md:shadow-xl',
  );

  const buttonStyle = classNames(
    'w-full bg-gray-400',
    isMobile ? 'mt-4' : 'w-2/12 md:w-2/5 md:mt-0',
  );

  const containerStyle = classNames(
    'flex items-center bg-white flex-wrap',
    isMobile ? 'flex-col justify-center' : null,
    // isFocused ? 'border-blue-400' : 'border-transparent border-gray-300'
  );

  return (
    <Fragment>
      <div className={containerCls}>
        <div className={boxCls}>
          <span className="text-sm font-bold">Set Up Personal Network Wi-Fi Password</span>
          <div className="w-full my-4">
            <Label className="mb-2" htmlFor="password">
              <span className="text-sm font-thin">Wi-Fi Password</span>
            </Label>
            <div className={containerStyle}>
              <div>
                <Input
                  id="password"
                  name="wifiPassword"
                  onBlur={onBlurHandler}
                  onChange={onChangeHandler}
                  onFocus={onFocusHandler}
                  type="text"
                  value={wifiPassword}
                />
              </div>
              <Button
                className="flex"
                layout="link"
                onClick={() => {
                  navigator.clipboard.writeText(user.preWiredKey);
                  addToast('Wi-Fi password copied', { appearance: 'success' });
                }}
              >
                <span className="text-sm font-black text-blue-700">Copy</span>
              </Button>
              <Button
                className={buttonStyle}
                disabled={isPending}
                loading={isPending}
                onClick={onClickHandler}
              >
                Save
              </Button>
            </div>
            <div className="flex items-center w-full h-8">
              {isError && (
                <HelperText valid={false}>
                  Password too short. Must be 8 characters long.
                </HelperText>
              )}
            </div>
          </div>
        </div>
      </div>
      {isMobile && <SeparatorLine />}
    </Fragment>
  );
};

export default SetUpPassword;
