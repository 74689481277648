import React, { forwardRef } from 'react';
import classNames from 'classnames';

interface Props extends React.TdHTMLAttributes<HTMLTableCellElement> {
  colSpan?: number;
}

const TableCell = forwardRef<HTMLTableCellElement, Props>(function TableCell(props, ref) {
  const { className, children, colSpan = 1, ...other } = props;

  const baseStyle = 'px-4 py-3';

  const cls = classNames(baseStyle, className);

  return (
    <td className={cls} ref={ref} {...other} colSpan={colSpan}>
      {children}
    </td>
  );
});

export default TableCell;
