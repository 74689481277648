import React, { forwardRef } from 'react';
import classNames from 'classnames';

interface Props extends React.HTMLAttributes<HTMLTableSectionElement> {}

const TableHeader = forwardRef<HTMLTableSectionElement, Props>(function TableHeader(props, ref) {
  const { className, children, ...other } = props;

  const baseStyle =
    'text-xs font-semibold tracking-wide text-left text-gray-600 uppercase border-b bg-gray-50';

  const cls = classNames(baseStyle, className);

  return (
    <thead className={cls} ref={ref} {...other}>
      {children}
    </thead>
  );
});

export default TableHeader;
