import { FCWithChildren } from '@types';
import React from 'react';
import { SeparatorLine } from '@components';
import classNames from 'classnames';

type StepsProps = {
  currentStep: number;
  steps: { title: string; stepTitle: string; iconTitle: number }[];
};

interface StepProps {
  title: string;
  iconTitle: number;
  stepTitle: string;
}
const Steps: FCWithChildren<StepsProps> = ({ steps, currentStep = 1 }) => {
  const stepsLength = steps.length;

  return (
    <div className="flex">
      {steps.map((step: StepProps, index: number) => {
        const stepClassNames = classNames(
          'w-auto',
          index + 1 === currentStep ? 'text-black' : 'text-silver',
        );
        const iconClassNames = classNames(
          'steps-item-icon inline-block w-8 h-8 text:base leading-loose text-center rounded-3xl text-white',
          index + 1 === currentStep ? 'bg-black border-black' : 'bg-sliver border-sliver',
        );

        return (
          <div className={stepClassNames} key={index}>
            <div className="relative mb-2 inline-block">
              <div className="steps-item-container">
                <div className={iconClassNames}>
                  <span className="steps-icon">{step.iconTitle}</span>
                </div>
                <div className="steps-item-content inline-block">
                  <div className="steps-item-title ml-2">{step.title}</div>
                </div>
                {index + 1 !== stepsLength ? (
                  <div className="steps-tail inline-block inline-block w-20 h-1 bg-black ml-3 mr-3 align-middle">
                    <SeparatorLine />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Steps;
