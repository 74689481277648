"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var getUserByToken = function (_a) {
    var _b = _a.token, token = _b === void 0 ? '' : _b, _c = _a.subdomain, subdomain = _c === void 0 ? '' : _c;
    return (0, service_1.getService)()
        .get("/customers/subdomain/".concat(subdomain, "/get_user_by_token/").concat(token))
        .then(function (_a) {
        var data = _a.data;
        return data;
    })
        .catch(function (_a) {
        var response = _a.response;
        return Promise.reject(response);
    });
};
exports.default = getUserByToken;
