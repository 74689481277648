import React, { useEffect } from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { Button } from '@components';
import { useHistory } from 'react-router-dom';
import useProfile from '@hooks/useProfile';
import { useAppContext } from '@contexts/AppContext';
declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

const CheckOutStep3 = () => {
  const history = useHistory();
  const { data: userInfo } = useProfile();
  const { user, saveUser } = useAppContext();

  const updatedMaxDevices = userInfo?.maxDevices;
  const expiredPackageWarning = userInfo?.expiredPackageWarning;

  const updatedUser = {
    ...user,
    maxDevices: updatedMaxDevices,
    expiredPackageWarning: expiredPackageWarning,
  };

  useEffect(() => {
    saveUser(updatedUser);
  }, [userInfo, updatedUser]);

  return (
    <div className="w-full mt-10">
      <span className="text-3xl  pb-8">Confirmation</span>
      <div className="flex-row">
        <div className="w-full">
          <div className="w-full mt-5 mb-5">Thank you for your purchase</div>
          <div className="w-full mt-5 mb-5">Your receipt will be emailed to you shortly.</div>
        </div>
        <div className="w-full">
          <Button
            className="block text-xs flex w-full justify-left items-left py-2 text-gray-700 mt-10 mb-10"
            layout="link"
            size="noPadding"
            onClick={() => {
              if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage('ReturnToDashboard');
              }
              history.push('/home');
            }}
          >
            <span className="text-sm">
              <FaAngleLeft className="inline text-lg" /> Return to your Dashboard
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CheckOutStep3;
