import { FCWithChildren } from '@types';
import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';

import { addGroup, ICampusGroup, updateCampusGroup } from 'sdk-apogee';

import {
  Button,
  HelperText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from '@components';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

type AddGroupModalProps = {
  closeModal: () => void;
  isOpen: boolean;
  group: ICampusGroup | undefined;
};

type Params = {
  deviceGroup: {
    name: string;
  };
};

type FormData = {
  name: string;
};

const CAMPUS_DEVICE_GROUP_SCHEMA = yup.object().shape({
  name: yup.string().required('Group Name is required').min(1),
});

const AddGroupModal: FCWithChildren<AddGroupModalProps> = ({ isOpen, closeModal, group }) => {
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const { mutate: saveGroup, ...saveQueryInfo } = useMutation({
    mutationFn: (data: Params) => addGroup(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['campusGroups'] });
      queryClient.invalidateQueries({ queryKey: ['campus-devices'] });
      queryClient.invalidateQueries({ queryKey: ['addCampusDeviceModalOptions'] });
      closeModal();
    },
    onError: (apiError: any) => {
      const formErrors = apiError?.errors;
      const formError = apiError?.error;
      if (formErrors && formErrors.constructor === Object) {
        addToast('Check one or more fields', { appearance: 'error' });
        Object.keys(formErrors).forEach(function (key) {
          // @ts-ignore
          setError(key, { type: 'server', message: formErrors[key].join('. ') });
        });
      } else if (formError) {
        addToast(formError, { appearance: 'error' });
      } else {
        addToast('An unknown error occurred trying to create the device group', {
          appearance: 'error',
        });
      }
    },
  });

  const { mutate: updateGroup, ...updateQueryInfo } = useMutation({
    mutationFn: (values: Params) => updateCampusGroup(group?.id as number, values),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['campusGroups'] });
      closeModal();
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    setError,
  } = useForm<FormData>({
    resolver: yupResolver(CAMPUS_DEVICE_GROUP_SCHEMA),
  });

  useEffect(() => {
    if (group) {
      setValue('name', group.name);
    }
  }, [group, setValue]);

  const onSubmit = (data: FormData) => {
    const selectedValues = {
      deviceGroup: {
        name: data.name,
      },
    };

    group ? updateGroup(selectedValues) : saveGroup(selectedValues);
  };

  const isValid = (input: string): boolean => !(errors as any)[input]?.message;

  const isLoading = updateQueryInfo.isPending || saveQueryInfo.isPending;

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <ModalHeader>
        <span className="font-semibold">{group ? 'Edit Group' : 'Create a Group'}</span>
      </ModalHeader>
      <ModalBody>
        <Label className="my-4">
          <span className="text-xs">Group Name</span>
          <Input className="my-1" {...register('name')} type="text" />
        </Label>
        <HelperText valid={isValid('name')}>{errors.name?.message}</HelperText>
      </ModalBody>
      <ModalFooter>
        <Button block disabled={isLoading} loading={isLoading} onClick={handleSubmit(onSubmit)}>
          {group ? 'Save' : 'Create Group'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddGroupModal;
