import { getUserByToken } from 'sdk-apogee';
import { useSuspenseQuery } from '@tanstack/react-query';

const useUserByToken = ({ token = '', subdomain = '' }) =>
  useSuspenseQuery({
    queryKey: ['getUserByToken'],
    queryFn: () => getUserByToken({ token, subdomain }).then((account) => account),
    // enabled: !!token,
  });

export default useUserByToken;
