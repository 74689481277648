import { useHistory } from 'react-router-dom';

const useLogout = () => {
  const history = useHistory();

  const logout = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('order');
    history.push('/');
  };

  return {
    logout,
  };
};

export default useLogout;
