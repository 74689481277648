import React from 'react';

import Loading from '../Loading';

const LoadingContainer = () => (
  <div className="w-full h-screen flex justify-center items-center">
    <Loading />
  </div>
);

export default LoadingContainer;
