import { FCWithChildren } from '@types';
import React from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { INetworkInfo, IInvitee, getReceivedInvites, IReceivedInvitation } from 'sdk-apogee';

import useLayoutContext from '@hooks/useLayoutContext';

import { ActionTypes } from '../SharedList';
import Invite from './Invite';

type InvitesProps = {
  myNetworkStatus: INetworkInfo | null | undefined;
  dispatch: (action: ActionTypes) => void;
};

const Invites: FCWithChildren<InvitesProps> = ({ myNetworkStatus, dispatch }) => {
  const { isMobile } = useLayoutContext();

  const { data: receivedInvites } = useSuspenseQuery<IReceivedInvitation[]>({
    queryKey: ['received-invites'],
    // @ts-ignore
    queryFn: getReceivedInvites,
  });

  const sentInvites = myNetworkStatus
    ? myNetworkStatus.invitations.filter((user) => !user.accepted)
    : [];

  const awaitingResponse = receivedInvites
    ? receivedInvites
        // @ts-ignore
        .filter(({ accepted }) => !accepted)
        .map(({ id, ownerId, ownerName, ownerEmail, invitations }) => ({
          accountId: ownerId,
          accountName: ownerName,
          accountEmail: ownerEmail,
          id: id,
          invitations,
        }))
    : [];

  return (
    <>
      {isMobile ? (
        <>
          <Invite
            title="Sent Invites"
            list={sentInvites as IInvitee[]}
            isMobile
            dispatch={dispatch}
          />
          <Invite
            title="Received Invites"
            list={awaitingResponse as IReceivedInvitation[]}
            isMobile
            isRowClickable
            dispatch={dispatch}
          />
        </>
      ) : (
        <div className="w-full flex flex-row justify-between text-sm my-8">
          <Invite title="Sent Invites" list={sentInvites as IInvitee[]} dispatch={dispatch} />

          <div className="w-2/12" />

          <Invite
            title="Awaiting Response"
            list={awaitingResponse as IReceivedInvitation[]}
            isRowClickable
            dispatch={dispatch}
          />
        </div>
      )}
    </>
  );
};

export default Invites;
