import { FCWithChildren } from '@types';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useReducer, useEffect } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { ICampusDevice, getCampusDevices, ICampusDevices } from 'sdk-apogee';

import usePrevious from '@hooks/usePrevious';

import {
  DashedButton,
  RequestManagerWrapper,
  StatusInfoModal,
  Table,
  TableBody,
  TableContainer,
} from '@components';
import useConnectionStatus from '@hooks/useConnectionStatus';

import CampusDevice from '../CampusDevice';
import CampusDeviceModal from '../CampusDeviceModal';
import AddDeviceOptionsModal from '../AddDeviceOptionsModal';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import TableHead from '../TableHead';
import ExportCSV from '../ExportCSV';
import ImportCSV from '@pages/ManagedCampus/components/ImportCSV';

type StateType = {
  campusDevice: ICampusDevice | undefined;
  isModalOpen: boolean;
  isImportModalOpen: boolean;
};
interface ActionType {
  type: string;
  isModalOpen?: boolean;
  isImportModalOpen?: boolean;
}
interface EditActionInterface extends ActionType {
  campusDevice?: ICampusDevice;
}

interface AddActionType extends ActionType {
  campusDevice?: undefined;
}

type ActionTypes = EditActionInterface | AddActionType;

type DeleteModalStateType = {
  isOpen: boolean;
  deleteFn: null | (() => void);
  name: string;
};

const initialState = {
  isModalOpen: false,
  campusDevice: undefined,
  isImportModalOpen: false,
};

const reducer = (state: StateType, action: ActionTypes): StateType => {
  switch (action.type) {
    case 'addDevice':
      return { ...state, isModalOpen: true };
    case 'editDevice':
      return {
        ...state,
        campusDevice: action.campusDevice && action.campusDevice,
        isModalOpen: true,
      };
    case 'closeModal':
      return {
        ...state,
        campusDevice: undefined,
        isModalOpen: false,
      };
    case 'importCampusDevices':
      return { ...state, isImportModalOpen: true };
    case 'closeImportCSVModal':
      return { ...state, isImportModalOpen: false };
    default:
      return state;
  }
};

type DevicesTableProps = {
  deviceSearchResult: ICampusDevices | undefined;
  page: number;
  pageSize: { label: number; value: number };
  setTotal: (amount: number) => void;
  isShowingSearchResult: boolean;
  sortBy: string;
  sortOrder: string;
  setSortOrder: (str: string) => void;
  setSortBy: (str: string) => void;
};

const DevicesTable: FCWithChildren<DevicesTableProps> = ({
  deviceSearchResult,
  page,
  pageSize,
  setTotal,
  isShowingSearchResult,
  sortBy,
  sortOrder,
  setSortOrder,
  setSortBy,
}) => {
  const [list, setList] = useState<ICampusDevice[]>();
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [isStatusInfoModalOpen, setIsStatusInfoModalOpen] = useState(false);
  const [deviceStatus, setDeviceStatus] = useState('');

  const [deleteModalState, setDeleteModalState] = useState<DeleteModalStateType>({
    isOpen: false,
    deleteFn: null,
    name: '',
  });

  const [{ isModalOpen, isImportModalOpen, campusDevice }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  const prevPageIndex = usePrevious(page);
  const prevPageSize = usePrevious(pageSize);

  const { data: allDevices, refetch } = useSuspenseQuery<ICampusDevices>({
    queryKey: ['campus-devices'],
    queryFn: () => getCampusDevices({ page, pageSize: pageSize.value, sortBy, sortOrder }),
    refetchOnMount: true,
  });

  useEffect(() => {
    if (isShowingSearchResult) {
      deviceSearchResult && setTotal(deviceSearchResult.total);
      deviceSearchResult && setList(deviceSearchResult?.devices);
    } else {
      allDevices && setTotal(allDevices.total);
      allDevices && setList(allDevices?.devices);
    }
  }, [deviceSearchResult, allDevices, isShowingSearchResult]);

  useEffect(() => {
    if (!isShowingSearchResult) {
      if (prevPageSize && pageSize.value !== prevPageSize?.value) refetch();
      if (page !== prevPageIndex) refetch();
    }
  }, [pageSize, page, isShowingSearchResult]);

  useEffect(() => {
    if (!isShowingSearchResult) refetch();
  }, [sortOrder, isShowingSearchResult]);

  const { data: statusDiagnostics = [] } = useConnectionStatus();

  const openAddModal = () => {
    dispatch({ type: 'addDevice' });
  };

  const openImportModal = () => {
    dispatch({ type: 'importCampusDevices' });
  };

  const closeModal = () => dispatch({ type: 'closeModal' });
  const closeImportCsvModal = () => dispatch({ type: 'closeImportCSVModal' });

  const openEditModal = (device: ICampusDevice) =>
    dispatch({ type: 'editDevice', campusDevice: device });

  const openDeleteModal = (name: string, fn: () => void) =>
    setDeleteModalState({ isOpen: true, deleteFn: fn, name });

  const closeDeleteModal = () => setDeleteModalState({ isOpen: false, deleteFn: null, name: '' });

  const openOptionsModal = () => setIsOptionsModalOpen(true);

  const closeOptionsModal = () => setIsOptionsModalOpen(false);

  const closeStatusInfoModal = () => {
    setIsStatusInfoModalOpen(false);
    setDeviceStatus('');
  };

  const openStatusInfoModal = (status: string) => {
    setIsStatusInfoModalOpen(true);
    setDeviceStatus(status);
  };

  const diagnostic = statusDiagnostics.find(({ value }) => value === deviceStatus);

  return (
    <div className="flex flex-col min-h-screen w-full px-12 pt-16 bg-gray-100">
      <AddDeviceOptionsModal
        isOpen={isOptionsModalOpen}
        closeModal={closeOptionsModal}
        openAddModal={openAddModal}
        openImportModal={openImportModal}
      />

      <RequestManagerWrapper>
        <CampusDeviceModal closeModal={closeModal} device={campusDevice} isOpen={isModalOpen} />
      </RequestManagerWrapper>

      <RequestManagerWrapper>
        <ImportCSV closeModal={closeImportCsvModal} isOpen={isImportModalOpen} />
      </RequestManagerWrapper>

      <DeleteConfirmationModal
        isOpen={deleteModalState.isOpen}
        closeModal={closeDeleteModal}
        confirmedAction={deleteModalState.deleteFn}
        name={deleteModalState.name}
      />

      <StatusInfoModal
        closeModal={closeStatusInfoModal}
        isOpen={isStatusInfoModalOpen}
        statusDiagnostic={diagnostic}
      />
      <DashedButton title="Add a Device" handler={openOptionsModal} />

      <ExportCSV />

      <TableContainer className="pb-12">
        <Table>
          <TableHead setSortBy={setSortBy} setSortOrder={setSortOrder} sortOrder={sortOrder} />
          <TableBody>
            {list?.length
              ? list.map((device) => (
                  <CampusDevice
                    key={device.id}
                    device={device}
                    openEditModal={openEditModal}
                    openDeleteModal={openDeleteModal}
                    openStatusInfoModal={openStatusInfoModal}
                  />
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DevicesTable;
