import { FCWithChildren } from '@types';
import React, { useLayoutEffect, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSuspenseQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import className from 'classnames';
import useLayoutContext from '@hooks/useLayoutContext';
import { Notification, Button } from '@components';
import { getSubdomain, outageNotificationMessage, outageNotificationValidator } from '@utils';
import { useAppContext } from '@contexts/AppContext';

import {
  INotifications,
  INotification,
  getNotifications,
  markNotificationAsRead,
  dismissNotification,
  markNotificationAsUnread,
  IOutageNotification,
  getOutageNotifications,
} from 'sdk-apogee';
import usePageTitle from '@hooks/usePageTitle';

const SUBDOMAIN = getSubdomain();

type NotificationsProps = {
  isOverlay?: boolean;
  closeDropDown?: () => void;
};

const Notifications: FCWithChildren<NotificationsProps> = ({
  isOverlay = false,
  closeDropDown = undefined,
}) => {
  usePageTitle('Notifications');
  const history = useHistory();
  const queryClient = useQueryClient();
  const { isMobile } = useLayoutContext();
  const { user } = useAppContext();

  const { data: notificationsObject } = useSuspenseQuery<INotifications>({
    queryKey: ['allNotifications'],
    queryFn: () => getNotifications({}),
  });

  const { data: outageNotifications = [] } = useSuspenseQuery<IOutageNotification[]>({
    queryKey: ['outageNotifications'],
    queryFn: () => getOutageNotifications(SUBDOMAIN),
  });

  const validNotificationForUser = useMemo(
    () => outageNotifications.length && outageNotificationValidator(user, outageNotifications),
    [outageNotifications, user],
  );

  const outageMessage = useMemo(
    () => outageNotificationMessage(outageNotifications),
    [outageNotifications[0]],
  );

  const hasNotifications = useMemo(
    () => notificationsObject?.notifications.length || outageNotifications.length,
    [notificationsObject?.notifications, outageNotifications],
  );

  useEffect(() => {
    if (!isOverlay) window.scrollTo(0, 0);
  }, [isOverlay]);

  useLayoutEffect(() => {
    if (isMobile && isOverlay) {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = originalStyle;
      };
    }
  }, [isMobile, isOverlay]);

  const invalidateNotificationQueryCache = () => {
    queryClient.invalidateQueries({ queryKey: ['allNotifications'] });
    queryClient.invalidateQueries({ queryKey: ['unreadNotificationsCount'] });
  };

  const { mutate: markAsRead } = useMutation({
    mutationFn: (idArray: number[]) => markNotificationAsRead(idArray),
    onSuccess: () => invalidateNotificationQueryCache(),
  });

  const { mutate: markAsUnread } = useMutation({
    mutationFn: (idArray: number[]) => markNotificationAsUnread(idArray),
    onSuccess: () => invalidateNotificationQueryCache(),
  });

  const asRead = (notifId: number, makeAsUnread?: boolean) =>
    makeAsUnread ? markAsUnread([notifId]) : markAsRead([notifId]);

  const { mutate: deleteNotification } = useMutation({
    mutationFn: (id: number) => dismissNotification(id),
    onSuccess: () => {
      invalidateNotificationQueryCache();
    },
  });

  const markAllAsRead = () => {
    if (notificationsObject?.notifications.length) {
      const ids = notificationsObject.notifications.map((notification) => Number(notification.id));
      markAsRead(ids);
    }
  };

  const redirectToNotificationTypePage = (notifId: number, notifType: string) => {
    if (notifType === 'DeviceConflictNotification') {
      history.push('/manage-devices', { activeTab: 'MY_DEVICES', updated: new Date().valueOf() });
    } else if (notifType === 'NetworkInvitationNotification') {
      history.push('/manage-devices', { activeTab: 'SHARED_WITH', updated: new Date().valueOf() });
    }
    asRead(notifId);
  };

  const titleContainerCls = className(
    'text-black',
    isOverlay && closeDropDown ? 'text-center py-2 w-full' : 'my-2 md:my-6',
  );

  const titleCls = className(
    'font-semibold text-black text-base md:text-xl',
    isOverlay && isMobile ? 'pl-4' : '',
  );

  const viewAllHandler = () => history.push('/notifications');

  const sectionContainerClass = className(
    'my-2 w-full',
    isOverlay ? 'max-h-screen-75 over overflow-y-auto overflow-x-hidden' : '',
  );

  return (
    <section className={sectionContainerClass}>
      <div className={titleContainerCls}>
        <h1 className={titleCls}>Notifications</h1>
      </div>
      <div>
        {notificationsObject?.notifications.length ? (
          <div className="my-2 text-right">
            <Button layout="link" onClick={markAllAsRead} className="border-none outline-none">
              <span className="cursor-pointer text-sm text-indigo-500">Mark All As Read</span>
            </Button>
          </div>
        ) : null}
        <div className="rounded-sm">
          {hasNotifications ? (
            <>
              {!!outageNotifications.length && validNotificationForUser && (
                <div className="border border-t-0 first:border-t pr-4">
                  <Notification
                    type="SystemNotification"
                    message={outageMessage}
                    small={isOverlay}
                  />
                </div>
              )}
              {(notificationsObject?.notifications as INotification[])
                .filter((notification) => notification.type !== 'SystemNotification')
                .map((notification, index) =>
                  isOverlay && index >= 5 ? null : (
                    <div key={notification.id} className="border border-t-0 first:border-t pr-4">
                      <Notification
                        {...notification}
                        small={isOverlay}
                        deleteNotification={deleteNotification}
                        asRead={asRead}
                        redirectToNotificationTypePage={redirectToNotificationTypePage}
                      />
                    </div>
                  ),
                )}
            </>
          ) : (
            <div className="w-full h-16 flex justify-center items-center border-t">
              <span className="text-black text-base">No notifications</span>
            </div>
          )}
        </div>
        {isOverlay && !!notificationsObject?.notifications.length ? (
          <div className="flex w-full justify-center pt-4">
            <Button
              layout="link"
              block
              onClick={viewAllHandler}
              className="border-none outline-none"
            >
              <span className="text-indigo-500">View all</span>
            </Button>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default Notifications;
