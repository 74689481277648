"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var searchUsers = function (_a) {
    var searchText = _a.searchText;
    return (0, service_1.getService)()
        .get('/users', { params: { search: searchText } })
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.default = searchUsers;
