import { useSuspenseQuery } from '@tanstack/react-query';

import { IConnectionDiagnostics, getConnectionStatus } from 'sdk-apogee';

const useConnectionStatus = () =>
  useSuspenseQuery<IConnectionDiagnostics[]>({
    queryKey: ['connection-statuses'],
    queryFn: getConnectionStatus,
  });

export default useConnectionStatus;
