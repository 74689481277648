import { FCWithChildren } from '@types';
import React from 'react';

import { ISupportQuestions } from 'sdk-apogee';

type SectionsListProps = {
  list: ISupportQuestions[];
  onClickHandler: (reference: React.MutableRefObject<HTMLElement | null>) => void;
  refsContainer: {
    current: Record<string, React.MutableRefObject<HTMLElement | null>>;
  };
};

const TopicsList: FCWithChildren<SectionsListProps> = ({ list, refsContainer, onClickHandler }) => {
  return (
    <div className="w-1/3 relative">
      <div className="py-8 sticky" style={{ top: '5rem' }}>
        <span className="font-black">On this Page:</span>
        <ul className="py-8">
          {list.map(({ id, topic }) => (
            <li key={id}>
              <button
                className="my-2 underline cursor-pointer focus:shadow-focus rounded text-left"
                onClick={() => onClickHandler(refsContainer?.current[id])}
              >
                {topic}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TopicsList;
