import { FCWithChildren } from '@types';
import React from 'react';

import { FaExternalLinkAlt } from 'react-icons/fa';

import { Button } from '@components';
import className from 'classnames';

type LinkProps = {
  url: string;
  buttonClassName?: string;
};

const Link: FCWithChildren<LinkProps> = ({ url, children, buttonClassName, ...other }) => {
  const openNewTab = () => {
    window.open(url, '_blank');
  };
  const linkButtonClassName = className('justify-start', buttonClassName);

  return (
    <Button
      layout="link"
      size="noPadding"
      className={linkButtonClassName}
      onClick={openNewTab}
      {...other}
    >
      <div className="w-full flex flex-row items-center justify-between text-blue-500 text-base font-medium text-left py-1">
        {children}
        <FaExternalLinkAlt
          className="mx-2 text-sm"
          style={{
            display: 'inline-flex',
            alignSelf: 'center',
            paddingBottom: '0.18rem',
            height: '1rem',
          }}
        />
      </div>
    </Button>
  );
};

export default Link;
