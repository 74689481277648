import { FCWithChildren } from '@types';
import React, { Fragment, useState } from 'react';
import { Button, Modal, ModalBody } from '@components';
import { useHistory } from 'react-router-dom';
import usePageTitle from '@hooks/usePageTitle';

type DeviceConfirmProps = {
  isOpen: boolean;
  onClose: () => void;
};

const DeviceConfirm: FCWithChildren<DeviceConfirmProps> = () => {
  usePageTitle('Device Confirmation');
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(true);
  const onClose = () => {
    setIsOpen(false);
    return history.push('/login');
  };

  return (
    <Fragment>
      <Modal closeModal={onClose} isOpen={isOpen}>
        <ModalBody>
          <div className="mb-2">
            <h2 className="text-xl">This device is already registered</h2>
          </div>
          <p>
            Go back and click{' '}
            <a
              href=""
              target="_blank"
              rel="noreferrer noopener"
              className="underline"
              onClick={() => history.push('/forgot-password')}
            >
              Forgot Username or Password
            </a>{' '}
            link to have email sent to account.
          </p>
          <div className="flex justify-center items-center">
            <Button
              className="w-24 h-8 sm:w-auto mt-3 p-2"
              variant="primary"
              size="lg"
              onClick={onClose}
            >
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default DeviceConfirm;
