import { FCWithChildren } from '@types';
import React, { ReactElement } from 'react';

import { Button } from '@components';

type ContactButtonProps = {
  contactNumber?: string;
  title: string;
  icon: ReactElement;
  onClick?: () => void;
};

const ContactButton: FCWithChildren<ContactButtonProps> = ({
  icon,
  title,
  contactNumber = void 0,
  onClick,
}) => {
  return (
    <Button
      layout="outline"
      className="w-full my-2 px-0 h-12 md:w-1/3 md:my-0 md:px-4 md:h-auto flex bg-white"
      onClick={onClick}
    >
      <div className="w-1/5 flex justify-center text-black">{icon}</div>
      <div className="flex flex-col content-center w-3/5 text-black">
        <span className="text-xs font-light leading-none md:text-sm">{title}</span>
        {contactNumber && (
          <span className="text-xs font-light leading-none md:text-sm">{contactNumber}</span>
        )}
      </div>
    </Button>
  );
};

export default ContactButton;
