import React, { forwardRef } from 'react';

import Select from 'react-select';

import { SearchBarWMagnifier, Pagination } from '@components';

type OptionType = {
  label: number;
  value: number;
};

type FilterAndPaginationProps = {
  total: number;
  input: string;
  // eslint-disable-next-line no-unused-vars
  onChangehandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDownHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  pageSizeHandler: (option: OptionType) => void;
  pageSize: OptionType;
  setCurrentPage: (index: number) => void;
  activeButton: string;
};

const PAGE_SIZES = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 50, value: 50 },
];

const FilterAndPagination = forwardRef<HTMLDivElement, FilterAndPaginationProps>(
  function WrappedSection(props, ref) {
    const {
      input,
      pageSizeHandler,
      onChangehandler,
      onKeyDownHandler,
      pageSize,
      total,
      setCurrentPage,
      activeButton,
    } = props;

    return (
      <div className="flex items-center justify-between h-24 w-full px-12 shadow-xl ">
        <div className="flex items-center h-12 w-4/12 bg-white">
          <SearchBarWMagnifier
            input={input}
            onChangehandler={onChangehandler}
            onKeyDownHandler={onKeyDownHandler}
          />
        </div>
        <div className="flex w-6/12">
          <div className="flex items-center justify-center h-12 w-4/12">
            <span className="">
              {activeButton === 'devices' ? 'Devices: ' : 'Groups: '} {total}
            </span>
          </div>
          <div className="flex items-center h-12 w-8/12 justify-between">
            <span className="pr-2">Show: </span>
            <Select
              value={pageSize}
              options={PAGE_SIZES}
              className="w-1/3"
              onChange={(option) => {
                pageSizeHandler(option as OptionType);
              }}
            />
            <div className="flex flex-row justify-between w-2/3 px-4 text-blue-600">
              <Pagination
                totalResults={total}
                resultsPerPage={pageSize.value}
                onChange={(activePage: number) => setCurrentPage(activePage)}
                label="Page navigation"
                ref={ref}
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default FilterAndPagination;
