import React from 'react';
import classNames from 'classnames';

import useLayoutContext from '@hooks/useLayoutContext';
import { RequestManagerWrapper, Information } from '@components';
import { FCWithChildren } from '@types';

const Instructions: FCWithChildren<{}> = () => {
  const { isMobile } = useLayoutContext();

  const containerCls = classNames(isMobile ? 'w-full' : 'w-full md:w-1/2');

  return (
    <RequestManagerWrapper>
      <div className={containerCls}>
        <Information currentPage="my_devices" />
      </div>
    </RequestManagerWrapper>
  );
};

export default Instructions;
