export const DEVICE_NAME_REGEX = /^[A-Za-z0-9 ]+$/;
export const DEVICE_NAME_VALIDATION_MESSAGE = 'Only allows letters numbers and spaces (A-z, 0-9, )';
export const SAM_DEVICE_NAME_REGEX = /^[A-Za-z0-9]+$/;
export const SAM_DEVICE_NAME_VALIDATION_MESSAGE = 'Only allows letters and numbers (A-z, 0-9)';
export const SAM_REPLACE_DEVICE_NAME_REGEX = /[^A-Za-z0-9]/g;
export const DeviceStatus: Record<string, string> = {
  ACTIVE: 'active',
  DISCONNECTED: 'disconnected',
  INACTIVE: 'inactive',
  QUARANTINED: 'quarantined',
  UNKNOWN: 'unknown',
};
export const DeviceTooltipStatus: Record<string, string> = {
  active: 'Connected',
  disconnected: 'Disconnected',
  inactive: 'Status Unknown',
  quarantined: 'Quarantined',
  unknown: 'Not on Network',
};
