import { FCWithChildren } from '@types';
import React, { useReducer } from 'react';

import { BsThreeDots } from 'react-icons/bs';
import { FaPen, FaTrash } from 'react-icons/fa';

import { Dropdown, StatusInfo, StatusLed } from '@components';

import EditDeviceModal from '../../EditDeviceModal';
import RemoveDeviceModal from '../../RemoveDeviceModal';

const MobileDevice: FCWithChildren<any> = ({
  id,
  lastLogin,
  macAddress,
  name,
  status,
  openStatusInfoModal,
}) => {
  const [isOpenDropdown, toggleDropdown] = useReducer((isOpenDropdown) => !isOpenDropdown, false);

  const [isOpenEditDeviceModal, toggleEditDeviceModal] = useReducer(
    (isOpenEditDeviceModal) => !isOpenEditDeviceModal,
    false,
  );

  const [isOpenRemoveDeviceModal, toggleRemoveDeviceModal] = useReducer(
    (isOpenRemoveDeviceModal) => !isOpenRemoveDeviceModal,
    false,
  );

  return (
    <div className="flex flex-row w-full py-2 border-b boder-gray-400">
      <RemoveDeviceModal
        id={id}
        closeModal={toggleRemoveDeviceModal}
        isOpen={isOpenRemoveDeviceModal}
      />
      <EditDeviceModal
        id={id}
        name={name}
        isOpen={isOpenEditDeviceModal}
        closeModal={toggleEditDeviceModal}
      />
      <div className="flex flex-col w-3/5 font-hairline text-gray-300 pl-4">
        <span className="text-black">{name}</span>
        <span className="text-gray-700">{macAddress}</span>
      </div>

      <div className="flex w-2/5 px-2">
        <StatusLed dataTip dataFor={`device-${id}`} type={status} />
        <StatusInfo
          lastLogin={lastLogin}
          status={status}
          id={id}
          openStatusInfoModal={openStatusInfoModal}
        />
      </div>

      <div className="flex w-1/5 items-center justify-end pr-4">
        <BsThreeDots className="text-xl" onClick={toggleDropdown} />
        <Dropdown align="right" isOpen={isOpenDropdown} onClose={toggleDropdown}>
          <div className="flex flex-col justify-center space-y-6">
            <div aria-hidden="true" className="flex items-center" onClick={toggleEditDeviceModal}>
              <FaPen className="cursor-pointer text-gray-800 mx-2" />
              <span>Rename</span>
            </div>
            <div aria-hidden="true" className="flex items-center" onClick={toggleRemoveDeviceModal}>
              <FaTrash className="cursor-pointer text-gray-800 mx-2" />
              <span>Remove</span>
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default MobileDevice;
