import { useSuspenseQuery } from '@tanstack/react-query';

import { IPackage, getPackages } from 'sdk-apogee';

const usePackages = () =>
  useSuspenseQuery<IPackage[]>({
    queryKey: ['packages'],
    queryFn: getPackages,
  });

export default usePackages;
