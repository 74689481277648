const STEPS = [
  {
    title: 'Checkout',
    stepTitle: 'Checkout',
    iconTitle: 1,
  },
  {
    title: 'Review',
    stepTitle: 'Review',
    iconTitle: 2,
  },
  {
    title: 'Confirm',
    stepTitle: 'Confirm',
    iconTitle: 3,
  },
];

export default STEPS;
