import { FCWithChildren } from '@types';
import React, { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { Input } from '@components';

type MACAddressInputProps = {
  clearInput?: boolean;
  control?: any;
  register: any;
  nextFocus?: () => void;
  focusMACInput?: boolean;
  setFocusMACInput?: () => void;
  id?: string;
};

type objReferenceType = Record<string, React.RefObject<any> | null | undefined>;

const MACAddressInput: FCWithChildren<MACAddressInputProps> = ({
  clearInput,
  control,
  register,
  nextFocus,
  focusMACInput,
  setFocusMACInput,
  id,
}) => {
  let objRefs: objReferenceType = {};

  const value = useWatch(control ? { name: 'macAddress', control } : { name: 'macAddress' });

  const autocompletePlaceholder = useMemo(() => {
    if (value && /^(([A-Fa-f0-9]{2}[\W]?){5}[A-Fa-f0-9]{2}[,]?)+$/.test(value)) {
      return value;
    } else if (value && /^[A-F0-9]{3}/i.test(value)) {
      return '************';
    } else if (value && /^[A-F0-9]{2}-/i.test(value)) {
      return '**-**-**-**-**-**';
    }
    return '**:**:**:**:**:**';
  }, [value]);
  useEffect(() => {
    if (focusMACInput) {
      (objRefs['macAddress'] as any)?.focus();
      setFocusMACInput && setFocusMACInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusMACInput, setFocusMACInput]);

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key, shiftKey } = e;

    if (!shiftKey && key === 'Tab') {
      e.preventDefault();
      nextFocus && nextFocus();
      return;
    }
  };

  return (
    <div>
      <input autoComplete="false" name="hidden" type="text" className="hidden" />
      <div>
        <Input
          id={id}
          autocompletePlaceholder={autocompletePlaceholder}
          autocompleteKey=":"
          autoComplete="off"
          clearInput={clearInput}
          maxLength={17}
          onKeyDown={onKeyDownHandler}
          {...register('macAddress')}
          // ref={(e) => {
          //   registerInput && registerInput(e);
          //   objRefs = { ...objRefs, macAddress: e };
          // }}
        />
      </div>
    </div>
  );
};

export default MACAddressInput;
