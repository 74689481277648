"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var utils_1 = require("../utils");
var getSchoolByDomain = function (_a) {
    var domain = _a.domain;
    return (0, service_1.getService)()
        .get("/customers/subdomain/".concat(domain))
        .then(function (_a) {
        var data = _a.data;
        return (__assign(__assign({}, data), { largeHeroImage: (0, utils_1.getFileUrl)(data.largeHeroImage), logo: (0, utils_1.getFileUrl)(data.logo), favicon: (0, utils_1.getFileUrl)(data.favicon), smallHeroImage: (0, utils_1.getFileUrl)(data.smallHeroImage), setupInstructions: (0, utils_1.getFileUrl)(data.setupInstructions) }));
    })
        .catch(function (error) {
        throw new Error(error);
    });
};
exports.default = getSchoolByDomain;
