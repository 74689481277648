import { FCWithChildren } from '@types';
import React, { useReducer } from 'react';

import { Tooltip } from 'react-tooltip';
import { FaPen, FaTrash } from 'react-icons/fa';

import { Button, StatusInfo, StatusLed, TableCell, TableRow } from '@components';

import EditDeviceModal from '../EditDeviceModal';
import RemoveDeviceModal from '../RemoveDeviceModal';
import useLayoutContext from '@hooks/useLayoutContext';
import { MomentInput } from 'moment';

type DeviceProps = {
  id: number;
  lastLogin?: MomentInput | undefined;
  macAddress: string;
  name: string;
  deviceType: string;
  status: string;
  openStatusInfoModal: (str: string) => void;
};

const Device: FCWithChildren<DeviceProps> = ({
  id,
  lastLogin,
  macAddress,
  name,
  deviceType,
  status,
  openStatusInfoModal,
}) => {
  const [isOpenEditDeviceModal, toggleEditDeviceModal] = useReducer(
    (isOpenEditDeviceModal) => !isOpenEditDeviceModal,
    false,
  );

  const [isOpenRemoveDeviceModal, toggleRemoveDeviceModal] = useReducer(
    (isOpenRemoveDeviceModal) => !isOpenRemoveDeviceModal,
    false,
  );

  const { isSam } = useLayoutContext();

  return (
    <TableRow>
      <RemoveDeviceModal
        id={id}
        closeModal={toggleRemoveDeviceModal}
        isOpen={isOpenRemoveDeviceModal}
      />
      <EditDeviceModal
        id={id}
        name={name}
        isOpen={isOpenEditDeviceModal}
        closeModal={toggleEditDeviceModal}
      />
      <TableCell headers="device_Type">
        <div className="flex flex-col justify-center font-thin">
          <p>{deviceType}</p>
          <span className="text-sm text-gray-600">{macAddress}</span>
        </div>
      </TableCell>
      <TableCell headers="name">
        <div className="leading-5 text-blue-900 font-thin">{name}</div>
      </TableCell>
      <TableCell className="flex items-center" headers="status">
        <div className="flex flex-row h-12 w-full xl:w-2/3">
          <StatusLed dataTip dataFor={`device-${id}`} type={status} />
          <StatusInfo
            lastLogin={lastLogin}
            status={status}
            id={id}
            openStatusInfoModal={openStatusInfoModal}
          />
        </div>
      </TableCell>
      <TableCell headers="active">
        {!isSam && (
          <div className="flex justify-end space-x-6">
            <Tooltip id={`device-edit-${id}`} place="top">
              Rename
            </Tooltip>
            <Button
              layout="link"
              data-tip
              data-for={`device-edit-${id}`}
              onClick={toggleEditDeviceModal}
              aria-label="Rename"
            >
              <FaPen />
            </Button>
          </div>
        )}
      </TableCell>
      <TableCell headers="actions">
        <div className="flex justify-center space-x-6">
          <Tooltip id={`device-remove-${id}`} place="top">
            Remove
          </Tooltip>
          <Button
            layout="link"
            className="cursor-pointer text-gray-800"
            data-tip
            data-for={`device-remove-${id}`}
            onClick={toggleRemoveDeviceModal}
            aria-label="Remove"
          >
            <FaTrash />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default Device;
