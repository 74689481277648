"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var getPanRoomsByBuildingId = function (buildingId) {
    return (0, service_1.getService)()
        .get("buildings/".concat(buildingId, "/pan_rooms"))
        .then(function (_a) {
        var data = _a.data;
        return data.sort(function (_a, _b) {
            var a = _a.name;
            var b = _b.name;
            return (a.toLowerCase() < b.toLowerCase()) ? -1 : (a.toLowerCase() > b.toLowerCase()) ? 1 : 0;
        });
    })
        .catch(function (_a) {
        var response = _a.response;
        return Promise.reject(response.data);
    });
};
exports.default = getPanRoomsByBuildingId;
