import React from 'react';
import classNames from 'classnames';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  valid?: boolean;
  warning?: boolean;
}

type Type = 'base' | 'valid' | 'warning' | 'invalid';

const helperTextStyle: Record<Type, string> = {
  base: 'text-xs',
  valid: 'text-green-600',
  warning: 'text-yellow-600',
  invalid: 'text-red-600',
};

const HelperText = React.forwardRef<HTMLSpanElement, Props>(function HelperText(props, ref) {
  const { children, valid, warning, className, ...other } = props;

  const baseStyle = helperTextStyle.base;
  const warningStyle = helperTextStyle.warning;
  const validStyle = helperTextStyle.valid;
  const invalidStyle = helperTextStyle.invalid;

  const validationStyle = (valid: boolean | undefined): string => {
    switch (valid) {
      case true:
        return validStyle;
      case false:
        return invalidStyle;
      default:
        return warning ? warningStyle : '';
    }
  };

  const cls = classNames(baseStyle, validationStyle(valid), className);

  return (
    <span className={cls} ref={ref} {...other}>
      {children}
    </span>
  );
});

export default HelperText;
