import { FCWithChildren } from '@types';
import React from 'react';

import { ISupportQuestionsAndAnswers } from 'sdk-apogee';

import { Collapsible } from '@components';
import { getParsedHtml } from '@utils';
import ReactHtmlParser from 'react-html-parser';
import { renderToStaticMarkup } from 'react-dom/server';

type QuestionsListProps = {
  questionsAndAnswers: ISupportQuestionsAndAnswers[];
  searchTerm?: string | undefined;
};

const QuestionsList: FCWithChildren<QuestionsListProps> = ({ questionsAndAnswers, searchTerm }) => {
  const getHighlightedText = (text: string) => {
    if (searchTerm) {
      const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const parts = text.split(new RegExp(`(${escapedSearchTerm})`, 'gi'));
      const matchCount = parts.length;
      const highlightedContent = parts.map((part) =>
        part.toLowerCase() === searchTerm.toLowerCase()
          ? renderToStaticMarkup(<span className="bg-yellow-200">{part}</span>)
          : part,
      );
      return { highlightedContent: highlightedContent.join(''), matchCount: matchCount };
    } else {
      return { highlightedContent: text, matchCount: 2 };
    }
  };

  const searchAndHighLightContent = (id: string, question: string, answerHtml: string) => {
    const { highlightedContent: highlightedQuestion, matchCount: questionMatchCount } =
      getHighlightedText(question);
    const { highlightedContent: highLightedAnswer, matchCount: answerMatchCount } =
      getHighlightedText(answerHtml);

    return questionMatchCount > 1 || answerMatchCount > 1 ? (
      <Collapsible key={id} title={ReactHtmlParser(highlightedQuestion)} wide>
        <ul className="list-disc py-2">
          <div>{getParsedHtml(highLightedAnswer)}</div>
        </ul>
      </Collapsible>
    ) : null;
  };

  return (
    <>
      {questionsAndAnswers.map(({ id, question, answerHtml }) => {
        return searchAndHighLightContent(id, question, answerHtml || '');
      })}
    </>
  );
};

export default QuestionsList;
