import React, { forwardRef } from 'react';

interface Props extends React.HTMLAttributes<HTMLTableRowElement> {}

const TableRow = forwardRef<HTMLTableRowElement, Props>(function TableRow(props, ref) {
  const { className, children, ...other } = props;

  return (
    <tr className={className} ref={ref} {...other}>
      {children}
    </tr>
  );
});

export default TableRow;
