import { FCWithChildren } from '@types';
import React from 'react';

import { MdAddCircleOutline } from 'react-icons/md';
import { Button } from '@components';

type DashedButtonProps = {
  title: string;
  noIcon?: boolean;
  handler: () => void;
};

const DashedButton: FCWithChildren<DashedButtonProps> = ({ title, noIcon = false, handler }) => {
  return (
    <div className="flex justify-center items-center w-full border-2 border-dashed border-gray-400 sm:mb-12">
      <Button layout="link" block onClick={handler} className="flex items-center justify-center">
        {!noIcon && <MdAddCircleOutline className="text-blue-700" />}
        <span className="text-blue-700 ml-2">{title}</span>
      </Button>
    </div>
  );
};

export default DashedButton;
