import { REACT_APP_EPROTECT_API_JS } from 'sdk-apogee';

const appendEProtectScript = () => {
  if (!window.eProtect) {
    const script = document.createElement('script');
    script.src = REACT_APP_EPROTECT_API_JS;
    document.body.appendChild(script);
  }
};

const callEProtectApi = (
  data: { [x: string]: any },
  order: any,
  orderTransactionId: string,
  successCallback: {
    (response: any, paypageRegistrationId: any): void;
    (response: any, data: any): void;
    (arg0: any, arg1: any): void;
  },
  successCallbackArgument: any,
  errorCallback: { (response: { response: any }): void; (response: { response: any }): void },
) => {
  const formFields = {
    accountNum: { value: data['card_number'] },
    cvv: { value: data['card_cvc'] },
    cvv2: { value: data['card_cvc'] },
  };
  const eProtectRequest = {
    paypageId: order.paypageNum,
    reportGroup: order.reportGroup,
    id: orderTransactionId,
    orderId: order.orderNum,
  };
  new window.eProtect().sendToEprotect(
    eProtectRequest,
    formFields,
    (response: any) => {
      successCallback(response, successCallbackArgument);
    },
    errorCallback,
    errorCallback,
    15000,
  );
};

export { appendEProtectScript, callEProtectApi };
