import { FCWithChildren } from '@types';
import React from 'react';

type SectionObject = {
  title: string;
  ref: React.MutableRefObject<HTMLElement | null>;
};

type MobileAccountSectionsListProps = {
  accountSections: {
    logInInfo: SectionObject;
    personalInfo: SectionObject;
    servicesPackages: SectionObject;
    preferences: SectionObject;
  };
  // eslint-disable-next-line no-unused-vars
  onClickHandler: (reference: React.MutableRefObject<HTMLElement | null>) => void;
};

const MobileAccountSectionsList: FCWithChildren<MobileAccountSectionsListProps> = ({
  accountSections,
  onClickHandler,
}) => {
  return (
    <div className="divide-x-2 divide-gray-300 whitespace-no-wrap border-b border-gray-300 bg-gray-100 -mx-4 w-screen h-8 overflow-x-scroll scrolling-touch">
      {Object.entries(accountSections).map(([key, val]) => (
        <button
          key={key + val.title}
          className="underline cursor-pointer focus:outline-none text-xs text-thin place-self-center px-4 mt-1 h-6"
          onClick={() => onClickHandler(val.ref)}
          onKeyPress={() => onClickHandler(val.ref)}
        >
          {val.title}
        </button>
      ))}
    </div>
  );
};

export default MobileAccountSectionsList;
