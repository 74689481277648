import React from 'react';

import { useAppContext } from '@contexts/AppContext';
import { NotFound } from '@components';

import { RequestManagerWrapper } from '@components';
import ManagedCampus from './ManagedCampus';

const Index = () => {
  const { accountType } = useAppContext().user;

  return (
    <div>
      {accountType === 'admin' ? (
        <RequestManagerWrapper>
          <ManagedCampus />
        </RequestManagerWrapper>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default Index;
