import { FCWithChildren } from '@types';
import React, { Fragment, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

import { NotificationsMenu, RequestManagerWrapper } from '@components';
import { useAppContext } from '@contexts/AppContext';
import useLayoutContext from '@hooks/useLayoutContext';
import useLogout from '@hooks/useLogout';
import { Button } from '@components';
import { textCssFromBgContrast } from '@utils';
import classNames from 'classnames';

type MobileNavigationProps = {
  publicRoute: boolean;
};

type OverlayMenuProps = {
  logout: () => void;
  publicRoute: boolean;
  user: any;
};

const OverlayMenu: FCWithChildren<OverlayMenuProps> = ({ logout, publicRoute, user }) => {
  const history = useHistory();
  const { authType, logInRedirect } = useLayoutContext();

  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  const redirectToLogin = () => {
    if (authType !== 'sam') {
      const url = logInRedirect && logInRedirect();

      return window.location.assign(url as string);
    } else {
      return history.push('/login');
    }
  };

  const redirectToSupport = () => history.push('/support');

  const redirectToRegister = () => history.push('/register');

  const redirectToFAQ = () => history.push('/faq');

  const redirectToAccount = () => history.push('/account');

  const redirectToManagedCampus = () => history.push('/managed-campus');

  const redirectToManageDevices = () => history.push('/manage-devices');

  const linkBtnCls = 'mx-2 py-2';

  return (
    <div
      className="fixed w-full h-full inset-0 flex flex-col flex justify-start items-center z-40 p-4 bg-primary-background appear-done enter-done"
      style={{ top: '5rem' }}
      role="navigation"
    >
      {publicRoute ? null : (
        <Fragment>
          {user?.accountType === 'admin' ? (
            <Button
              className={linkBtnCls}
              layout="link"
              size="noPadding"
              onClick={redirectToManagedCampus}
              aria-label="Manage devices"
              isBgPrimary
            >
              <span className="font-black text-lg"> Manage Devices</span>
            </Button>
          ) : null}
          <Button
            className={linkBtnCls}
            layout="link"
            size="noPadding"
            onClick={redirectToManageDevices}
            aria-label="My devices"
            isBgPrimary
          >
            <span className="font-black text-lg"> My Devices</span>
          </Button>
        </Fragment>
      )}

      {publicRoute ? (
        <Fragment>
          <Button
            className={linkBtnCls}
            layout="link"
            size="noPadding"
            onClick={redirectToSupport}
            aria-label="Support"
            isBgPrimary
          >
            <span className="font-black text-lg"> Support</span>
          </Button>

          <Button
            className={linkBtnCls}
            layout="link"
            size="noPadding"
            onClick={() => redirectToLogin()}
            aria-label="Sign in"
            isBgPrimary
          >
            <span className="font-black text-lg">Sign In</span>
          </Button>
          {authType !== 'sso' && (
            <Button
              className={linkBtnCls}
              layout="link"
              size="noPadding"
              onClick={redirectToRegister}
              aria-label="Sign up"
              isBgPrimary
            >
              <span className="font-black text-lg"> Sign Up</span>
            </Button>
          )}
        </Fragment>
      ) : (
        <Button
          className={linkBtnCls}
          layout="link"
          size="noPadding"
          onClick={redirectToFAQ}
          aria-label="Support"
          isBgPrimary
        >
          <span className="font-black text-lg"> Support</span>
        </Button>
      )}

      {publicRoute ? null : (
        <Fragment>
          <Button
            className={linkBtnCls}
            layout="link"
            size="noPadding"
            onClick={redirectToAccount}
            aria-label="Account"
            isBgPrimary
          >
            <span className="font-black text-lg"> Account</span>
          </Button>

          <Button
            className="my-2"
            layout="link"
            size="noPadding"
            onClick={logout}
            aria-label="Account"
            isBgPrimary
          >
            <span className="font-black text-lg">Sign Out</span>
          </Button>
        </Fragment>
      )}
    </div>
  );
};

const MobileNavigation: FCWithChildren<MobileNavigationProps> = ({ publicRoute }) => {
  const { user } = useAppContext();
  const { logout } = useLogout();
  const { pathname } = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { setIsAuthenticated } = useAppContext();

  const [bg, setBg] = useState('');

  useEffect(() => {
    const newBg = getComputedStyle(document.documentElement).getPropertyValue(
      '--background-primary',
    );
    if (newBg !== bg) {
      setBg(newBg);
    }
  });

  const textCss = useMemo(
    () =>
      !bg
        ? ''
        : textCssFromBgContrast({
            bg,
            fontSize: '16px',
            fontWeight: '900',
          }),
    [bg],
  );

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const signOut = () => {
    setIsAuthenticated(false);
    logout();
  };

  return (
    <div className={classNames('w-full flex justify-end items-center', textCss)}>
      {publicRoute ? null : (
        <RequestManagerWrapper>
          <div className="flex flex-col items-center">
            <NotificationsMenu />
          </div>
        </RequestManagerWrapper>
      )}
      {pathname !== '/select' ? (
        isMenuOpen ? (
          <AiOutlineClose className="text-3xl" onClick={toggleMenu} />
        ) : (
          <AiOutlineMenu className="text-3xl" onClick={toggleMenu} />
        )
      ) : null}
      {isMenuOpen ? <OverlayMenu logout={signOut} publicRoute={publicRoute} user={user} /> : null}
    </div>
  );
};

export default MobileNavigation;
