import { FCWithChildren } from '@types';
import React, { useReducer, useEffect } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { INetworkInfo, getCurrentNetwork, IReceivedInvite } from 'sdk-apogee';

import { NetworkMembersTable } from '@components';

import CreateNetwork from './CreateNetwork';
import Invites from './Invites';
import PANModal from './PANModal';

export const CREATE_NETWORK = 'CREATE_NETWORK';
export const EXPIRE_NETWORK = 'EXPIRE_NETWORK';
export const SEND_INVITE = 'SEND_INVITE';
export const REMOVE_SENT_INVITATION = 'REMOVE_SENT_INVITATION';

export const LEAVE_NETWORK = 'LEAVE_NETWORK';
export const JOIN_NETWORK = 'JOIN_NETWORK';
export const REMOVE_RECEIVED_INVITATION = 'REMOVE_RECEIVED_INVITATION';

export const SET_MY_NETWORK_STATUS = 'SET_MY_NETWORK_STATUS';
export const CLOSE_PAN_MODAL = 'CLOSE_PAN_MODAL';

type SharedListProps = {};

export type StateType = {
  actionToPerform: string;
  isModalOpen: boolean;
  myNetworkStatus: INetworkInfo | null | undefined;
  invitation: IReceivedInvite | null | undefined;
  sentInvitationId: number | null | undefined;
};
interface ActionType {
  type: string;
  payload?: {
    myNetworkStatus?: INetworkInfo | null | undefined;
    invitation?: IReceivedInvite | null | undefined;
    sentInvitationId?: number | null | undefined;
  };
}
interface EditActionInterface extends ActionType {
  network?: string;
}

interface AddActionType extends ActionType {
  network?: string;
}

export type ActionTypes = EditActionInterface | AddActionType;

const initialState = {
  isModalOpen: false,
  myNetworkStatus: null,
  actionToPerform: '',
  invitation: null,
  sentInvitationId: null,
};

const reducer = (state: StateType, action: ActionTypes): StateType => {
  switch (action.type) {
    case CREATE_NETWORK:
      return {
        ...state,
        isModalOpen: true,
        actionToPerform: CREATE_NETWORK,
      };

    case EXPIRE_NETWORK:
      return {
        ...state,
        isModalOpen: true,
        actionToPerform: EXPIRE_NETWORK,
      };

    case SEND_INVITE:
      return {
        ...state,
        actionToPerform: SEND_INVITE,
        isModalOpen: true,
      };

    case JOIN_NETWORK:
      return {
        ...state,
        actionToPerform: JOIN_NETWORK,
        invitation: action.payload?.invitation,
        isModalOpen: true,
      };

    case LEAVE_NETWORK:
      return {
        ...state,
        isModalOpen: true,
        actionToPerform: LEAVE_NETWORK,
      };

    case CLOSE_PAN_MODAL:
      return {
        ...state,
        isModalOpen: false,
        invitation: null,
        actionToPerform: '',
        sentInvitationId: null,
      };

    case SET_MY_NETWORK_STATUS:
      return {
        ...state,
        myNetworkStatus: action.payload?.myNetworkStatus,
      };

    case REMOVE_RECEIVED_INVITATION:
      return {
        ...state,
        isModalOpen: true,
        actionToPerform: REMOVE_RECEIVED_INVITATION,
        invitation: action.payload?.invitation,
      };

    case REMOVE_SENT_INVITATION:
      return {
        ...state,
        isModalOpen: true,
        actionToPerform: REMOVE_SENT_INVITATION,
        sentInvitationId: action.payload?.sentInvitationId,
      };

    default:
      return state;
  }
};

const SharedList: FCWithChildren<SharedListProps> = () => {
  const [modalState, dispatch] = useReducer(reducer, initialState);

  const { data: myNetworkInfo } = useSuspenseQuery<INetworkInfo | null>({
    queryKey: ['my-network'],
    queryFn: getCurrentNetwork,
  });

  useEffect(() => {
    dispatch({ type: SET_MY_NETWORK_STATUS, payload: { myNetworkStatus: myNetworkInfo } });
  }, [myNetworkInfo]);

  const { isModalOpen, myNetworkStatus } = modalState;

  const openCreateNetworkModal = () => dispatch({ type: CREATE_NETWORK });

  return (
    <>
      <PANModal isOpen={isModalOpen} modalState={modalState} dispatch={dispatch} />

      {myNetworkStatus ? (
        <NetworkMembersTable
          myNetworkStatus={myNetworkStatus as INetworkInfo}
          dispatch={dispatch}
        />
      ) : (
        <CreateNetwork openModal={openCreateNetworkModal} />
      )}

      <Invites myNetworkStatus={myNetworkStatus} dispatch={dispatch} />
    </>
  );
};

export default SharedList;
