import React from 'react';
import classNames from 'classnames';

import useLayoutContext from '@hooks/useLayoutContext';

import { SeparatorLine } from '@components';
import { FCWithChildren } from '@types';

const MyDevices: FCWithChildren<{}> = ({ children }) => {
  const { isMobile } = useLayoutContext();

  const childrenCls = classNames(
    'flex flex-col py-4 ',
    isMobile ? 'mt-2 w-full' : 'md:flex-row w-full md:divide-x',
  );

  return (
    <section className="flex flex-col pt-8">
      <h1 className="text-xl md:text-4xl pb-8">My Devices</h1>
      <SeparatorLine />
      <div className={childrenCls}>{children}</div>
    </section>
  );
};

export default MyDevices;
