import moment from 'moment-timezone';

export const LocalDayDateTimeFormat = {
  DEFAULT: 'dddd MMM D, YYYY h:mm A z',
  NO_TIMEZONE: 'dddd MMM D, YYYY h:mm A',
  TIMEZONE_ONLY: 'z',
};

const localDayDateTime = (date?: string, format = LocalDayDateTimeFormat.DEFAULT) =>
  !moment(date).isValid()
    ? ''
    : moment.utc(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(format);

export default localDayDateTime;
