import React from 'react';

import School from './School';
import { FCWithChildren } from '@types';

const MOCKED_SCHOOL_LIST = new Array(10).fill({
  id: 1,
  logo: 'url',
  name: 'University of Greatness',
  status: 'Public',
  url: 'uofg.apogee.us',
});

const SchoolList: FCWithChildren<{}> = () => (
  <section>
    <section className="flex flex-col justify-center items-center p-4">
      {MOCKED_SCHOOL_LIST.map((school) => (
        <School key={school.id} {...school} />
      ))}
    </section>
  </section>
);

export default SchoolList;
