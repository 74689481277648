import React from 'react';
import { Link } from 'react-router-dom';

import { SeparatorLine } from '@components';
import useSelectedPackages from '@hooks/useSelectedPackages';
import usePackages from '@hooks/usePackages';

type ServicePackagesProps = {
  isMobile: boolean;
};

const ServicePackages = React.forwardRef<HTMLElement, ServicePackagesProps>(function WrappedSection(
  { isMobile },
  ref,
) {
  const { data = [] } = useSelectedPackages();
  const { data: packages = [] } = usePackages();

  return (
    <>
      <section className="py-4 md:py-8 flex flex-col" ref={ref}>
        <h2 className="text-sm md:text-xl font-bold pb-4">Service Packages</h2>
        <span className="text-sm md:text-base pb text-gray-600 pb-2 font-thin">
          {data.length > 0
            ? data.map(({ planName }) => planName).join(' | ')
            : 'No package selected'}
        </span>
        {packages.length > 1 && (
          <Link className="text-blue-600 font-thin text-sm md:text-base" to="/services">
            {data.length > 0 ? 'View Upgrades' : 'View Plans'}
          </Link>
        )}
      </section>

      {isMobile && <SeparatorLine />}
    </>
  );
});

export default ServicePackages;
