import { FCWithChildren } from '@types';
import React from 'react';

import classNames from 'classnames';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';

import { Button } from '@components';

type NotificationType = 'error' | 'success' | 'warning';

const ICONS: Record<NotificationType, React.ReactNode> = {
  error: <FaExclamationTriangle className="text-xl text-red-600 mr-4" />,
  success: <FaCheckCircle className="text-xl text-green-700 mr-4" />,
  warning: <FaExclamationTriangle className="text-xl text-yellow-600 mr-4" />,
};

const Toast: FCWithChildren<any> = ({ appearance, children, ...props }) => {
  const baseStyle = 'font-sans bg-white border-2 border-l-8 m-2 px-2 py-4 rounded shadow-md';

  const appearanceStyle: Record<NotificationType, string> = {
    success: 'border-green-700',
    warning: 'border-yellow-600',
    error: 'border-red-600',
  };

  const clsx = classNames(baseStyle, appearanceStyle[appearance as NotificationType]);

  return (
    <div className={clsx} {...props} style={{ width: '18rem' }} role="alert">
      <div className="flex items-center">
        {ICONS[appearance as NotificationType]}
        {children}
      </div>
      {appearance === 'warning' ? (
        <div className="flex justify-end mt-2">
          <Button className="uppercase" layout="link" size="small">
            Dismiss
          </Button>
          <Button className="uppercase" layout="link" size="small">
            Accept
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default Toast;
