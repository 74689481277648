// get the default eProtect error
const getDefaultEProtectError = (phoneNumber: string | null) => {
  return {
    key: 'card_number',
    message: `We are experiencing technical difficulties Please try again later or call ${phoneNumber}`,
  };
};

// define the eprotect errors
const eProtectErrors: any = {
  871: { key: 'card_number', message: 'Invalid Card Number' },
  872: { key: 'card_number', message: 'Invalid Card Number. Card Number too short' },
  873: { key: 'card_number', message: 'Invalid Card Number. Card Number too long' },
  874: { key: 'card_number', message: 'Invalid Card Number. Not a number' },
  876: {
    key: 'card_number',
    message: 'Invalid Card Number. An error occurred while validating your card. Please try again.',
  },
  881: { key: 'card_cvc', message: 'Invalid Card Validation Number. Not a number' },
  882: { key: 'card_cvc', message: 'Invalid Card Validation Number. Validation Number too short' },
  883: { key: 'card_cvc', message: 'Invalid Card Validation Number. Validation Number too long' },
  886: { key: 'card_expiration', message: 'Expiration date is invalid' },
};

const getEProtectError = (errorCode: number | null, phoneNumber: string | null = null) => {
  return {
    errorCode: errorCode || '',
    ...(errorCode && errorCode in eProtectErrors
      ? eProtectErrors[errorCode]
      : getDefaultEProtectError(phoneNumber)),
  };
};

export default getEProtectError;
