import React from 'react';

import { useFormContext } from 'react-hook-form';

import { Input, SeparatorLine } from '@components';

type PreferencesProps = {
  isMobile: boolean;
};

const Preferences = React.forwardRef<HTMLElement, PreferencesProps>(function WrappedSection(
  { isMobile },
  ref,
) {
  const methods = useFormContext();

  return (
    <>
      <section className="py-8 flex flex-col" ref={ref}>
        <h2 className="text-sm md:text-xl pb-4 font-bold">Preferences</h2>

        <div className="flex flex-row w-full md:w-3/5 justify-between items-center">
          <div className="flex flex-col">
            <span className="text-sm md:text-base pb text-black">
              Receive Notifications Via Email
            </span>
          </div>
          <Input
            size="large"
            type="checkbox"
            {...methods.register('emailNotification')}
            aria-label="Email notification"
          />
        </div>
        <span className="text-sm text-gray-600 font-thin">
          Get notifications you may have missed to your email
        </span>
      </section>

      <SeparatorLine />
    </>
  );
});

export default Preferences;
