import { FCWithChildren } from '@types';
import React from 'react';
import { Button, Modal, ModalBody } from '@components';
import useLayoutContext from '@hooks/useLayoutContext';
import PskBody from './PskBody';
import OtherBody from './OtherBody';

type ModalWifiEnabledProps = {
  isOpen: boolean;
  onClose: () => void;
  preWiredKey?: string;
};

const ModalWifiEnabled: FCWithChildren<ModalWifiEnabledProps> = ({
  isOpen,
  onClose,
  preWiredKey,
}) => {
  const { pskEnabled } = useLayoutContext();
  return (
    <Modal
      customWebClass="xl:w-1/3 l:w-2/3 m:w-90"
      maxWidthClass="max-w-90"
      closeModal={onClose}
      isOpen={isOpen}
    >
      <ModalBody>
        <div className="mb-2">
          <h3 className="text-2xl">GET CONNECTED!</h3>
        </div>
        {pskEnabled ? (
          <PskBody preWiredKey={preWiredKey} />
        ) : (
          <OtherBody preWiredKey={preWiredKey} />
        )}
        <div className="flex justify-center mt-6">
          <Button layout="secondary" className="w-32" onClick={onClose}>
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalWifiEnabled;
