"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var service_1 = require("../service");
var removeShared = function (id) {
    return (0, service_1.getService)()
        .delete("/shares/".concat(id))
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.default = removeShared;
