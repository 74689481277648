import { IOutageNotification } from 'sdk-apogee';
import { localDayDateTime, LocalDayDateTimeFormat } from './index';

const outageNotificationMessage = (outageNotifications: IOutageNotification[]): string => {
  if (!outageNotifications.length) return '';
  const { startDate, endDate, notificationType } = outageNotifications[0];
  switch (notificationType) {
    case 'planned':
      const plannedMessage =
        'There is a maintenance planned for this site. Users can expect to experience service interruption during the maintenance period';
      const plannedEndMessage = !startDate
        ? !endDate
          ? ''
          : ` until ${localDayDateTime(endDate)}`
        : !endDate
        ? ` starting at ${localDayDateTime(startDate)}`
        : ` from ${localDayDateTime(
            startDate,
            LocalDayDateTimeFormat.NO_TIMEZONE,
          )} to ${localDayDateTime(
            endDate,
            LocalDayDateTimeFormat.NO_TIMEZONE,
          )} (${localDayDateTime(endDate, LocalDayDateTimeFormat.TIMEZONE_ONLY)})`;
      return `${plannedMessage}${plannedEndMessage}.`;
    case 'unplanned':
    default:
      const cannedMessage =
        'There is a known outage at your school. Users can expect to experience service interruption. Thank you for your patience while we try to resolve this issue.';
      const endMessage = !endDate
        ? ''
        : `The outage is expected to last until ${localDayDateTime(endDate)}.`;
      return `${cannedMessage} ${endMessage}`;
  }
};

export default outageNotificationMessage;
