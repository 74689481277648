import { FCWithChildren } from '@types';
import React, { ReactComponentElement } from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { Button } from '@components';
import { useAppContext } from '@contexts/AppContext';
import { useHistory } from 'react-router-dom';

type CheckOutStep2Props = {
  getCardIcon: (value: string) => ReactComponentElement<any>;
  getSelectedPackages: () => any;
  getValues: (fieldName: any) => any;
  previousStep: () => void;
  loading: boolean;
  formatCardNumberOrCode: (value: string) => any;
};

const CheckOutStep2: FCWithChildren<CheckOutStep2Props> = ({
  getCardIcon,
  getSelectedPackages,
  getValues,
  loading,
  previousStep,
  formatCardNumberOrCode,
}) => {
  const { order }: any = useAppContext();
  const history = useHistory();
  const getOrderTotalByKey = (key: string) => Number(order[key]).toFixed(2) || 0;
  const cardNumber = formatCardNumberOrCode(getValues('card_number'));

  const getOrderValue = (key: string, label: string) => {
    return (
      <div className="px-8  flex w-full space-x-12 pb-2">
        <div className="w-2/3">
          <div>{label}</div>
        </div>
        <div className="w-1/3">${getOrderTotalByKey(key)}</div>
      </div>
    );
  };

  return (
    <div className="w-full mt-10">
      <span className="text-3xl  pb-8">Checkout</span>
      <div className="flex flex-col md:flex-row">
        {getOrderTotalByKey('total') !== 0 ? (
          <div className="w-full md:w-6/12">
            <span className="text-lg font-bold pb-2  mt-12 block">Payment Information</span>
            <div className="w-full">
              <div className="my-4 mr-10 w-11/12 relative">
                {getOrderTotalByKey('total') !== 0 ? (
                  <>
                    <span className="inline-block text-3xl align-bottom">
                      {getCardIcon(cardNumber)}
                    </span>
                    <span className="inline-block ml-2">
                      {cardNumber ? `************${cardNumber.slice(cardNumber.length - 4)}` : ''}
                    </span>
                  </>
                ) : (
                  <span className="inline-block ml-2">
                    All refunds for package downgrades, upgrades and cancellations will be sent back
                    to the method of your original payment
                  </span>
                )}
              </div>
            </div>
          </div>
        ) : null}
        <div className="w-full md:w-1/3">
          <span className="text-lg font-bold pb-2  mt-12 block mb-3 w-full">Your Order</span>
          <div className="py-4 flex border rounded-lg shadow-lg">
            <div className="flex-row w-full">
              {getSelectedPackages()}
              {getOrderValue('subTotal', 'Sub Total')}
              {getOrderValue('tax', 'Tax')}
              {getOrderValue('total', 'Total')}
              <Button
                className="block text-xs flex w-full justify-center items-center py-2 text-gray-700"
                layout="link"
                size="noPadding"
                onClick={() => {
                  getOrderTotalByKey('total') === 0 ? history.push('/services') : previousStep();
                }}
              >
                <span className="text-sm">
                  <FaAngleLeft className="inline text-lg" />
                  {getOrderTotalByKey('total') === 0 ? 'Modify your order' : 'Back to Checkout'}
                </span>
              </Button>
              <div className="px-8  flex w-full space-x-12 pb-2">
                <Button block type="submit" loading={loading}>
                  Purchase
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOutStep2;
