import React, { Fragment, useState } from 'react';

import useLayoutContext from '@hooks/useLayoutContext';

import { useFormContext } from 'react-hook-form';

import { Input, Label, SeparatorLine, Button, HelperText } from '@components';

type LoginInformationProps = {
  isMobile?: boolean;
  isChangingPassword: boolean;
  setIsChangingPassword: React.Dispatch<React.SetStateAction<boolean>>;
};

const LoginInformation = React.forwardRef<HTMLElement, LoginInformationProps>(
  function WrappedSection({ isMobile = false, isChangingPassword, setIsChangingPassword }, ref) {
    const {
      register,
      formState: { errors },
    } = useFormContext();
    const { usernameLabel, isSso } = useLayoutContext();
    const isValid = (input: string): boolean => !(errors as any)[input]?.message;

    const toggle = () => setIsChangingPassword((prev) => !prev);

    return (
      <section className="py-4 md:py-8 flex flex-col w-full md:w-3/5" ref={ref}>
        <h2 className="text-sm md:text-xl font-bold pb2 md:pb-4">Log In Information</h2>
        <Label className="my-2 font-thin text-sm">
          <span>{usernameLabel} (Read only)</span>
          <Input
            className="my-1"
            type="text"
            {...register('username')}
            {...(isValid('username') ? {} : { valid: false })}
            readOnly
          />
        </Label>

        {!isSso && (
          <Fragment>
            <Label className="my-2 flex flex-col text-sm">
              <div className="flex justify-start">
                <Button
                  className="text-blue-600 font-thin"
                  layout="link"
                  size="noPadding"
                  onClick={toggle}
                >
                  {isChangingPassword ? "Don't Change Password" : 'Change Password'}
                </Button>
              </div>
            </Label>

            {isChangingPassword && (
              <>
                <Label className="my-2 font-thin text-sm">
                  <span>Old Password</span>
                  <Input
                    className="my-1"
                    {...register('oldPassword')}
                    placeholder="Password"
                    type="password"
                  />
                  <HelperText valid={isValid('passwordConfirmation')}>
                    {/* @ts-ignore */}
                    {errors.oldPassword?.message}
                  </HelperText>
                </Label>

                <Label className="my-2 font-thin text-sm">
                  <span>New Password</span>
                  <Input
                    className="my-1"
                    {...register('password')}
                    placeholder="New Password"
                    type="password"
                  />
                  <HelperText valid={isValid('passwordConfirmation')}>
                    {/* @ts-ignore */}
                    {errors.password?.message}
                  </HelperText>
                </Label>

                <Label className="my-2 pb-2 font-thin text-sm">
                  <span>Confirm New Password</span>
                  <Input
                    className="my-1"
                    {...register('passwordConfirmation')}
                    placeholder="New Password Confirmation"
                    type="password"
                  />
                  <HelperText valid={isValid('passwordConfirmation')}>
                    {/* @ts-ignore */}
                    {errors?.passwordConfirmation?.message}
                  </HelperText>
                </Label>
              </>
            )}
          </Fragment>
        )}

        {isMobile && <SeparatorLine />}
      </section>
    );
  },
);

export default LoginInformation;
