import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import Loading from '../Loading';
import textCssFromBgContrast from '../../utils/textCssFromBgContrast';

const buttonStyles: any = {
  base: 'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-light focus:shadow-focus border-2 border-transparent',
  block: 'w-full',
  size: {
    larger: 'px-10 py-4 rounded-lg text-xl',
    large: 'px-5 py-3 rounded-lg text-lg',
    regular: 'px-4 py-2 rounded-lg text-md',
    small: 'px-3 py-1 rounded-md text-sm',
    icon: {
      larger: 'p-4 rounded-lg',
      large: 'p-3 rounded-lg',
      regular: 'p-2 rounded-lg',
      small: 'p-2 rounded-md',
    },
    pagination: 'px-3 py-1 rounded-md text-xs',
    noPadding: 'px-0 py-0',
  },
  icon: {
    larger: 'h-5 w-5',
    large: 'h-5 w-5',
    regular: 'h-5 w-5',
    small: 'h-3 w-3',
    left: 'mr-2 -ml-1',
    right: 'ml-2 -mr-1',
  },
  primary: {
    base: 'bg-primary-background border border-transparent font-black',
    active: 'hover:bg-primary-lighten-background',
    disabled: 'opacity-50 cursor-not-allowed',
  },
  secondary: {
    base: 'text-gray-900 bg-secondary-background border border-transparent',
    active: 'hover:bg-secondary-lighten-background',
    disabled: 'opacity-50 cursor-not-allowed',
  },
  outline: {
    base: 'text-lg text-gray-600 border border-gray-400',
    active: 'active:bg-sec-background hover:border-black active:text-gray-500',
    disabled: 'opacity-50 cursor-not-allowed bg-gray-300',
  },
  link: {
    base: 'text-blue-700 border-2 border-transparent rounded -mx-1 px-1',
    basePrimary: 'border-2 border-transparent rounded -mx-1 px-1',
    active: 'focus:border-blue-700 active:bg-transparent',
    activePrimary: 'focus:border-blue-700 active:bg-transparent',
    disabled: 'opacity-50 cursor-not-allowed',
  },
  dropdownItem: {
    base: 'inline-flex items-center cursor-pointer w-full px-2 py-1 text-sm font-medium transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800',
  },
};

const Button = React.forwardRef<any, any>(function Button(props, ref) {
  const localRef = useRef<HTMLDivElement | null>(null);
  const {
    tag = 'button',
    icon,
    iconLeft,
    iconRight,
    disabled = false,
    loading = false,
    size = 'regular',
    layout = 'primary',
    isBgPrimary = layout === 'primary',
    block = false,
    className,
    onClick,
    children,
    ...other
  } = props;

  const [bg, setBg] = useState('');
  const [fontSize, setFontSize] = useState('');
  const [fontWeight, setFontWeight] = useState('');
  const shouldSetTextColor = useMemo(
    () => !!(bg && fontSize && fontWeight && isBgPrimary),
    [bg, fontSize, fontWeight, isBgPrimary],
  );
  const contrastCorrectionTextClass = useMemo(
    () => shouldSetTextColor && textCssFromBgContrast({ bg, fontSize, fontWeight }),
    [bg, fontSize, fontWeight, shouldSetTextColor],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isBgPrimary) {
      const newBg = getComputedStyle(document.documentElement).getPropertyValue(
        '--background-primary',
      );
      if (newBg !== bg) {
        setBg(newBg);
      }
    }
  });

  useEffect(() => {
    const el = localRef.current;

    if (el && isBgPrimary) {
      const compStyle = getComputedStyle(el);
      const newFontSize = compStyle.getPropertyValue('font-size');
      const newFontWeight = compStyle.getPropertyValue('font-weight');

      if (fontSize !== newFontSize) {
        setFontSize(newFontSize);
      }
      if (fontWeight !== newFontWeight) {
        setFontWeight(newFontWeight);
      }
    }
  }, [fontSize, fontWeight, isBgPrimary, localRef]);

  if ((tag === 'button' || tag === 'input') && !other.type) {
    other.type = 'button';
  }

  function hasIcon() {
    return !!icon || !!iconLeft || !!iconRight;
  }

  const Component = tag;
  const IconLeft = iconLeft || icon;
  const IconRight = iconRight;

  const baseStyle = buttonStyles.base;
  const blockStyle = buttonStyles.block;
  const sizeStyles: any = {
    larger: buttonStyles.size.larger,
    large: buttonStyles.size.large,
    regular: buttonStyles.size.regular,
    small: buttonStyles.size.small,
    noPadding: buttonStyles.size.noPadding,
    /**
     * Only used in Pagination.
     * Not meant for general use.
     */
    pagination: buttonStyles.size.pagination,
  };
  const iconSizeStyles: any = {
    larger: buttonStyles.size.icon.larger,
    large: buttonStyles.size.icon.large,
    regular: buttonStyles.size.icon.regular,
    small: buttonStyles.size.icon.small,
  };
  const iconStyle = buttonStyles.icon[size];
  const layoutStyles: any = {
    primary: buttonStyles.primary.base,
    secondary: buttonStyles.secondary.base,
    outline: buttonStyles.outline.base,
    link: isBgPrimary ? buttonStyles.link.basePrimary : buttonStyles.link.base,
  };
  const activeStyles: any = {
    primary: buttonStyles.primary.active,
    secondary: buttonStyles.secondary.active,
    outline: buttonStyles.outline.active,
    link: isBgPrimary ? buttonStyles.link.activePrimary : buttonStyles.link.active,
  };
  const disabledStyles: any = {
    primary: buttonStyles.primary.disabled,
    secondary: buttonStyles.secondary.disabled,
    outline: buttonStyles.outline.disabled,
    link: buttonStyles.link.disabled,
  };

  /**
   * Only used in DropdownItem.
   * Not meant for general use.
   */
  const dropdownItemStyle = buttonStyles.dropdownItem.base;

  const cls =
    layout === '__dropdownItem'
      ? classNames(dropdownItemStyle, className)
      : classNames(
          layout === 'link' ? layoutStyles['link'] : baseStyle,
          // has icon but no children
          hasIcon() && !children && iconSizeStyles[size],
          // has icon and children
          hasIcon() && children && sizeStyles[size],
          // does not have icon
          !hasIcon() && sizeStyles[size],
          layoutStyles[layout],
          disabled ? disabledStyles[layout] : activeStyles[layout],
          block ? blockStyle : null,
          className,
          contrastCorrectionTextClass,
        );

  const iconLeftStyles = classNames(iconStyle, children ? buttonStyles.icon.left : '');
  const iconRightStyles = classNames(iconStyle, children ? buttonStyles.icon.right : '');

  const clickHandler = (e: React.BaseSyntheticEvent<object, any, any>) => {
    if (layout === 'link') {
      e.currentTarget.blur();
    }
    onClick && onClick(e);
  };

  const setRef = (node: HTMLDivElement | null) => {
    localRef.current = node;
    if (typeof ref === 'function') {
      ref(node);
    } else if (ref) {
      ref.current = node;
    }
  };

  return (
    <Component className={cls} ref={setRef} disabled={disabled} {...other} onClick={clickHandler}>
      {(icon || iconLeft) && <IconLeft className={iconLeftStyles} aria-hidden="true" />}
      {loading ? <Loading size="xsm" /> : children}
      {iconRight && <IconRight className={iconRightStyles} aria-hidden="true" />}
    </Component>
  );
});

export default Button;
